.discover-main {
    height: 100vh;
    background-color: #EBFAFE;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.discover-main .site-header-lower {
    display: none;
}

.discover-main .new-home-2-new {
    margin-top: -3px;
    background-color: #EBFAFE;
}

.discover-checklist li {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

.discover-checklist li::before {
    background-image:url('../img/discover/icon-check.png');
    width: 21px;
    height: 21px;
    top: 4px;
    margin-top: 0;
}

.discover-play-img img {
    width: 142px;
}

.discover-main .home-1-right-col-new-2 {
    backdrop-filter: blur(4px) brightness(0.74);
    background-color: rgba(8, 86, 154, .77);
}

.discover-main .new-banner-img {
    width: 304px;
    position: absolute;
    left: -150px;
    top: -112px;
}

.discover-2 {
    background-color: #EBFAFE;
    padding-bottom: 4rem;
}

/* Footer */
.discover-main .footer {
    background: transparent !important;
    padding:0 !important;
}

.discover-main .footer-upper {
    display: none !important;
}

.discover-main .footer-lower {
    background: #dcebf0 !important;
    margin-top: 0;
}

.discover-main .footer-copyright li a,
.discover-main .footer-copyright li::after,
.discover-main .footer-copyright li {
    color: #87969d !important; 
}

@media (max-width:1199px) {
    .discover-main .new-home-2-new {
        margin-top: 33px;
    }
    
    .discover-main .new-home-2-new {
        padding-top: 2rem;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .discover-main .new-banner-img {
        left: 0;
        top: 0;
        margin: 0;
        position: relative;
        margin: 0 auto;
        margin-top: 0px;
        margin-top: -6rem;
    }

    .discover-checklist li {
        font-size: 17px;
    }

    .discover-logo-img-wrap {
        margin-top: -6rem;
        margin-bottom: 1rem !important;
    }

    .discover-play-img img {
        width: 125px;
    }

    .discover-main .new-home-2-new {
        padding-top: 4rem;
        padding-bottom: 0;
    }

    .discover-main {
        display: block;
    }
}
/* END 910px */

@media (max-width:600px) {
    .discover-main .home-1-right-col-new-2 {
        max-width: 320px !important;
        height: 320px !important;
    }

    .discover-logo-img-wrap {
        margin-top: -3rem;
        margin-bottom: 0.5rem !important;
    }

    .discover-main .new-home-2-new {
        margin-top: 27px;
    }

    .discover-logo-img-wrap img {
        width: 66px;
    }

    .discover-main .new-banner-img {
        width: 196px;
    }

    .discover-checklist li {
        font-size: 13px;
        margin-bottom: 0.25rem;
    }

    .discover-main .new-banner-img {
        margin-top: 0;
    }

    .discover-play-img img {
        width: 75px;
    }

    .discover-main .double-underline-heading::after {
        width: 150px;
        margin-top: 0;
    }

    .discover-main  .home-1-right-col-new-2 .ul-checklist {
        padding-left: 1rem;
        margin-top: 0.25rem;
    }

    .discover-main .new-banner-img {
        margin-top: -3.5rem;
    }

    .discover-play-img {
        margin-top: 0.25rem;
    }

    .discover-main .new-home-2-new {
        padding-top: 3rem;
    }

    .discover-2 {
        padding-bottom: 2rem;
    }

    .discover-main .home-3-btn-wrap {
        margin-top: 3rem !important;
    }
}
/* END 600px */