/* First Section */
.banner-section {
  margin-top: 159px;
}

/* Container */
.container {
  max-width: 1280px !important;
  margin:0 auto !important;
}

/* Image */
img {
  max-width: 100%;
  height: auto;
}

/* BG Colors */
.white-bg {
  background-color: #fff !important;
}

/* Gradients */
.gradient-bg {
  background: rgb(27,36,106);
  background: linear-gradient(90deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%);
}

/* Fonts */
.font-1 {
  font-family: "Poppins", sans-serif;
}

/* Font Size */
.v2-font-14 {
  font-size: 14px !important;
}

.v2-font-15 {
  font-size: 15px !important;
}

.v2-font-16 {
  font-size: 16px !important;
}

.v2-font-18 {
  font-size: 18px !important;
}

.v2-font-21 {
  font-size: 21px !important;
}

.v2-font-22 {
  font-size: 22px !important;
}

.v2-font-31 {
  font-size: 31px !important;
}

.v2-font-35 {
  font-size: 35px !important;
}

/* Font Weights */
.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

/* Font Color */
.white-text {
  color:#fff !important;
}

.orange-text {
  color: #ff880c !important;
}

/* Line Height */
.lh-1-3 {
  line-height: 1.3 !important;
}

/* Letter Spacing */
.ls-3 {
  letter-spacing: 3px !important;
}

/* Paddings */
.v2-py-0-5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

/* Margins */
/* Margin Horizontal */
.v2-mx-0-25 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.v2-mx-0-5 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

/* Margin Top */
.v2-mt-1-5 {
  margin-top: 1.5rem !important;
}

/* Form Inputs */
.modal {
  background: rgba(32,37,70,.67) !important
}

.video-wrap {
  cursor: pointer;
}

.wcp-input-wrap {
  margin-top: 1rem;
}

.wcp-input-wrap input {
  border-radius: 50px;
  border: 1px solid #999;
  padding: 0 1.5rem;
  height: 40px;
  font-size: 14px;
  width: 100%;
}

.wcp-body {
  margin-top: 2rem;
}

@media (max-width:1199px) {
  /* Fonts */
	.v2-tl-font-17 {
		font-size: 17px !important;
	}

  .v2-tl-font-16 {
		font-size: 16px !important;
	}
} 
/* END 1199px */

@media (max-width:910px) {

}
/* END 910px */

@media (max-width:600px) {
  /* Fonts */
  .v2-m-font-12 {
    font-size: 12px !important;
  }

  /* Letter spacings */
  .v2-m-ls-1 {
    letter-spacing: 1px !important;
  }

  .nft-1 {
    margin-top: 137px;
  }

  .v2-m-font-24 {
		font-size:24px !important;
	}

  .leaderboard-tbl-body-row > div {
    text-align: right;
  }

  .leaderboard-tbl-body-row > div::before {
    text-align: left;
  }
}
/* END 600px */