.v2-tools-3-col-5 .img-wrap {
    background-color: #1068A2;
    border: 1px solid #6DC9DC;
    border-radius: 30px;
    padding: 2rem;
    margin-top: 1rem;
}

@media (max-width:910px) {
    .tools-3-col .img-wrap {
        max-width: 100% !important;
    }

    .tools-3-col-3 .col {
        max-width: 50% !important;
        flex: 0 0 50% !important;
    }
}
/* END 910px */

@media (max-width:600px) {
    .tools-3-col-3 .col {
        padding: 0 0.25rem;
    }

    .v2-tools-3-col-5 .img-wrap {
        padding:1rem;
    }

    .tools-3-row {
        margin-top: 3rem !important;
    }
}   
/* END 600px */