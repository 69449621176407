.v2-hiw-1 {
    background: no-repeat center url('../img/how-it-works-2/bg-01-new.jpg');
    background-size: 100% 100%;
}

.v2-hiw-step {
    background-color: #C11D52;
    border-radius: 5px;
    font-size: 28px !important;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    display: inline-flex;
    color: #fff !important;
    margin:0;
    padding: 0.5rem 1.5rem;
}

.v2-hiw-text-line {
    position: relative;
}

.v2-hiw-text-line {
    content: '';
    position: static;
    left: 0;
    bottom: 0;
    display: inline-block;
    background: no-repeat center bottom url('../img/how-it-works-2/img-text-line.png');
    background-size: contain;
    padding-bottom: 0.5rem;
}

.v2-hiw-1-box {
    background: rgba(17, 24, 78, .18);
    border-radius: 15px;
}

.v2-hiw-1-text {
    padding: 1rem 7rem 4rem;
}

/* Section 2 */
.v2-hiw-2 {
    background: rgb(226,248,255);
    background: linear-gradient(180deg, rgba(226,248,255,1) 0%, rgba(255,255,255,1) 100%); 
}

.v2-hiw-2-box {
    background: rgb(27,36,106);
    background: linear-gradient(201deg, rgba(27,36,106,1) 0%, rgba(42,120,175,1) 100%);
    border-radius: 25px;
}

.v2-hiw-2-box-body {
    padding:2rem 5rem 2rem;
}

/* Section 2-2 */
.v2-hiw-2-box-2 {
    background: no-repeat center #1B246A url('../img/how-it-works-2/bg-appreciable.png');
    background-size: cover;
    border-radius: 20px;
    margin-bottom: -8rem;
    position: relative;
    z-index: 2;
}

.v2-hiw-2-box-2-ul li {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    line-height: 1.2;
    margin-bottom: 1.5rem;
}

.v2-hiw-2-box-2 {
    padding-left: 50% !important;
    padding-top: 6rem;
    padding-bottom: 4rem;
}

/* Section 3 */
.v2-hiw-3 {
    background: rgb(226,248,255);
    background: linear-gradient(180deg, rgba(234,250,255,1) 0%, rgba(255,255,255,1) 100%); 
}

.v2-hiw-3-curve::before {
    background: no-repeat center url('../img/how-it-works-2/top-curve-3.jpg');
    background-size: cover;
    width: 100%;
    height: 169px;
}

.v2-hiw-3-box {
    background-color: #EAF7FB;
    border: 1px solid #92CFE1;
    border-radius: 30px;
    padding: 0 4rem 4rem 4rem;
    height: 100%;
}

.v2-hiw-3-box img {
    background-color: #fff;
    border-radius: 10px;
}

.v2-hiw-3-subheading {
    display: inline-flex;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    background: rgb(27,36,106);
    background: linear-gradient(270deg, rgba(27,36,106,1) 0%, rgba(52,184,228,1) 100%);
    border-radius: 50px;
    text-transform: uppercase;
    padding: 0.75rem 4rem;
    min-width: 290px;
    text-align: center;
    justify-content: center;
}

.v2-hiw-3-subheading-wrap {
    text-align: center;
    margin-top: -28px;
}

.v2-hiw-2-coming-soon {
    background-color: #C11D52;
    border-radius: 3px;
    font-size: 7px;
    position: absolute;
    right: 0;
    bottom: -5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    padding:0.15rem 0.75rem;
}

.v2-hiw-2-coming-soon-top {
    bottom: auto;
    right: auto;
    left: 0;
    top:-10px;
}

@media (max-width:1199px) {
    .v2-hiw-1 {
        background-size: cover;
    }

    .v2-hiw-step {
        font-size: 24px !important;
    }

    .v2-hiw-2-box-2-ul li {
        font-size: 20px;
    }

    .v2-hiw-3-subheading {
        font-size: 20px;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .v2-hiw-step {
        margin-top: -24px !important;
    }

    .v2-hiw-2-box-2-ul li {
        font-size: 18px;
    }

    .v2-hiw-2-box-2 {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }
}
/* END 910px */

@media (max-width:600px) {
    .v2-hiw-1-text {
        padding: 1rem 3rem 3rem;
    }

    .v2-hiw-1 {
        background-image: url('../img/how-it-works-2/bg-banner-mobile.jpg');
        padding-bottom: 6rem !important;
    }

    .v2-hiw-2-box-body {
        padding: 1rem 3rem 3rem 3rem;
    }

    .v2-hiw-2-box-2 {
        background-image:url('../img/how-it-works-2/bg-appreciable-mobile.png');
        padding:2rem !important;
    }

    .v2-hiw-2-box-2-ul li {
        font-size: 16px;
    }

    .v2-hiw-3-subheading {
        min-width: 1px;
        line-height: 1.2;
        font-size: 16px;
        padding: 1rem 3rem;
    }

    .v2-hiw-3-box {
		padding: 0 2rem 2rem 2rem;
	}
}
/* END 600px */