.login-modal .modal-header {
    border: 0;
    background: #fdfdfd;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 15px;
    display: block;
    position: relative;
    padding: 1.5rem !important;
    padding-bottom: 0 !important;
    text-align: center;
}

.login-modal .modal-header .btn-close {
    position: absolute;
    right: 16px;
    top: 16px;
    border: 0;
    outline: 0;
    box-shadow: unset;
}

.login-modal .modal-content {
    border:0;
    border-radius: 15px;
}

.login-modal .modal-title {
    text-align: center;
    color: #1b246a !important;
    font-size: 25px !important;
    text-transform: uppercase;
    display: inline-flex;
    border-bottom: 4px solid #d51f5e !important;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.login-modal form button {
    font-size: 18px !important;
    background: #f69139 !important;
    border-radius: 49px !important;
    opacity: 1;
    width: auto;
    font-weight: 700 !important;
    margin-top: 0.25rem;
    text-transform: uppercase;
    height: 51px;
    transition: all .3s ease;
    width: 100%;
    border-color: transparent !important;
    font-family: 'Poppins',sans-serif;
    color: #fff !important;
}

.login-modal form button:disabled {
    background-color: gray !important;
    color: lightgray !important;
    cursor: not-allowed;
}

.login-modal form input {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid #bebebe;
    border-radius: 39px;
    padding: 0 !important;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    height: 50px;
    padding-left: 3.25rem !important;
    padding-right: 1.5rem !important;
}

.login-modal.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.login-modal .modal-dialog {
    width: 100%;
}

.login-modal .remember-me label {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}

.login-modal .remember-me input {
    width: auto;
    height: auto;
    margin-right: 0.5rem;
    outline: 0 !important;
}

.login-modal .remember-me {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 14px;
    margin-top: 1.5rem;
}

.login-modal .form-row {
    margin-top: 1.2rem;
}

.login-modal .modal-body {
    padding:2rem;
}

#login-form .password-row {
    position: relative;
}

#login-form .password-row:before {
    content: '';
    background: no-repeat center url('../img/login-modal/icon-password.png');
    background-size: contain;
    display: block;
    width: 17px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    top: 50%;
    margin-top: -11px;
    left: 22px;
}

#login-form .form-row {
    position: relative;
}

#login-form .PhoneInputCountry {
    position: absolute;
    left: 50px;
    top: 50%;
    z-index: 1;
    margin-top: -8px;
}

#login-form .phone-row {
    position: relative;
}

#login-form .phone-row::before {
    content: '';
    background: no-repeat center url('../img/login-modal/icon-phone.png');
    background-size: contain;
    display: block;
    width: 17px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    top: 50%;
    margin-top: -11px;
    left: 22px;
}

.login-modal .phone-row input {
    padding-left: 5.8rem !important;
}

.login-modal .phone-row .form-control {
    height: 50px;
    border-radius: 50px;
    width: 100%;
}

.login-modal .react-tel-input .flag-dropdown {
    left: 45px;
    padding: 0;
    border:0;
    border-radius: 0;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    background: 0;
}

.react-tel-input .country-list .search-box {
    height: auto !important;
    padding: 3px 8px 5px !important;
    font-size: 14px !important;
}

.react-tel-input .country-list .search {
    display: flex;
    align-items: center;
    padding-right: 6px;
}

@media (max-width:600px) {
    .login-modal .modal-body {
        padding:1.5rem;
    }

    .login-modal .modal-header .btn-close {
        font-size: 18px;
    }

    .login-modal .modal-title {
        font-size: 22px !important;
    }
}
/* END 600px */