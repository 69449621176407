/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

@media (min-width: 1200px) {
	.h2, h2 {
		font-size: 36px !important;
	}
}

/* Animations */
@keyframes slideDown {
  from {
    top: -132px;
  }
  to {
    top: 0px;
    position: fixed;
  }
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.loader-icon {
	animation: spin 1s infinite linear;
}

/* Default */
html,
body {
  overflow-x: unset;
}

body {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}

/* Max Widths */
.v2-mw-1000 {
	max-width:1000px !important;
}

.v2-mw-1100 {
	max-width:1100px !important;
}

/* Paddings */
.px-8 {
	padding-left:8rem !important;
	padding-right:8rem !important;
}

html body .py-4 {
	padding-top:4rem !important;
	padding-bottom:4rem !important;
}

.pt-14 {
	padding-top:14rem !important;
}

.pt-16 {
	padding-top:16rem !important;
}

.relative {
	position:relative;
}

.z-index-2 {
	z-index:2;
}

.z-index-3 {
	z-index:3;
}

.content-flex-end {
	justify-content:flex-end !important;
}

/* Fonts */
.v2-font-15 {
	font-size:15px !important;
}

.v2-font-16 {
	font-size:16px !important;
}

.v2-font-17 {
	font-size:17px !important;
}

.v2-font-18,
.v2-font-18 li {
	font-size:18px !important;
}

.v2-font-20 {
	font-size:20px !important;
}

.v2-font-22 {
	font-size:22px !important;
}

.v2-font-23 {
	font-size:23px !important;
}

.v2-font-24 {
	font-size:24px !important;
}

.v2-font-27 {
	font-size:27px !important;
}

.v2-font-28 {
	font-size:28px !important;
}

.v2-font-30 {
	font-size:30px !important;
}

.v2-font-32 {
	font-size:32px !important;
}

.v2-font-26 {
	font-size:26px !important;
}

.v2-font-36 {
	font-size:36px !important;
}

.v2-font-47 {
	font-size:47px !important;
}

/* Max Width */
.v2-mw-50 {
	max-width:50% !important;
}

/* Margin LEft */
.v2-ml--4 {
	margin-left:-4rem !important;
}

/* Margins */
.v2-mt--14 {
	margin-top:-14rem !important;
}

.v2-mt--10 {
	margin-top:-10rem !important;
}

.v2-mt--4 {
	margin-top:-4rem !important;
}

.v2-mt--2 {
	margin-top:-2rem !important;
}

.v2-mt-0 {
	margin-top:0rem !important;
}

.v2-mt-0-5 {
	margin-top:0.5rem !important;
}

.v2-mt-1 {
	margin-top:1rem !important;
}

.v2-mt-1-5 {
	margin-top:1.5rem !important;
}

.v2-mt-2 {
	margin-top:2rem !important;
}

.v2-mt-3 {
	margin-top:3rem !important;
}

.v2-mt-4 {
	margin-top:4rem !important;
}

.v2-mt-6 {
	margin-top:6rem !important;
}

/* Margin Right */
.v2-mr--20 {
	margin-right:-20rem !important;
}

.v2-mr--10 {
	margin-right:-10rem !important;
}

.v2-mr--24 {
	margin-right:-24rem !important;
}

/* Margin Bottom */
.v2-mb--12 {
	margin-bottom:-12rem !important;
}

.v2-mb-0 {
	margin-bottom: 0 !important;
}

.v2-mb-0-75 {
	margin-bottom: 0.75rem !important;
}

.v2-mb-1 {
	margin-bottom:1rem !important;
}

/* Paddings */
.v2-pt-1-5 {
	padding-top:1.5rem !important;
}

.v2-pt-2 {
	padding-top:2rem !important;
}

.v2-pt-3 {
	padding-top:3rem !important;
}

.v2-pt-4 {
	padding-top:4rem !important;
}

.v2-pt-8 {
	padding-top:8rem !important;
}

.v2-pt-12 {
	padding-top:12rem !important;
}

html body .v2-pb-0 {
	padding-bottom:0rem !important;
}

html body .v2-pb-1 {
	padding-bottom:1rem !important;
}

html body .v2-pb-2 {
	padding-bottom:2rem !important;
}

html body .v2-pb-3 {
	padding-bottom:3rem !important;
}

.v2-pb-5 {
	padding-bottom:5rem !important;
}

.v2-pb-6 {
	padding-bottom:6rem !important;
}

.v2-pb-8 {
	padding-bottom:8rem !important;
}

.v2-pb-12 {
	padding-bottom:12rem !important;
}

.v2-pr-4 {
	padding-right:4rem !important;
}

/* Padding Left */
.v2-pl-2 {
	padding-left: 2rem !important;
}

section {
  background: no-repeat center;
  background-size: cover;
}

.align-center {
  align-items: center;
}

.justify-center {
	justify-content:center;
}

.relative {
	position:relative;
}

.flex {
	display:flex;
}

.row-wrap {
	flex-flow:row wrap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 700 !important;
  color: #3E3E3E;
  text-transform: none;
	margin-bottom:20px;
}

h1,
h2,
h3 {
  font-size: 36px;
  color: #3e3e3e !important;
  margin-bottom: 20px !important;
}

p {
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 17px;
	color: #4b4b4b;
	line-height: 1.5;
	font-weight: 300;
}

dl,
ol,
ul {
  margin-bottom: 20px;
}

.container {
  max-width: 1280px;
}

a {
  transition: all 0.3s ease;
  text-decoration: none !important;
  color: #ff880c;
}

a:hover {
  color: #f69f54;
  text-decoration: none;
}

b, strong {
	font-weight:700 !important;
}

img {
  max-width: 100%;
  height: auto;
  transition: all 0.3s ease;
}

/* Max Widths */
.v2-mw-70 {
	max-width:70% !important;
}

.v2-mw-90 {
	max-width:90% !important;
}

.v2-mw-1200 {
	max-width:1200px !important;
}

/* Display */
.inline-block {
  display: inline-block;
}

.source-sans-pro {
	font-family: 'Source Sans Pro', sans-serif !important;
}

.green-text {
	color: #4DA92E !important;
}

.dark-text {
  color: #363636 !important;
}

.dark-text-2 {
  color: #3e3e3e !important;
}

.dark-text-3 {
	color: #414141 !important;
}

.dark-text-4 {
	color: #686868 !important;
}

.dark-text-5 {
	color: #393939 !important;
}

.light-gray-text {
  color: #aeaeae !important;
}

.light-gray-text-2 {
  color: #c1c1c1 !important;
}

.gray-text {
  color: #7e7e7e !important;
}

.gray-text-2,
.gray-text-2 li {
 color: #8a8a8a !important;
}

.gray-text-3 {
 color: #a2a2a2 !important;
}

.gray-text-4 {
	color: #8A8A8A !important;
}

.gray-text-5 {
	color:#888 !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-30 {
  font-size: 28px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-36 {
  font-size: 26px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-49 {
  font-size: 49px !important;
}

.font-51 {
  font-size: 51px !important;
}

.font-53 {
  font-size: 43px !important;
}

.font-71 {
  font-size: 61px !important;
}

.font-73 {
  font-size: 63px !important;
}

.font-81 {
  font-size: 81px !important;
}

.justify-left {
	justify-content:flex-start !important;
}

.video-img-wrap {
	border:15px solid #fff;
	box-shadow:0 14px 66px rgba(43, 103, 148, .16);
	display:inline-block;
	margin-top:15px;
}

.mb-1 {
	margin-bottom:1rem !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-12 {
	padding-top:12rem !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

html body .pb-2 {
	padding-bottom:2rem !important;
}

.pb-4 {
	padding-bottom:4rem !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.display-block {
  display: block;
}

form [type='button'],
form [type='reset'],
form [type='submit'] {
  height: 67px;
	background: rgb(238,163,61);
	background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%);
	border: 0;
  color: #fff;
  border: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 100%;
  text-transform: uppercase;
  border-radius: 50px;
  font-size: 25px;
	transition:all 0.3s ease;
}

form [type='button']:hover,
form [type='reset']:hover,
form [type='submit']:hover,
form button:hover {
  transform:scale(1.01);
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #fb9123;
  border: 0;
}

.img-wrap {
  margin-bottom: 20px;
}

.modal-dialog {
  max-width: 768px;
}

html body div.wpforms-container-full .wpforms-form input[type='text'],
html body div.wpforms-container-full .wpforms-form input[type='email'],
.form-control,
html
  body
  div.wpforms-container-full
  .wpforms-form
  textarea.wpforms-field-medium {
  height: 60px;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #4b4b4b;
  font-size: 16px;
  border: 1px solid #bebebe;
  width: 100%;
  display: block;
  max-width: 100%;
}

html body div.wpforms-container-full .wpforms-form .wpforms-field-label {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: 500;
}

html body div.wpforms-container-full .wpforms-form button[type='submit'] {
  height: 55px;
	background: rgb(238,163,61);
	background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%);
  color: #fff;
  border: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  width: auto;
  text-transform: uppercase;
  border-radius: 50px;
  font-size: 19px;
  letter-spacing: 2px;
  padding-left: 50px;
  padding-right: 50px;
  transition: all 0.3s ease;
}

html body div.wpforms-container-full .wpforms-form button[type='submit']:hover {
	transform:scale(1.02);
}

textarea.form-control,
html
  body
  div.wpforms-container-full
  .wpforms-form
  textarea.wpforms-field-medium {
  height: 212px;
  border-radius: 30px;
  padding-top: 15px;
}

.modal-close {
  font-size: 20px;
  color: #000;
}

.modal-body {
  padding: 15px 0 30px 0;
}

.modal-header {
  display: block;
  padding: 0;
  border: 0;
}

.modal-content {
  border-radius: 20px;
  border: 0;
}

.modal-header h2 {
  font-size: 35px;
  text-align: center;
  margin: 0;
  padding-top: 60px;
  color: #194fa6;
}

.modal-header .close {
  right: 15px;
  top: 15px;
  position: absolute;
  font-size: 22px;
  color: #999;
}

.modal-header .close:hover {
  color: #000;
}

.form-check-label {
  cursor: pointer;
}

.red-text {
  color: red;
}

.map-wrap iframe {
  display: block;
  height: 300px;
  width: 100%;
}

/* Global */
.poppins {
	font-family:'Poppins', sans-serif;	
}

.hand-of-sean-demo {
	font-family:'Hand of Sean Demo', sans-serif;	
}

.n-font-13 {
	font-size:13px !important;
}

.n-font-14 {
	font-size:14px !important;
}

.n-font-15 {
	font-size:15px !important;
}

.n-font-16 {
	font-size:16px !important;
}

.n-font-18 {
	font-size:18px !important;
}

.n-font-19 {
	font-size:19px !important;
}

.n-font-20 {
	font-size:20px !important;
}

.n-font-21 {
	font-size:21px !important;
}

.n-font-22 {
	font-size:22px !important;
}

.n-font-23 {
	font-size:23px !important;
}

.n-font-25 {
	font-size:25px !important;
}

.n-font-26 {
	font-size:20px !important;
}

.n-font-27 {
	font-size:27px !important;
}

.n-font-28 {
	font-size:28px !important;
}

.n-font-29 {
	font-size:29px !important;
}

.n-font-30 {
	font-size:30px !important;
}

.n-font-31 {
	font-size:31px !important;
}

.n-font-33 {
	font-size:33px !important;
}

.n-font-32 {
	font-size:32px !important;
}

.n-font-35 {
	font-size:35px !important;
}

.n-font-36 {
	font-size:36px !important;
}

.n-font-38 {
	font-size:38px !important;
}

.n-font-39 {
	font-size:39px !important;
}

.n-font-40 {
	font-size:40px !important;
}

.n-font-41 {
	font-size:41px !important;
}

.n-font-42 {
	font-size:42px !important;
}

.n-font-43 {
	font-size:43px !important;
}

.n-font-44 {
	font-size:44px !important;
}

.n-font-45 {
	font-size:45px !important;
}

.n-font-48 {
	font-size:48px !important;
}

.n-font-50 {
	font-size:50px !important;
}

.n-font-55 {
	font-size:55px !important;
}

.font-36 {
	font-size:36px !important;
}

.font-63 {
	font-size:63px !important;
}

.font-95 {
	font-size:95px !important;
}

.font-110 {
	font-size:110px !important;
}

.font-126 {
	font-size:126px !important;
}

.p-t-3 {
	padding-top:3rem !important;
}

.w-white-double-underline {
	position:relative;
	display:inline-block;
}

.w-white-double-underline::after {
	content: '';
	display: block;
	/* background: no-repeat center url('/wp-content/uploads/2022/11/white-double-underline.png'); */
    background: no-repeat center url('');
	background-size: auto;
	background-size: contain;
	width: 198px;
	height: 15px;
	margin-top: 10px;
	margin: 10px auto 0 auto;
}

/* BG Gradient 1 */
.bg-gradient-1 {
	background: rgb(234, 250, 255);
	background: linear-gradient(180deg, rgb(234, 250, 255) 0%, rgb(239, 251, 252) 90%, rgba(219,255,235,0) 100%);
}

.mix-blend-mode-multiply {
	mix-blend-mode: multiply !important;
}

.white-bottom-curve {
  position: relative;
  padding-bottom: 60px;
}

.white-bottom-curve::after {
  content: '';
  width: 100%;
  height: 190px;
  /* ../img/img-otext-white-bottom-curve.png */
  background: no-repeat center url('');
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  bottom: -190px;
  z-index: 1;
}

.pink-bottom-curve {
  position: relative;
}

.pink-bottom-curve::after {
  content: '';
  /* ../img/img-otext-pink-bottom-curve.png */
  background: no-repeat center url('');
  width: 100%;
  height: 324px;
  display: block;
  position: absolute;
  left: 0;
  bottom: 70px;
}

.divider-ridge {
  height: 2px;
  /* ../img/img-divider-ridge.png */
  background: repeat-x center url('');
  background-size: contain;
  width: 100%;
  display: block;
}

.bg-gradient-blue {
  background: rgb(69, 143, 228);
  background: linear-gradient(
    90deg,
    rgba(69, 143, 228, 1) 0%,
    rgba(25, 79, 166, 1) 100%
  );
}

.w-blue-arrow {
  position: relative;
}

.w-blue-arrow::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 38px 0 38px;
  border-color: #2e6dc3 transparent transparent transparent;
  margin-left: -38px;
}

.transparent-wave {
  position: relative;
}

.transparent-wave::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  /* ../img/bg-transparent-wave-1.png */
  background: no-repeat center url('');
  background-size: 100% 100%;
  height: 211px;
  width: 100%;
  display: block;
}

.transparent-wave-1 {
  padding-bottom: 340px;
}

.transparent-wave-2 {
  padding-bottom: 280px;
}

.transparent-wave-2::after {
    /* ../img/bg-transparent-wave-2.png */
  background-image: url('');
  height: 214px;
}

.transparent-wave-3 {
  padding-bottom: 280px;
}

.transparent-wave-3::after {
    /* ../img/bg-transparent-wave-3.png */
  background-image: url('');
  height: 211px;
}

.faded-brown-bg {
  position: relative;
  background: rgb(255, 242, 213);
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 66.6%,
    rgba(249, 242, 229, 1) 100%
  );
}

.faded-brown-bg-2 {
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 85%,
    rgb(249, 242, 229) 100%
  );
}

.faded-brown-bg-3 {
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 40%,
    rgb(249, 242, 229) 100%
  );
}

.faded-brown-bg-4 {
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 95%,
    rgb(249, 242, 229) 100%
  );
}

.faded-brown-bg-5 {
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 85%,
    rgb(249, 242, 229) 100%
  );
}

.text-transform-none {
  text-transform: none;
}

.text-normal {
  text-transform: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-right {
	text-align:right;
}

.shadow-1 {
  box-shadow: 0 61px 89px rgba(0, 0, 0, 0.11);
}

.img-shadow-1 {
  filter: drop-shadow(12px 12px 24px rgba(0, 0, 0, 0.2));
}

.block {
	display:block;
}

.va-middle {
	vertical-align:middle;
}

.close {
  cursor: pointer;
}

.font-13 {
  font-size: 13px;
}

.font-15 {
  font-size: 15px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-19 {
  font-size: 19px;
}

.font-22 {
  font-size: 21px !important;
}

.font-23 {
  font-size: 21px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-26 {
  font-size: 26px;
}

.font-26 {
  font-size: 26px;
}

.font-25 {
  font-size: 25px;
}

.font-28 {
  font-size: 28px;
}

.font-29 {
  font-size: 29px;
}

.font-31 {
  font-size: 31px;
}

.font-32 {
  font-size: 32px;
}

.font-37 {
  font-size: 37px;
}

.font-38 {
  font-size: 38px;
}

.font-39 {
  font-size: 39px;
}

.font-41 {
  font-size: 41px;
}

.font-42 {
  font-size: 42px;
}

.font-43 {
  font-size: 43px;
}

.font-44 {
  font-size: 44px;
}

.font-51 {
  font-size: 51px;
}

.font-50 {
  font-size: 50px;
}

.font-54 {
  font-size: 54px;
}

.font-55 {
  font-size: 55px;
}

.font-57 {
  font-size: 47px;
}

.font-65 {
  font-size: 60px;
}

.font-67 {
  font-size: 67px;
}

.font-97 {
  font-size: 97px;
}

.font-229 {
  font-size: 229px;
}

.font-256 {
  font-size: 256px;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600 !important;
}

html body .semi-bold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700;
}

.font-extra-bold {
  font-weight: 800 !important;
}

.italic {
	font-style:italic !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.double-underline {
	display:inline-block;
}

.double-underline::after {
	content:'';
	display: block;
	width:200px;
	height:22px;
    background: no-repeat center url('./assets/img/get-started/orange-double-underline.png');
	background-size:contain;
	margin-left:auto;
	margin-right:auto;
	margin-top:15px;
}

.double-underline-2::after {
	background-image: url('./assets/img/home/double-underline-2.png');
	width: 249px;
	height: 30px;
}

.double-underline-3::after {
	background-image: url('./assets/img/home/img-double-underline-3.png');
	width: 456px;
	height: 21px;
}

.double-underline-heading::after {
	margin-top:5px;
}

.double-underline-left::after {
	margin-left:0;
	margin-right:0;
	margin-top:15px;
}

.lh-1{
  line-height: 1;
}

.lh-1-1 {
  line-height: 1.1;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-3 {
  line-height: 1.3;
}

.lh-1-5 {
  line-height: 1.5;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-5 {
  letter-spacing: 5px;
}

.pr-3 {
	padding-right:3em;
}

.mw-45 {
	max-width:45%;
}

.mx-auto {
	margin-left:auto !important;
	margin-right: auto !important;
}

.white-text {
  color: #fff !important;
}

.white-text li {
	color:#fff !important;
}

.dark-text {
  color: #363636;
}

.dark-text-2 {
	color: #3E3E3E;
}

.dark-text-3 {
	color: #4B4B4B;
}

.dark-text-5 {
  color: #3e3e3e;
}

.dark-text-6 {
	color:#4b4b4b;
}

.dark-text-7 {
	color:#676767;
}

.dark-text-8 {
	color:#393939;
}

.pink-text-2 {
  color: #d6225e !important;
}

.violet-text {
  color: #1d266b;
}

.blue-text {
  color: #1b246a !important;
}

.light-blue-text {
  color: #427cdb !important;
}

.blue-text-2 {
  color: #1b246a;
}

.blue-text-3 {
  color: #31A7D6;
}

.blue-text-4 {
  color: #1E92C1;
}

.blue-text-5 {
  color: #36c3ed;
}

.blue-text-6 {
  color: #2C4D9A;
}

.dark-blue {
  color: #142e47 !important;
}

.dark-blue-2 {
  color: #1D266B !important;
}

.dark-blue-3 {
	color:#1A1660 !important;
}

.orange-text {
  color: #ff880c;
}

.orange-text-2 {
  color: #F69F54;
}

.orange-text-3 {
	color: #EEA33D;
}

.orange-text-4 {
	color: #F49334;
}

.font-weight-medium,
.medium {
  font-weight: 500;
}

.regular {
	font-weight:400;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.site-btn {
	display: inline-block;
	color: #fff !important;
	font-size: 20px;
	font-weight: 500;
	border: 2px solid transparent;
	border-radius: 100px;
	padding: 16px 28px;
	line-height: 1;
	min-width: 250px;
	text-align: center;
	text-decoration: none !important;
	letter-spacing: 1px;
	transition: all 0.3s ease;
	text-transform:uppercase;
	outline:0;
}

.site-btn i {
  margin-left: 5px;
}

.site-btn-2 {
	background: rgb(238,163,61);
	background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%); 
	border:0;
}

.site-btn:hover {
  transform: scale(1.01);
}

.site-btn-2:hover {
  box-shadow: 0 1px 1px 1px  rgb(238,163,61);
}

.site-btn-3 {
  color: #ff880c !important;
}

.site-btn-3:hover {
  color: #fff !important;
}

.site-btn-4 {
  text-transform: uppercase;
  font-size: 38px;
}

.site-btn-5 {
  min-width: 316px;
}

.site-btn-6 {
  min-width: 316px;
  background: #ffffff !important;
  color: #ff880c !important;
  text-transform: uppercase;
  font-size: 38px;
}

.site-btn-6:hover {
  transform: scale(1.1);
  background: #ffffff !important;
  color: #ff880c !important;
}

.site-btn-7 {
  min-width: 538px;
  background: #ffffff !important;
  color: #ff880c !important;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 800;
}

.site-btn-7:hover {
  transform: scale(1.1);
  background: #ffffff !important;
  color: #ff880c !important;
}

.w-top-wave {
  position: relative;
}

.w-top-wave::before {
  content: '';
  position: absolute;
  left: 0;
  top: -160px;
  width: 100%;
  height: 435px;
  display: block;
  background-size: 100% 100%;
  background-position: 100% 100%;
  /* ../img/bg-white-wave.png */
  background-image: url('');
}

.btn-close {
  cursor: pointer;
}

.w-brown-bg {
  background: rgb(255, 242, 213);
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 0%,
    rgba(249, 242, 229, 1) 100%
  );
}

.ul-checklist {
  padding: 0;
}

.ul-checklist li {
	list-style: none;
	font-size: 16px;
	font-weight: 400;
	color: #4b4b4b;
	position: relative;
	padding-left: 40px;
	margin-bottom: 1rem;
	text-align: left;
}

.ul-checklist li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 20px;
  height: 14px;
  background: no-repeat center url('./assets/img/nft/icon-orange-check.png');
  display: block;
	background-size:contain;
}

.ul-checklist-small li::before {
	width: 18px;
	height: 18px;
	top: 50%;
	margin-top: -9px;
}

.ul-checklist-small li {
	padding-left:30px;
	margin-bottom:20px;
}

.ul-checklist-2 {
  padding: 0;
}

.ul-checklist-2 li {
  list-style: none;
  font-size: 37px;
  font-weight: 800;
  color: #363636;
  position: relative;
  padding-left: 75px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.ul-checklist-2 li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 48px;
  height: 44px;
  /* ../img/icon-checklist-2.png */
  background: no-repeat center url('');
  background-size: contain;
  display: block;
}

.ul-checklist-3 {
  padding: 0;
}

.ul-checklist-3 li {
  list-style: none;
  font-size: 25px;
  font-weight: 700;
  color: #194fa6;
  position: relative;
  padding-left: 70px;
  margin-bottom: 20px;
  text-transform: none;
  min-height: 43px;
}

.ul-checklist-3 li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 43px;
  height: 43px;
  /* ../img/icon-checklist-2.png */
  background: no-repeat center url('');
  background-size: contain;
  display: block;
}

.ul-checklist-3-with-p span {
  font-size: 29px;
  font-weight: 800;
  line-height: 1.2;
  display: block;
  margin-bottom: 10px;
}

.ul-checklist-3-with-p p {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

.ul-checklist-4 {
  padding: 0;
}

.ul-checklist-4 li {
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  color: #4b4b4b;
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
  text-transform: none;
  min-height: 1;
  line-height: 1.3;
}

.ul-checklist-4 li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 28px;
  height: 28px;
  /* ../img/icon-checklist-2.png */
  background: no-repeat center url('');
  background-size: contain;
  display: block;
}

.ul-checklist-big li::before {
	background-size: 100%;
	width: 25px;
	height: 17px;
}

.ul-checklist-big li {
	margin-bottom:0.5rem;
}

/* Site Header */
.site-header .container {
  max-width: 1280px;
  margin: 0 auto;
}

.site-header .row {
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.nav-up.site-header .row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.site-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: top 0.2s ease-in-out;
}

.nav-up {
	top: -138px;
	background: #fff;
	border-bottom: 5px solid #3b8921;
	z-index: 99;
}

.nav-down {
  background: #fff;
  border-bottom: 5px solid #3b8921;
  z-index: 99;
}

.nav-down .site-menu li a {
  color: #1d266b;
}

.site-header.sticky {
  position: fixed;
  background: #fff;
  border-bottom: 5px solid #ff880c;
  z-index: 99;
  animation: slideDown 0.3s linear;
}

.site-menu-mobile-btn {
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -12px !important;
	width: auto !important;
}

.site-menu-mobile-btn a {
  display: block;
}

.site-menu-mobile-btn a span {
  height: 3px;
  width: 36px;
  margin-bottom: 5px;
  background: #194fa6;
  display: block;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.site-menu-mobile-btn a:hover span {
  background: #ff880c;
}

/* Site Header Upper */
.site-header-upper {
	background: rgb(27,36,106);
	background: linear-gradient(90deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%); 
}

/* Site Header Lower */
.site-header-lower {
	background: #fff;
}

/* Site Logo */
.site-logo-col {
	max-width: 116px !important;
	flex: 0 0 116px !important;
	padding: 0 5px !important;
	transition:all 0.3s ease;
}

.site-logo a {
	display:block;
}

.site-logo img {
	mix-blend-mode: multiply !important;
	border-radius: 50%;
	border: 0;
}

/* Site Social Col */
.site-social-col {
  flex: 0 0 27%;
  text-align: right;
}

/* Site Menu */
.site-menu-col {
  flex: 1;
  text-align: center;
}

.site-menu {
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-evenly;
}

.site-menu li {
	display: inline-block;
	vertical-align: middle;
	font-size: 15px;
	color: #1B246A;
	font-weight: 400;
	text-transform: none;
	margin: 0 10px;
}

.site-menu li a {
  color: #1B246A;
  text-decoration: none;
  font-weight: 400;
}

.site-menu li a:hover {
  color: #3446d0;
}

.has-submenu {
  position: relative;
}

.has-submenu > a::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-left: 10px;
  color: #313131;
}

.submenu {
  position: absolute;
  left: 0;
  top: auto;
  background: #75a6f5;
  text-align: left;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
	width:200px;
}

.has-submenu:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.submenu li {
  display: block;
  padding: 0;
  margin: 0;
}

.submenu li a {
	display: block;
	color: #fff !important;
	padding: 10px 15px;
	border-bottom: 1px solid #93bcff;
	font-size: 12px;
}

.submenu li a:hover {
  background: #7fb0ff;
}

.submenu form {
	margin:0;
}

.submenu button {
	padding: 10px 15px !important;
	background: none;
	text-align: left;
	margin: 0 !important;
	width: auto !important;
	height: auto;
	font-size: 12px;
	text-transform: none !important;
	font-weight: 400;
	display: block !important;
	border-bottom: 1px solid rgb(147, 188, 255);
	border-radius: 0;
}

/* Site Translation Col */
.site-translation-col {
	flex:0 0 103px !important;
}

.site-translation-wrap {
	position:relative;
}

.site-translation-selected {
	display:flex;
	align-items: center;
	cursor:pointer;
}

.site-translation-selected-lang {
	font-size: 16px;
	font-weight: 400;
	color: #1B246A;
	margin-right: 8px;
}

.site-translation-selected-flag {
	width:32px;
	margin-right:10px;
	border: 1px solid #e6e6e6;
}

.site-translation-selected-down {
	font-size:8px;
}

/* Site Translation Options */
.site-translation-options {
	position: absolute;
	left: 0;
	top: auto;
	background: #2d8cbf;
	padding: 5px 0;
	margin: 0;
	width: 150px;
	border-radius: 3px;
	opacity:0;
	visibility:hidden;
	transition: all 0.3s ease;
	z-index:99;
}

.site-translation-options.active {
	opacity:1;
	visibility:visible;
}

.site-translation-options li {
	list-style-type: none !important;
	padding: 0 10px;
}

.site-translation-option-heading {
	text-align: center;
	color: #fff;
	font-size: 13px;
	font-weight: 400;
}

.site-translation-option-text {
	cursor:pointer;
	transition: all 0.3s ease;
}

.site-translation-option-text:hover {
	background: #329ad3;
}

.site-translation-option-text img {
	width:32px;
	margin-right:5px;
}

.site-translation-option-text span {
	color: #fff;
	font-size: 13px;
	font-weight: 300;
}

.site-translation-option-heading {
	margin-bottom:3px;
}

/* Site Social */
.site-social {
	padding: 0;
	margin: 0;
	text-align:right;
}

.site-social li {
  display: inline-block;
  padding: 0 1px;
}

.site-social li a {
	display: flex;
	width: 29px;
	height: 29px;
	background: #A0CBE2;
	border-radius: 50px;
	color: #2D8CBF !important;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	text-decoration: none !important;
}

.site-social li a:hover {
	background:#fff !important;
}

/* Site Social 2 */
.site-social-2 li a {
  width: 56px;
  height: 56px;
  background: #bdbfd4;
  color: #fff;
  font-size: 28px;
}

/* Home Banner */
.home-banner {
  background-size: cover;
  background-position: center;
  min-height: 1068px;
}

.home-banner-dl img {
  margin-right: 20px;
}

.home-banner-dl {
  margin-top: 40px;
}

.home-banner-heading {
  margin-top: 200px;
  font-weight: 700;
}

/* National Stores Section */
.home-display .row {
  padding: 60px 0;
  padding-bottom: 150px;
}

.home-display-slider-wrap {
  margin-top: 45px;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slide {
  padding: 0 15px;
}

.slick-slide img {
  margin: auto;
}

.home-display-slider img {
  filter: grayscale(100%);
}

.home-display-slider img:hover {
  filter: grayscale(0%);
}

.slick-arrow {
  width: 51px;
  height: 51px;
  background: no-repeat center !important;
  background-size: contain !important;
  text-indent: -999999px;
  z-index: 2;
	border-radius:50%;
}

.slick-arrow::before {
  display: none;
}

.slick-prev {
	background: #D6225E !important;
  left: -60px !important;
	width:100px !important;
	height:100px !important;
	display:flex;
	justify-content:flex-end;
	align-items:center;
	padding-right:15px;
	top:50% !important;
	margin-top:-50px !important;
}

.slick-prev::after {
	content:'';
	display:block;
	width:19px;
	height:33px;
    background:no-repeat center url('./assets/img/how-it-works/white-left-arrow-slider.png') !important;
	background-size:contain !important;
	opacity:1;
}

.slick-next {
	background: #D6225E !important;
  right: -60px !important;
	width:100px !important;
	height:100px !important;
	justify-content:flex-start;
	align-items:center;
	padding-left:15px;
	top:50% !important;
	margin-top:-50px !important;
}

.slick-next::after {
	content:'';
	display:block;
	width:19px;
	height:33px;
    background:no-repeat center url('./assets/img/how-it-works/white-right-arrow-slider.png') !important;
	background-size:contain !important;
	opacity:1;
}

/* All In One Section */
.home-all-in-one {
  min-height: 1205px;
  background: no-repeat;
  background-size: cover;
  background-position: center;
}

.all-in-one-lists {
  padding: 0;
  margin-top: 40px;
}

.all-in-one-lists li {
  width: 32.3%;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 20px;
  padding-right: 20px;
}

.home-all-in-one .col-sm {
  padding-left: 30%;
  left: 180px;
  position: relative;
}

.all-in-one-lists img:hover {
  opacity: 0.8;
}

.home-all-in-one-heading {
  margin-top: 30px;
}

/* Home 6 Reasons */
.home-6-reasons-video {
  margin-top: 45px;
}

.home-6-reasons-video {
  border: 20px solid #fff;
  width: 100%;
  height: 603px;
  background: no-repeat center #333;
  background-size: cover;
  box-shadow: 0 14px 66px rgba(43, 103, 148, 0.16);
  max-width: 1055px;
  margin: 0 auto;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;
}

.play-video-text {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 60px;
  margin-top: 20px;
}

.home-6-reasons {
  background: linear-gradient(
    180deg,
    rgba(255, 242, 213, 0) 0%,
    rgb(249, 242, 229) 100%
  );
  padding-top: 100px;
  padding-bottom: 260px;
}

.home-6-reasons-heading img {
  display: inline-block;
  vertical-align: top;
}

.home-6-reasons-lists img {
  margin-right: 20px;
}

.home-6-reasons-lists {
  max-width: 1093px;
  margin: 60px 0 0 180px;
  position: relative;
}

.home-6-reasons-cta .site-btn {
  margin: 0 10px;
}

.home-6-reasons-cta {
  margin-top: 60px;
  position: relative;
  z-index: 2;
}

.home-video-play {
  display: block;
  transition: all 0.3s ease;
}

.home-video-play:hover {
  transform: scale(1.1);
}

/* Owards Rewards Section */
.owards-rewards {
  position: relative;
}

.owards-rewards .container {
  max-width: 1350px;
  position: relative;
}

.owards-rewards::before {
  content: '';
  position: absolute;
  left: 0;
  top: -160px;
  width: 100%;
  height: 435px;
  display: block;
  background-size: 100% 100%;
  background-position: 100% 100%;
  /* ../img/bg-white-wave.png */
  background-image: url('');
}

.owards-cta {
  position: relative;
  z-index: 2;
}

.owards-cta a {
  color: #ff880c;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
}

.owards-cta a:hover {
  color: #1e57a3;
}

.rewards-row {
  display: flex !important;
  flex-flow: row wrap;
}

.rewards-row.slick-slide {
  padding-bottom: 120px;
  padding-top: 60px;
  padding-right: 85px;
  padding-left: 85px;
}

.rewards-wrap.slick-slider {
  margin-bottom: -110px;
  margin-top: -60px;
}

.rewards-wrap-mobile {
  display: none;
}

.rewards-wrap .slick-prev {
  left: 0;
}

.rewards-wrap .slick-next {
  right: 0;
}

.rewards-col {
  max-width: 25%;
  flex: 0 0 25%;
  text-align: center;
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  transition: all 0.3s ease;
  cursor: default;
}

.rewards-col::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(78, 153, 255, 0.39);
  max-width: 90%;
  left: 50%;
  margin-left: -45%;
}

.rewards-col-inner {
  border-right: 1px solid rgba(78, 153, 255, 0.39);
  padding-bottom: 15px;
}

.rewards-col-img {
  min-height: 175px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rewards-col img {
  filter: grayscale(100%);
  opacity: 0.51;
}

.rewards-percent {
  color: rgba(28, 71, 129, 0.65);
  font-size: 38px;
  font-weight: 800;
  margin: 0 0 8px 0;
  line-height: 1;
}

.rewards-text {
  color: #42474e;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.rewards-col:nth-of-type(4n + 4) .rewards-col-inner {
  border-right: 0;
}

.rewards-col:nth-of-type(5n)::after,
.rewards-col:nth-of-type(6n)::after,
.rewards-col:nth-of-type(7n)::after,
.rewards-col:nth-of-type(8n)::after {
  display: none;
}

.rewards-col:hover {
  transform: scale(1.1);
  background: #fff;
  border: 1px solid #a6cbe8;
  box-shadow: 0 40px 69px rgba(51, 133, 204, 0.24);
}

.rewards-col:hover::after {
  display: none;
}

.rewards-col:hover .rewards-col-inner {
  border-right: 0;
}

.rewards-col:hover img {
  filter: grayscale(0%);
  opacity: 1;
}

.rewards-col:hover .rewards-percent {
  color: #1c4781;
}

/* Home Marketing Solution */
.home-marketing-solution {
  background: no-repeat center;
  background-image: none;
  background-size: auto;
  background-size: cover;
  min-height: 1561px;
  padding-top: 200px;
}

.home-marketing-solution img {
  margin-right: 20px;
}

.home-marketing-solution .img-wrap {
  margin-bottom: 75px;
}

.home-marketing-solution-cta {
  margin-top: 60px;
}

/* Home Follow Signup */
.home-follow-signup {
  padding-top: 80px;
  padding-bottom: 100px;
}

/* Subscribe Form */
.subscribe-form,
.subscribe-form form {
  position: relative;
  width: 395px;
}

.subscribe-form input[type='text'],
html
  body
  div.wpforms-container-full.subscribe-form
  .wpforms-form
  input[type='email'] {
  height: 69px;
  border-radius: 50px;
  border: 1px solid #6a9ab9;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding-left: 30px;
  padding-right: 155px;
  width: 100%;
  font-size: 16px;
}

.subscribe-form [type='submit'] {
  width: 132px;
  position: absolute;
  top: 7px;
  right: 7px;
  height: 55px;
  font-size: 20px;
}

html
  body
  div.wpforms-container-full.subscribe-form
  .wpforms-form
  .wpforms-submit-container {
  width: 132px;
  position: absolute;
  top: 7px;
  right: 7px;
  height: 55px;
  font-size: 20px;
}

html
  body
  div.wpforms-container-full.subscribe-form
  .wpforms-form
  button[type='submit'] {
  height: auto !important;
  font-size: 20px;
  border: transparent !important;
}

.home-follow-signup-left {
  border-right: 2px solid #75a9c1;
  padding-right: 50px;
}

.home-follow-right {
  padding-left: 90px;
}

/* Footer */
.site-footer {
  /* background: no-repeat center transparent url('/wp-content/uploads/2022/10/bg-footer-new.png'); */
  background: no-repeat center transparent url('');
  background-size: 100% 100%;
  padding-top: 480px;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 2;
  position: relative;
}

.site-footer ul {
  list-style: none;
  padding: 0;
}

.site-footer ul li {
	font-size: 16px;
	font-weight: 300;
	color: #fff;
	margin-bottom: 10px;
}

.site-footer ul li a {
  color: #fff;
}

.site-footer ul li a:hover {
  color: #eea33d;
}

.site-footer .container {
  max-width: 1280px;
}

.footer-title {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.site-footer-heading {
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 23px;
  margin-bottom: 30px;
}

.site-footer-menu {
  padding: 0 !important;
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.site-footer-menu-full {
  width: 100%;
  display: block;
}

.site-footer-menu li {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 17px;
  list-style: none;
  margin-bottom: 15px;
  padding-right: 20px;
}

.site-footer-menu li a:hover {
  text-decoration: none !important;
  color: #f69f54;
}

.site-footer-col-1 {
  flex: 0 0 36%;
}

.site-footer-col-2 {
  flex: 0 0 27%;
}

.site-footer-col-3 {
  flex: 0 0 16%;
}

.site-footer-col-4 {
  text-align: center;
}

.footer-logo-ovato {
  margin-bottom: 0;
}

.footer-col-2 {
  padding-left: 60px !important;
}

.footer-col-3 {
  padding-left: 60px !important;
}

.footer-col-4-inner-1 {
  margin-bottom: 45px;
}

.footer-col-heading {
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	text-transform: uppercase;
	line-height:1.3;
	margin-bottom:20px;
}

.footer-col {
	max-width:20% !important;
	flex: 0 0 20% !important;
}

.footer-ovato-img {
	width: 279px;
	margin-left: -35px;
	margin-bottom: -15px;
	margin-top: -100px;
}

/* Footer Form */
html body .site-footer input {
	height: 45px !important;
	background: transparent !important;
	color: #fff !important;
	font-size: 15px !important;
	border: 1px solid #fff !important;
	font-weight: 300 !important;
}

html body .site-footer .wpforms-submit {
	height: 49px !important;
	padding: 0 !important;
	width: 100% !important;
	font-size: 18px !important;
	letter-spacing: 1px !important;
	font-family: Poppins, sans-serif !important;
	font-weight: 500 !important;
}

html body .site-footer .wpforms-container {
	margin-top:0 !important;
	margin-bottom:20px !important;
}

.footer-form-wrap .footer-col-heading {
	text-align:center;
	margin-bottom:5px;
}

.footer-form-wrap .wpforms-field {
	padding-bottom:0 !important;
}

.footer-form-wrap div.wpforms-container-full .wpforms-form label.wpforms-error {
	color:#fff !important;
	text-align:center !important;
	margin-top:5px !important;
}

/* Footer App DL */
.footer-app-dl {
	display:flex;
	flex-flow:row;
	justify-content: center;
}

.footer-app-dl li {
	margin:0 3px;
}

/* After Footer */
.after-footer {
  text-align: center;
  border-top: 1px solid #568cd2;
  max-width: 1303px;
  margin: 0 auto;
  padding-top: 40px;
  margin-top: 30px;
}

.after-footer p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* Site Footer Lower */
.site-footer-lower {
	background: rgba(0,0,0,0.09);
	margin-top:60px;
}

.site-footer-lower-ul {
	display:flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 20px 0 10px 0;
}

.site-footer-lower-ul li {
	font-size:15px;
}

.site-footer-lower-ul li::after {
	content: '|';
	color:#fff;
	margin:0 15px;
}

.site-footer-lower-ul li:last-of-type::after {
	display:none;
}
/* Scroll To Top */
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 99;
}

.scroll-to-top.active {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top a {
	display: flex;
	color: #fff;
	background-color: #eea33d;
	width: 60px;
	height: 60px;
	border-radius: 50px;
	text-align: center;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-size: 20px;
	outline:0;
}

.scroll-to-top a:hover {
	background-color: #d58211;
}

/* Mobile Menu BTN */
.site-menu-mobile-btn {
  display: none;
  width: auto;
}

/* Mobile Menu */
.mobile-menu .img-wrap {
  width: 200px;
  margin: 0;
}

.mobile-menu .offcanvas-body {
  padding: 0;
  background: #1B246A;
}

.mobile-site-menu {
  padding: 0;
  margin: 0;
  transform: scale(1) !important;
}

.mobile-site-menu li {
  list-style-type: none;
}

.mobile-site-menu li a {
	display: block;
	color: #fff;
	text-decoration: none;
	font-size: 16px !important;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	padding: 15px;
	text-transform: uppercase;
}

.mobile-site-menu li a:hover {
  background: #ff880c;
}

.mobile-submenu {
  padding: 0;
  display: none;
}

.mobile-has-submenu > a {
  position: relative;
  padding-right: 50px !important;
}

.mobile-has-submenu > a::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 12px;
}

.mobile-has-submenu > a.active::after {
  content: '\f077';
}

.mobile-submenu li a {
  padding-left: 40px;
}

.mobile-menu .site-social {
  margin-top: 20px;
  text-align: center;
}

.mobile-menu .site-social li {
  padding: 0 3px;
  margin-bottom: 15px;
}

.mobile-menu .site-social li a:hover {
  background: #ff880c;
  color: #fff;
}

/******************* Mobile Keywords ****************/
.subpage-banner {
  padding-top: 127px;
  min-height: 672px;
  background: no-repeat center bottom;
  background-size: cover;
}

.subpage-banner h1 {
  color: #fff;
  line-height: 1;
  margin-top: 170px;
}

.mobile-keywords-2 {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background: no-repeat center bottom;
  background-image: none;
  min-height: 654px;
}

.mobile-keywords-2 p {
  max-width: 970px;
  margin: 0 auto 20px;
}

.mobile-keywords-2 h2 {
  margin-bottom: 30px;
}

/* Mobile Keywords 3 */
.mobile-keywords-3 {
  min-height: 1465px;
}

.rounded-num {
  background: rgb(69, 143, 228);
  background: radial-gradient(
    circle,
    rgba(69, 143, 228, 1) 0%,
    rgba(25, 79, 166, 1) 100%
  );
  width: 154px;
  height: 154px;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 65px;
  font-family: Poppins, sans-serif;
  font-weight: 800;
  color: #fff;
  padding: 20px;
  flex: 0 0 154px;
  max-width: 154px;
  margin-right: 45px;
}

.mobile-keyword-count p {
  display: flex;
  flex-flow: row;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
}

.mobile-keyword-count {
  position: relative;
  max-width: 574px;
}

.mobile-keyword-count::before {
  content: '';
  /* ../img/img-border.png */
  background: no-repeat center url('');
  width: 3px;
  height: 90%;
  position: absolute;
  left: 77px;
  top: 0;
  display: block;
}

/* Mobile Keywords 4 */
.mobile-keywords-4 {
  background-size: 100% 910px;
  background-position: center bottom;
  padding-bottom: 300px;
}

/* In Store Digital Currency Page */
.inds-banner {
  min-height: 1046px;
}

.inds-banner h1 {
  margin-top: 105px;
}

/* Inds 2 */
.inds-2 {
  min-height: 905px;
  padding-bottom: 180px;
  margin-top: -80px;
}

.inds-2 .img-wrap {
  margin-left: -100px;
  margin-top: 60px;
}

/* SMS Marketing Page */
.sms-banner {
  min-height: 877px;
}

.sms-banner h1 {
  margin-top: 110px;
}

/* SMS 2 */
.sms-2 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.sms-2 {
  background-position: center bottom;
  background-size: 100% auto;
  padding-bottom: 240px;
}

/* SMS 3 */
.sms-3-inner-wrap {
  justify-content: center;
}

.sms-3-inner {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 12px 93px rgba(0, 0, 0, 0.09);
  padding: 60px 30px 30px 30px;
  height: 100%;
}

.sms-3-inner h4 {
  font-size: 34px;
  margin-top: 30px;
}

.sms-3-inner p {
  font-size: 19px;
  margin-top: 15px;
}

.sms-3-inner-wrap .col-sm-4 {
  padding: 0 20px;
  margin-bottom: 40px;
}

.sms-3 {
  padding-bottom: 120px;
  padding-top: 40px;
}

/* SMS 4 */
.sms-4 {
  background: #ff880c;
  min-height: 357px;
  padding: 80px;
}

.sms-4-col {
  position: relative;
}

.sms-4-col .img-wrap {
  position: absolute;
  right: -210px;
  top: -230px;
}

/* SMS 5 */
.sms-5 {
  min-height: 1358px;
  background-position: center bottom;
  background-color: #fff;
  padding-top: 60px;
  margin-top: 60px;
}

/* SMS 6 */
.sms-6-col-img {
  border-radius: 66px 0 0 66px;
  background: no-repeat center;
  background-size: cover;
}

.sms-6-col-text {
  background: rgb(69, 143, 228);
  background: linear-gradient(
    180deg,
    rgba(69, 143, 228, 1) 0%,
    rgba(25, 79, 166, 1) 100%
  );
  border-radius: 0 66px 66px 0;
  padding: 66px;
}

.sms-6-col-text h2 {
  margin-bottom: 15px;
}

/* SMS 7 */
.sms-7 .img-wrap {
  text-align: center;
}

.sms-7-col-img {
  position: relative;
}

.sms-7-col-img .img-wrap {
  position: relative;
  width: 924px;
  left: -260px;
}

.sms-7 {
  padding-top: 120px;
}

.sms-7-col-text-heading-2 {
  margin-top: 60px;
}

/* SMS 8 */
.sms-8 {
  min-height: 1165px;
  padding-top: 120px;
}

.sms-8-col-img img {
  box-shadow: 0 62px 99px rgba(0, 0, 0, 0.13);
  border-radius: 50%;
}

.sms-8-col-img .img-wrap {
  text-align: right;
}

/* SMS 9 */
.sms-9 {
  padding-bottom: 60px;
}

.sms-9-col-img .img-wrap {
  position: relative;
  width: 905px;
  left: -250px;
}

/* SMS 10 */
.sms-10 {
  min-height: 846px;
  background: no-repeat center;
  background-size: 100% 100%;
  padding-top: 280px;
}

.sms-10-col-right {
  position: relative;
}

.sms-10-col-right .img-wrap {
  position: absolute;
  left: 0;
  top: 0;
}

.mobile-only {
  display: none;
}

/********** Text To Vote Page *************/
.text-to-vote-banner {
  min-height: 1063px;
}

/* Text to Vote 2 */
.t2v-2 {
  background-position: center bottom;
  min-height: 661px;
  padding-top: 80px;
  padding-bottom: 240px;
}

.t2v-num {
  background: rgb(69, 143, 228);
  background: radial-gradient(
    circle,
    rgba(69, 143, 228, 1) 0%,
    rgba(25, 79, 166, 1) 100%
  );
  width: 161px;
  height: 161px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  line-height: 1;
  color: #fff;
  font-size: 59px;
  font-weight: 700;
  text-align: center;
  margin: auto;
  position: relative;
  z-index: 2;
}

.t2v-2-row-2 {
  position: relative;
}

.t2v-2-row-2::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 80px;
  width: 80%;
  height: 5px;
  /* ../img/img-vertical-border.png */
  background: repeat-x center url('');
  background-size: auto;
  background-size: 100%;
  margin-left: -40%;
}

/* Text to Vote 3 */
.t2v-3 {
  background-size: 100% !important;
  background: no-repeat center bottom;
  padding-bottom: 240px;
}

.t2v-3-list {
  display: flex;
  flex-flow: row;
  margin-bottom: 0;
}

.t2v-3-list-img {
  max-width: 134px;
  flex: 0 0 134px;
}

.t2v-3-list-content h3 {
  font-size: 37px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.t2v-3-list-content {
  padding: 20px 0 0 40px;
}

.t2v-3 .img-wrap {
  position: relative;
  width: 1276px;
  left: -756px;
}

/* T2V 4 */
.t2v-4 {
  background: no-repeat center bottom;
  background-size: 100%;
  padding-bottom: 260px;
  padding-top: 40px;
}

.t2v-4 .img-wrap {
  position: relative;
  width: 1226px;
  left: -260px;
}

.t2v-4-left {
  position: relative;
  z-index: 1;
}

/* *****Social Influencers Page**** */
/* Social Influencers Banner */
.social-influencers-banner {
  min-height: 806px;
}

.social-influencers-banner h1 {
  margin-top: 160px !important;
}

/* SI 2 */
.si-2 {
  padding-top: 80px;
}

.si-2-row-1 {
  max-width: 891px;
  margin: 0 auto;
}

.si-2-row-2 h3 {
  margin-bottom: 5px;
}

.si-2-row-3 .img-wrap {
  width: 815px;
  left: 90px;
  position: relative;
}

/* SI 3 */
.si-3-col-left {
  position: relative;
}

.si-3-img-2 {
  position: absolute;
  left: -980px;
  top: 0;
  width: 940px;
}

/* SI 4 */
.si-4 {
  padding-bottom: 360px;
  padding-top: 40px;
}

.si-4-col-right {
  position: relative;
}

.si-4-img-1 {
  position: relative;
  z-index: 1;
  width: 787px;
  left: 40px;
}

.si-4-img-2 {
  position: absolute;
  right: -630px;
  top: 0;
  width: 940px;
  margin-top: 320px;
}

/* SI 5 */
.si-5 {
  padding-bottom: 240px;
}

.si-5-col-text {
  padding-left: 60px;
}

.si-5-col-text-left {
  padding-right: 60px;
  padding-top: 45px;
}

.si-5-row-4-img-1 {
  position: relative;
  width: 895px;
  left: -350px;
  z-index: 2;
}

.si-5-row-4-col-left {
  position: relative;
}

.si-5-row-4-img-2 {
  width: 1188px;
  position: absolute;
  left: -600px;
  top: -370px;
}

.si-5-row-4-text-1,
.si-5-row-4-text-2 {
  position: relative;
  z-index: 2;
  margin-left: -470px;
}

/**** Contact Page ****/
/* Contact 2 */
.contact-banner {
  background-color: #1a51a8 !important;
  text-align: center;
  min-height: 300px !important;
  padding-top: 10px !important;
}

.contact-details {
  display: flex;
  flex-flow: row;
}

.contact-icon {
  flex: 0 0 40px;
  max-width: 40px;
  margin-right: 20px;
}

.contact-detail-heading {
  margin-bottom: 5px;
}

.contact-2-left {
  border-right: 1px solid #9ee3f6;
  padding-right: 60px;
}

.contact-2-right {
  padding-left: 60px;
}

.contact-2 {
  padding-top: 100px;
}

/* 404 Page */
.banner-404 h1 {
  margin-top: 0;
}

.banner-404 {
  min-height: 835px;
  padding-top: 230px;
}

.site-footer-404 {
  background: transparent !important;
  padding-top: 0;
}

.site-footer-404 * {
  color: #363636;
}

.site-footer-404 .after-footer {
  border: 0;
}

/* Popup Form */
.modal-open,
.modal {
  padding: 0 !important;
}

.modal-design-1 .modal-dialog {
  position: relative;
  width: 100%;
  max-width: 1100px;
}

.modal-design-1 .modal-content::before {
  content: '';
  width: 550px;
  height: 789px;
  /* ../img/bg-popup.png */
  background: no-repeat center url('');
  background-size: contain;
  display: block;
  position: absolute;
  left: -26px;
  bottom: 0;
  z-index: 1;
}

.modal-design-1 .modal-content {
  padding-left: 470px;
  min-height: 893px;
}

html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-label {
  font-size: 16px;
  font-weight: 500;
}

html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-checkbox
  .wpforms-field-label {
  font-size: 18px;
  margin-bottom: 15px;
}

html body .modal-design-1 div.wpforms-container-full .wpforms-form ul li {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-right: 20px !important;
}

html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  input[type='text'],
html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  input[type='email']
  .modal-design-1
  html
  body
  div.wpforms-container-full
  .wpforms-form
  textarea.wpforms-field-medium {
  height: 54px;
}

.modal-design-1 .wpforms-field-description {
  text-align: center;
}

html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-text,
html
  body
  .modal-design-1
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-email,
html body .modal-design-1 .wpforms-submit-container {
  padding: 10px 100px;
}

/* Search Popup */
#search-modal .modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

#search-modal .modal-body {
  padding: 30px;
}

#search-modal .modal-content {
  border-radius: 0;
}

.search-form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}

.search-text-box {
  width: 100%;
}

.search-text-box .form-control {
  font-size: 20px !important;
  padding-left: 30px;
  font-style: italic;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding-right: 265px;
}

.search-submit {
  position: absolute;
  right: 0;
  top: 0;
}

.search-form button {
  width: 255px;
  height: 60px;
  font-size: 24px;
  font-weight: 700;
}

.search-close {
  position: absolute;
  top: 50%;
  right: -40px;
  margin-top: -15.5px;
}

.search-close a {
  font-size: 28px;
  color: #999;
  line-height: 1;
}

.search-close a:hover {
  color: #000;
}

/* Search Result */
.search-result h3 {
  font-size: 27px;
  text-transform: none;
  font-weight: 500;
  margin-bottom: 10px;
}

.search-result h3 a:hover {
  color: #000;
}

.search-result {
  margin-bottom: 30px;
}

.search-results-wrap {
  max-width: 659px;
  margin: 45px auto 0 auto;
}

.search-2 {
  padding-top: 80px;
}

/* Pagination */
.custom-pagination {
  text-align: center;
  margin-top: 60px;
}

.wp-pagenavi span {
  font-family: 'Poppins', sans-serif;
  border: 0;
  font-weight: 500;
}

.wp-pagenavi a,
.wp-pagenavi span.current {
  border-radius: 50%;
  margin: 0 2px;
  background: #194fa6;
  color: #fff;
  border: 0;
  display: inline-flex;
  width: 40px;
  height: 40px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.wp-pagenavi a:hover {
  background: #1a57b9;
}

.wp-pagenavi span.current {
  background: #ff880c;
}

/* CRM */
.crm-banner {
  height: 625px;
  min-height: 1px;
}

.crm-banner .img-wrap {
  margin-top: 80px !important;
}

.crm-banner h1 {
  margin-top: 130px !important;
}

/* CRM 2 */
.crm-2 {
  padding-top: 280px;
  padding-bottom: 300px;
}

.crm-2-img {
  width: 805px;
  position: relative;
  margin-left: -200px;
}

/* CRM 3 */
.crm-3-img {
  width: 771px;
  position: relative;
  margin-right: -200px;
}

/* CRM 4 */
.crm-4 {
  padding-top: 0;
  padding-bottom: 220px;
}

.crm-4-img {
  width: 669px;
  position: relative;
  margin-left: -140px;
  margin-top: 90px;
}

/* Integrated Contacts */
.integrated-banner {
  min-height: 1px;
  height: 565px;
}

.integrated-banner h1 {
  margin-top: 140px !important;
}

.integrated-2-img {
  margin-left: -100px;
}

.integrated-2 {
  padding-top: 80px;
}

.integrated-2-right-img {
  margin-right: -100px;
  margin-left: 0;
}

/* Integrated 3 */
.integrated-3 .sms-3-inner h3 {
  font-size: 27px;
}

.integrated-3 .sms-3-inner p {
  font-size: 18px;
}

.integrated-3 .img-wrap {
  margin-bottom: 30px;
}

/* Social Media Marketing */
.social-marketing-banner {
  min-height: 756px;
}

/* Social Marketing 2 */
.social-marketing-2-img {
  width: 765px;
  position: relative;
  padding-left: 20px;
  margin-left: 30px;
}

.social-marketing-2-box-row .col-sm-2 {
  width: 20%;
}

.social-marketing-2-box {
  text-align: center;
  font-size: 16px;
  background: rgb(69, 143, 228);
  background: linear-gradient(
    180deg,
    rgb(69, 143, 228) 0%,
    rgb(25, 79, 166) 100%
  );
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.social-marketing-2-box p {
  color: #fff;
  font-size: 16px;
}

/* Social Marketing 3 */
.social-marketing-3 {
  padding-top: 0 !important;
}

/* Social Media Marketing 5 */
.social-marketing-5-btn a {
  margin: 0 5px;
}

.social-marketing-5-img {
  width: 817px;
  position: relative;
  top: 80px;
  left: -550px;
}

/* Social Media Marketing 6 */
.img-facebook-tab-editor {
  width: 330px;
  margin-left: -75px;
}

.img-like-gating {
  width: 886px;
  position: relative;
  left: -100px;
}

.img-facebook-coupon {
  width: 648px;
  position: relative;
  left: -250px;
}

.multi-channel-voting-row {
  position: relative;
  padding-bottom: 70px;
}

.img-multi-channel-voting {
  position: absolute;
  right: -550px;
  top: -200px;
  width: 971px;
}

/* Social Media Marketing 7 */
.social-marketing-7-img {
  width: 691px;
  position: relative;
  left: -300px;
}

/* Social Media Marketing Follow Us */
.social-media-marketing .home-follow-signup {
  padding-top: 0;
}

/* Investors Page */
.investor-2-img {
  width: 775px;
  position: relative;
  margin-left: -250px;
}

/* Investor 3 */
.investor-3-img {
  width: 847px;
  position: relative;
  right: -40px;
}

.investors-3 {
  padding-bottom: 220px;
}

/* Investor 4 */
.investors-4 {
  margin-top: -80px;
}

/* Investor 5 */
.investor-5-btn {
  width: 100%;
  min-width: 100%;
  font-size: 21px;
  text-transform: uppercase;
  display: inline-flex;
  width: auto;
  min-width: auto;
}

/* Investor 6 */
.investor-6-img {
  width: 1338px;
  position: relative;
  margin-top: -210px;
  margin-left: -660px;
}

.no-above-footer .home-follow-signup {
  display: none;
}

/* Download Popup */
#download-modal .modal-content::before {
  width: 400px;
  height: 589px;
  bottom: -8px;
  left: -19px;
}

#download-modal .modal-dialog {
  max-width: 863px;
}

#download-modal .modal-content {
  padding-left: 400px;
  padding-right: 50px;
  min-height: 640px;
}

.modal-design-1
  div.wpforms-container-full
  .wpforms-form
  .wpforms-submit-container {
  padding-left: 40px;
  padding-right: 40px;
}

html
  body
  #download-modal
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-text,
html
  body
  #download-modal
  div.wpforms-container-full
  .wpforms-form
  .wpforms-field-email,
html body #download-modal .wpforms-submit-container {
  padding-left: 0;
  padding-right: 0;
}

/* Terms of Use Banner */
.terms-of-use-banner {
  min-height: 400px;
  background: #1d266b;
}

.terms-of-use-banner h1 {
  margin-top: 100px !important;
}

.terms-of-use-2 {
  padding-top: 60px;
}

.terms-of-use-2 h2 {
  font-size: 25px;
}

.terms-of-use-2 p {
  font-size: 18px;
}

/* News */
.news-banner {
  min-height: 570px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.news-banner h1 {
  margin-top: 0 !important;
}

.news-2 img {
  width: 100%;
  height: 219px;
  object-fit: cover;
  object-position: center;
}

.news-2 .site-btn {
  min-width: 100%;
  width: 100%;
  font-size: 26px;
  text-transform: uppercase;
  height: 67px;
}

.news-2 .col-inner {
  height: 100%;
  background: #fff;
  box-shadow: 0 40px 69px rgba(51, 133, 204, 0.24);
}

.news-2 .col {
  margin-bottom: 45px;
}

.news-2 .col-body {
  padding: 40px;
}

.news-2 .col-title a {
  color: #363636;
}

/* News Single */
.news-single h2,
.news-single h3,
.news-single h4,
.news-single h5,
.news-single h6 {
  font-size: 24px;
  color: #194fa6;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 20px;
}

.news-single h2 strong,
.news-single
  h3
  strong
  .news-single
  h4
  strong
  .news-single
  h5
  strong
  .news-single
  h6
  strong {
  font-weight: inherit;
}

/* .news-single em {
    font-size: 12px;
    display: block;
    line-height: 1.7;
} */

.news-single p {
  font-size: 17px;
}

/* News Sidebar */
.news-sidebar-box {
  border: 1px solid #a4bfe9;
}

.news-sidebar-box h2 {
  padding-left: 30px;
  margin-top: -15px;
}

.news-sidebar-box h2 span {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}

.news-sidebar-wrap {
  padding: 0 40px;
}

.news-sidebar {
  margin-bottom: 20px;
}

.news-sidebar-footer {
  display: flex;
  justify-content: space-between;
}

.news-sidebar-img {
  margin-bottom: 10px;
}

/* Social Icons Shortcode */
html body .social-icons {
  margin: 0 0 15px 0;
  padding: 0;
}

html body .social-icons li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  margin-right: 5px;
  line-height: 1;
}

html body .social-icons-text {
  font-weight: 700;
}

html body .social-icons li a {
  font-size: 15px;
  display: flex;
  color: #fff !important;
  width: 35px;
  height: 35px;
  background-color: #ff980c;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 50px;
}

html body .social-icons li a:hover {
  background-color: #194fa6;
}

html body .social-icons-2 li a {
  background: #d0205f;
}

html body .social-icons-2 li a:hover {
  background: #e12267;
}

/* Owards Rewards Page */
.owards-rewards-banner {
  min-height: 810px;
}

.owards-rewards-banner .col-sm {
  position: relative;
}

.owards-rewards-banner-img {
  position: absolute;
  left: -135px;
  top: 130px;
  width: 1050px;
}

/* Owards Rewards */
.owards-rewards-3-img {
  width: 1234px;
  position: relative;
  margin-left: -550px;
  margin-top: -235px;
}

.img-text-wrap {
  display: flex;
  align-items: center;
}

.img-text-icon {
  margin-right: 40px;
  flex: 0 0 134px;
}

/* Owards Rewards 2 */
.owards-rewards-3-img-2 {
  width: 861px;
  position: relative;
}

.owards-rewards-pg .home-follow-signup {
  padding-top: 0;
}

/* News Banner */
.media-banner {
  min-height: 441px;
}

.media-2 .col-inner {
  background: #fff;
  box-shadow: 0 12px 93px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  min-height: 305px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

/* Anti Spam Policy */
.anti-spam-banner {
  min-height: 456px;
}

.anti-spam-2 h2 {
  margin-bottom: 10px;
}

.anti-spam-2 br {
  margin-bottom: 20px;
}

/* Help Form */
.help-form {
  width: 670px;
  margin: 0 auto;
  position: relative;
}

.help-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.help-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.help-form :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}
.help-form :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.help-form input {
  width: 100%;
  height: 60px;
  border-radius: 100px;
  background: transparent;
  color: #fff;
  opacity: 1;
  border: 1px solid #fff;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  padding-left: 30px;
}

.help-form button {
  position: absolute;
  right: 10px;
  top: 0;
  width: 60px;
  border-radius: 0;
  height: 100%;
  background: transparent !important;
  font-size: 22px;
  color: #ff880c;
  transition: all 0.3s ease;
}

.help-form button:hover {
  color: #fff;
}

/* Help Lists */
.help-lists {
  padding: 0;
  max-width: 774px;
  margin: 0 auto;
}

.help-lists li {
  font-size: 21px;
  font-weight: 500;
  color: #363636;
  list-style: none;
}

.help-lists li a {
  color: inherit;
  display: block;
  border-bottom: 1px solid #c2e2e6;
  padding: 15px 0;
}

.help-lists li a:hover {
  color: #194fa6;
}

.help-lists li a::before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #ff880c;
  font-size: 20px;
  margin-right: 10px;
}

/* Help Single Page */
.help-2-single-content p {
  font-size: 18px;
  color: #4b4b4b;
}

.help-2-single-content ul,
.help-2-single-content ol {
  padding: 0;
  font-size: 18px;
  color: #4b4b4b;
}

.help-2-single-content ol {
  margin-left: 20px;
}

.help-2-single-content ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}

.help-2-single-content ul li::before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #ff880c;
  position: absolute;
  left: 0;
}

/* Other Help Section */
.single-help-main {
  max-width: 900px;
  margin: 0 auto;
}

.other-help-heading {
  position: relative;
}

.other-help-heading::after {
  content: '';
  height: 2px;
  width: 100%;
  background: #ff880c;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
}

.other-help-heading span {
  position: relative;
  z-index: 1;
  background: #fdfaf5;
  padding: 0 25px;
}

.other-help-wrap {
  display: flex;
  flex-flow: row wrap;
}

.other-help-col {
  max-width: 33.3%;
  flex: 0 0 33.3%;
  padding: 0 15px;
  margin-bottom: 30px;
}

.other-help-col h3 a {
  color: #4b4b4b;
}

.other-help-col h3 a:hover {
  color: #194fa6;
}

.other-help-col .inner {
  background: #fff;
  box-shadow: 0 9px 34px rgba(44, 47, 49, 0.1);
  min-height: 148px;
  border-radius: 20px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.other-help-col .inner::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #ff880c;
  position: absolute;
  right: 20px;
  font-size: 24px;
}

/* Custom Accordion */
html body .custom-accordion {
  max-width: 900px;
  margin: 0 auto;
}

html body .custom-accordion .accordion-button {
  font-size: 21px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: #363636;
  text-transform: none;
  background: transparent;
  border: 0;
  border-radius: 0 !important;
  box-shadow: unset;
  position: relative;
  padding-left: 30px !important;
}

html body .custom-accordion .accordion-item {
  border: 0;
  border-bottom: 1px solid #c2e2e6;
}

html body .accordion-body p,
html body .accordion-body ol,
html body .accordion-body ul {
  font-size: 18px;
  line-height: 1.5;
  color: #4b4b4b;
}

html body .accordion-button::after {
  display: none;
}

html body .accordion-button::before {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #ff880c;
  font-size: 20px;
  margin-right: 10px;
  position: absolute;
  left: 0;
}

html body .accordion-button[aria-expanded='true']::before {
  content: '\f078';
}

html body .accordion-item {
  background: transparent;
}

html body .accordion-body {
  padding: 0 15px 10px 30px;
}

/* Get Free Ovato Page */
.get-free-ovato-page {
  background: #e9fbff !important;
}

.get-free-ovato-page .site-header,
.get-free-ovato-page .home-follow-signup,
.get-free-ovato-page .site-footer {
  display: none !important;
}

.get-free-01 {
  background: no-repeat center #e9fbff;
  background-size: 100% 100%;
  padding-top: 40px;
  padding-bottom: 100px;
}

.get-free-img-wrap {
  width: 421px;
  text-align: center;
  margin: 0 auto;
}

.get-free-dl-wrap {
  text-align: center;
  margin-top: -20px;
}

.get-free-dl-wrap a {
  margin-right: 10px;
}

.get-free-form-wrap {
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 36px 78px rgba(80, 135, 155, 0.09);
  padding: 60px 60px 40px 60px;
}

/* Get Free Form */
.get-free-form .wpforms-form input[type='text'],
.get-free-form .wpforms-form input[type='email'],
.get-free-form textarea.wpforms-field-medium {
  background: #f1f1f1 !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif;
  border: 0 !important;
  padding-left: 30px !important;
}

.get-free-form .wpforms-form button[type='submit'] {
  background: #d6205e !important;
  font-size: 20px !important;
  height: 60px !important;
  border: 0 !important;
  transition: all 0.3s ease !important;
}

.get-free-form .wpforms-form button[type='submit']:hover {
  background: #e82466 !important;
}

html body .get-free-form label {
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px;
  font-weight: 500 !important;
  color: #5e636b !important;
}

.get-free-form .promo-code {
  display: flex !important;
  flex-flow: row wrap !important;
  align-items: center !important;
}

.get-free-form .promo-code label {
  flex: 0 0 150px !important;
}

.get-free-form .promo-code input {
  flex: 1;
}

.get-free-form-wrap p {
  margin-bottom: 15px !important;
}

div.wpforms-container-full .wpforms-form label.wpforms-error {
  color: red !important;
}

/* Get Free Footer */
.get-free-footer p {
  font-size: 15px !important;
  color: rgba(104, 104, 104, 0.54);
  font-weight: 500;
  font-family: Poppins, sans-serif;
}

.get-free-footer p a {
  color: inherit !important;
}

.get-free-footer p a:hover {
  color: #d6205e !important;
}

.get-free-footer .sep {
  margin: 0 10px;
  display: inline-block;
}

.get-free-footer {
  padding-top: 20px;
  padding-bottom: 5px;
}

/* Affiliate Page */
.custom-affiliate-title {
	font-size:14px;
	font-weight:500;
	margin-bottom:5px;
}

.custom-affiliate-code-wrap {
	margin-bottom:10px;
}

.ul-arrow-right {
  padding-left: 0;
  margin-top: 30px;
}

.ul-arrow-right li {
  position: relative;
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #fff !important;
  padding-left: 45px;
  margin-bottom: 15px;
}

.ul-arrow-right li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  /* ../img/img-arrow-right.png */
  background: no-repeat center url('');
  background-size: contain;
  display: block;
}

/* New Styles */
section {
  background: no-repeat center;
  background-size: cover;
}

.home-1 {
	/* background:no-repeat center bottom url('/wp-content/uploads/2023/01/bg-home-banner-new-2.png'); */
    background:no-repeat center bottom url('');
	background-size:cover;
	padding-top: 240px;
	position: relative;
	z-index:2;
}

.home-1-img-wrap {
	margin-left: auto;
	max-width: 556px;
	margin-right: -125px;
	margin-top: 0;
}

.home-1-right-col {
	padding:0 40px;
	margin-top:15px;
}

.home-1 .container {
	position:relative;
	z-index:2;
}

.home-1-row-2 {
	margin-top:30px;
}

/* Home 2 */
.home-2 {
	padding-top:180px;
	position:relative;
	background: rgb(236,251,255);
	background: linear-gradient(180deg, rgba(236,251,255,1) 0%, rgba(255,255,255,1) 100%); 
	margin-top:-300px;
	padding-top:340px;
}

.home-2 .container {
	position:relative;
	z-index:2;
	max-width: 100% !important;
}

/* Home 3 */
.home-3 {
	margin-top:-240px;	
	padding-top:240px;
	margin-bottom:0;
	padding-bottom: 0 !important;
}

.home-3-col {
	text-align:center;
	padding:0 30px !important;
}

.home-3-subheading {
	font-size: 20px;
	margin-bottom: 15px;
	margin-top: 30px;
}

.home-3-col p {
	line-height: 1.8;
	max-width: 360px;
	margin: 0 auto;
	font-size:15px;
}

.home-3-col ul {
	padding-left:20px;
}

.home-3-heading {
	margin-bottom:60px !important;
}

.home-3 .container {
	position:relative;
	z-index: 3;
}

.home-3-box {
  display: flex;
  border-bottom: 1px solid #6aaaac;
  margin-bottom: 30px;
  padding-bottom: 15px;
  max-width: 409px;
}

.home-3-icon {
  font-size: 50px;
  color: #d51f5e;
}

.home-3-right h3 {
  font-size: 37px;
  text-transform: none;
  color: #1b246a;
  margin: 0 0 10px 0;
}

.home-3-right p {
  font-size: 20px;
  font-weight: 500;
}

.home-3-right {
  margin-top: 12px;
  margin-left: 30px;
}

.home-3-box:last-of-type {
  border-bottom: 0;
}

.home-3-img {
  width: 485px;
  margin-left: 60px;
}

.home-3-col-2 {
  margin-top: 20px;
}

.home-3-img-wrap-1 img {
  cursor: pointer;
}

.home-3-btn-wrap {
	text-align:center;
	margin-top:60px !important;
}

.home-3-img-wrap img {
	border-radius:10px;
}

.home-3-col .ul-checklist li {
	font-size:15px;
	font-weight:300;
}

/* Home 4 */
.home-4 {
  padding: 0 0 210px 0;
}

.home-4-img-wrap {
  width: 694px;
  position: relative;
  top: 30px;
  z-index: 1;
  left: 60px;
}

.home-4-img-wrap img {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(-14px 57px 99px rgba(0, 0, 0, 0.16));
}

.home-4 p {
  position: relative;
  z-index: 1;
}

/* Home 5 */
.home-5 {
  margin-top: -100px;
}

.home-5 h3 {
  font-size: 25px;
  color: #1b246a;
  font-weight: 800;
  text-align: center;
  text-transform: none;
}

.home-5 h4 {
  text-align: center;
  font-size: 18px;
  color: #4b4b4b;
  margin-bottom: 15px;
  font-weight: 700;
}

.home-5 p {
  font-size: 17px;
  text-align: center;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 45px;
}

.home-5-col-4 .img-wrap {
  margin-top: -20px;
}

/* Home 6 */
.home-6 {
  background: #f0f7f9;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 240px;
  position: relative;
  padding-top: 260px;
}

.home-6 .container {
  position: relative;
}

.home-6 .container::before {
  content: '';
  position: absolute;
  right: -110px;
  top: -245px;
  /* ../img/img-otext-ring.png */
  background: no-repeat center url('');
  background-size: contain;
  width: 325px;
  height: 225px;
  display: block;
  z-index: 1;
}

.home-6 .col-sm-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.home-6-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 61px 99px rgba(0, 0, 0, 0.03);
  padding: 0 60px 30px 60px;
  height: 100%;
}

.home-6-box h3 {
  text-align: center;
  font-size: 28px;
  text-transform: none;
  color: #1b246a;
  margin-bottom: 5px;
}

.home-6-box h4 {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1b246a;
  margin-bottom: 20px;
}

.home-6-img img {
  margin-top: -50px;
}

.home-6-img {
  text-align: center;
  margin-bottom: 25px;
}

.home-6-row-2 {
  max-width: 1200px;
  margin: 80px auto 0 auto;
}

.site-menu-mobile-share-btn {
  display: none;
}

.mobile {
  display: none !important;
}

.mobile-menu .offcanvas-body {
  position: relative;
  padding-top: 50px;
}

.mobile-menu .btn-close {
  position: absolute;
  right: 15px !important;
  top: 15px !important;
  color: #fff !important;
  filter: brightness(0) invert(1);
  opacity: 1;
}

.home-4-col-inner {
  position: relative;
  z-index: 2;
}

/* Top Curve */
.top-curve {
	position:relative;
}

.top-curve .container {
	position:relative;
}

.top-curve::before {
	content:'';
	display:block;
	width:100%;
	height:169px;
	position:absolute;
	left:0;
	top:0;
	/* background:no-repeat center url('/wp-content/uploads/2022/12/top-curve-blue.jpg'); */
    background:no-repeat center url('');
	background-size:cover;
}

/* Top Curve 2 */
.top-curve-2 {
	position:relative;
}

.top-curve-2::before {
	content:'';
	display:block;
	width:100%;
	height:176px;
	position:absolute;
	left:0;
	top:0;
    /* /wp-content/uploads/2022/09/img-top-curve-02.jpg */
	background:no-repeat center url('');
	background-size:cover;
}

.top-curve-3 {
	position:relative;
}

.top-curve-3::before {
	content:'';
	display:block;
	width:100%;
	height:140px;
	position:absolute;
	left:0;
	top:0;
    /* /wp-content/uploads/2022/12/blue-curve.png' */
	background:no-repeat #fff center url('');
	background-size:cover;
}

.top-curve-4 {
	position:relative;
}

.top-curve-4::before {
	content:'';
	display:block;
	width:100%;
	height:145px;
	position:absolute;
	left:0;
	top:-145px;
    /* /wp-content/uploads/2023/01/gsn-2-new-top-curve.png */
	background:no-repeat transparent center url('');
	background-size:100% 100%;
}

.top-curve-5 {
	position:relative;
}

.top-curve-5::before {
	content:'';
	display:block;
	width:100%;
	height:213px;
	position:absolute;
	left:0;
	top:0;
    /* /wp-content/uploads/2023/01/top-curve-5.jpg */
	background:no-repeat transparent center url('');
	background-size:100% 100%;
}

.top-curve-6 {
	position:relative;
}

.top-curve-6::before {
	content:'';
	display:block;
	width:100%;
	height:137px;
	position:absolute;
	left:0;
	top:0;
    /* /wp-content/uploads/2023/01/top-curve-6.jpg */
	background:no-repeat transparent center url('');
	background-size:100% 100%;
}

.top-curve-7::before {
	content:'';
	display:block;
	width:100%;
	height:171px;
	position:absolute;
	left:0;
	top:0;
	background:no-repeat #fff center url('./assets/img/rules/img-top-curve-7.png');
	background-size:100% 100%;
}

/* Top Curve Login */
.top-curve-login {
	position:relative;
}

.top-curve-login::before {
	content:'';
	display:block;
	width:100%;
	height:181px;
	position:absolute;
	left:0;
	top:-181px;
    /* wp-content/uploads/2022/10/top-curve-login.png */
	background:no-repeat center url('');
	background-size:cover;
}

/* CP Curve */
.cp-curve {
	position:relative;
}

.cp-curve::before {
	content:'';
	display:block;
	width:100%;
	height:350px;
	position:absolute;
	left:0;
	top:-350px;
    /* /wp-content/uploads/2022/11/bg-cp-curve-2-new.jpg */
	background:no-repeat center url('');
	background-size:cover;
}

/* Home Mission Section */
.mission-img-wrap {
	max-width: 447px;
	margin: 0 auto;
	margin-left:0;
}

.mission-left-col {
	padding-left:100px;
}

.home-2-heading {
	margin-bottom:60px;
}

/* Opportunity Section */
.opportunity-right-col {
	padding-left: 80px !important;
	padding-right: 40px !important;
}

.opportunity-img-wrap {
	margin-left:-1rem;
}

.ul-oppor li {
	margin-bottom:20px;
}

/* Mindmap */
.first-row {
	padding-top:220px;
}

.row-1 {
	margin-top: 164px;
}

.mindmap-1 .container {
	position:relative;
	z-index:5;
}

.mindmap-1 {
	margin-bottom:-100px;
}

.mindmap-content {
	max-width: 720px;
	margin: 0 auto;
}

.mindmap-wrap {
	display:flex;
	flex-flow: row wrap;
	margin-top:45px;
}

.mindmap-col {
	max-width:33.3%;
	flex:0 0 33.3%;
	padding:0 20px;
	margin-bottom:30px;
}

.mindmap-col-inner {
	background: rgb(27,36,106);
	background: linear-gradient(30deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%); 
	border-radius: 17px;
	min-height: 160px;
	padding: 40px;
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.mindmap-col-num {
	font-size: 58px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	color: rgba(255,255,255, .27);
	line-height:1;
	margin:0;
}

.mindmap-col-title {
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	margin:0;
	padding-left: 5px;
	line-height:1;
}

.mindmap-col-img {
	position: absolute;
	left: 0;
	top: 0;
}

.mindmap-col-inner-big {
	min-height:270px;
}

/* Mindmap Col 1 */
.mindmap-col-img-1 {
	top:-30px;
	width:179px;
}

.mindmap-col-1 .mindmap-col-inner {
	padding-left:180px;
	padding-right:20px;
}

/* Mindmap Col 2 */
.mindmap-col-img-2 {
	left: auto;
	right: 0;
	top: 0px;
	right: 0px;
	width: auto;
}

.mindmap-col-img-2 img {
	mix-blend-mode: screen;
}

.mindmap-col-2 .mindmap-col-inner {
	justify-content:flex-end;
}

/* Mindmap Col 3 */
.mindmap-col-img-3 {
	left: auto;
	right: 40px;
	top: 30px;
	width:89px;
}

/* Mindmap Col 4 */
.mindmap-col-img-4 {
	left: auto;
	right: -10px;
	top: 30px;
	width: 200px;
}

.mindmap-col-4 .mindmap-col-inner {
	justify-content:flex-end;
	min-height:265px;
}

/* Mindmap Col 5 */
.mindmap-col-img-5 {
	left: auto;
	right: 40px;
	top: 35px;
	width: 108px;
}

/* Mindmap Col 6 */
.mindmap-col-img-6 {
	left: auto;
	right: 40px;
	top: 40px;
	width:106px;
}

.mindmap-col-top {
	margin-top:-105px;
}

.mindmap-col-6 .mindmap-col-inner {
	justify-content:flex-end;
	min-height:265px;
}

/* Get Started Now */
.gsn-1-video-wrap {
	position:relative;
}

.gsn-1-video-wrap::before {
	content:'';
	position:absolute;
	left: -40px;
	top: -40px;
	width: 100px;
	height: 100px;
    /* /wp-content/uploads/2022/11/img-video-circle-1-new.png */
	background: no-repeat center url('');
	background-size:contain;
}

.gsn-1-video-wrap::after {
	content:'';
	position:absolute;
	right: -100px;
	bottom: -100px;
	width: 325px;
	height: 325px;
    /* /wp-content/uploads/2022/11/img-video-circle-2-new.png */
	background: no-repeat center url('');
	background-size:contain;
}

.gsn-1-video-wrap-coins::after {
    /* /wp-content/uploads/2022/11/img-circle-save.png */
	background-image: url('');
	width: 489px;
	height: 292px;
	right: -270px;
}

.gsn-1-video-wrap img {
	position:relative;
	z-index:2;
	width:100%;
}

.gsn-1 .container {
	position:relative;
	z-index: 2;
}

.gsn-1 {
	padding-bottom:100px;
}

.gsn-1-row-2 {
	max-width:1000px;
	margin-left:auto;
	margin-right:auto;
}

.gsn-2-col-img {
	max-width: 554px;
	margin: 0 auto;
}

/* GSN 2 */
.gsn-2 {
	margin-bottom: -220px;
	padding-top:100px;
}

.gsn-2-heading {
	max-width:361px;
}

.gsn-2 .container {
	position:relative;
	z-index: 5;
}

/* Tools Page */
.tools-video-wrap {
	display:flex;
	flex-flow: row wrap;
}

.tools-video-col {
	max-width:50%;
	flex: 0 0 50%;
	padding:0 20px;
	margin-bottom:40px;
}

.tools-1-row-2 {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

/* Tools 2 */
.tools-2 .container {
	position:relative;
	z-index:5;
}

.tools-2 {
	padding-top: 160px;
	margin-top: -80px;
	margin-bottom: -180px;
}

/* Tools Logged In */
.tools-2-logged {
	margin-bottom:100px;
	margin-top:178px;
	padding-top:140px;
}

.tools-2-logged::before {
    /* /wp-content/uploads/2022/12/img-tools-logged-curve.jpg */
	background-image:url('');
	height:178px;
}

.tools-2-logged-p {
	max-width: 500px;
	margin: 0 auto;
}

.tools-2-logged-row-2 {
	max-width: 800px;
	margin: 0 auto;
}

.tools-2-logged-row-2 .site-btn {
	font-size: 13px;
	min-width: 159px;
	min-height: 40px;
	padding: 13px 30px;
}

.tools-2-logged-row-2 .col-sm-4 {
	margin-bottom:60px;
}

/* Sign Up */
.sign-up-1-row {
	max-width:596px;
	margin:0 auto;
}

.sign-up-1-email {
	display: inline-block;
	border-bottom: 1px solid #707070;
	font-size: 20px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 5px;
	text-overflow: ellipsis;
	width: 161px;
	text-align: center;
	font-size: 15px !important;
	line-height: 1.1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align:bottom;
}

.sign-up-sponsor-img {
	border-radius: 15px;
	max-width: 100%;
	max-height: 89px;
	margin-left: auto;
	display: block;
}

/* Sign Up 2 */
.sign-up-2 .container {
	position: relative;
	z-index: 5;
	background: #fff;
	max-width: 973px;
	border-radius: 27px;
	box-shadow: 0 21px 86px rgba(117,136,85,.11);
	padding: 20px 0;
}

.sign-up-2 {
	padding-top:140px;
	margin-bottom:-240px;
	position:relative;
	background: rgb(242,252,254);
	background: linear-gradient(180deg, rgb(238, 252, 255) 90%, rgb(255, 255, 255) 100%);
	padding-top: 0;
	margin-top:0;
}

.sign-up-2::before {
	content:'';
	display:block;
	width:100%;
	height:344px;
    /* /wp-content/uploads/2022/11/signup-curve-1.jpg */
	background:no-repeat center url('');
	background-size: cover;
	position: absolute;
	left: 0;
}

.custom-signup-main .site-menu-col {
	display:none;
}

.custom-signup-main .site-logo-col {
	max-width:100%;
	flex:1;
	text-align:center;
}

.custom-signup-main .site-logo {
	max-width: 105px;
	display: inline-block;
	margin-left: 105px;
}

.custom-signup-main .new-site-social-col {
	display:none;
}

.custom-signup-main .site-header-sponsor-tshirt-right .btn-wrap, 
.custom-signup-main .site-header-sponsor-tshirt-right .site-header-sponsor-for-free {
	display:none;
}

/* Sign Up Form */
.suf-group {
	display:flex;
	flex-flow: row wrap;
}

.suf-left {
	max-width:54px;
	flex: 0 0 54px;
	height:58px;
	background: #F3F3F3;
	display:flex;
	align-items:center;
	justify-content:center;
}

.suf-right {
	flex: 1;
	position:relative;
}

.sign-up-form input[type='text'],
.sign-up-form input[type='email'],
.sign-up-form input[type='phone'],
.sign-up-form input[type='password']{
	width: 100%;
	height: 58px;
	border: 1px;
	outline: 0;
	font-size: 15px;
	color: #333;
	padding: 0 20px;
	font-family: Poppins, sans-serif;
	font-weight: 300;
}

.sign-up-form input[type='password'] {
	padding-right:38px;
}

.suf-group {
	border:1px solid #D5D5D5;
	border-radius:5px;
	overflow:hidden;
}

.suf-row {
	margin-bottom:15px;
}

.suf-row label {
	font-size:16px;
	font-family:'Poppins', sans-serif;
	font-weight:300;
	color: #4B4B4B;
}

.suf-upload-file-input {
	opacity:0;
	visibility:hidden;
	position:absolute;
}

.suf-row .suf-upload-file-btn {
	border: 1px solid #D6225E;
	border-radius: 50px;
	background: #fff;
	font-size: 15px;
	font-weight: 600;
	font-size: 15px;
	font-family: 'Poppins', sans-serif;
	color: #D6225E;
	width: 204px;
	height: 40px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-left:15px;
	cursor:pointer;
	transition:all 0.3s ease;
}

.suf-row .suf-upload-file-btn:hover {
	background:#f0f0f0;
}

.sign-up-form {
	max-width:868px;
	margin:0 auto;
	margin-top:45px;
	display:flex;
	flex-flow: row wrap;
}

.suf-row-file {
	text-align: left;
	margin-top: 10px;
	margin-bottom: 25px;
}

.suf-row button {
	height:55px;
	font-size:20px;
	text-transform:none;
}

.suf-row-submit {
	margin-top: 15px;
	max-width: 361px;
	flex: 0 0 100%;
	margin-left: auto;
	margin-right: auto;
	text-align:center;
}

.suf-left-col {
	flex:0 0 50%;
	max-width:50%;
	padding:0 10px;
}

.suf-right-col {
	flex:0 0 50%;
	max-width:50%;
	padding:0 10px;
}

.suf-view-pass {
	color: #979797;
	position: absolute;
	right: 15px;
	top: 50%;
	z-index: 5;
	margin-top: -12px;
	cursor: pointer;
	transition:all 0.3s ease;
}

.suf-view-pass:hover {
	color:#666;
}

/* Google Translation */
#google_translate_element {
	display:none !important;
	visibility:hidden;
	opacity:0;
}

/* Compensation Page */
/* CP 1 */
.cp-1 {
	margin-bottom:60px;
}

.cp-1 .container {
	position:relative;
	z-index:5;
}

/* CP 2 */
.cp-2 {
	padding-top:0;
	margin-bottom:-640px;
	background: rgb(241,252,255);
	background: linear-gradient(180deg, rgb(243, 252, 255) 30%, rgb(221, 248, 255) 70%);
}

.cp-col-wrap {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
}

.cp-col {
	max-width: 20%;
	flex: 0 0 20%;
	padding: 0 5px;
}

.cp-col-num {
	width: 56px;
	height: 56px;
	margin: 0 auto;
	background: #D6225E;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins, sans-serif;
	font-size: 25px;
	color: #fff;
	font-weight: 700;
}

.cp-col-title {
	font-size: 26px !important;
	font-weight: 700 !important;
	font-family: Poppins, sans-serif;
	text-align: center;
	margin-top:20px;
}

.cp-col-box {
	background: #DAF5FE;
	padding:30px;
	min-height:302px;
}

.cp-col-earn {
	font-family:'Poppins', sans-serif;
	font-size:21px;
	font-weight:700;
	color: #D6225E;
	margin-bottom:5px;
}

.cp-col-content {
	font-family:'Poppins', sans-serif;
	font-size:15px;
	font-weight:300;
	color: #4B4B4B;
	margin-bottom:15px;
}

.cp-1-left {
	padding-right:40px;
}

.cp-1-img-wrap {
	padding-left:100px;
}

.cp-1-video-wrap {
	max-width:580px;
	overflow:hidden;
}

.cp-1-video-wrap img {
	transition:all 0.8s ease;
}

.cp-1-video-wrap img:hover {
	transform:scale(1.1);
}

.cp-col-img-wrap {
	margin-top:30px;
	text-align:center;
}

.cp-col-2 .cp-col-box {
	min-height:400px;
}

.cp-col-3 .cp-col-box {
	min-height: 470px;
}

.cp-col-4 .cp-col-box {
	min-height: 503px;
}

.cp-col-5 .cp-col-box {
	min-height: 550px;
}

.cp-col-6 .cp-col-box {
	min-height: 640px;
}

.cp-col-box-inner {
	position:relative;
	z-index:6;
}

/* Multiple Ways */
.mw-2-col-wrap .cp-col {
	max-width:20%;
	flex:0 0 20%;
	position:relative;
	z-index:2;
}

.mw-sect.mw-2 .container {
	max-width:1500px !important;
}

/* Sticky Header Active */
.site-header.active {
	box-shadow: 0 6px 10px 0 rgba(0,0,0,.15);
}

.site-header.active .site-logo {
	transition:all 0.3s ease;
}

.site-header.active .site-logo {
	width:75px;
}

.home-1-heading {
	max-width:907px;
	margin-left:auto !important;
	margin-right:auto !important;
}

/* Login Form */

.login-form {
	flex-flow: column;
	max-width: 375px;
	margin: 30px auto 0 auto;	
}

.login-form .suf-row-submit {
	width: 100%;
	max-width: 321px;
	margin-top: 0;
}

.suf-row-forgot {
	text-align:center;
}

.forgot-password {
	color: #D6225E;
	display: inline-block;
	margin-top: 10px;
}

.login .site-header-lower {
	background:transparent;
}

.login-2 {
	margin-top: 300px;
	padding-top: 0;
	background: rgb(244,252,245);
	background: linear-gradient(180deg, rgb(239, 253, 254) 90%, rgb(255, 255, 255) 100%);
	padding-bottom: 100px;
	margin-bottom: 0;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.login .site-header.active .site-header-lower {
	background:#fff;
}

.login-2 .container {
	background:transparent;
	border-radius:0;
	box-shadow:unset;
}

.suf-row-forgot {
	margin-bottom:13px;
}

/* Site Footer 2 */
.site-footer-2 .site-footer-lower {
	background: #E5F6FF;
}

.site-footer-2 .site-footer-lower-ul li {
	list-style: none;
	color: #1B246A;
	font-family: 'Poppins', sans-serif;
}

.site-footer-2 .site-footer-lower-ul li a {
	color: #1B246A;
}

.site-footer-2 .site-footer-lower-ul {
	margin-bottom:20px;
}

.site-footer-2 {
	position:absolute;
	left:0;
	bottom:0;
	width:100%;
}

.site-footer-2 .site-footer-lower-ul li::after {
	color: #1B246A;
}

.site-footer-lower-ul li {
	font-size:14px !important;
}

/* Button Disabled */
.suf-row button:disabled,
.suf-row button[disabled] {
	background: #cccccc !important;
	color: #666666 !important;
}

/* Suf Error */
.suf-error {
	font-family: 'Poppins', sans-serif;
	color: red;
	font-weight: 400;
	font-size: 13px;
	margin-top: 5px;
	margin-left: 5px;
	line-height: 1.1;
}

.suf-form-error {
	font-family: 'Poppins', sans-serif;
	color: red;
	font-weight: 400;
	font-size: 16px;
	text-align:center;
	margin-bottom:20px;
}

.suf-form-success {
	color:green !important;
}

/* Manage Billing */
#manage-billing {
	width:100%;
	max-width:100%;
}

#manage-billing button[type="submit"] {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	max-width: unset;
	margin: 0 auto;
	padding: 20px 60px;
	text-transform: uppercase;
}

/* Active Menu Page */
.site-menu li a.active {
	color: #D6225E;
}

/* MW 1 */
.mw-1 {
/* 	background:url('/wp-content/uploads/2022/11/bg-mw-curve-banner.jpg') no-repeat center bottom; */
	background:url('') no-repeat center bottom;
	padding-bottom:560px;
	margin-bottom:15px;
	position:relative;
	z-index:1;
	padding-top:180px;
}

.mw-1 .cp-1-left {
	max-width: 60%;
	margin-bottom: -160px;
}

.mw-1 h1 {
	font-size:35px;
}

/* MW 2 */
.mw-2 {
	position: relative;
	margin-bottom: 0;
	margin-top: -300px;
	padding-bottom: 280px;
}

.mw-2-row-2 {
	margin-top:80px !important;
	position: relative;
}

.mw-2-row-2-text {
	max-width:526px;
	margin:0 auto;
}

/* MW Row 3 */
.mw-2-row-3-new .cp-1-video-wrap {
	max-width: 722px;
	margin: 0 auto;
	margin-top: 0;
	padding: 0;
	position:relative;
	z-index:1;
}

/* MW Collection */
.mw-collection-title {
	text-align:center;
	font-size:27px;
	font-weight:600 !important;
	color: #3E3E3E !important;
	font-family:'Poppins', sans-serif;
	margin-bottom:5px !important;
}

.mw-collection-price {
	text-align:center;
	font-size:20px;
	font-weight:600;
	color: #D6225E;
	font-family:'Montserrat', sans-serif;
	margin-bottom:30px;
}

.mw-collection-box {
	background: #4A7FC9;
	border-radius:13px;
	padding:20px;
}

.mw-collection-box-img {
	text-align:center;
}

.mw-collection-box-img img {
	border-radius:9px;
}

.mw-collection-box-num {
	font-family:'Montserrat', sans-serif;
	font-size:18px;
	font-weight:600;
	color:#fff;
	line-height: 1.2;
}

.mw-collection-box-country {
	font-family:'Poppins', sans-serif;
	font-size:14px;
	font-weight:500;
	color:rgba(255,255,255,.5);
	text-transform:uppercase;
}

.mw-collection-box-btn {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 500;
	background: #F69139;
	color: #fff !important;
	text-transform: uppercase;
	border-radius: 50px;
	padding: 8px 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	transition:all 0.3s ease;
}

.mw-collection-box-btn:hover {
	transform:scale(1.01);	
}

/* MW 2 ROW 3 */
.mw-2-row-3 {
	margin-top:60px !important;
	max-width:1080px;
	margin-left:auto !important;
	margin-right:auto !important;
}

.mw-sect .container {
	max-width:1180px !important;
}

.mw-collection-box-footer {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items:center;
	margin-top:20px;
}

.mw-2-row-4 {
	text-align:center;
	margin-top:40px !important;
}

.mw-2-row-3 {
	position:relative;
	z-index:6;
}

.mw-2-row-3::before {
	content: '';
	display: block;
	position: absolute;
	left: -175px;
	top: -150px;
	background: no-repeat center url('./assets/img/compensation-plan/img-collection-get-3d.png');
	background-size: auto;
	background-size: contain;
	width: 375px;
	height: 282px;
	z-index: 6;
}

.mw-2-row-4 {
	position:relative;
	z-index:6;
}

/* MW 3 */
.mw-3 {
	position: relative;
	padding-top: 60px;
	background: #fff;
}

.mw-3 .container {
	max-width:908px !important;
}

.mw-2-row-3-new {
	margin-top:0;
}

.mw-3::before {
	content: '';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:154px;
    /* /wp-content/uploads/2022/11/mw-3-new-curve.jpg */
	background:no-repeat center url('');
}

/* MW 2 New */
.mw-2-new {
	position: relative;
	margin-top: -180px;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(232,251,255,1) 100%); 
	padding-bottom:60px;
}

.mw-2-new::before {
	content:'';
	background: no-repeat center url('./assets/img/compensation-plan/mw-new-2-curve.jpg');
	background-size:cover;
	width:100%;
	height:236px;
	position:absolute;
	left:0;
	top:0;
}

.mw-2-new-img-wrap {
	text-align: center;
	position: relative;
	max-width: 427px;
	margin: -120px auto 0 auto;
	z-index:2;
}

/* Customer Portal */
.custom-notice-error {
	background: red;
	color: #fff;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	margin:0;
	z-index: 99999;
}

.custom-notice-error a {
	color: #fff;
	text-decoration:underline;
}

/* Profile Details */
.pd-1 {
	background: rgb(234, 250, 255);
	background: linear-gradient(180deg, rgb(234, 250, 255) 0%, rgb(236, 250, 254) 40%, rgba(219,255,235,0) 100%);
}

.pd-1-curve {
	position:relative;
	margin-top:400px;
}

.w-sponsor-header .pd-1-curve {
	margin-top:500px;
}

.pd-1-curve::before {
	content:'';
	position:absolute;
	left:0;
	top:-141px;
	width:100%;
	height:141px;
    /* /wp-content/uploads/2022/12/pd-curve-1-blue.png */
	background: no-repeat center url('');
	background-size:cover;
}

.pd-1-box {
	background: #fff;
	box-shadow: 0 21px 86px rgba(117, 136, 85, .11);
	border-radius: 36px;
	margin-top: -200px;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	padding: 40px 40px 80px 40px;
	margin-bottom: -240px;
	min-height:771px;
}

.pd-1-box-left {
	max-width: 33.3%;
	flex: 0 0 33.3%;
	padding: 0 20px;
	margin-top: 50px;
}

.pd-1-box-right {
	max-width: 66.6%;
	flex: 0 0 66.6%;
	padding: 0 30px;
	position: relative;
	z-index: 4;
}

.pd-1-box-img {
	width: 215px;
	height: 215px;
	background: no-repeat center #333;
	background-size: auto;
	background-size: cover;
	border-radius: 50%;
	margin: 0 auto 20px;
}

/* PD BOX 1 2 */
.pd-1-box-2 {
	border-radius: 11px;
	background: #F4F9FB;
	border: 1px solid #E7F1F4;
	padding: 30px;
}

.pd-box-2-follow {
	padding:0 !important;
	margin:0 !important;
	display:flex;
	flex-flow:row wrap;
}

.pd-box-2-follow li {
	list-style:none !important;
	margin-right:10px;
	margin-bottom:10px;
}

.pd-box-2-follow li a {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #2B84B9;
	color: #fff;
	border-radius: 50%;
	width: 43px;
	height: 43px;
	font-size: 22px;
}

.pd-box-2-follow li a:hover {
	background: #2F93CF;
}

.pd-1-box-sponsor-right a:hover {
	color: #76AC00;
}

.pd-box-2-follow-wrap p {
	margin-bottom:10px;
}

.pd-box-2-earnings-wrap p {
	margin-bottom:5px;
}

.pd-box-2-people-wrap p {
	margin-bottom:5px;
}

.pd-box-2-follow-wrap {
	margin-bottom:30px;
}

.pd-box-2-earnings-wrap {
	margin-bottom:20px;
}

/* PD Box 1 Sponsor */
.pd-1-box-sponsor {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-end;
}

.pd-1-box-sponsor-img {
	background: no-repeat center #333;
	background-size: auto;
	background-size: 100%;
	flex: 0 0 48px;
	border-radius: 100%;
	height: 48px;
	margin-right: 15px;
}

.pd-1-box-sponsor-right {
	text-align:right;
}

.pd-1-box-sponsor-right p {
	margin-bottom:0;
}

.pd-1-box-my-nft {
	border-top: 1px solid #E3F1F5;
	border-bottom: 1px solid #E3F1F5;
	margin: 30px 0;
	padding: 20px 0;
}

.pd-1-box-aff-ul {
	font-family:'Poppins', sans-serif;
	font-size:16px;
	font-style:italic;
	margin:0;
	padding:0 !important;
	position: relative;
	z-index: 6;
}

.pd-1-box-aff-ul li {
	list-style:none !important;
	margin-bottom:5px;
}

.pd-1-box-aff-ul li:last-of-type {
	list-style:none !important;
	margin-bottom:0px;
}

.pd-1-box-aff-ul li a {
	color: #31A7D6;
	font-weight:400;
}

.pd-1-box-aff-ul li a:hover {
	color: #76AC00;
}

.pd-1-box-right p {
	margin-bottom:15px;
}

.pd-1-box-affilliates p {
	margin-bottom:10px;
}

.pd-1-box-sponsor-right p {
	margin-bottom:0;
}

/* Profile Details NFT Slider */
.pd-1-box-my-nft-slider-wrap {
	display:flex;
	flex-flow:row wrap;
}

.pd-1-box-my-nft-slider-col {
	max-width:25%;
	flex:0 0 25%;
	padding:5px;
}

.pd-1-box-my-nft-slider-col-inner {
	background: #4A7FC9;
	border: 1px solid #B2D3FF;
	border-radius: 10px;
	padding: 10px;
}

.pd-1-box-my-nft-slider-col-info-num {
	font-family: 'Montserrat', sans-serif;
	font-size: 10px;
	font-weight: 600;
	color: #fff;
	display: block;
	line-height: 1.2;
	margin-top: 10px;
}

.pd-1-box-my-nft-slider-col-info-country {
	font-family:'Poppins', sans-serif;
	font-size:8px;
	font-weight:500;
	color:rgba(255,255,255,.51);
	display:block;
	line-height:1.2;
	text-transform:uppercase;
	margin-top:2px;
}

.pd-1-box-my-nft-slider-wrap {
	position:relative;
}

.pd-1-box-my-nft-slider-col.slick-slide {
	flex:1;
}

.pd-1-box-my-nft-slider-wrap .slick-arrow {
	width: 32px !important;
	height: 32px !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 8px;
	display:flex;
	margin:auto !important;
	background: #D6225E !important;
}

.pd-1-box-my-nft-slider-wrap .slick-arrow::after {
	background-size:50% !important;
}

.pd-1-box-my-nft-slider-wrap .slick-prev {
	left:-35px !important;
}

.pd-1-box-my-nft-slider-wrap .slick-next {
	right:-35px !important;
}

/* Profile Details Edit */
.pd-form .suf-row-file {
	margin-top:0;
}

.pde-1-box {
	margin-bottom: -240px;
	padding: 60px 0;
}

.pde-1-box-heading {
	width:100%;
	margin-bottom:40px;
}

.pd-form {
	display:flex;
	flex-flow:row wrap;
	width:100%;
	position: relative;
	z-index: 5;
}

.pd-form .suf-row-submit {
	margin-top:60px;
}

.pd-form label {
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: #3E3E3E;
	display: block;
	margin-bottom: 8px;
}

.pd-form input {
	height:48px;
	background: #F6F6F6;
	border:1px solid #C6DBB8; 
	border-radius:5px;
	font-family:'Poppins', sans-serif;
	font-size:15px;
	font-weight:400;
	color: #3E3E3E;
	padding:0 20px;
	outline:0;
	width:100%;
}

.pd-form input:focus {
  outline: 1px solid #C6DBB8;
}

.pd-form button {
	text-transform:uppercase;
}

.pd-form textarea {
	height:200px;
	background: #F6F6F6;
	border:1px solid #C6DBB8; 
	border-radius:5px;
	font-family:'Poppins', sans-serif;
	font-size:15px;
	font-weight:400;
	color: #3E3E3E;
	padding:10px;
	outline:0;
	width:100%;
}

.pd-form-row {
	margin-bottom:30px;
}

.pd-form-col {
	padding:0 25px;	
}

.pd-form textarea:focus {
	outline: 1px solid #C6DBB8;
}

.pd-form .suf-row-file-group .suf-profile-pic-name {
	display:inline-block;
	margin-bottom:0;
}

.pd-form-left {
	max-width: 33.3%;
	flex: 0 0 33.3%;
	border-right: 1px solid #C8C8C8;
	padding: 0 60px;
}

.pd-form-right {
	max-width:66.6%;
	flex:0 0 66.6%;
	padding:0 60px;
}

.pd-social {
	position: relative;
	margin-bottom: 10px;
}

.pd-social-icon {
	position: absolute;
	left: 15px;
	top: 12.5px;
	width: 25px;
	height: 25px;
	background: #CBCBCB;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #F6F6F6;
}

.pd-social input {
	padding-left:50px;
}

.pd-form-social {
	margin-top:30px;
}

/* Edit Profile Details */
.suf-uploaded-image {
	text-align:center;
	margin-bottom:20px;
}

.pd-row-file-group img {
	width: 215px;
	border-radius: 100%;
	height: 215px;
	object-fit: cover;
}

.pd-row-file-group .suf-profile-pic-name {
	display:block !important;
	text-align:center !important;
	margin: 15px 0;
}

.pd-row-file-group {
	text-align:center;
}

.pd-row-file-group .suf-upload-file-btn {
	margin:0;
}

.pd-row-file-group .suf-uploaded-image {
	margin:0;
}

/* Drag File Area */
.file_drag_area {
	width: 100%;
	height: 275px;
	border: 2px dashed #C6DBB8;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	padding: 3px 0;
	cursor:pointer;
}

.file_drag_over {
	color:#000;
	border-color:#000;
}

.file_drag_file_col {
	max-width: 25%;
	flex: 0 0 25%;
	padding: 0px 5px;
	margin-bottom: 10px;
}

.file_drag_col_inner {
	border: 1px solid #C6DBB8;
	border-radius: 5px;
	height: 127px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	background: #F6F6F6;
	overflow: hidden;
	position:relative;
}

.file_drag_col_inner_message {
	color: #3B8921;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height:1.2;
}

.file_drag_col_delete {
	position: absolute;
	right: 5px;
	top: 5px;
}

.file_drag_col_delete_fail {
	position: absolute;
	right: 5px;
	top: 5px;
}

.file_drag_text_status {
	color: red;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 500;
	margin-top: 5px;
	display:none;
}

.file_drag_col_delete i,
.file_drag_col_delete_fail i {
	color:red !important;
	font-size:12px !important;
	background: #000;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	transition:all 0.3s ease;
}

.file_drag_col_delete.disabled i {
	background:transparent;
}

.file_drag_col_delete i:hover {
	background:#fff;
}

.file_drag_col_inner_message.file_drag_col_inner_filename {
	color: #000;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100px;
	text-align: center;
	white-space: nowrap;
	margin-top: 5px;
	font-size: 11px;
}

.file_drag_col_inner i {
	color: #3B8921;
	font-size:24px;
}

.file_drag_col_inner i.fa-plus {
	color: #EEA8BF;
	font-size: 32px;
}

.file_drag_col_inner .error {
	color:red !important;
}

.file_drag_col_inner_message.error {
	padding:0 20px !important;
	text-align:center;
}

.file_drag_col_inner img {
	width:100%;
	height:127px;
	object-fit:cover;
	pointer-events: none;
}

.error.file_drag_col_inner_message {
	color:red;
	font-size:10px;
	line-height:1.2;
	text-align:center;
	font-weight:400;
}

.file_drag_file_col.error i {
	color:red;
}

.file_drag_file_col.error .file_drag_col_inner {
	border-color:red;
}

#file_drag_multiple {
	position: absolute;
	top: -16rem;
	opacity: 0;
}

.file_drag_text_guide {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 400;
	color: #3E3E3E;
	display: block;
	margin-bottom: 8px;
	margin-top:10px;
}

.file_drag_progress_wrapper {
	background: #ccc;
	font-size: 10px;
	width: 100%;
	color: #fff;
	margin-top:5px;
}

.file_drag_progress_bar {
	background: #649e00;
	width: 0;
	text-align: right;
	padding-right: 5px;
}

.custom-affiliate-code,
.custom-affiliate-code-link {
	background: #f6f6f6;
	color: #333;
	padding: 5px;
	padding-left: 5px;
	font-size: 14px;
	font-family: Poppins, sans-serif;
	padding-left: 10px !important;
	border: 1px solid #c6dbb8;
	border-radius: 5px;
	transition:all 0.3s ease;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor:pointer;
}

.custom-affiliate-code-link a {
	color: #333;
}

.custom-affiliate-code-status {
	font-size: 12px;
	margin-top: 5px;
	margin-bottom:5px;
	color: green;
	display:none;
}

.pd-1-box-affilliates {
	margin-bottom:20px;
}

.custom-affiliate-code:hover {
	cursor:pointer;
	color:green;
}

/* Custom Landing Page */
.custom-landing .site-header {
	display:none;
}

.custom-landing .site-footer {
	display:none;
}

/* LN 1 */
.ln-main {
	overflow-x: hidden;
}

.ln-main .container {
	max-width:1140px;
}

.ln-1 {
  padding-top: 30px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.ln-1 .container {
  position: relative;
  z-index: 5;
}

.ln-1-gift-line {
	width: 130px;
	border-bottom: 2px solid #1d266b;
	display: inline-block;
	margin-right: 10px;
	white-space: nowrap;
	overflow-x: hidden;
	font-size: 15px;
	color: #1d266b;
	text-align: justify;
	justify-content: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align:center;
}

.ln-1-gift-link {
  display: inline-flex;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #f49334;
  border-bottom: 1px solid #f49334;
  padding-bottom: 3px;
}

.ln-1-gift-link:hover {
  color: #1d266b;
}

.ln-1-gift-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.ln-1-gift-left {
  flex: 0 0 96px;
  margin-right: 20px;
}

.ln-1-gift-left img {
  border-radius:10px;
}

.ln-1-gift-right {
  flex: 1;
}

/* LN 1 Download Wrap */
.ln-1-download-wrap {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.ln-1-download-wrap li {
  list-style: none;
  margin-left: 10px;
}

.ln-1-download-wrap img {
  width: 91px;
}

.ln-1-trading-wrap {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  margin-top: 5px;
}

.ln-1-trading-wrap li {
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  margin-left: 15px;
  color: #fff;
}

.ln-1-gift-text {
  font-size: 16px !important;
  margin-right: 10px;
}

.ln-1-gift-heading {
  font-size: 34px !important;
}

.ln-1-gift-sponsor-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 5px;
}

/* LN 2 */
.ln-2 {
	padding: 0 0 0 0;
}

.ln-2-heading {
	margin-top:15px;
	padding-right:20px;
}

.ln-2-header {
	display: flex;
	flex-flow: row;
	align-items: center;
	margin-bottom: -20px;
	margin-top: -30px;
}

.ln-2-header-right p {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 600;
	color: #1d266b;
	max-width: 270px;
	line-height: 1.2;
}

.ln-2-header-right p a {
	color: #d6225e;
	text-decoration: underline;
}

.ln-2-header-left {
	flex: 0 0 136px;
	margin-left: -20px;
	margin-right: 15px;
}

.ln-2-header-left img {
	position:relative;
}

.ln-2-text {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #3e3e3e;
}

.ln-2-text-2 {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 700;
	color: #13a5d1;
}

.ln-2-list {
	padding: 0;
	margin: 0;
	display: flex;
	flex-flow: row;
	margin-top: 30px;
}

.ln-2-list li {
	list-style: none;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: #ffffff;
	background: #f69139;
	border-radius: 50px;
	padding: 10px 45px;
	margin-right: 20px;
	transition: all 0.3s ease;
	cursor: pointer;
}

.ln-2-list li a {
	color:#fff;
}

.ln-2-list li:hover {
	background: #f9a154;
	text-decoration: none !important;
	color: #fff !important;
	transform: scale(1.01);
}

.half-curve {
	position: relative;
}

.half-curve::before {
	content: '';
    /* /wp-content/uploads/2022/11/half-curve.png */
	background: no-repeat center url('');
	width: 1359px;
	height: 176px;
	position: absolute;
	left: 0;
	top: -176px;
	display: block;
}

.ln-2 {
	background: rgb(235, 247, 249);
	background: linear-gradient(
		180deg,
		rgba(235, 247, 249, 1) 0%,
		rgba(246, 251, 253, 1) 40%,
		rgba(219, 247, 255, 0) 100%
	);
	margin-top: 220px;
}

.ln-2-right-img-wrap-2 {
	width: 306px;
	position: absolute;
	left: 0;
	bottom: 40px;
}

.ln-2-right {
	position: relative;
}

.ln-2-right-img-wrap-1 {
	width: 882px;
	position: absolute;
	left: 0;
	top: -285px;
}

.ln-2-img-wrap {
	max-width: 462px;
	margin-top: 40px;
}

/* LN 3 */
.ln-3 {
  background: rgb(237, 248, 250);
  background: linear-gradient(
    180deg,
    rgba(237, 248, 250, 1) 0%,
    rgba(246, 251, 253, 1) 40%,
    rgba(237, 248, 250, 0) 100%
  );
  margin-top: 125px;
  padding-top: 40px;
}

.ln-3-img {
  transition: all 0.8s ease;
}

.ln-3-img:hover {
  transform: scale(1.05);
}

.ln-3-top-curve {
  position: relative;
}

.ln-3-top-curve::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: -125px;
  width: 100%;
  height: 125px;
  /* /wp-content/uploads/2022/11/ln-3-top-curve.png */
  background: no-repeat center url('');
  background-size: 100% 100%;
}

.ln-3-underline {
  position: relative;
  display: inline-block;
}

.ln-3-underline::after {
  content: '';
  display: block;
  width: 199px;
  height: 18px;
  /* /wp-content/uploads/2022/11/img-ln-3-line.png */
  background: no-repeat center url('');
  background-size: contain;
  margin: 0 auto;
}

.ln-3-underline-2::after {
  width: 217px;
  height: 18px;
  /* /wp-content/uploads/2022/11/img-ln-3-line.png */
  background-image: url('');
}

.ln-3-img-wrap-2 {
  overflow: hidden;
  border: 9px solid #fff;
  box-shadow: 0 14px 66px rgba(43, 103, 148, 0.16);
}

.ln-3-img-wrap {
  max-width: 471px;
  margin: 0 auto;
}

.ln-3-row-3-left {
  padding: 0 40px 0 80px !important;
}

/* LN 4 */
.ln-4 {
  background: rgb(237, 248, 250);
  background: linear-gradient(
    180deg,
    rgba(237, 248, 250, 1) 0%,
    rgba(246, 251, 253, 1) 40%,
    rgba(237, 248, 250, 0) 100%
  );
  margin-top: 260px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ln-4 .container {
  margin-top: -60px;
	position:relative;
}

.ln-4-top-curve {
  position: relative;
}

.ln-4-top-curve::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: -171px;
  width: 100%;
  height: 171px;
  /* /wp-content/uploads/2022/11/ln-4-top-curve.png */
  background: no-repeat center url('');
  background-size: 100% 100%;
}

.ln-4-img-wrap {
  max-width: 736px;
  margin: 0 auto;
  margin-top: 30px;
}

.ln-4-img-wrap-2 {
  max-width: 1095px;
  margin: 0 auto;
}

.ln-4-text {
  max-width: 639px;
  margin: 0 auto;
  margin-top: 15px;
}

.ln-4-img-wrap-2 {
  margin-top: -110px;
}

/* LN 5 */
.ln-5 {
    /* /wp-content/uploads/2022/11/bg-ln-5-new.png */
	background: no-repeat left center url('');
	background-size: calc(100% - 85px) 100%;
	padding: 170px 0;
}

.ln-5-box {
	background: #fff;
	height: 100%;
	border-radius: 27px;
	padding: 0 40px;
	display: flex;
	flex-flow: column;
	padding-bottom: 20px;
}

.ln-5-box-num {
	width: 80px;
	height: 80px;
	background: #d51f5e;
	font-family: 'Poppins', sans-serif;
	font-size: 35px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	color: #fff;
	border-radius: 100%;
	margin: 0 auto;
	position: relative;
	top: -40px;
	margin-bottom: -40px;
}

.ln-5-box-heading {
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-size: 23px;
	font-weight: 700;
	color: #3e3e3e;
	margin: 20px 0 20px 0;
}

.ln-5-box-ul {
	padding: 0;
	margin: 0;
	margin-bottom: 30px;
}

.ln-5-box-ul li {
	list-style: none;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	color: #3e3e3e;
	position: relative;
	padding-left: 25px;
	margin-bottom: 15px;
}

.ln-5-box-ul li strong {
	font-weight: 500;
	color: #3e3e3e;
	font-size: 16px;
	display: block;
}

.ln-5-box-ul li::before {
	content: '';
	display: block;
	width: 16px;
	height: 12px;
    /* /wp-content/uploads/2022/11/ln-pink-arrow.png */
	background: no-repeat center url('');
	position: absolute;
	left: 0;
	top: 3px;
	background-size: contain;
}

.ln-5-box-ul li:last-of-type {
	margin-bottom: 0;
}

.ln-5-box-img {
	margin-top: auto;
	text-align: center;
}

.ln-5-box-img-1 {
	max-width: 222px;
}

.ln-5-box-img-2 {
	max-width: 198px;
	margin-left: auto;
	margin-right: auto;
}

.ln-5-box-img-3 {
	max-width: 146px;
	margin-left: auto;
	margin-right: auto;
}

.ln-5-row-2 {
	margin-top: 80px;
}

/* LN 6 */
.ln-6 {
  background: rgb(237, 248, 250);
  background: linear-gradient(
    0deg,
    rgba(237, 248, 250, 1) 0%,
    rgba(246, 251, 253, 1) 40%,
    rgba(237, 248, 250, 0) 100%
  );
  padding-top: 80px;
  margin-bottom: -160px;
}

.ln-6-row-1-img {
  max-width: 276px;
  margin: 0 0 0 auto;
}

.ln-6-header-num {
  width: 87px;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-family: 'Montserrat', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #fff;
  background: #d51f5e;
}

.ln-btn {
  border-radius: 50px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #fff !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #f69139;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 13px 39px;
  line-height: 1.1;
  transition: all 0.3s ease;
}

.ln-btn:hover {
  background: #f9a154;
  text-decoration: none !important;
  color: #fff !important;
  transform: scale(1.01);
}

.ln-6-row-4 {
  background: #fff;
  box-shadow: 0 32px 99px rgba(32, 92, 145, 0.09);
  border-radius: 27px;
  margin-top: 60px;
  padding: 80px;
  position: relative;
  z-index: 5;
}

.ln-6-earn-text {
  display: inline-flex;
  /* /wp-content/uploads/2022/11/bg-ln-6-earn.png */
  background: no-repeat center url('');
  background-size: 100% 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 27px;
  font-weight: 900;
  color: #ffffff;
  padding: 40px;
}

.ln-6-earn-img {
  max-width: 309px;
  margin: 0 auto;
}

.ln-6-header-wrap {
  position: relative;
  display: flex;
}

.ln-6-header-left {
  margin-right: 30px;
}

.ln-6-header-right {
  margin-top: 20px;
}

.ln-6-header-right-text-3 {
  max-width: 460px;
}

.ln-6-row-2-text {
  max-width: 600px;
  margin: 0 auto;
}

.ln-6-row-3 .btn-wrap {
  text-align: center;
  margin-top: 20px;
}

.ln-6-earn {
  text-align: center;
}

/* LN 7 */
.ln-7-num {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-family: 'Montserrat', sans-serif;
  font-size: 42px;
  font-weight: 800;
  color: #fff;
  background: #d51f5e;
  position: absolute;
  left: 0;
  top: 0;
}

.ln-7-img-1-wrap {
  max-width: 345px;
}

.ln-7-ul {
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.ln-7-ul li {
  list-style: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #4b4b4b;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.ln-7-ul li::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #d51f5e;
}

.ln-7-img-2-wrap {
  max-width: 1000px;
  margin: 30px auto 0 auto;
}

.ln-7-left {
  position: relative;
  padding-left: 50px !important;
  padding-right: 60px !important;
}

.ln-7-left-heading {
	padding-left: 50px !important;
	margin-top: 20px;
}

.ln-7-right-img {
  width: 895px;
  margin-left: -70px;
}

.ln-7 {
  background: #fff;
  padding-top: 200px;
}

.ln-7-row-2 {
  margin-top: -60px;
}

/* LN 8 */
.ln-8 {
	background: rgb(237, 248, 250);
	background: linear-gradient(
		180deg,
		rgba(228, 246, 249, 1) 0%,
		rgba(246, 251, 253, 1) 40%,
		rgba(237, 248, 250, 0) 100%
	);
	margin-top: 220px;
	margin-bottom: -360px;
}

.ln-8-top-curve {
	position: relative;
}

.ln-8-top-curve::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: -167px;
	width: 100%;
	height: 167px;
    /* /wp-content/uploads/2022/11/ln-8-top-curve.png */
	background: no-repeat center url('');
	background-size: 100% 100%;
}

.ln-8-img-wrap {
	max-width: 349px;
	margin: 0 auto;
}

.ln-8-row-1-right {
	padding-left: 110px !important;
	padding-right: 60px !important;
	position:relative;
}

.ln-8-row-1-right h2 {
	padding-left: 0 !important;
	margin-top: 20px;
	margin-bottom: 40px;
}

.ln-8-row-2-img-wrap {
	max-width: 468px;
	margin: 0 auto;
}

.ln-8-row-2 {
	margin-top: 100px;
}

.ln-8-row-2-left {
	padding: 0 80px !important;
	position: relative;
	z-index: 3;
}

.ln-8-row-2-left .btn-wrap {
	position: relative;
	z-index: 3;
}

/* LN 9 */
.ln-9 {
    /* /wp-content/uploads/2022/11/bg-ln-9.png */
	background: no-repeat center top url('');
	background-size: 100% 100%;
	padding-top: 380px;
	position: relative;
	z-index: 2;
	padding-bottom: 60px;
}

.ln-9-ul {
	padding: 0;
	margin: 0;
	margin-top: 30px;
}

.ln-9-ul li {
	list-style: none;
	position: relative;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 300;
	color: #fff;
	padding-left: 40px;
	margin-bottom: 20px;
}

.ln-9-ul li::before {
	content: '';
	display: block;
    /* /wp-content/uploads/2022/11/icon-ln-9-ribbon.png */
	background: no-repeat center url('');
	background-size: contain;
	width: 24px;
	height: 24px;
	position: absolute;
	left: 0;
	top: 0;
}

.ln-9-box-left p {
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}

.ln-9-box-left p a {
	color: #fff !important;
	text-decoration: underline !important;
}

.ln-9-box {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-top: -40px;
}

.ln-9-box-left {
	flex: 1;
	margin-right: 20px;
	text-align: right;
}

.ln-9-box-right {
	flex: 0 0 211px;
}

.ln-9-btn {
	font-size: 29px;
	padding: 15px 60px;
}

.ln-9-img-wrap {
	width: 753px;
}

.ln-9-btn-wrap {
	margin-left: 150px;
	margin-top: -130px;
}

/* Consumer Wallet App Page */
.custom-wallet-app .container {
	max-width:1140px;
}

.custom-wallet-app .site-header {
	box-shadow:0 3px 40px rgba(0,0,0,.10);
}

.custom-wallet-app .site-header-upper {
	background: #1B246A;
}

.custom-wallet-app .site-social li a {
	color: #1B246A !important;
	background: #7176A2;
}

.custom-wallet-app .site-header-lower {
	height:107px;
}

.custom-wallet-app .site-header-lower .row {
	padding-top:0;
	padding-bottom:0;
}

.custom-wallet-app .site-header.active .site-menu-sponsor-col {
	margin-top:0;
}

.custom-wallet-app .site-header.active .cwa-tshirt-left-col .img-wrap {
	width:90px;
}

.custom-wallet-app .site-header.active .site-header-lower {
	padding-top:8px;
}

/* Consumer Wallet App Section 1 */
.cwa-1 {
    /* /wp-content/uploads/2022/11/bg-consumer-wallet-app-banner.png */
  background: no-repeat right top
    url('');
  background-size: 50%;
  min-height: 1062px;
  padding-top: 210px;
  margin-bottom: 0;
}

.cwa-1-img-1 img {
  width: 100px;
  height: 28px;
}

.cwa-1-img-1 a {
  display: inline-block;
  margin-left: 10px;
}

.cwa-1-img-1 {
  text-align: right;
}

.custom-pink-btn {
  background: #d51f5e;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  color: #fff !important;
  min-height: 50px;
  min-width: 250px;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0px 8px 18px rgba(255, 44, 117, 0.4);
  border: 1px solid #d51f5e;
  text-decoration: none !important;
}

.custom-pink-btn:hover {
  transform: scale(1.01);
}

.custom-blue-btn {
  background: #12c5f8;
  border-color: #12c5f8;
  box-shadow: 0 8px 18px rgba(6, 195, 225, 0.21);
}

.custom-pink-btn-transparent {
  background: transparent;
  color: #d51f5e !important;
  box-shadow: unset;
}

.custom-blue-btn-transparent {
  background: transparent;
  color: #12c5f8 !important;
  box-shadow: unset;
  border-color: #12c5f8;
}

.cwa-1-btn-wrap .custom-pink-btn {
  margin-right: 15px;
}

.cwa-1-btn-wrap .custom-pink-btn:last-of-type {
  margin-right: 0;
}

.cwa-1-img-2 a {
  display: inline-block;
  margin-right: 15px;
}

.cwa-1-img-2 a:last-of-type {
  margin-right: 0;
}

.cwa-1-img-2 img {
  border-radius: 5px;
}

.cwa-1-img-3 {
  width: 667px;
  margin-top: -30px;
  margin-left: 40px;
}

.cwa-1-heading {
  margin-top: 45px !important;
  max-width: 340px;
}

.cwa-1-img-2 {
  margin-top: 45px;
}

.cwa-1-count-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.cwa-1-count {
  font-family: Poppins, sans-serif;
  font-size: 73px;
  font-weight: 500 !important;
  color: #224585;
  line-height: 1;
  margin: 0;
  margin-right: 15px;
}

.cwa-1-count-text {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 700 !important;
  line-height: 1.2;
  color: #393939;
	margin-bottom:0;
}

.cwa-1-row-3 {
  position: relative;
  padding-bottom: 60px;
  margin-top: -100px;
}

.cwa-1-row-3::after {
  content: '';
  position: absolute;
  left: -10%;
  bottom: 0;
  height: 1px;
  width: 120%;
  background: rgba(112, 112, 112, 0.44);
}

.cwa-1-row-3::before {
  content: '';
  position: absolute;
  left: 0%;
  bottom: -141px;
  height: 141px;
  width: 100%;
  /* /wp-content/uploads/2022/11/shadow-cwa-1.png */
  background: no-repeat center url('');
}

/* Consumer Wallet App 2 */
.cwa-2 {
  padding-top: 60px;
}

.cwa-2-row-2 {
  margin-top: 60px;
}

.cwa-2-img-wrap img {
  width: 210px;
}

.cwa-2-row-3 {
  margin-top: 45px;
}

/* Consumer Wallet App 3 */
.cwa-3-img-wrap {
  position: relative;
  width: 659px;
}

.cwa-3-img-wrap::before {
  content: '';
  position: absolute;
  left: -600px;
  top: 0;
  width: 1137px;
  height: 1271px;
  /* /wp-content/uploads/2022/11/img-cwa-3-circle.png */
  background: no-repeat center url('');
  background-size: contain;
}

.cwa-3-img-wrap img {
  position: relative;
  z-index: 1;
  margin-left: -75px;
  margin-top: 260px;
}

.cwa-ul {
  padding: 0;
}

.cwa-ul li {
  list-style-type: none;
  margin-bottom: 40px;
  position: relative;
  padding-left: 70px;
}

.cwa-ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 35px;
  /* /wp-content/uploads/2022/11/cwa-checklist-icon.png */
  background: no-repeat center url('');
  background-size: contain;
}

.cwa-ul li strong {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #393939;
  display: block;
  margin-bottom: 5px;
}

.cwa-ul li p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #8a8a8a;
}

.cwa-ul-3 {
  margin-top: 60px;
}

.cwa-btn-wrap-3 {
  padding-left: 60px;
}

.cwa-3-col-2 {
  padding-left: 100px !important;
  padding-top: 220px !important;
}

/* Consumer Wallet App 4 */
.cwa-4-col-2 {
  padding-right: 100px !important;
  padding-top: 270px !important;
}

.cwa-4-img-wrap {
  width: 1254px;
  position: absolute;
  right: -760px;
  top: 0;
}

.cwa-ul-4 {
  margin-top: 45px;
}

/* Consumer Wallet App 5 */
.cwa-5 {
  margin-top: 300px;
  /* /wp-content/uploads/2022/11/bg-cwa-5.png */
  background: no-repeat center url('');
  background-size: 100% 100%;
}

.cwa-5 .container {
  max-width: 100%;
}

.cwa-5-slider {
  margin: -45px auto 0 auto;
  max-width: 1581px;
}

.cwa-5-slider .img-wrap {
  padding: 150px 15px;
}

.cwa-5-slider .img-wrap img {
  box-shadow: unset;
  border-radius: 40px;
  transition: all 0.3s ease;
}

.cwa-5-slider .slick-active .img-wrap img {
  box-shadow: 0 27px 74px rgba(61, 110, 139, 0.28);
}

.cwa-5-col-2 {
  padding: 0 !important;
}

.cwa-5-slider {
  padding: 0 0 !important;
}

.cwa-5-slider .slick-center .img-wrap-inner img {
  transform: scale(1.1);
}

.cwa-5-slider .slick-arrow {
  width: 26px !important;
  height: 52px !important;
  background: no-repeat center !important;
  background-size: contain !important;
}

.cwa-5-slider .slick-arrow.slick-prev {
    /* /wp-content/uploads/2022/11/left-arrow-blue-consumer.png */
  background-image: url('') !important;
}

.cwa-5-slider .slick-arrow.slick-next {
    /* /wp-content/uploads/2022/11/right-arrow-blue-consumer.png */
  background-image: url('') !important;
}

.cwa-5-slider .slick-prev::after,
.cwa-5-slider .slick-next::after {
	display:none !important;
}

/* Consumer Wallet App 6 */
.cwa-6 {
  background-size: 50%;
  padding-bottom: 120px;
  padding-top: 0;
  margin-bottom: -280px;
}

.cwa-6 .container {
  position: relative;
  z-index: 5;
}

.cwa-6-img-2 img {
  border-radius: 5px;
}

.cwa-6-img-2 a {
  margin-right: 15px;
}

.cwa-6-col-2 {
  padding-left: 80px !important;
  padding-top: 80px !important;
}

.cwa-6 p {
  padding-right: 120px;
}

/* Merchant Wallet App Page */
.mwa-1 {
    /* /wp-content/uploads/2022/11/bg-merchant-app-wallet-banner.png */
  background-image: url('');
}

.mwa-1-text {
  max-width: 460px;
}

.mwa-1 .cwa-1-img-3 {
  margin-top: -60px;
  margin-left: 0;
}

.mwa-1 .cwa-1-row-3 {
  padding-top: 30px;
}

/* Merchant Wallet App Section 2 */
.mwa-2-text-2 {
  max-width: 700px;
  margin: 0 auto;
}

/* Merchant Wallet App Section 3 */
.mwa-3 .cwa-3-img-wrap {
  width: 560px;
}

.mwa-3 .cwa-3-img-wrap::before {
    /* /wp-content/uploads/2022/11/img-mwa-3-circle-1.png */
  background-image: url('');
}

/* Merchant Wallet App Section 5 */
.mwa-5-video-wrap {
  max-width: 800px;
  margin: 45px auto -30px auto;
  position: relative;
}

.mwa-5-watch-video {
  position: absolute;
  left: -230px;
  top: -175px;
  width: 225px;
}

.mwa-5-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.3s ease;
  margin-top: -72px;
  margin-left: -72px;
}

.mwa-5-btn:hover {
  transform: scale(1.05);
}

.mwa-5 {
    /* /wp-content/uploads/2022/11/bg-mwa-5.png */
  background-image: url('');
}

/* Merchant Wallet App Section 6 */
.mwa-6 {
  background-size: 50%;
  padding: 0;
  position: relative;
  padding-top: 100px;
  margin-bottom: -220px;
}

.mwa-6-img-wrap-1 {
  width: 525px;
  margin-left: -125px;
}

.mwa-6::before {
  content: '';
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
  /* /wp-content/uploads/2022/11/img-mwa-6-ring.png */
  background: no-repeat center url('');
  background-size: contain;
  width: 225px;
  height: 133px;
}

/* Consumer Wallet App Slider */
.cwa-5-slider .slick-next::after,
.cwa-5-slider .slick-next::before {
	display:none !important;
}

/* Consumer Site Menu Middle */
.site-menu-sponsor-col {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	margin-top: -5px;
}

.cwa-sponsor-name {
	display: inline-block;
	border-bottom: 1px solid #707070;
	color: #707070;
	width: 138px;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: bottom;
}

.cwa-sponsor-wrap {
	display:flex;
	flex-flow:row;
	align-items:center;
	margin-right:20px;
}

.cwa-sponsor-img {
	width:61px;
	height:61px;
	border-radius:13px;
	background: #E4E4E4 no-repeat center;
	background-size:cover;
}

.cwa-sponsor-left-col {
	margin-right:20px;
}

.cwa-sponsor-right-col {
	max-width:340px;
}

/* Merchant Wallet App */
.custom-merchant-wallet-app .cwa-tshirt-right-col .custom-pink-btn  {
	background: #12C5F8;
	border-color: #12C5F8;
	box-shadow:0px 8px 18px rgba(44,226,255,.40);
}

/* Tshirt Wrap */
.cwa-tshirt-wrap {
	display: flex;
	flex-flow: row;
	align-items: center;
}

.cwa-tshirt-left-col .img-wrap {
	width: 118px;
	box-shadow: 0 3px 6px rgba(0,0,0,.16);
	border-radius: 100px;
	border: 4.5px solid #fff;
	margin:0;
	position:relative;
}

.cwa-tshirt-left-col .img-wrap::after {
	content: '';
	display: block;
	position: absolute;
	right: -55px;
	top: 13px;
	width:48px;
	height:30px;
    /* /wp-content/uploads/2022/11/cwa-tshirt-arrow.png */
	background:no-repeat center url('');
	background-size:contain;
	z-index:1;
}

.cwa-tshirt-right-col .custom-pink-btn {
	font-size: 9px;
	width: auto;
	padding: 7px 27px !important;
	min-width: 1px;
	min-height: 1px;
}

.cwa-tshirt-left-col {
	margin-right:20px;
}

.cwa-tshirt-right-col {
	position: relative;
	padding-top: 15px;
}

.cwa-tshirt-free-text {
	position: absolute;
	right: -45px;
	bottom: -25px;
	transform: rotate(-15deg);
}

.cwa-tshirt-free-text::after {
	content:'';
	display:block;
    /* /wp-content/uploads/2022/11/yes-double-line.png */
	background:no-repeat center url('');
	background-size: contain;
	width: 108px;
	height: 35px;
	margin: 0 auto;
	transform: rotate(14deg);
	position: absolute;
	bottom: -25px;
	left: -10px;
}

/* Free Popup T Shirt */
.cwa-popup-section {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	top: 0;
	left: 0;
	overflow:auto;
	visibility:hidden;
	opacity:0;
	transition: all 0.3s ease;
}

.cwa-popup-overlay {
	background: rgba(14, 18, 52, 0.63);
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
}

.cwa-popup-section.active {
	visibility:visible;
	opacity:1;
}

.cwa-popup-wrap {
	max-width: 918px;
	margin: 0 auto;
	margin-top:60px;
	position:relative;
	z-index:99;
}

.cwa-popup-form-header {
    /* /wp-content/uploads/2022/11/bg-cwa-popup-header.jpg */
	background: transparent no-repeat center url('');
	background-size:cover;
	text-align: center;
	padding: 48px 20px 48px 45%;
	border-radius: 29px 29px 0 0;
	position:relative;
}

.cwa-popup-close {
	display: flex;
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: -10px;
	top: -10px;
	color: #d51f5e;
}

.cwa-popup-form-header p {
	margin-bottom:0;
}

.w-orange-double-underline {
	display:inline-block;
}

.w-orange-double-underline::after {
	content:'';
	display:block;
    /* /wp-content/uploads/2022/11/w-orange-double-underline.png */
	background:no-repeat center url('');
	background-size:contain;
	width: 244px;
	height: 21px;
	margin: 0 auto;
	margin-top:5px;
}

.cwa-popup-form-body {
	background:#fff;
	border-radius:0 0 29px 29px;
	padding-bottom: 40px;
}

.cwa-popup-form-body-img img {
	width: 161px;
	height: 161px;
	border-radius: 100px;
	border: 6px solid #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	display: inline-block;
	margin-top: -115px;
	position: relative;
	background: #fff;
	margin-left: 30px;
}

.cwa-label-title {
	font-size:19px;
	font-family:'Poppins', sans-serif;
	font-weight:500;
	color: #393939;
}

.cwa-label-input {
	font-size:15px;
	font-family:'Poppins', sans-serif;
	font-weight:400 !important;
	color: #393939;
}

.cwa-radio-ul {
	padding: 0 !important;
	margin: 0 !important;
	display: flex;
	flex-flow: row wrap;
}

.cwa-radio-ul li {
	font-family:'Poppins', sans-serif;
	font-size:19px;
	font-weight:400;
	color: #393939;
	list-style:none;
	margin-right:40px;
}

.cwa-radio-ul li:last-of-type {
	margin-right:0;
}

.cwa-radio-ul label {
	line-height:1;
}

.cwa-radio-ul label input {
	vertical-align:1px;
}

.cwa-popup-form input[type="text"],
.cwa-popup-form input[type="email"],
.cwa-popup-form select {
	height:38px;
	background: #F9F9F9;
	border:1px solid #BCBCBC;
	border-radius:4px;
	font-size:14px;
	font-family:'Poppins', sans-serif;
	font-weight:400;
	color: #393939;
	padding: 0 10px;
	width:75%;
	outline:0;
}

.cwa-popup-form button {
	width: auto;
	height: auto;
	font-size: 15px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	background: #D51F5E;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 11px 48px;
	box-shadow: 0px 8px 18px rgba(255, 44, 117, .40);
}

/* CWA Shirt Row */
.cwa-shirt-row {
	display:flex;
	flex-flow:row wrap;
	justify-content: space-between;
	margin-bottom:20px;
	width:100%;
}

.cwa-shirt-row > div {
	padding:0 20px;
}

/* CWA User Row */
.cwa-user-row {
	display:flex;
	flex-flow:row wrap;
	align-items: center;
	margin-bottom:30px;
}

.cwa-user-row > div {
	max-width: 40%;
	flex: 0 0 40%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	padding: 0 20px;
}

.cwa-user-col-1 {
	max-width:20% !important;
	flex:0 0 20% !important;
}

.cwa-user-row > div .cwa-label-input {
	margin-right:20px;
	flex:0 0 16%;
}

/* CWA Ship Row */
.cwa-ship-inner {
	border: 1px solid #CECECE;
	border-radius: 12px;
	display: flex;
	flex-flow: row wrap;
	padding: 0 15px 15px 15px;
}

.cwa-ship-row {
	padding:0 20px;
	margin-bottom:30px;
}

.cwa-ship-inner-col {
	max-width:50%;
	flex:0 0 50%;
}

.cwa-ship-inner-col-1 {
	max-width: 100% !important;
	flex: 0 0 100% !important;
	margin-top: -17px;
	margin-bottom:20px;
}

.cwa-ship-inner-col-1 .cwa-label-title {
	background:#fff;
	padding:0 10px;
}

.cwa-ship-inner-col > div {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	padding: 0 15px;
	margin-bottom:15px;
}

.cwa-ship-inner-col label {
	flex:0 0 29%;
	margin-right: 20px;
	font-size:14px;
}

.cwa-field-row .suf-error {
	max-width:100%;
	flex:0 0 100%;
}

.cwa-ship-inner-col input[type="text"],
.cwa-ship-inner-col input[type="email"], 
.cwa-ship-inner-col select {
	width:61%;
}

/* CWA Btn Row */
.cwa-btn-row {
	padding:0 20px;
	text-align:center;
}

.cwa-popup-form {
	padding:0 60px;
}

/* Leaderboard */
.leaderboard-1 {
	margin-bottom:140px;
}

.leaderboard-header {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	justify-content:space-between;
}

.leaderboard-filter {
	padding:0;
	margin:0;
	display:flex;
	flex-flow:row wrap;
}

.leaderboard-filter li {
	list-style:none;
}

.leaderboard-filter li a {
	font-family:'Poppins', sans-serif;
	font-size:14px;
	font-weight:300;
	color: #4B4B4B;
	border-radius: 50px;
	padding: 3px 17px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.leaderboard-filter li a:hover {
	color:#000;
}

.leaderboard-filter li a.active {
	background: #31A7D6;
	color:#fff;
}

.leaderboard-search-right {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.leaderboard-filter-select-wrap {
	align-items:center;
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: #818181;
	padding: 10px 25px;
}

.leaderboard-select {
	background: #fff;
	border: 1px solid #8f8f9d;
	font-family: Poppins, sans-serif;
	color: #333;
	height: 35px;
	padding-left: 15px;
	margin-left:10px;
}

/* Leaderboard Table */
.leaderboard-tbl-header {
	display:flex;
	flex-flow:row wrap;
	background:rgba(49, 167, 214, .16);
	border-radius:14px;
}

.leaderboard-tbl-header > div {
	flex: 1;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #31A7D6;
	padding: 10px 25px;
}

/* Leaderboard Table Body */
.leaderboard-body {
	margin-top:30px;
}

.leaderboard-tbl-body-row {
	display:flex;
	flex-flow:row wrap;
}

.leaderboard-tbl-body-row:nth-of-type(even) {
	background: #F6FBFD;
}

.leaderboard-tbl-body-row > div {
	flex: 1;
	font-family:'Poppins', sans-serif;
	font-size:15px;
	font-weight:400;
	color: #818181;
	padding: 10px 25px;
}

.leaderboard-tbl-body-row a {
	color:#818181;
	text-decoration:underline;
}

/* Leaderboard Pagination */
.leaderboard-footer {
	margin-top:30px;
}

.leaderboard-pagination {
	padding:0;
	margin:0px 0 0 0;
	display:flex;
	flex-flow: row wrap;
	justify-content:center;
}

.leaderboard-pagination li {
	list-style:none;
}

.leaderboard-pagination li a {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #818181;
	border: 1px solid transparent;
	border-radius: 50%;
	min-height: 24px;
	min-width: 24px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.leaderboard-pagination li a:hover {
	color: #000;
}

.leaderboard-pagination li a.active {
	border-color: #31A7D6;
}

.no-record-found {
	text-align:center; 
	padding: 20px; 
	font-size:15px;
}

/* Affiliation Counter Link */
.custom-affiliate-code-count {
	font-size: 13px;
	margin-top: 5px;
	font-family: Poppins, sans-serif;
	font-weight: 400;
}

/* Wallet Compensation Page */
.wcp-1 {
	position:relative;
	margin-top:171px;
	background: rgb(233,250,255);
	background: linear-gradient(180deg, rgba(233,250,255,1) 0%, rgba(240,252,255,1) 40%, rgba(240,252,255,0) 100%);
	padding-top:40px;
	margin-bottom: -200px;
}

.wcp-1::before {
	content:'';
    /* /wp-content/uploads/2022/12/wcp-curve.jpg */
	background:no-repeat center url('');
	background-size:cover;
	width:100%;
	height:141px;
	position:absolute;
	left:0;
	top:0;
}

.wcp-1 .container {
	position:relative;
	z-index:3;
}

.wcp-body {
	background: #fff;
	box-shadow: 0px 21px 86px rgba(117,136,85,.11);
	padding: 20px;
	border-radius: 36px;
	margin-top:40px;
}

/* NFT Territories Page */
.nft-territories-header {
	flex-flow:column;
	margin-bottom: 20px;
}

.nft-territories-header p {
	max-width: 661px;
	text-align: center;
	margin-top: 15px;
}

.nft-territories-body {
	max-width:842px;
	margin:0 auto 40px auto;
}

/* Sign Up Coupon */
.suc-add-btn-wrap {
	width:100%;
	text-align:right;
}

.suc-add-btn-wrap .site-btn {
	width: auto;
	min-width: 1px;
	font-size: 14px;
	min-height: 1px;
	padding: 10px 20px;
}

#suc-body {
	margin-top:30px;
}

/* SUC Popup */
.suc-popup-wrap {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	visibility:hidden;
	opacity:0;
	transition:all 0.3s ease;
	overflow-y:scroll;
}

.suc-popup-wrap.active {
	visibility: visible;
	opacity: 1;
	z-index: 999999999;
}

.suc-popup-overlay {
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background: rgba(0,0,0,0.5);
}

.suc-popup-inner {
	background: #fff;
	position: relative;
	max-width: 600px;
	margin: 100px auto;
	border-radius:10px;
	padding:40px;
}

.suc-popup-inner h2 {
	font-size:25px;
}

.suc-form-row {
	margin-bottom:15px;
}

.suc-form-label {
	margin-bottom:5px;
}

/* SUC Close */
.suc-close {
	width: 30px;
	height: 30px;
	background: #EEA33D;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	position: absolute;
	right: -10px;
	top: -10px;
	cursor:pointer;
}

/* SUC Form Label */
.suc-form-label label {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #3E3E3E;
}

.suc-form-input input,
.suc-form-input select {
	background: #F6F6F6;
	height: 40px;
	border: 1px solid #C6DBB8;
	border-radius: 5px;
	width: 100%;
	font-size: 14px;
	padding: 0 20px;
	color: #3E3E3E;
}

.suc-form-error {
	color: red;
	font-size: 13px;
	font-weight: 400;
	margin-top: 5px;
}

/* SUC Form Submit */
.suc-form-submit {
	margin-top: 25px;
	margin-bottom: 0;	
}

.suc-form-submit button {
	width: 100%;
	font-size: 16px;
	height: auto;
	padding: 15px;
}

#suc-tbl-body div[data-label="Code"] {
	width: 20%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.suc-link-code {
	white-space:nowrap;
	cursor:pointer;
	transition:all 0.3s ease;
}

.suc-link-code:hover {
	color: #000;
}

/* SUF Promo Code */
.suf-promo-code {
	color: #D6225E;
	text-decoration:underline;
}

.suf-promo-code-wrap {
	margin-top:20px;
}

/* Registered Users */
.leaderboard-tbl-body-row > div {
	flex: 1;
}

.custom-aff-code-text {
	width: 200px;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
}

#ru-tbl-body .leaderboard-tbl-body-row > div {
	flex:1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* New Site Header */
.new-site-social-col {
	flex: 0 0 250px;
}
.new-site-header .site-social li a {
	background:#31A7D6 !important;
	color:#fff !important;
	width:26px !important;
	height:26px !important;
	font-size:16px !important;
}

.new-site-header .site-social li a:hover {
	background: #36B5E8 !important;
}

/* New Home */
.new-home-1 {
	margin-top: 164px;
	background: #1C245D;
	padding: 30px 0 10px 0;
}

.w-sponsor-header .new-home-1 {
	margin-top:220px;
}

/* New Home 2 */
.new-home-2 {
	padding-top:20px;
	padding-bottom: 60px;
}

.new-home-2-right-img-1 {
	max-width:465px;
}

.new-home-2-right-img-2 {
	max-width:639px;
	margin-left: -120px;
	margin-top: 100px;
	margin-bottom: -83px;
}

.new-home-2 .home-1-row-2 {
	margin-top:0;
}

.new-home-2-rating-wrap {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.new-home-2-rating-wrap img {
	width:175px;
}

.new-home-2-rating-wrap .img-wrap {
	margin-right:20px;
}

.new-home-2-dl-wrap img {
	width:125px;
	border-radius:5px;
}

.new-home-2-dl-wrap .img-wrap {
	margin-right:15px;
}

.new-home-2-dl-wrap {
	margin-top:20px;
}

.new-home-2-right {
	display:flex;
	flex-flow: row;
	width:879px;
}

/* New Home 3 */
.new-home-3 {
	background: #EDFBFF;
	position: relative;
	margin-top: -200px;
	padding-top: 200px;
	padding-bottom: 0 !important;
}

.new-home-3-row {
	border-bottom:1px solid #D3DCDE;
	padding: 80px 20px 40px 20px;
}

.new-home-3-row::after {
	content:'';
	display:block;
    /* /wp-content/uploads/2023/01/new-home-3-shadow.png */
	background:no-repeat center url('');
	background-size: cover;
	width: 100%;
	height: 141px;
	margin: 0 auto;
	position: absolute;
	bottom: -141px;
	z-index: 5;
	max-width: 1146px;
}

.new-home-3-col-inner {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.new-home-3-col-text-1 {
	font-family:'Poppins', sans-serif;
	font-size:55px;
	font-weight:500;
	color: #224585;
	line-height:1;
	text-align:right;
	padding-right:20px;
}

.new-home-3-col-text-2 {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 700;
	color: #393939;
	flex: 1;
	line-height: 1.2;
	margin-left: 0;
}

/* New Home 4 */
.new-home-4 {
	background: rgb(237,251,255);
	background: linear-gradient(180deg, rgba(237,251,255,1) 0%, rgba(255,255,255,1) 100%); 
	padding-top:80px;
	padding-bottom:60px;
}

.new-home-4 .container {
	position:relative;
	z-index:5;
}

.new-home-4-row-2 {
	margin-top:20px !important;
}

.new-home-4-img-left {
	max-width:507px;
	margin:0 auto;
}

.new-home-4-img-text-wrap {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	margin-top:5px;
}

.new-home-4-img-text-wrap {
	margin-top:30px;
}

.new-home-4-img-text-wrap .text-wrap {
	flex: 1;
	padding-left: 40px;
}

.new-home-4-img-text-wrap h3 {
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	color: #393939;
	margin-bottom: 5px;
}

.new-home-4-img-text-wrap p {
	font-size:17px;
	font-weight:400;
	font-family:'Poppins', sans-serif;
	color: #8A8A8A;
}

.new-home-4-img-text-wrap .img-wrap {
	max-width:110px;
}

/* New Home 5 */
.new-home-5 {
	margin:0 !important;
	padding:0 !important;
	position:relative;
	padding-top:200px !important;
}

.new-home-5::before {
	content:'';
	display:block;
	width:100%;
	height:166px;
    /* /wp-content/uploads/2023/01/new-home-5.jpg */
	background:no-repeat center url('');
	background-size:cover;
	position:absolute;
	left:0;
	top:0;
}

.new-home-5 .mission-img-wrap {
	max-width:400px;
}

/* CWA Step Popup Form */
.cwa-popup-form-line {
	height:2px;
	background: #F69F54;
	margin-top:50px;
}

.cwa-popup-form-step {
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 21px;
	color: #F69F54;
	text-transform: uppercase;
	margin-top: -17px;
}

.cwa-popup-form-step span {
	display:inline-block;
	background:#fff;
	padding: 0 20px;
}

.cwa-popup-form-step-text {
	font-family:'Poppins', sans-serif;
	font-size:19px;
	font-weight:700;
	color: #393939;
	text-align:center;
}

.cwa-popup-form-img-wrap {
	padding:0;
	margin:0;
	display:flex;
	flex-flow: row wrap;
	justify-content:center;
}

.cwa-popup-form-img-wrap li {
	list-style:none;
	margin:0 10px;
}

.cwa-popup-form-img-wrap img {
	width:150px;
}

.cwa-popup-form-step-wrap {
	padding:0 60px;
}

.my-network-count {
	color: #31A7D6 !important;
	text-decoration:underline;
}

.my-network-count:hover {
	color: #D6225E !important;
}

/* New Sign Up */
.sign-up-2 .sign-up-2-container-1 {
	background: #F5FDFF;
	border: 1px solid #AECDE5;
	border-radius: 9px;
	padding-bottom: 0;
	margin-bottom: 30px;
	box-shadow: unset;
}

.sign-up-2-container-1 .sign-up-sponsor-img-tshirt {
	width:100px;
}

/* Free Tshirt A */
#fta-tbl-body .leaderboard-tbl-body-row > div,
.fta-tbl-body .leaderboard-tbl-body-row > div{
	max-width: 16.6%;
	flex: 0 0 16.6%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.fta-main .site-footer-2 {
	position:relative;
}

/* Free Tshirt A Popup */
.suc-popup-details-row {
	margin-top:40px;
	text-align:center;
}

.suc-popup-details-col {
	display:flex;
	flex-flow:row wrap;
}

.suc-popup-details-left {
	flex: 0 0 50%;
	max-width: 50%;
	font-weight: 700;
	font-size: 16px;
	color: #3e3e3e;
	border: 1px solid #999;
	padding: 10px 15px;
	text-align:left;
}

.suc-popup-details-right {
	flex: 0 0 50%;
	max-width: 50%;
	font-weight: 400;
	font-size: 16px;
	color: #3e3e3e;
	border: 1px solid #999;
	padding: 10px 15px;
	text-align: left;
	word-wrap: break-word;
}

#fta-view-popup,
.fta-view-popup{
	overflow:scroll;
}

#fta-view-popup .suc-popup-overlay,
.fta-view-popup .suc-popup-overlay{
	position:fixed;
}

#fta-view-popup .suc-popup-inner,
.fta-view-popup .suc-popup-inner{
	max-width:1080px;
}

/* Responsive */
.mobile-flex {
	display:none !important;
}

.mobile {
	display:none !important;
}

.site-menu-sponsor-col-mobile {
	display:none !important;
} 

/* Leaderboard Search */
.leaderboard-search {
	display:flex;
	flex-flow:row;
}

.leaderboard-search input {
	height: 45px;
	font-size: 14px;
	padding: 0 20px;
}

.leaderboard-search button {
	height: 45px;
	border-radius: 0;
	font-size: 14px;
	padding: 0 20px;
	text-transform: none;
	background: #31a7d6;
	transform:unset !important;
}

.leaderboard-search button:hover {
	background: #34aedf;
}

.leaderboard-header-2 {
	margin-top:40px;
}

.sign-up-2 .sign-up-2-container-2 {
	padding:40px 0;
}

.sign-up-2 .sign-up-form input[type="text"], 
.sign-up-2 .sign-up-form input[type="email"], 
.sign-up-2 .sign-up-form input[type="phone"], 
.sign-up-2 .sign-up-form input[type="password"] {
	background: #F8F8F8;
}

.sign-up-2 .sign-up-form input[type="text"]:focus, 
.sign-up-2 .sign-up-form input[type="email"]:focus, 
.sign-up-2 .sign-up-form input[type="phone"]:focus, 
.sign-up-2 .sign-up-form input[type="password"]:focus {
	background: #fff;
}

/* Get Started New */
.gsn-1-new {
    /* /wp-content/uploads/2023/01/gsn-1-new-bg.png */
	background:no-repeat center url('');
	background-size: cover;
	padding-bottom: 300px;
	padding-top:220px;
}

.ul-white-text li {
	color:#fff;
}

.ul-blue-icon li::before {
    /* /wp-content/uploads/2023/01/blue-check-icon.png */
	background-image:url('');
}

.gsn-1-new-video-wrap {
	position:relative;
}

.gsn-1-new-video-wrap-img {
	position: absolute;
	right: -130px;
	width: 350px;
	top: 100px;
}

.gsn-2-new {
	margin-top: -145px;
	padding-top:40px;
}

/* Tools New */
/* Tools 1 New */
.tools-1-new {
    /* /wp-content/uploads/2023/01/tools-1-new-bg.png */
	background:no-repeat center url('');
	background-size:cover;
	padding-bottom:200px;
}

.tools-1-new-left {
	margin-bottom:45px;
}

.tools-2-new {
	padding:0;
	margin:0;
	margin-top:-20px;
	padding-bottom:40px;
}

/* Tools 3 New */
.tools-3-new {
	background: rgb(233,250,255);
	background: linear-gradient(180deg, rgb(232, 251, 255) 50%, rgba(255,255,255,0) 100%);
	padding-top:180px;
	padding-bottom:40px;
}

.tools-3-new .container {
	position:relative;
}

.tools-3-new-img {
	max-width:600px;
	margin:0 auto;
}

.tools-3-new .row {
	max-width: 80%;
	margin: 0 auto;
}

/* Tools 4 New */
.tools-4-new {
	background: rgb(233,250,255);
	background: linear-gradient(180deg, rgba(233,250,255,1) 0%, rgba(255,255,255,0) 100%);
	padding-top: 180px;
	margin-bottom: -200px;
}

.tools-4-new .container {
	position:relative;
	z-index:4;
}

.ul-affiliate {
	padding:0 !important;
	margin:0 !important;
}

.ul-affiliate li {
	list-style-type:none !important;
	font-size:16px;
	font-style:italic;
	font-weight:400;
	color: #31A7D6;
}

.ul-affiliate li a {
	color: #31A7D6;
}

.ul-affiliate li a:hover {
	text-decoration:underline;
}

.tools-4-new-heading {
	margin-bottom:15px;
}

.tools-4-new-img {
	max-width: 500px;
}

.tools-4-new .row {
	max-width:80%;
	margin:0 auto;
}

/* Tools Logged */
.tools-logged-new-1 {
	padding-bottom:120px;
}

.tools-new .site-footer-2 {
	position:relative;
}

.tools-new .site-footer-2 .site-footer-lower {
	margin-top:0;
}

/* Send Verification Code */
.send-verification-code {
	background: #eea33d;
	color: #fff !important;
	border-radius: 50px;
	padding: 5px 15px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.send-verification-code:hover {
	background: #eea747;
}

/* Earning Options */
.eo-1 {
	position:relative;
	background: rgba(232,251,255,1);
	background: linear-gradient(180deg, rgba(232,251,255,1) 90%, rgba(255,255,255,0) 100%);
	margin-bottom:-240px;
	margin-top: 140px;
	padding-top: 30px;
}

.eo-1 .container {
	position:relative;
	z-index:3;
}

.eo-1::before {
	content:'';
	display:block;
	width:100%;
	height:154px;
    /* /wp-content/uploads/2023/01/img-earning-options-curve.jpg */
	background:no-repeat center url('');
	background-size:cover;
	position:absolute;
	left:0;
	top:0;
}

/* Earning Options Box */
.eo-1-box {
	background: #FFFFFF;
	border:1px solid #C0CFD4;
	box-shadow:0 20px 63px rgba(144,161,167,.10);
	border-radius:23px;
	max-width: 902px;
	margin: 0 auto;
	margin-top:60px;
	padding:60px;
}

.eo-1-box .btn-wrap {
	margin-top:40px;
}

/* EO Payments */
.eo-payments {
	display:flex;
	flex-flow: row wrap;
	margin:0;
	padding: 0;
	justify-content: space-between;
	margin-bottom: 30px;
	margin-top: 40px;
}

.eo-payments li {
	list-style:none;
	padding:0 10px;
}

.eo-payments li span {
	font-family:'Poppins', sans-serif;
	font-size:18px;
	font-weight:400;
	color: #4B4B4B;
	margin-left:10px;
}

.eo-payments li input:checked + span {
	color:#4B4B4B;
	font-size:20px;
}

.eo-1-box-divider {
	height:1px;
	background:#BEBEBE;
	margin-top:60px;
}

.eo-1-box-upgrade-heading {
	margin-top:-15px;
	margin-bottom:10px;
}

.eo-1-box-upgrade-heading span {
	display:inline-block;
	padding: 0 20px;
	background:#fff;
}

.eo-btn {
	font-family:'Poppins', sans-serif;
	font-size:24px;
	font-weight:500;
	color: #FFFFFF;
	background: #EEA33D;
	border-radius:50px;
	border:0;
	min-width:211px;
	transition:all 0.3s ease-in-out;
	min-height:46px;
	font-size:18px;
	text-transform:uppercase;
}

.eo-btn:hover {
	background: #FFB044;
}

/* Earning Options Upgrade */
.eo-1-box-upgrade-row {
	display:flex;
	flex-flow:row wrap;
}

.eo-ul {
	padding:0;
	margin:0;
}

.eo-ul li {
	list-style-type:none;
	font-family:'Poppins', sans-serif;
	font-weight:300;
	font-size:16px;
	color: #4B4B4B;
	position:relative;
	padding-left:40px;
	margin-bottom:10px;
}

.eo-ul li::before {
	content:'';
	display:block;
	width:20px;
	height:16px;
    /* /wp-content/uploads/2023/01/icon-check-orange.png */
	background:no-repeat center url('');
	background-size:contain;
	position:absolute;
	left:0;
	top:5px;
}

.eo-1-box-upgrade-col {
	flex: 0 0 30%;
	max-width:30%;
}

.eo-1-box-upgrade-img-1 img {
	max-width:265px;
}

.eo-1-box-upgrade-img-2 img {
	max-width:289px;
}

.eo-1-box-upgrade-col-2 {
	margin-top:40px;
	max-width:40%;
	flex:0 0 40%;
}

.eo-1-box-upgrade .btn-wrap {
	margin-top:0;
	margin-bottom:20px;
}

/* Popup Request Star */
.cwa-popup-request-star img {
	width:130px;
}

.cwa-popup-request-star {
	margin-top:30px;
}

.cwa-popup-request-star .img-wrap {
	margin-bottom:10px;
}

/* Trading Wrap New */
.ln-1-trading-wrap-new,
.ln-1-download-wrap-new {
	position:relative;
	z-index:2;
}

/* Custom Landing 2 */
.custom-landing-new .site-header {
	display:block;
}

.custom-landing-new .site-header-lower {
	display:none;
}

/* Site Header Sponsor */
.site-header-sponsor {
	background: rgb(255,195,75);
	background: linear-gradient(93deg, rgba(255,195,75,1) 0%, rgba(235,128,24,1) 100%); 
	display:none;
	position:relative;
}

.sponsor-header-ads-close {
	position: absolute;
	right: 15px;
	top: 5px;
	color: #fff;
}

.sponsor-header-ads-close a {
	color:#fff;
	font-size:25px;
}

.w-sponsor-header .site-header-sponsor {
	display:block;
}

.site-header-sponsor-tshirt {
	border: 3.5px solid #fff;
	border-radius: 50px;
	width: 76px;
	height: 76px;
	overflow: hidden;
	margin:0 10px 0 0;
}

.site-header-sponsor-btn {
	background: #D51F5E;
	box-shadow: 0px 8px 18px rgba(255, 44, 117, .40);
	color: #fff !important;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 500;
	font-size: 12px;
	border-radius: 50px;
	text-transform: uppercase;
	display: inline-flex;
	padding: 8px 24px;
	white-space:nowrap;
}

.site-header-sponsor-left p {
	margin:0;
}

p.site-header-sponsor-for-free {
	font-family: 'Hand of Sean Demo', sans-serif;
	color:#fff;
	position:relative;
	font-size:15px;
	line-height:1;
	margin-left: 20px;
	margin-top: 20px;
}

.site-header-sponsor-for-free::before {
	content:'';
	display:block;
	position:absolute;
	left: -30px;
	top: -15px;
	width:60px;
	height:23px;
    /* /wp-content/uploads/2023/01/img-left-arrow.png */
	background:no-repeat center url('');
	background-size:contain;
}

.site-header-sponsor-for-free::after {
	content:'';
	display:block;
	left:0;
	top:0;
	width:144px;
	height:46px;
    /* /wp-content/uploads/2023/01/img-double-line.png */
	background:no-repeat center url('');
	background-size:contain;
	width:120px;
}

.site-header-sponsor-for-free .text-wrap {
	transform: rotate(-15deg);
	display: block;
	margin-bottom: -5px;
}

.site-header-sponsor-left .btn-wrap {
	margin:0 15px;
}

.site-header-sponsor-right-p {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 15px;
	color: #fff;
	margin: 0;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.site-header-sponsor-right-p span {
	margin-right:1rem;
}

.site-header-sponsor-right-p div {
	display:flex;
	flex-flow:column;
}

.site-header-sponsor-right-p img {
	width: 54px;
	border-radius: 50px;
	overflow: hidden;
	height: 54px;
	object-fit: cover;
}

.site-header-sponsor-right-p a {
	color: #fff;
	text-decoration: underline;
	max-width: 140px;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	margin:0 5px;
	font-size:12px;
}

.site-header-sponsor-right {
	justify-content:flex-end;
}

/* Site Header Sponsor Active */


/* LN 2 New */
.ln-2-new {
	background:transparent;
	margin-top: 60px;
}

.w-sponsor-header .ln-2-new {
	margin-top:140px;
}

.ln-2-right-img-wrap-1-new {
	top:-240px;
}

.ln-2-right-img-wrap-2-new {
	width: 275px;
	position: absolute;
	left: 0;
	bottom: 0;
}

/* Consumer Wallet App New */
.custom-wallet-app-new .site-header-lower {
	background:transparent;
	padding-top:10px;
	height:90px;
}

.custom-wallet-app-new .site-header {
	box-shadow:unset;
}

.custom-wallet-app-new .site-translation-selected-lang {
	color:#fff;
}

.custom-wallet-app-new .site-translation-selected-down {
	color:#fff;
}

.custom-wallet-app-new .site-header.active .site-header-lower {
	background:#fff;
}

.custom-wallet-app-new .site-header.active {
	box-shadow: 0 3px 40px rgba(0,0,0,.10);
}

.custom-wallet-app-new .site-header.active .site-translation-selected-lang {
	color: #1B246A;
}

.custom-wallet-app-new .site-header.active .site-translation-selected-down {
	color: #1B246A;
}

/* Confirmation Popup */
.cwa-popup-section-ty .cwa-popup-form-header {
	padding: 40px 80px 60px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cwa-popup-section-ty .cwa-popup-form-header img {
	width:100px;
}

.cwa-popup-section-ty .cwa-popup-form-header p {
	line-height: 1.2;
	font-size: 42px !important;
}

.cwa-popup-section-ty .video-wrap {
	max-width:612px;
	border:6px solid #fff;
	box-shadow: 0 10px 57px rgba(0,0,0,0.16);
	margin:0 auto;
	margin-top:-30px;
	position:relative;
	padding-top:40%;
}

.cwa-popup-section-ty .video-wrap iframe {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
}

.cwa-popup-section-ty .btn-wrap {
	text-align:center;
	margin-top:30px;
	margin-bottom:20px;
}

.cwa-popup-section-ty .btn-wrap .site-btn {
	font-size: 18px;
	letter-spacing: 0;
	padding: 15px 45px;
}

.site-header-sponsor-tshirt-right {
	display:flex;
	align-items:center;
}

/* Renew Now */
.open-renew-subscription-popup {
	color: #31a7d6 !important;
}

/* Promo 1 */
.promo-1 {
	position:relative;
	margin-top:0;
}

.w-sponsor-header .promo-1 {
	margin-top:96px;
}

.promo-1::after {
	content:'';
	display:block;
	width:100%;
	height:903px;
    /* /wp-content/uploads/2023/01/img-promo-1-curve.png */
	background:no-repeat center url('');
	position:absolute;
	left:0;
	bottom:120px;
	display:none;
}

.custom-promo-main .site-header-lower {
	display:none !important;
}

.custom-promo-main .site-footer {
	display:none !important;
}

.custom-promo-main .container {
	max-width:1180px;
}

.promo-1 {
    /* /wp-content/uploads/2023/01/bg-promo-1-new.jpg */
	background:no-repeat center url('');
	background-size:cover;
	padding: 20px 0 300px 0;
}

.promo-1-left {
	position: relative;
	z-index: 1;
}

.promo-1-header {
	display:flex;
	flex-flow: row wrap;
	align-items:center;
	margin-left:-30px;
	margin-bottom:-30px;
}

.promo-1-header p {
	margin:0;
	margin-bottom: 30px;
	margin-left: 10px;
}

.promo-1-rating {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.promo-1-dl-wrap {
	display:flex;
	flex-flow:row wrap;
}

.promo-btn {
	background: #FFC533;
	color: #1E2871 !important;
	font-family:'Poppins', sans-serif;
	font-size:27px;
	font-weight:700;
	text-transform:uppercase;
	border-radius:50px;
	padding: 10px 40px;
	display: inline-flex;
	transition: all 0.3s ease;
}

.promo-btn:hover {
	transform:scale(1.02);
}

.promo-1-rating p {
	margin-left:15px;
}

.promo-1-dl-wrap .img-wrap {
	margin-right:20px;
}

.promo-1-dl-wrap {
	margin-top:5px;
}

.promo-btn-wrap {
	margin-top:15px;
}

.promo-free-img-wrap {
	margin-top: 60px;
	margin-left: -40px;
}

.promo-1-client-img-wrap {
	margin-top: 20px;
	position: absolute;
	left: -60px;
	top: 0;
	width: 550px;
}

.promo-1-app-img-wrap {
	position: absolute;
	left: 60px;
	top: 400px;
	width: 550px;
	z-index: 1;
}

/* Promo 1 Right */
.promo-1-right {
	position:relative;
}

/* Promo 2 */
.promo-2 {
	background: rgb(30,40,113);
	background: linear-gradient(135deg, rgba(30,40,113,1) 0%, rgba(33,135,213,1) 100%);
    /* /wp-content/uploads/2023/01/bg-promo-2.png */
	background:no-repeat center url('');
	background-size: 100% 100%;
	position: relative;
	margin-top: -1080px;
	padding: 0;
	padding-top: 0px;
	padding-bottom:60px;
	min-height: 1198px;
	padding-top: 640px;
}

.promo-2-heading {
	transform: rotate(-10deg);
}

.promo-2-arrow {
	text-align: center;
	margin-left: 120px;
	position: relative;
	z-index: 1;
}

.promo-2-video-wrap {
	max-width:622px;
	border:10px solid #fff;
	margin:0 auto;
	position:relative;
	margin-top: -140px;
}

.promo-2-video-play-wrap {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -50px;
	margin-top: -50px;
	transition:all 0.3s ease;
}

.promo-2-video-play-wrap:hover {
	transform:scale(1.1);
}

/* Tools New 2 */
.tools-new-2-logged-1 {
	background-size:100% 100%;
	padding-top: 180px;
}

.tools-new-2-img-wrap {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -140px;
}

/* Tools New 2 Section 2 */
.tools-new-2-logged-2 {
	padding-top:40px;
}

.tools-new-2-logged-2-row-1  a {
	cursor:pointer;
	display:block;
	overflow:hidden;
}

.tools-new-2-logged-2-row-1 {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.tools-new-2-logged-2-row-1 h2 {
	font-family:'Poppins', sans-serif;
	font-size:22px;
	font-weight:500;
	color: #585858;
	text-align:center;
}

.tools-new-2-logged-2-row-1 .video-wrap {
	text-align:center;
	overflow:hidden;
}

.tools-new-2-logged-2-row-1 .col {
	margin-bottom:40px;
}

/* Tools New 2 Logged Section 3 */
.tools-new-2-logged-3 {
	position:relative;
	background: rgb(244,254,255);
	background: linear-gradient(to bottom, rgba(244,254,255,1) 75%, rgba(255,255,255,1) 100%);
	padding-top: 180px;
	padding-bottom:100px;
	margin-top:-220px;
}

.tools-new-2-logged-3::before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height: 158px;
    /* /wp-content/uploads/2023/01/tools-new-2-logged-curve.jpg */
	background:no-repeat center url('');
	background-size:cover;
}

.tools-new-2-logged-3 .container {
	position:relative;
	z-index:1;
}

.tools-new-2-logged-3-box {
	background: #fff;
	border: 1px solid #CEE9F1;
	box-shadow: 0 10px 44px rgba(27, 36, 106, .13);
	border-radius: 20px;
	max-width: 720px;
	margin: 0 auto;
	padding: 60px;
}

.tools-new-2-logged-3-box p {
	font-size:16px;
	color: #3E3E3E;
	font-weight:400;
}

.tools-new-2-logged-3-box ul {
	margin:0;
	padding:0;
}

.tools-new-2-logged-3-box ul li {
	list-style:none;
	color: #40CAF1;
	font-weight:600;
}

/* Tools Video */
.tools-video-wrap-new a {
	display:flex;
	position:relative;
}

.tools-video-wrap-new a::after {
	content:'';
	display:block;
	width:78px;
	height:78px;
    /* /wp-content/uploads/2023/01/img-play-video.png */
	background:no-repeat center url('');
	background-size:cover;
	position:absolute;
	left:50%;
	margin-top: -39px;
	top:50%;
	margin-left:-39px;
}

.site-btn-subtext {
	padding: 16px 48px;
}

.site-btn-subtext span {
	display:block;
	font-size:60%;
	margin-top:5px;
}

/* Sign Up Button */
.signup-button {
	height: auto !important;
	text-transform: uppercase !important;
	line-height: 1.3;
	padding: 15px !important;
	width: auto !important;
	padding: 15px 60px !important;
}

.signup-button span {
	display:block;
	font-size: 60%;
}

/* QR Link Button */
.custom-qr-link {
	background: #2b84b9;
	color: #fff !important;
	font-size: 14px;
	border-radius: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.custom-qr-link:hover {
	opacity:.9;
}

/* Malar 1 */
.malar-1 {
	position:relative;
	margin-top:140px;
	background: rgb(233,250,255);
	background: linear-gradient(180deg, rgba(233,250,255,1) 0%, rgba(240,252,255,1) 40%, rgba(219,255,248,0) 100%);
	padding-top:160px;
	margin-bottom: -260px;
}

.malar-1 .container {
	position:relative;
}

.malar-1-heading {
	margin-bottom:45px;
}

.malar-1::before {
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
    /* /wp-content/uploads/2023/02/malar-curve.jpg */
	background:no-repeat center url('');
	background-size:cover;
	display:block;
	height:154px;
}

.malar-1 .container {
	position:relative;
	z-index:3;
}

.malar-left-wrap {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
}

.malar-left-num p {
	margin: 0;
	line-height: 1;
	background: #fff;
	color: #31A7D6;
	font-family: 'Poppins', sans-serif;
	font-size: 29px;
	font-weight: 700;
	border-radius: 50px;
	border: 1px solid transparent;
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.malar-left-num {
	margin-right:20px;
}

.malar-right-info {
	flex: 1;
}

.malar-1-row {
	background:rgba(38, 100, 159, 0.03);
	border-radius:27px;
	max-width:1066px;
	margin:0 auto;
	padding: 40px 0;
	margin-bottom: 20px;
}

.malar-right-title {
	font-size:22px;
	font-family:'Poppins', sans-serif;
	font-weight:600;
	color: #3E3E3E;
	margin-bottom:10px;
	margin-top:10px;
}

.malar-right-content {
	font-size:16px;
	font-weight:300;
	color: #4B4B4B;
}

.malar-right-col p {
	font-family:'Poppins', sans-serif;
	font-size:15px;
	color: #4B4B4B;
	font-weight:300;
}

.malar-left-col {
	border-right: 1px solid rgba(49,167,214,.65);
	max-width:33.3%;
	flex:0 0 33.3%;
	padding: 0 40px;
}

.malar-right-col {
	max-width: 66.6%;
	flex: 0 0 66.6%;
	padding: 0 50px;
}

/* Malar UL */
.malar-ul {
	padding:0;
}

.malar-ul li {
	list-style-type:none;
	font-style: italic;
	color: #37C7F0;
	font-weight: 500;
	font-family: Poppins, sans-serif;
	font-size: 15px;
	margin-bottom: 5px;
}

.malar-right-3-col {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.malar-right-3-col p {
	flex: 1;
}

/* Malar 4 */
.malar-right-4-col {
	display:flex;
	align-items:center;
}

.malar-download-wrap {
	flex: 1;
	padding: 0 20px;
}

.malar-download a {
	display: flex;
	align-items: center;
	border: 2px solid #31A7D6;
	border-radius: 50px;
	justify-content: center;
	font-family: Poppins, sans-serif;
	color: #4B4B4B;
	font-size: 18px;
	font-weight: 500;
	padding: 0 20px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.malar-download a:hover {
	background: #31A7D6;
	color: #fff;
}

.malar-download a img {
	margin-top: -20px;
	margin-right: 10px;
	width: 45px;
}

.malar-download {
	margin-bottom:25px;
}

.malar-download:last-of-type {
	margin-bottom:0;
}

/* New Profile Details 2 */
.custom-affiliate-code-wrap-2 {
	display:flex;
	flex-flow: row wrap;
}

.custom-qr-link-wrap img:hover {
	opacity:.6;
}

.custom-affiliate-code-right {
	flex:1;
	padding-left:10px;
	text-align:left;
}

.pd-1-box-affilliates p {
	margin-bottom:20px !important;
}

/* PD1 Box Premium */
.pd-1-box-premium {
	background: rgb(27,36,106);
	background: linear-gradient(135deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%);
}

.pd-1-box-premium .pd-1-box-right {
	background: #ffffff;
	border: 3px solid #EEA33D;
	border-radius: 15px;
	padding: 20px 40px 40px 40px;
}

.pd-1-box-premium .pd-1-box-my-nft {
	border-top: 1px solid #F6CF9B;
}

.pd-1-box-premium .pd-1-box-premium .pd-1-box-my-nft {
	border-bottom:1px solid #F6CF9B;
}

.pd-1-box-premium .pd-1-box-my-nft-slider-wrap .slick-arrow {
	background: #EEA33D !important;
}

.pd-1-box-premium .pd-1-box-img {
	width: 250px;
	height: 250px;
	border: 10px solid #EEA33D;
}

.pd-1-box-premium .pd-1-box-2 {
	background: rgba(212,234,241,.10);
	border: 1px solid #4492A8;
}

.pd-1-box-premium .pd-1-box-2 * {
	color:#fff !important;
}

.pd-1-box-premium .pd-box-2-follow li a i {
	color: #345B94 !important;
}

.pd-1-box-premium .pd-box-2-follow li a {
	background-color:#fff !important;
}

.pd-1-box-premium .pd-1-box-left {
	margin-top:0 !important;
}

.pd-1-box-premium .pd-1-box-left-heading {
	font-family:'Poppins', sans-serif;
	font-size:23px;
	font-weight:600;
	text-align:center;
	color: #EEA33D;
	margin-top:20px;
}

.pd-1-box-premium .pd-1-box-sponsor a {
	color: #EEA33D;
}

.pd-1-box-premium .pd-1-box-sponsor a:hover {
	color:#000;
}

.pd-1-box-premium .pd-1-box-my-nft {
	border-bottom:0;
	padding-bottom: 0.25rem;
}

.pd-1-box-premium .pd-1-box-my-nft-2 {
	border-bottom: 1px solid #F6CF9B;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

/* Earning Options 2 */
.eo-2-row {
	padding: 40px 60px 30px 60px;
	position:relative;
}

.eo-2-box {
	max-width: 902px;
	margin: 0 auto;
}

.eo-2-row-1 {
	background: #F9FEFF;
	border:1px solid #C0CFD4;
	border-radius:23px 23px 0px 0px;
}

.eo-2-radio {
	padding:0;
}

.eo-2-radio li {
	margin:0;
	list-style:none;
	margin-bottom:5px;
}

.eo-2-row-2-heading {
	position:relative;
}

.eo-2-row-2-heading .img-wrap {
	position: absolute;
	left: -270px;
	top: -40px;
	width: 225px;
}

.eo-2-row-2 {
	background:#fff;
	border-left:1px solid #C0CFD4;
	border-right:1px solid #C0CFD4;
	border-bottom:1px solid #C0CFD4;
	border-radius:0 0 23px 23px;
	padding-bottom:60px;
}

.eo-2-btn {
	background: #EEA33D;
	color: #fff;
	border-radius: 50px;
	padding: 20px 60px;
	display: inline-flex;
	color: #fff !important;
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	border:0;
	line-height:1;
}

.eo-2-btn.disabled {
	background:#999;
}

.eo-2-btn:hover {
	opacity:.9;
}

.teo2 .site-menu-col,
.teo2 .new-site-social-col {
	display:none;
}

.eo-2-row-inner {
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}

.eo-2-row-inner h2 {
	position:relative;
}

.eo-2-option {
	width: 20px;
	height: 20px;
	border: 1px solid #707070;
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	flex-flow: column;
	align-items: stretch;
	padding: 2px;
	position: absolute;
	left: -40px;
	top: 5px;
	z-index: 2;
}

.eo-2-option-inner {
	display: block;
	width: 100%;
	height: 100%;
	background: transparent;
	border-radius: 50px;
}

.eo-2-option.active .eo-2-option-inner {
	background: #EEA33D;
}

.eo-2-option-premium {
	top:38px;
}

/* Form Message */
.form-message {
	display:none;
	text-align:center;
	margin-top:20px;
}

.form-error {
	color:red;
}

.form-success {
	color:green;
}

/* New Signup V2 */
.cwa-popup-request-star-2 {
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:15px;
}

.cwa-popup-request-star-2 .img-wrap {
	margin-bottom: 0;
	margin-right: 10px;
}

.cwa-popup-form-header-v2 .cwa-popup-form-img-wrap {
	margin-top:15px;	
}

.cwa-popup-form-step-text {
	font-size:22px;
	margin-bottom:15px;
}

.sign-up-form-2 {
	margin-top:40px;
}

.cwa-popup-form-2 {
	padding:0;
}

.btn-blue {
	background: #12C5F8;
	border-radius: 50px;
	color: #fff;
	border: 0;
	outline: 0;
	padding: 0;
	margin: 0;
	box-shadow: 0 8px 18px rgba(44, 219, 255, .40);
	padding: 13px 26px;
	min-width: 250px;
	text-transform: uppercase;
	font-weight: 500;
	font-family: Roboto, sans-serif;
	min-height: 50px;
	line-height: 1;
}

.btn-blue:hover {
	background: #22C9F9;
}

.cwa-popup-form-body {
	padding-bottom:60px;
}

.cwa-popup-form {
	padding:0;
}

.cwa-ship-row-2 {
	margin-top:40px;
}

.cwa-popup-form-2 {
	margin-top:30px;
}

.signup-v2-submit {
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.signup-v2-submit .signup-button {
	font-size:20px;
}

.cwa-popup-form-1 {
	width: 100%;
	display: flex;
}

/* Pledge Drive Page */
.pledge-1 {
  background: #fff;
}

.pledge-drive-main .site-header,
.pledge-drive-main .site-footer {
	display:none !important;
}

/* Pledge 2 */
.pledge-2 {
    /* /wp-content/uploads/2023/03/banner-pledge-2.jpg */
  background: no-repeat center bottom
    url('');
  min-height: 568px;
  padding: 10rem 0;
}

.pledge-border {
  display: inline-flex;
  flex-flow: column;
}

.pledge-border::after {
  content: '';
  display: flex;
  /* /wp-content/uploads/2023/03/img-pledge-2-borders.png */
  background: no-repeat center
    url('');
  background-size: contain;
  height: 16px;
  width: 100%;
}

/* Pledge 3 */
.pledge-3 {
  padding-top: 2rem;
  padding-bottom: 10rem;
}

.pledge-3-box {
  background: #f2f8ff;
  border: 1px solid #6baeff;
  border-radius: 34px;
  position: relative;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 80px auto 80px auto;
  padding: 0 150px 0 320px;
}

.pledge-3-box-img {
  position: absolute;
  left: -100px;
  top: -40px;
}

.pledge-3-text {
  max-width: 480px;
  margin: 0 auto;
}

/* Pledge 4 */
.pledge-4 {
  position: relative;
  background: rgb(238, 246, 249);
  background: linear-gradient(
    180deg,
    rgba(238, 246, 249, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-bottom: 100px;
}

.pledge-4::after {
  content: '';
  width: 100%;
  height: 178px;
  /* /wp-content/uploads/2023/03/bg-pledge-4.jpg */
  background: no-repeat center
    url('');
  position: absolute;
  left: 0;
  top: 0;
}

.pledge-4 .container {
  position: relative;
  z-index: 2;
}

.pledge-4-time {
  border-radius: 30px;
  background: rgb(27, 36, 106);
  background: linear-gradient(
    135deg,
    rgba(27, 36, 106, 1) 0%,
    rgba(55, 199, 240, 1) 100%
  );
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  margin-top: -40px;
}

.pledge-4-time-col {
  display: flex;
  flex-flow: column;
  padding: 0 20px;
  flex: 1;
}

.pledge-4-time-col-num {
  font-family: 'Poppins', sans-serif;
  font-size: 79px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.pledge-4-time-col-text {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}

.pledge-4-time-sep {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  margin-top: -15px;
}

/* Pledge 4 Form */
.pledge-form {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 60px;
}

.pledge-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.pledge-form-field {
  display: flex;
  flex-flow: column;
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 13px;
}

.pledge-form-field label {
  font-family: 'Poppins', sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #3e3e3e;
  margin-bottom: 20px;
  text-align: center;
}

.pledge-form-field input {
  height: 68px;
  border: 1px solid #c3c4c4;
  background: #fff;
  border-radius: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding: 0 20px;
}

.pledge-form-button {
  background: #f69139;
  border-radius: 34px;
  color: #fff !important;
  border: 0;
  padding: 13px 39px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.pledge-form-button:hover {
  background: #f69c4d;
}

/* Pledge UL */
.pledge-ul {
  padding: 0;
  margin: 0;
}

.pledge-ul li {
  list-style: none;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #4b4b4b;
  padding-left: 30px;
  margin-bottom: 15px;
}

.pledge-ul li::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  width: 17px;
  height: 13px;
  /* /wp-content/uploads/2023/03/pledge-check-icon.png */
  background: no-repeat center
    url('');
  background-size: contain;
}

.pledge-4-container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 60px;
}

.pledge-group-btn {
  margin-top: 30px;
}

/* Leaderboard */
.leaderboard-add-wrap {
	text-align:right;
	margin-bottom:1rem;
}

.leaderboard-btn {
	background: #ff880c;
	color: #fff !important;
	border-radius: 50px;
	padding: 10px 30px;
	display: inline-flex;
	font-size: 14px;
	font-weight: 500;
}

.leaderboard-btn:hover {
	opacity:.9;
}

.suc-form-input input[type="radio"] {
	width:auto;
	height:auto;
}

.suc-form-radio label {
	margin-right:20px;
}

/* Custom New Header */
.custom-no-header .site-header {
	display:none;
}

/* July Kick Off Page */
.jko-main {
	background: rgb(24,8,85);
}

.jko-main .container {
	max-width:1185px;
}

/* JKO 1 */
.jko-1 {
	padding:2rem 0;
}

.jko-time {
	border: 4px solid #F49334;
	border-radius: 20px;
	display: flex;
	flex-flow: row wrap;
	color: #fff;
	justify-content: center;
}

.jko-time-col {
	display: flex;
	flex-flow: column;
	text-align: center;
	line-height: 1.1;
	flex: 1;
	padding: 2rem;
}

.jko-time-col-text {
	font-family:'Poppins', sans-serif;
	font-size:16px;
	font-weight:400;
}

.jko-time-col-num {
	font-size:45px;
	font-weight:700;
}

.jko-img-wrap {
	width: 913px;
	position: absolute;
	right: -226px;
	top: 0;
}

.jko-1 .row {
	position:relative;
}

.jko-1-col-left {
	position:relative;
	z-index:2;
}

.jko-ul {
	padding:0;
}

.jko-ul li {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 300;
	color: #fff;
	position: relative;
	list-style: none;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 30px;
	text-align:left;
}

.jko-ul li::before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	top:5px;
	width:17px;
	height:13px;
    /* /wp-content/uploads/2023/06/jko-check.png */
	background:no-repeat center url('');
	background-size:contain;
}

/* JKO 2 */
.jko-2 {
	padding-top:40px;
}

.jko-2 .container {
	max-width:1072px;
}

/* JKO 3 */
.jko-3 {
	padding: 4rem 0 4rem 0;
    /* https://govato.io/wp-content/uploads/2023/06/bg-july-circle.png */
	background:no-repeat center url('');
	background-size:contain;
}

.jko-white-box {
	background: #fff;
	border-radius: 55px;
	max-width: 667px;
	margin: 0 auto;
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
	min-height: 400px;
	padding: 4rem;
}

/* JKO TBL */
.jko-tbl {
	max-width: 319px;
	margin: 0 auto;
	width: 100%;
}

.jko-tbl-row {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 0.5rem;
	margin-top: 0.75rem;
}

.jko-tbl-col {
	flex: 1;
	font-size:24px;
	font-weight:500;
	color:#1A1660;
}

.jko-tbl-ovato {
	position: absolute;
	left: -140px;
	top: 75px;
}

.jko-tbl-circle {
	position: absolute;
	top: -40px;
	right: 120px;
}

/* JKO 4 */
.jko-4 {
    /* /wp-content/uploads/2023/06/bg-jko-oval.png */
	background: no-repeat left center url('');
	background-size: auto;
	background-size: auto;
	background-size: auto;
	background-size: 90% 100%;
	min-height: 1097px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding-top: 180px !important;
	margin-top: -10rem;
}

.jko-4 .container {
	position:relative;
}

.jko-4 .container::before {
	content:'';
	width:114px;
	height:114px;
    /* /wp-content/uploads/2023/06/img-circle-top.png */
	background:no-repeat center url('');
	background-size: contain;
	position: absolute;
	right: 0;
	top: -120px;
}

.jko-4 .container::after {
	content:'';
	width:61px;
	height:61px;
    /* /wp-content/uploads/2023/06/img-circle-bottom.png */
	background:no-repeat center url('');
	background-size: contain;
	position: absolute;
	left: 0;
	bottom: -70px;
}

/* JKO Btn Wrap */
.jko-btn-wrap {
	display:flex;
	justify-content:center;
	margin-top:1.5rem;
	position:relative;
	z-index:2;
}

.jko-btn-wrap a {
	display: inline-flex;
	background: #F69139;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	text-transform: uppercase;
	padding: 1rem 3rem;
	border-radius: 50px;
	line-height: 1;
}

.jko-btn-wrap a:hover {
	background: #F89C4C;
}

/* JKO 5 */
.jko-5 {
	background:transparent;
	padding-top:0;
}

.jko-5::before {
	display:none;
}

.jko-5 .mw-collection-title {
	color: #81D2FF;
	font-size:24px;
}

.jko-5 .mw-collection-price {
	font-size:20px;
	color:#fff;
	font-weight:600;
}

.jko-5 .mw-2-row-3::before {
    /* /wp-content/uploads/2023/06/img-get-3d-white.png */
	background-image:url('');
}

/* JKO Easy */
.jko-easy {
    /* /wp-content/uploads/2023/06/bg-right-curve.png */
	background: no-repeat right center url('');
	background-size: auto;
	background-size: auto;
	background-size: auto;
	background-size: 80% 100%;
	min-height: 1060px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding-top: 300px !important;
	margin-top: -100px;
	margin-bottom: 60px;
}

.jko-rating {
	display:flex;
	flex-flow:row wrap;
	align-items:center;
}

.jko-rating .img-wrap {
	margin-bottom:0;
}

.jko-rating p {
	margin-bottom:0;
	margin-left:1rem;
}

.jko-dl {
	padding:0;
	margin:0;
	display:flex;
	flex-flow:row wrap;
	margin-top:2rem;
}

.jko-dl li {
	list-style:none;
	margin-right:1rem;
}

.jko-easy-right {
	position:relative;
}

.jko-easy-tee {
	position: absolute;
	right: 0;
	bottom: 100px;
}

.jko-easy-img-1 {
	width: 629px;
	margin-left: -40px;
}

/* Sign Up V3 */
.signup-v3-popup .cwa-popup-form-wrap {
	display:grid;
	grid-template-columns:1fr 1fr;
}

.signup-v3-popup .cwa-popup-wrap {
	max-width:1023px;
}

.signup-v3-popup .cwa-popup-form-header {
	padding:0;
	background:no-repeat center top  #1B246A;
    /* /wp-content/uploads/2023/08/bg-popup-left.png */
	background-image:url(''), linear-gradient(180deg,  rgba(55,199,240,1) 0%, rgba(27,36,106,1) 100%);
	border-radius: 20px 0 0 20px;
	padding: 40px;
}

.cwa-v3-by a {
	color:#fff !important;
	font-family:'Poppins', sans-serif;
	font-size:18px;
	font-weight:500;
	text-decoration:underline;
}

.cwa-v3-left-heading {
	max-width: 400px;
	margin: 0 auto !important;
	margin-top: 220px !important;
}

.cwa-v3-congrats {
	max-width: 250px;
	margin: 0 auto;
	margin-top: 0px;
	margin-top: 40px;
}

.cwa-v3-by-img {
	margin-top:1rem;
}

.cwa-v3-by-img img {
	border-radius:50px;
}

.signup-v3-popup a {
	color: #12C5F8;
}

.signup-v3-popup .cwa-popup-form-step {
	margin-top:-19px;
}

.signup-v3-popup .cwa-popup-form-step span {
	font-size:15px;
}

.signup-v3-popup .cwa-popup-form-1 {
	flex-flow:row wrap;
}

.signup-v3-popup .suf-left-col,
.signup-v3-popup .suf-right-col {
	max-width:100%;
	flex:0 0 100%;
}

.signup-v3-popup .sign-up-form input[type="text"], 
.signup-v3-popup .sign-up-form input[type="email"], 
.signup-v3-popup .sign-up-form input[type="phone"], 
.signup-v3-popup .sign-up-form input[type="password"],
.signup-v3-popup .suf-left {
	height:52px;
}

.signup-v3-popup .signup-button {
	background:#12C5F8;
	box-shadow:0px 8px 18px rgba(44, 219, 255,.40);
}

.signup-v3-popup .signup-button:hover {
	background:#1EC9F9;
}

.signup-v3-popup .cwa-popup-close {
	color:#fff;
	background: #1EC9F9;
	z-index:10;
}

.signup-v3-btn {
	position:relative;
	z-index:10;
}

.signup-v3-popup .cwa-popup-request-star img {
	width:101px;
}

.signup-v3-popup .cwa-popup-form-img-wrap img {
	width:84px;
}

.signup-v3-popup .cwa-popup-form-img-wrap li {
	margin:0 5px;
}

.social-signup {
	display: flex;
	flex-flow: row wrap;
	padding: 20px 0;
	margin: 0 auto;
	background: #F8FAFB;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	max-width: 200px;
}

.social-signup li {
	list-style: none;
	margin: 0 10px;
}

.social-signup li a {
	background: #fff;
	border-radius: 10px;
	width: 40px;
	height: 40px;
	display: block;
	box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.5);
	padding: 7px;
	text-align: center;
}

.social-signup li a:hover {
	background:rgba(255,255,255,.8);
}

.cwa-v3-step-1 {
	margin-top:30px;
}

.cwa-v3-step-1-2 {
	margin-bottom:10px;
}

.signup-v3-popup .sign-up-form-2 {
	margin-top:20px;
}

.signup-v3-popup .cwa-popup-form-step-wrap {
	padding: 40px 40px 0 40px;
}

.signup-v3-popup .cwa-popup-form-body {
	border-radius: 0 20px 20px 0px;
	padding-bottom: 40px;
}

html body .google-signup-li {
	margin:0 !important;
}

html body .google-signup-li a {
	background: transparent;
	box-sizing: unset;
	padding: 0;
	width: auto;
	height: auto;
	border-radius: 0;
}

/* Sign Up V3 Loader */
.signup-v3-loader {
	background: rgba(14, 18, 52, 0.63);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index:9999999;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	opacity:0;
	visibility:hidden;
}

.signup-v3-loader.active {
	opacity:1;
	visibility:visible;
}

.signup-v3-loader-inner {
	font-size:32px;
	color:#fff;
}

/* Ty Popup */
#thank-you-popup .cwa-popup-form-header {
	background: no-repeat center bottom #1B246A;
	background-image: linear-gradient(180deg, rgba(43,127,181,1) 0%, rgba(27,36,106,1) 40%);
	background-size:100%;
	position:relative;
	overflow:hidden;
}

#thank-you-popup .cwa-popup-form-header::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
    /* /wp-content/uploads/2023/09/bg-ty-popup-left.png */
	background: no-repeat center top url('');
	background-size: auto;
	width: 100%;
	height: 405px;
	background-size: 100%;
}

.popup-btn {
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	color: #fff !important;
	font-weight: 500;
	text-align: center;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	background: #12C5F8;
	padding: 0.75rem 3rem;
	box-shadow:0px 8px 18px rgba(6, 195, 255, .21);
	text-transform:uppercase;
}

.popup-btn:hover {
	background: #27CDFC;
}

.ty-popup-header {
	background: no-repeat center bottom #1B246A;
	background-image: linear-gradient(90deg, rgba(55,199,240,1) 0%, rgba(27,36,106,1) 100%);
	position:relative;
	padding:1.75rem;
}

.ty-popup-header::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
    /* /wp-content/uploads/2023/09/bg-ty-popup-right.png */
	background: no-repeat center url('');
	background-size: auto;
	height: 100%;
	width: 265px;
	background-size: 100%;
}

.ty-popup-header {
	position:relative;
}

.ty-popup-header p {
	position: relative;
	z-index: 1;
	color: #fff;
	text-align: center;
	font-weight: 700;
	font-size: 22px;
	max-width: 288px;
	line-height: 1.3;
	margin-left: auto;
	margin-bottom:0;
}

.ty-popup-textbox {
	background: #E8F4FF;
	border-radius: 15px;
	padding: 1.5rem 1.5rem 0.25rem 1.5rem;
	margin-bottom: 1.5rem;
}

#thank-you-popup .cwa-popup-form-body {
	padding-bottom:0;
}

.ty-popup-body {
	padding: 2rem 2rem 3rem 2rem;
}

/* Multiple Ways 2 */
.cp-col-box .mw-collection-box-footer-right {
	margin-top:1.5rem;
}

.cp-col-box .mw-collection-box-btn {
	font-size:16px;
	padding:10px 30px;
	background: rgb(238,163,61);
	background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%);
}

.cp-col-box strong {
	color:#D6225E;
}

/* Site Header Upper */
.site-header-upper-new {
	background: rgb(27,36,106);
	background: linear-gradient(90deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%); 
}

.site-header.active .site-header-upper-new p {
	font-size:16px !important;
}

/* New Home 2 New */
.new-home-2-new {
	margin-top:160px;
    /* https://govato.io/wp-content/uploads/2024/01/banner-bg-new.png */
	background:no-repeat center bottom url('./assets/img/home/banner-bg-new.png');
	background-size:cover;
}

.home-1-right-col-new {
	background: rgb(8, 86, 154, .9);
	border-radius: 100%;
	position: relative;
	height: 537px;
	width: 537px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 0 6rem !important;
}

.home-1-right-col-new::before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	bottom:0;
	background: url('./assets/img/home/img-banner-circle-bottom.png') no-repeat center;
	background-size:contain;
	width:48px;
	height:48px;
}

.home-1-right-col-new::after {
	content:'';
	display:block;
	position:absolute;
	right:0;
	top:0;
	background: url('./assets/img/home/img-banner-circle-top.png') no-repeat center;
	background-size:contain;
	width:66px;
	height:66px;
}

.new-banner-img {
	width: 477px;
	position: absolute;
	left: -160px;
	top: -20px;
}

.new-home-2-rating-wrap-2 img {
	width:150px;
}

/* New Home 4 */
.new-home-4-img-left {
	max-width:658px;
}

.new-home-4-img-text-wrap-new {
	flex-flow:column;
	justify-content:center;
	align-items:center;
	text-align:center;
}

.new-home-4-img-text-wrap-new h3 {
	font-size:18px;
}

.new-home-4-img-text-wrap-new p {
	font-size: 16px;
	margin-top: 0.75rem;
}

.new-home-4-img-text-wrap-new .img-wrap {
	max-width:100px;
}

.new-home-4-row-2-new {
	margin-bottom:3rem;
}

.new-home-4-img-text-wrap-new .text-wrap {
	padding-left:0;
}

/* New home 44 */
.new-home-44 {
	background:no-repeat center url('./assets/img/home/bg-ecosystem.png');
	background-size:100% 100%;
	padding: 12rem 0 10rem;
	position: relative;
	z-index: 1;
}

.new-home-44 .img-wrap {
	text-align:center;
	max-width:920px;
	margin:0 auto;
	margin-top:3rem;
}

/* New Home 55 */
.new-home-55::before {
	display:none;
}

.new-home-55 {
	padding-top: 100px !important;
	margin-top: -200px !important;
}

.new-home-33 .home-3-img-wrap {
	margin-top:1.5rem;
}

.site-menu-2 {
	justify-content:center;
}

.site-menu-2 li {
	font-size: 16px;
	margin: 0 0.75rem;
	padding-left: 2rem;
}

/* How it works */
.hiw-1 {
	background: linear-gradient(180deg, rgba(222,248,255,1) 0%, rgba(255,255,255,1) 100%);
	margin-top: 140px;
	padding-top: 4rem;
	padding-bottom: 3rem;
}

.hiw-1-ul {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;
}

.hiw-1-ul li {
	font-size:18px;
	padding-left:2rem;
	margin:0 0.75rem;
	color: #8A8A8A;
}

/* HIW 2 */
.hiw-2 {
    /* /wp-content/uploads/2024/01/bg-hiw-2.png */
	background:no-repeat center url('');
	background-size: 100% 100%;
	padding: 12rem 0 10rem 0;
	position:relative;
}

.hiw-2-box {
	background: rgba(17,24,78,.18);
	border-radius: 18px;
	padding: 1.5rem 3rem;
}

.hiw-2-box h3 {
	font-size:27px;
	font-family:'Poppins', sans-serif;
	font-weight:700 !important;
	color:#fff !important;
	margin-bottom: 0.5rem !important;
}

.hiw-2-box p {
	font-size:18px;
	font-family:'Poppins', sans-serif;
	font-weight:400;
	color:#fff;
	margin:0;
}

.hiw-2-row-2 {
	max-width: 768px;
	margin: 1.5rem auto 0 auto;
}

.hiw-2-img {
	max-width: 840px;
	margin: 0 auto;
	margin-top: 3rem;
	display:block;
}

/* HIW 3 */
.hiw-3 {
	background: linear-gradient(180deg, rgba(222,248,255,1) 0%, rgba(255,255,255,1) 100%);
	margin-top: -8rem;
	padding-top: 10rem;
	margin-bottom: -14rem;
}

.hiw-3-img {
	filter: drop-shadow(0px 29px 37px rgba(0, 0, 0, 0.16));
	max-width: 1024px;
	margin: 0 auto;
	display: block;
}

.hiw-3-tbl-wrap .slick-prev,
.hiw-3-tbl-wrap .slick-next {
	width: 30px !important;
	height: 30px !important;
	margin: 0 !important;
	padding:0 !important;
}

.hiw-3-tbl-wrap .slick-prev {
	left: -5px !important;
}

.hiw-3-tbl-wrap .slick-next {
	right: -5px !important;
}

.hiw-3-tbl-wrap .slick-prev::after,
.hiw-3-tbl-wrap .slick-next::after {
	width:16px;
	height:16px;
	margin:0 auto;
}

/* NFT */
.nft-1 {
	margin-top:163px;
	background: #FFB44E;
	padding:1rem;
}

/* NFT 2 */
.nft-2 {
    /* /wp-content/uploads/2024/01/bg-nft-2.png */
	background:no-repeat center url('');
	background-size:100% 100%;
	padding: 6rem 0 8rem;
	position:relative;
}

.nft-2 ul li {
	color:#fff !important;
	margin-bottom:1rem;
}

/* NFT 3 */
.nft-3 {
	background: rgb(240,251,255);
	background: linear-gradient(to bottom, rgba(240,251,255,1) 0%, rgba(255,255,255,1) 100%); 
	margin-top: -10rem;
	padding-top: 13rem;
}

.nft-3-row {
	background: #DCF7FF;
	border-radius: 20px;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	margin-top: 0px;
	margin-top: 2rem !important;
	position: relative;
	z-index: 6;
}

.nft-3-left-col {
	background: linear-gradient(220deg, rgb(27, 36, 106) 50%, rgb(55, 199, 240) 100%);
	border-radius: 20px;
	position: relative;
	padding: 2rem 1rem;
}

.nft-3-left-col::after {
	content: '';
	display: block;
	position: absolute;
	right: -20px;
	top: 50%;
	width: 0;
	height: 0;
	border-width: 32px 0 32px 21px;
	border-color: transparent transparent transparent #1B246A;
	border-style: solid;
	margin-top: -32px;
}

.nft-3-left-col h3 {
	font-family:'Poppins', sans-serif;
	font-size:25px;
	font-weight:600;
	color: #FFFFFF !important;
	margin-bottom:1rem !important;
}

.nft-3-left-col ul {
	padding-left:0;
}

.nft-3-left-col ul li {
	font-size:18px;
	font-family:'Poppins', sans-serif;
	font-weight:400;
	color:#fff !important;
	list-style:none;
}

.nft-3-left-col .img-wrap {
	margin-bottom:0;
	text-align:center;
}

.nft-3-row h4 {
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	font-weight: 600;
	color: #3E3E3E;
	margin-bottom: 1rem;
}

.nft-3-row ul {
	padding:0;
}

.nft-3-row ul li {
	list-style:none;
}

.nft-3-row ul li {
	font-size:18px;
	color: #3E3E3E;
	color: #3E3E3E;
	margin-bottom: 0.25rem;
}

.nft-3-mid-col {
	padding-left:6rem !important;
}

/* Tools 3 */
.tools-3 {
	margin-top:163px;
	background: rgb(224,248,255);
	background: linear-gradient(180deg, rgba(224,248,255,1) 0%, rgba(255,255,255,1) 100%); 
	padding-top:4rem;
}

.tools-3-subheading {
	max-width:500px;
	margin:0 auto;
}

/* Tools 3 Row */
.tools-3-row {
	max-width: 1180px !important;
	margin: 0 auto !important;
	margin-top: 2rem !important;
}

/* Tools 3 Col */
.tools-3-col {
	background:no-repeat center;
	background-size: cover;
	border-radius: 40px;
	position: relative;
	padding: 3rem 5rem 1rem 5rem !important;
	background-image:url('./assets/img/tools/bg-nft-box-1.png');
	z-index:3;
}

.tools-3-num {
	width: 51px;
	height: 51px;
	border-radius: 50px;
	background: #EEA33D;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins, sans-serif;
	font-weight: 700;
	color: #fff;
	font-size: 24px;
	position: absolute;
	left: -25.5px;
	top: 40px;
}

.tools-3-col-heading {
	font-size: 24px !important;
	color: #fff !important;
	font-weight: 500 !important;
}

/* Tools 2 */
.tools-3-col h3 {
	font-size:18px;
	font-family:'Poppins', sans-serif;
	font-weight:400 !important;
	color:#fff !important;
}

.tools-3-col ul {
	padding:0;
}

.tools-3-col ul li {
	list-style: none;
	font-size: 16px;
	margin-bottom: 0.5rem;
}

.tools-3-col ul li a {
	color:#53CEFF;
}

.tools-3-col ul li a:hover {
	color:#fff;
}

.tools-3-col .img-wrap {
	margin-top:2rem;
}

.tools-3-col p {
	font-family:'Poppins', sans-serif;
	font-size:20px;
	font-weight:400;
	color: #FFFFFF;
}

/* Tools 5 */
.tools-3-col-5 .tools-3-col-5-left {
	padding-left: 6rem;
}

.tools-3-col-5 .img-wrap {
	margin-top: -3rem;
}

/* Tools 6 */
.tools-3-col-6 {
	padding-bottom:3rem;
	background-image:url('./assets/img/tools/bg-tools-6.png');
}

/* Get Started */
.get-started-box {
	box-shadow:0 11px 23px rgba(0,0,0,.05);	
}

.get-started-box-header {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	background: #1B246A;
	align-items: center;
	border-radius: 30px 30px 0px 0px;
	padding: 2rem 3rem;
}

.get-started-box{
	position: relative;
	z-index: 3;
	overflow: hidden;
	border-radius: 30px;
	margin-top: 3rem;
}

.get-started-box-header h2 {
	color: #fff;
	margin: 0;
	font-size: 45px;
	font-family: Poppins, sans-serif;
	font-weight: 700;
}

.get-started-box-header p {
	font-family:'Poppins', sans-serif;
	font-size:25px;
	font-weight:400;
	color:#fff;
	margin:0;
}

.get-started-box-body .site-btn {
	font-size: 18px;
	padding: 1rem 2rem !important;
	min-width: 200px;
}

.get-started-box-body {
	background: #fff;
	border-radius: 0px 0px 30px 30px;
	padding: 3rem 4rem;
	min-height: 475px;
}

.get-started-box-body .ul-checklist li {
	font-size:18px;
	margin-bottom:1rem;
}

.get-started-box-body-choices label {
	position: relative;
	padding-left: 1.5rem;
}

.get-started-box-body-choices label input {
	position:absolute;
	left:0;
	top:6px;
}

.get-started-box-body-text,
.get-started-box-body-price {
	display:block;
}

.get-started-box-body-text {
	font-size: 18px;
	font-weight: 700;
	color: #3E3E3E;
	font-family: Poppins, sans-serif;
}

.get-started-box-body-price {
	font-size:13px;
	font-family:'Poppins', sans-serif;
	font-weight:400;
	color:#3E3E3E;
}

.get-started-box-body-choices {
	margin-top:2rem;
}

.site-btn-pink {
	background:#C11D52;
}

.site-btn-pink:hover {
	background:#D6225C;
	box-shadow:unset;
}

.get-started-box-header-2 {
	background:#EEA33D;
}

.get-started-box-col {
	padding:0 1rem;
}

.get-started-1-row-2 {
	max-width:1200px;
	margin:0 auto;
}

/* SUC Popup 2 */
.suc-popup-2 .suc-popup-overlay {
	background:rgba(27,36,106,.81);
}

.suc-popup-2 .suc-popup-inner {
	max-width: 957px;
	padding: 0;
	border-radius: 0;
	background:transparent;
	width: 95%;
}

.wc-popup-header {
    /* /wp-content/uploads/2024/02/bg-popup-header.jpg */
	background:#1B246A no-repeat center url('');
	background-size:cover;
	text-align:center;
	border-radius: 20px 20px 0 0;
	padding: 2rem;
}

.wc-popup-form {
	max-width:375px;
	width:100%;
	margin:0 auto;
	position:relative;
}

.wc-popup-form input {
	height: 50px;
	border-radius: 50px;
	border: 0;
	font-size: 16px;
	width: 100%;
	font-family: Poppins, sans-serif;
	font-weight: 400;
	padding: 0 60px 0px 20px;
}

.wc-popup-form button {
	width: 46px;
	height: 46px;
	border-radius: 50px;
	color: #fff;
	background: #EEA33D;
	border: 0;
	transition: all 0.3s ease;
	position: absolute;
	right: 4px;
	top: 2px;
	z-index: 5;
}

.wc-popup-form button:hover {
	background:#FFAE41;
}

.suc-popup-2 .suc-close {
	background: transparent;
	right: 10px;
	top: 10px;
	color: #fff;
	font-size: 24px;
	z-index:2;
}

.suc-popup-2 .suc-close:hover {
	opacity:.9;
}

.wc-popup-footer {
	background:#fff;
	text-align:center;
	border-radius:0px 0px 20px 20px;
	padding: 1.5rem;
}

.wc-popup-footer a {
	color:#31A7D6;
	text-decoration:underline;
	font-weight:500;
	font-size:16px;
}

.wc-popup-footer p {
	font-size:14px;
	color:#3E3E3E;
	font-weight:500;
}

/* GS Step Wrap */
.gs-step-wrap {
	background: #fff;
	box-shadow: 0px 8px 29px rgba(85, 143, 165, .16);
	border-radius: 30px;
	margin: 0;
	max-width: 1048px;
	margin: 0 auto !important;
	position:relative;
}

.gs-step-wrap::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 3px;
	max-width: 638px;
	background: no-repeat center url('./assets/img/get-started/step-sep.png');
	background-size: auto;
	background-size: contain;
	margin-top: -20px;
	margin-left: -312px;
}

.gs-step-status span {
	font-family:'Poppins', sans-serif;
	font-size:11px;
	text-transform:uppercase;
	font-weight:500;
	color:#EEA33D;
	position:relative;
	padding-left: 1.2rem;
	display:inline-flex;
}

.gs-step-status span::before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	top:2px;
    /* /wp-content/uploads/2024/03/icon-radio.png */
	background:no-repeat center url('./assets/img/get-started/icon-radio.png');
	background-size:contain;
	width:12px;
	height:12px;
}

.gs-step-status-complete span {
	color: #6FA843;
	padding-left: 1.1rem;
}

.gs-step-status-complete span::before {
	background-image:url('./assets/img/get-started/icon-complete.png');
	width: 14px;
	height: 12px;
	top:2px;
}

.gs-step-num {
	width: 50px;
	height: 50px;
	background: #C11D52;
	border-radius: 50px;
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	color: #fff;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	margin: 0 auto;
	margin-top: 0.5rem;
}

.gs-step-text {
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	color: #3E3E3E;
	line-height: 1.3;
	text-transform: capitalize;
	margin-top: 1rem;
}

.gs-step-col {
	text-align: center;
	padding: 4rem 0;
	position: relative;
	z-index: 2;
}

/* GS SML */
.gs-sml-header {
	background: linear-gradient(145deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%);
	border-radius: 25px 25px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	padding: 1.5rem;
}

.gs-sml-main {
	background:#fff;
	box-shadow:0 11px 23px rgba(0,0,0,0.05);
	border-radius:30px;
	height: 100%;
}

.gs-sml-box {
	width:36px;
	height:36px;
	border-radius:6px;
	background:#fff;
}

.gs-sml-header-title {
	font-family:'Poppins', sans-serif;
	font-size:40px;
	font-weight:500;
	color: #FFFFFF;
	line-height:1.2;
}

.gs-sml-header-price {
	font-family:'Poppins', sans-serif;
	font-size:22px;
	font-weight:500;
	color: #FFFFFF;
	line-height:1.2;
}

.gs-sml-header-img img {
	width:67px;
	height:77px;
	border-radius: 10px;
}

/* GS SML Body */
.gs-sml-body {
	display: flex;
	justify-content: center;
	padding: 3rem 1rem 1rem 1rem;
}

.gs-sml-level {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 1rem;
}

.gs-sml-level-title {
	line-height:1.2;
	font-family:'Poppins', sans-serif;
	font-size:17px;
	font-weight:600;
	color: #3E3E3E;
}

.gs-sml-ul {
	padding: 0;
	margin-top: 0.75rem;
}

.gs-sml-ul li {
	list-style-type: none;
	font-size: 16px;
	font-weight: 400;
	font-family: Poppins, sans-serif;
	color: #3E3E3E;
	margin-bottom: 0.25rem;
}

.gs-sml-footer {
	text-align: center;
	padding: 0 1rem 4rem 1rem;
	margin-top: 1rem;
}

.gs-sml-btn-wrap {
	margin-top:1rem;
}

.gs-sml-row {
	position: relative;
	z-index: 4;
	margin-top: 3rem !important;
}

/* New Home 5 */
.new-home-2-dl-wrap-3 {
	margin-top:0;
}

.new-home-2-rating-wrap-3 img {
	width:100px;
}

.new-home-2-dl-wrap img {
	width:75px;	
}

.new-home-2-rating-wrap-3 .img-wrap,
.new-home-2-rating-wrap-3 p {
	margin-bottom:0.5rem;
}

.home-1-right-col-new-2 {
	height: 579px;
	width: 587px !important;
}

/* C Video */
.c-video-wrap {
	max-width: 515px;
	position: relative;
	margin-top: 2rem;
}

.c-video-wrap-full {
	max-width:100%;
	margin-top:0;
}

.c-video-img-wrap {
	border: 10px solid #fff;
	box-shadow: 0 14px 66px rgba(43,103,148,.16);
	position:relative;
	z-index:2;
}

.c-video-play-btn {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -24px;
	margin-top: -24px;
	transition:all 0.3s ease;
	z-index:2;
}

.c-video-play-btn:hover {
	transform:scale(1.05);
}

.c-video-play-btn img {
	width:48px;
	height:48px;
}

.c-video-play-btn-full img {
	width:75px;
	height:75px;
}

.c-video-play-text {
	position: absolute;
	right: -150px;
	top: 40px;
	z-index:2;
}

.c-video-play-text img {
	width:200px;
}

.c-video-play-text-bottom img {
	width:100px;
}

.c-video-play-text-bottom {
	right: 50%;
	bottom: -90px;
	top: auto;
	margin-right: -130px;
}

.c-img-circle-wrap {
	position: absolute;
	top: 100px;
	width: 556px;
	left: -10px;
}

.v2-mw-1 {
	background:no-repeat center url('./assets/img/compensation-plan/bg-compensation-new.jpg') !important;
	position: relative;
	padding-bottom: 350px;
	background-size: 100% !important;
	background-position: center 85% !important;
}

.v2-mw-1 .container {
	z-index:unset;
}

.v2-mw-2-new {
	background:#fff;
	padding-bottom:0;
}

.v2-mw-3::before {
	display:none;
}

.v2-mw-3 {
	padding-top:0;
}

/* V2 NFT */
.v2-nft-2 {
	background-image:url('./assets/img/nft/banner-nft-new.png');
	background-size:100% 100%;
}

/* Rules Page */
.rules-1 {
	background: linear-gradient(to bottom, #E2F9FF, white);
}
.rules-2 {
	background: linear-gradient(to bottom, #E7F9FF, white);
}

.rules-2 .container {
	position:relative;
	z-index:4;
}

.rules-box {
	background: #FFFFFF;
	box-shadow: 0 20px 26px rgba(119, 154, 178, .16);
	border-radius: 30px;
	max-width: 800px;
	margin: 0 auto;
	margin-top: 0px;
	text-align: center;
	padding: 0 2rem 2rem 2rem;
	margin-top: 5rem;
}

.rules-box-h2 {
	text-align: center;
	display: inline-flex;
	margin-top: -35px;
	margin-bottom: 3rem;
}

.rules-box-h2 span {
	font-family: 'Poppins', sans-serif;
	font-size: 29px;
	text-align: center;
	display: inline-flex;
	background: linear-gradient(220deg, #1B246A, #255F9B, #37C7F0);
	border-radius: 50px;
	color: #fff;
	padding: 0.75rem 3rem;
	position:relative;
}

.rules-box-ul li {
	font-size:17px;
	font-weight:400;
	font-family:'Poppins', sans-serif;
	color: #8A8A8A;
	text-align:center;
	counter-increment: list-counter;
	list-style:none;
	text-align:left;
	position:relative;
	padding-left:3rem;
	margin-bottom: 1rem;
}

.rules-box-ul li::before {
	content: counter(list-counter);
	color: #fff;
	font-weight: bold;
	margin-right: 5px;
	width: 32px;
	height: 32px;
	background: #EEA33D;
	display: flex;
	border-radius:50px;
	line-height:1;
	justify-content:center;
	align-items:center;
	position:absolute;
	left:0;
	top:0;
}

/* Finance it */
.site-cta {
	font-size:16px;
	font-family:'Poppins', sans-serif;
	font-weight:500;
	color:#31A7D6;
}

/* Finance It Popup */
.fi-popup-header {
	background-image: url('./assets/img/popup/bg-fi-popup-header.png'), linear-gradient(180deg, #235592 0%, #2C87BB);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:contain;
	position:relative;
	border-radius: 20px 20px 0px 0px;
}

.fi-popup-header-main {
	padding-left: 55%;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	padding-right: 1.5rem;
}

.fi-header-img-wrap img {
	width:200px;
}

.fi-popup-body {
	background: #fff;
	padding: 2.5rem 3rem 3rem 3rem;
	border-radius: 0px 0px 20px 20px;
}

.fi-popup-tbl-header {
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
}

.fi-popup-tbl-header-col {
	font-size:14px;
	font-family:'Poppins', sans-serif;
	font-weight:600;
	color:#A4A4A4;
	text-transform:uppercase;
	text-align:center;
	padding: 1rem;
}

.fi-popup-tbl-body-row {
	background: #DCF7FF;
	border-radius: 15px;
	margin-bottom: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.fi-popup-tbl-body-col {
	font-size: 15px;
	font-family: 'Poppins', sans-serif;
	color: #585858;
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	padding: 2rem;
}

.fi-popup-tbl-body-col-1 {
	font-size: 19px;
	font-family: 'Poppins', sans-serif;
	color: #41498E;
	font-weight: 700;
	text-align:left;
	text-transform:none;
}

.fi-popup-tbl-body-col-1 input {
	margin-right:0.5rem;
}

.fi-btn {
	min-width:200px;
}

.fi-popup-text {
	display:none;
	font-size:9px;
	font-weight:500;
	font-family:'Poppins', sans-serif;
	color:#A4A4A4;
	text-transform:uppercase;
}

/* Tools 4 Link Wrap */
.tools-4-link-wrap {
	background: rgba(255,255,255,.10);
	border-radius: 10px;
	align-items: center;
	padding:1rem;
}

.tools-4-link-wrap .custom-affiliate-title {
	color:#fff;
	font-size:15px;
}

.tools-4-link-wrap .custom-qr-link-wrap img {
	width:68px;
	height:68px;
}

.tools-4-link-wrap .custom-affiliate-code {
	height: 29px;
	border-radius: 50px;
	font-weight: 500;
	color: #9B9B9B;
	font-size: 13px;
	padding: 0;
	display: flex;
	align-items: center;
	padding: 0.25rem;
	padding-left: 0.25rem;
	border: 0;
}

.tools-4-link-wrap .custom-affiliate-code-count {
	font-family:'Poppins', sans-serif;
	font-weight:500;
	font-size:15px;
	color:#fff;
}

.tools-4-link-wrap .custom-affiliate-code-right,
.tools-4-link-wrap .custom-code-aff-code-3,
.tools-4-link-wrap .custom-affiliate-code-left {
	padding:0 1rem;
}

.tools-4-logged-in .tools-3-col {
	padding-bottom:4rem;
}


.tools-4-link-wrap .custom-affiliate-code-status {
	color:#fff;
}

.tools-4-btn {
	font-weight: 600;
	font-size: 13px !important;
	min-width: 1px;
	letter-spacing: 0;
	padding: 1rem 2rem;
}

.tools-4-logged-download .malar-download a {
	color:#fff;
	border-color:#F69139;
}

.tools-4-logged-download .malar-download a:hover {
	background-color:#F69139;
}

/* Google Translation */
.gt_switcher-popup {
	display:flex;
	align-items:center;
	position:relative;
}

.gt_switcher-popup img + span {
	display:none !important;
}

.gt_switcher-popup img {
	opacity:1 !important;
	width:36px !important;
	box-shadow: 0 0 1px 1px rgba(0,0,0,0.1) !important;
}

.gt_switcher-popup span {
	margin-left:1rem !important;
}

.gt_white_content {
	background:#1d3478 !important;
	border-radius:10px !important;
}

.gt_white_content  span {
	color:#fff !important;
	font-size:15px !important;
	margin-left:0.5rem !important;
}

.gt_white_content a {
	display:flex !important;
	align-items:center !important;
}

.gt_white_content a img {
	width:32px !important;
}

/* Woc Page  */

/* WOC 1 */
.woc-1 {
    /* /wp-content/uploads/2024/05/bg-woc-1.png */
	background:no-repeat center url('');
	background-size:cover;
}

.woc-1 .container {
	max-width:900px !important;
}

/* WOC 2 */
.woc-2 {
	background: rgb(235,250,255);
	background: linear-gradient(180deg, rgba(235,250,255,1) 0%, rgba(255,255,255,1) 100%); 
}

/*  How it Works V2 */
.hiw-1-v2 {
	background:no-repeat center url('./assets/img/how-it-works/bg-hiw-1-v2.png');
	background-size: cover;
	position: relative;
	z-index: 3;
	background-position: center bottom;
	padding-bottom:12rem;
}

.hiw-3-v2 {
	margin-top:-10rem !important;
}

.new-last-section {
	padding-bottom: 0rem;
}

/* Tablet View */
@media (max-width: 1199px) {
	.wcp-1 .container {
		position:relative;
		z-index:3;
	}
	
	.wcp-1 {
		margin-bottom:-180px;
	}
  .site-menu-mobile-share-btn {
    display: block;
  }

  .home-6-box {
    padding: 0 30px 30px 30px;
  }

  .home-6-box h3 {
    font-size: 25px;
  }

  .white-bottom-curve::after {
    height: 150px;
    bottom: -150px;
  }

  .home-6 {
    padding-top: 220px;
  }

  .home-4-img-wrap {
    width: auto;
  }

  .home-4 {
    padding-bottom: 300px;
    padding-top: 70px;
  }

  .home-5 {
    margin-top: -150px;
  }

  .home-5 h3 {
    font-size: 22px;
  }

  .home-5 h4 {
    font-size: 16px;
  }

  .home-5 p {
    font-size: 16px;
  }

  html body .home-2 {
	  padding-bottom: 60px;
	  padding-top: 300px;
	  margin-top: -300px;
  }

  .home-3-right h3 {
    font-size: 30px;
  }

  .home-3-icon {
    font-size: 40px;
  }

  html body .home-3-img {
    width: 400px;
    margin-top: 30px !important;
  }

  .home-3-right p {
    font-size: 19px;
  }

  .font-57 {
    font-size: 40px;
  }

  .home-2-box {
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
  }

  .home-1 {
    min-height: 1px;
    padding-bottom: 160px;
  }

  .home-2 {
    padding-top: 60px;
    padding-bottom: 100px;
    margin-top: 0;
  }

  .home-1-img {
    top: 0;
  }

  .home-1 {
    padding-top: 220px;
	  padding-bottom:0;
  }

  .home-1-img {
    width: auto;
  }

  .site-menu-mobile-share-btn {
    position: absolute;
    right: 0;
    width: auto;
    top: 50%;
    margin-top: -13px;
  }

  .nav-down {
    background: #1d266b;
    border-bottom-color: #3b8921;
  }

  .site-menu-col,
  .site-social-col {
    display: none;
  }

  .site-logo-col {
    max-width: 100px !important;
    flex: 0 0 100%;
    text-align: center;
    margin: 0 auto;
  }

  .site-menu-mobile-btn {
    display: block;
  }

  .site-header .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .home-banner {
    min-height: 900px;
  }

  .font-32 {
    font-size: 25px;
  }

  .home-display .row {
    padding-bottom: 60px;
  }

  .home-display-slider-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }

  h2 {
    font-size: 36px;
  }

  .home-all-in-one {
    min-height: 900px;
  }

  .home-6-reasons {
    padding-top: 40px;
    padding-bottom: 150px;
  }

  .home-6-reasons-video {
    margin-top: 45px;
  }

  .home-6-reasons-lists {
    max-width: 100%;
    margin-left: 0;
  }

  .home-6-reasons-cta {
    margin-top: 45px;
  }

  .owards-rewards::before {
    height: 135px;
    top: -75px;
  }

  .owards-cta a {
    font-size: 25px;
  }

  .rewards-percent {
    font-size: 28px;
  }

  .rewards-text {
    font-size: 17px;
  }

  .rewards-col-img {
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-marketing-solution {
    padding-top: 100px;
  }

  .home-marketing-solution-img img {
    width: 250px;
  }

  .home-marketing-solution .img-wrap {
    margin-bottom: 45px;
  }

  .home-marketing-solution-cta {
    margin-top: 45px;
  }

  .home-marketing-solution {
    min-height: 1250px;
  }

  .home-follow-signup {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .home-follow-signup-left p br {
    display: none;
  }

  .site-footer-col-1,
  .site-footer-col-2,
  .site-footer-col-3,
  .site-footer-col-4 {
    flex: 0 0 50%;
    margin-bottom: 20px;
  }

  .site-footer-col-4 {
    text-align: left;
  }

  .after-footer {
    margin-top: 15px;
  }

  .scroll-to-top a {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .scroll-to-top {
    right: 15px;
    bottom: 15px;
  }

  .subpage-banner {
    min-height: 572px;
  }

  .subpage-banner h1 {
    margin-top: 90px;
  }

  .mobile-keywords-2 {
    min-height: 550px;
  }

  .mobile-keywords-3 {
    padding-top: 0 !important;
  }

  .rounded-num {
    font-size: 55px;
  }

  .mobile-keywords-3 {
    min-height: 1265px;
  }

  .font-51 {
    font-size: 35px;
  }

  .mobile-keywords-4 img {
    width: 350px;
  }

  .inds-banner {
    min-height: 946px;
  }

  .inds-2 {
    background-position: -175px;
  }

  .sms-banner {
    min-height: 877px;
  }

  .font-65 {
    font-size: 45px;
  }

  .sms-banner {
    min-height: 777px;
  }

  .sms-2 {
    padding-top: 60px;
    padding-bottom: 160px;
  }

  .sms-2 img {
    width: 200px;
  }

  .mt-45 {
    margin-top: 30px !important;
  }

  .font-39 {
    font-size: 29px;
  }

  .font-41 {
    font-size: 30px;
  }

  h3 {
    font-size: 45px;
  }

  .sms-3-inner .img-wrap img {
    width: 150px;
  }

  .sms-3-inner h4 {
    font-size: 24px;
  }

  .sms-3-inner p {
    font-size: 16px;
  }

  .sms-4-col .img-wrap {
    width: 593px;
    top: -165px;
    right: -180px;
  }

  .sms-4 {
    min-height: 1px;
    padding: 60px;
  }

  .sms-5 {
    margin-top: 0;
  }

  .sms-5 {
    min-height: 956px;
  }

  .pt-80 {
    padding-top: 60px !important;
  }

  .sms-6-col-text {
    padding: 50px;
  }

  .sms-7 {
    padding-top: 80px;
  }

  .sms-7-col-text-heading-2 {
    margin-top: 30px;
  }

  .sms-8 {
    padding-top: 60px;
  }

  .mt-60 {
    margin-top: 45px !important;
  }

  .sms-8-col-img .img-wrap {
    width: 488px;
  }

  .sms-9-col-img .img-wrap {
    width: 755px;
  }

  .sms-10 {
    min-height: 1px;
    padding-top: 240px;
    padding-bottom: 200px;
  }

  .font-24 {
    font-size: 20px;
  }

  .text-to-vote-banner {
    min-height: 663px;
  }

  .text-to-vote-banner h1 {
    margin-top: 0 !important;
  }

  .t2v-2 {
    padding-top: 40px;
    min-height: 1px;
    padding-bottom: 180px;
  }

  .t2v-num {
    font-size: 39px;
    width: 140px;
    height: 140px;
  }

  .t2v-3 .img-wrap {
    width: 976px;
    left: -556px;
  }

  .t2v-3-list-content h3 {
    font-size: 25px;
  }

  .t2v-3 {
    padding-bottom: 180px;
  }

  .ul-checklist-2 li {
    font-size: 27px;
    padding-left: 55px;
    padding-top: 4px;
  }

  .t2v-4 .img-wrap {
    width: 826px;
    left: -226px;
  }

  .t2v-4 {
    padding-bottom: 140px;
  }

  .font-44 {
    font-size: 29px;
  }

  .social-influencers-banner h1 {
    margin-top: 60px !important;
  }

  .si-2 {
    padding-top: 60px;
  }

  .font-37 {
    font-size: 27px;
  }

  .mt-75 {
    margin-top: 45px !important;
  }

  .si-2-row-3 img {
    width: 615px;
  }

  .transparent-wave::after {
    height: 111px;
  }

  .font-229 {
    font-size: 150px;
  }

  .font-50 {
    font-size: 30px;
  }

  .transparent-wave-2 {
    padding-bottom: 180px;
  }

  .si-4-img-1 {
    width: 100%;
    left: 0;
  }

  .si-4 {
    padding-bottom: 200px;
    overflow: hidden;
  }

  .mt-90 {
    margin-top: 45px !important;
  }

  .si-5-row-4-img-2 {
    left: -555px;
    top: -200px;
  }

  .si-5-row-4-text-1,
  .si-5-row-4-text-2 {
    margin-left: 0;
  }

  .si-5-row-4-col-left {
    padding-top: 150px;
  }

  .si-5-row-4-img-1 {
    width: 595px;
    left: -110px;
  }

  .contact-2 {
    padding-top: 60px;
  }

  .banner-404 h1 {
    margin-top: 0;
  }

  .site-footer-404 {
    padding-top: 20px;
  }

  .search-text-box {
    width: 90%;
  }

  .search-submit {
    right: 10%;
  }

  .search-close {
    right: 5%;
  }

  .search .search-text-box {
    width: 100%;
  }

  .search .search-submit {
    right: 0;
  }

  .contact-banner {
    min-height: 250px !important;
    padding-top: 40px !important;
  }

  .contact-banner h1 {
    margin-top: 110px !important;
  }

  .crm-banner {
    height: 425px;
    min-height: 1px !important;
  }

  .crm-banner h1 {
    margin-top: 50px !important;
  }

  .crm-banner .img-wrap {
    width: 390px;
    margin: 0 auto;
    margin-top: 40px !important;
  }

  .transparent-wave-2 {
    padding-bottom: 150px;
  }

  .crm-2 {
    padding-top: 220px;
    padding-bottom: 150px;
  }

  .ul-checklist-3 li {
    font-size: 20px;
  }

  .ul-checklist-3 li {
    margin-bottom: 10px;
  }

  .crm-3 {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .crm-4 {
    padding-bottom: 150px;
  }

  .crm-5 {
    padding-top: 40px;
  }

  .integrated-banner {
    min-height: 450px !important;
    height: auto;
  }

  .integrated-banner h1 {
    margin-top: 100px !important;
  }

  .ul-checklist-3-with-p span {
    font-size: 23px;
  }

  .ul-checklist-3-with-p p {
    font-size: 19px;
  }

  .integrated-2 {
    padding-bottom: 200px;
    padding-top: 0;
  }

  .integrated-2 .row {
    margin-top: 90px !important;
  }

  .integrated-3 .sms-3-inner h3 {
    font-size: 23px;
  }

  .integrated-3 .sms-3-inner p {
    font-size: 17px;
  }

  .integrated-3 {
    padding-bottom: 180px;
  }

  .social-marketing-banner img {
    width: 250px;
  }

  .social-marketing-3-img {
    max-width: 600px;
    margin: 0 auto;
  }

  .transparent-wave-3 {
    padding-bottom: 150px;
  }

  .font-29 {
    font-size: 25px;
  }

  .font-31 {
    font-size: 22px;
  }

  .font-43 {
    font-size: 28px;
  }

  .pt-100 {
    padding-top: 80px !important;
  }

  .img-like-gating {
    left: 0;
  }

  .font-23 {
    font-size: 20px;
  }

  .img-facebook-coupon {
    width: auto;
    left: 0;
  }

  .img-multi-channel-voting {
    right: -200px;
    top: 0;
    width: 500px;
  }

  .social-marketing-7 {
    padding-top: 80px !important;
  }

  .social-marketing-7-img {
    width: auto;
    left: 0;
  }

  .social-media-marketing .home-follow-signup {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .investor-2-img {
    width: auto;
    margin-left: 0;
  }

  .investor-3-img {
    right: 0;
    width: auto;
  }

  .transparent-wave-1::after {
    height: 70px;
  }

  .transparent-wave-1 {
    padding-bottom: 150px;
  }

  .investor-6-img {
    width: auto;
    margin-top: 0;
    margin-left: 0;
  }

  .investors-6 {
    padding-bottom: 60px;
  }

  .terms-of-use-banner {
    min-height: 356px !important;
  }

  .terms-of-use-banner h1 {
    margin-top: 60px !important;
  }

  .news-2 .col {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .news-2 .col {
    margin-bottom: 40px;
  }

  .news-banner h1 {
    margin-top: 0px !important;
  }

  .news-single .col {
    width: 100%;
    flex: 0 0 100%;
  }

  .news-single-sidebar-col {
    max-width: 400px !important;
    margin: 0 auto !important;
  }

  .news-banner {
    padding-top: 97px;
  }

  .font-81 {
    font-size: 51px !important;
  }

  .font-28 {
    font-size: 20px;
  }

  .owards-rewards-banner-img {
    width: 750px;
  }

  .owards-rewards-banner {
    min-height: 615px !important;
  }

  .owards-rewards-2-row-2 img {
    width: 150px;
  }

  .font-22 {
    font-size: 17px !important;
  }

  .owards-rewards-3-img {
    width: auto;
    margin-left: -80px;
    margin-top: 0;
  }

  .owards-rewards-3-img-2 {
    width: auto;
  }

  .anti-spam-banner {
    min-height: 450px !important;
  }

  .anti-spam-2 {
    padding-top: 40px !important;
  }

  .help-form {
    width: 500px;
  }

  .help-form input {
    font-size: 16px;
  }

  .help-lists li {
    font-size: 18px;
  }

  html body .custom-accordion .accordion-button {
    font-size: 20px;
  }

  .font-71 {
    font-size: 51px !important;
  }

  #mobile-menu img {
    width: 100px;
  }

  .subpage .site-header {
    background: #1d266b;
  }
	
	.site-translation-col {
		position: absolute;
		right: 0;
		top: 50%;
		width: auto !important;
		margin-top: -12px !important;
	}
	
	.site-translation-options {
		left:auto;
		right:0;
	}
	
	.home-1-img-wrap {
		margin-left:0;
	}
	
	.mission-img-wrap {
		max-width:400px;
	}
	
	.font-73 {
		font-size:43px !important;
	}
	
	.opportunity-img-wrap {
		margin-left:0;
	}
	
	.opportunity-right-col {
		padding-left:40px;
	}
	
	.opportunity-left-col {
		padding-left:0 !important;
	}
	
	.home-3 {
		padding-top:220px;
	}
	
	.ul-checklist li {
		font-size:15px;
		margin-bottom: 0.5rem;
	}
	
	.ul-checklist li::before {
		width:23px;
		height:23px;
		background-size: 100%;
	}
	
	.home-3 {
		margin-bottom:-200px;
	}
	
	.footer-ovato-img {
		width:auto;
		margin-left:0;
	}
	
	.footer-col-heading {
		font-size:16px;
	}
	
	html body .site-footer .wpforms-submit {
		font-size:14px !important;
	}
	
	.site-footer {
		background-size:cover;
		padding-top:440px;
	}
	
	.site-footer-lower {
		margin-top:40px;
	}
	
	.site-footer-lower-ul li {
		font-size:14px !important;
	}
	
	.site-btn {
		font-size:18px;
	}
	
	.offcanvas-body p {
		padding: 0 20px;
		text-align: center;
		margin-bottom: 15px !important;
		margin-top: 15px !important;
	}
	
	.site-header-upper .site-social {
		display:none;
	}
	
	.site-header-upper .col-sm {
		flex: 0 0 100%;
	}
	
	.site-header-upper p {
		text-align:center;
	}
	
	.mindmap-col-num {
		font-size:59px;
	}
	
	.mindmap-col-title {
		line-height:1.1;
	}
	
	.gsn-1-video-wrap::before {
		width:100px;
		height:100px;
		left:-50px;
		top:-50px;
	}
	
	.gsn-1-video-wrap::after {
		width:225px;
		height:225px;
		bottom:-100.5px;
		right: -100.5px;
	}
	
	.font-51 {
		font-size:41px !important;
	}
	
	.cp-col-title {
		font-size:25px !important;
	}
	
	.cp-col-num {
		width:46px;
		height:46px;
		font-size:20px;
	}
	
	.font-49 {
		font-size:39px !important;
	}
	
	.mission-left-col {
		padding-left:40px;
	}
	
	.mindmap-col-img-2 {
		width:176px;
	}
	
	.login .site-header {
		background:transparent;
	}
	
	.mw-1 .cp-1-left {
		margin-bottom: -100px;
		padding-right: 0;
	}
	
	.mw-2-row-3::before {
		width: 325px;
		height: 182px;
		left: -35px;
		top: -135px;
	}
	
	.pd-1-box {
		padding:40px 15px;
	}
	
	.ln-2-heading {
		font-size:35px !important;
	}
	
	.ln-3-top-curve::before {
		height: 75px;
		top: -75px;
	}
	
	.n-font-55 {
		font-size:45px !important;
	}
	
	.n-font-32 {
		font-size:27px !important;
	}
	
	.n-font-21 {
		font-size:19px !important;
	}
	
	.ln-4 {
		margin-top: 180px;
		padding-bottom: 0;
	}
	
	.ln-4-top-curve::before {
		top: -121px;
		height: 121px;
	}
	
	.n-font-42 {
		font-size:37px !important;
	}
	
	.ln-5 {
		background-size: cover;
	}
	
	.ln-6 {
		padding-top: 40px;
	}
	
	.n-font-44 {
		font-size: 35px !important;
	}
	
	.n-font-35 {
		font-size: 30px !important;
	}
	
	.mt-80 {
		margin-top: 40px !important;
	}
	
	.n-font-25 {
		font-size: 23px !important;
	}
	
	.mt-30 {
		margin-top: 15px;
	}
	
	.ln-7-right-img {
		width:auto;
	}
	
	.ln-7-row-2 {
		margin-top:40px;
	}
	
	.ln-8 {
		margin-top: 160px;
	}
	
	.ln-8-top-curve::before {
		top: -117px;
		height: 117px;
	}
	
	.ln-9-btn {
		font-size: 24px;
	}
	
	.ln-9 {
		background-size: cover;
	}
	
	#manage-billing {
		margin:0 !important;
	}
	
	#manage-billing button[type="submit"] {
		margin: 0;
		padding: 0;
		background: transparent;
		font-size: 16px;
		font-weight: 400;
		height: auto;
		text-align: left;
		display: block;
		padding: 15px;
		padding-left: 15px;
		padding-left: 40px;
	}
	
	.opportunity-row {
		margin-top:60px;
	}
	
	.sign-up-2 .container {
		max-width:90%;
		padding:20px;
	}
	
	.custom-pink-btn {
		font-size: 16px;
		min-width: 200px;
		position: relative;
		z-index: 2;
	}

	.cwa-1-img-3 {
		width: auto !important;
	}

	.cwa-1-count {
		font-size: 63px !important;
	}

	.cwa-1-count-text {
		font-size: 18px !important;
	}

	.cwa-1-row-3 {
		padding-bottom: 40px !important;
		margin-top: 0;
	}

	.cwa-1 {
		min-height: 1px !important;
	}

	.cwa-2-img-wrap img {
		width: 150px !important;
	}

	.cwa-ul li p {
		font-size: 16px !important;
	}

	.cwa-ul li strong {
		font-size: 18px !important;
	}

	.cwa-ul li::before {
		width: 30px !important;
		height: 30px !important;
	}

	.cwa-ul li {
		padding-left: 50px !important;
	}

	.cwa-3-img-wrap {
		width: auto !important;
		text-align: right !important;
	}

	.cwa-3-img-wrap::before {
		width: 100% !important;
		height: 100% !important;
		left: 0 !important;
		top: -140px !important;
	}

	.cwa-3-img-wrap img {
		margin-left: 0 !important;
		margin-top: 0 !important;
	}

	.cwa-3-col-2 {
		padding-left: 60px !important;
		padding-top: 0px !important;
	}

	.cwa-3 {
		padding-top: 140px !important;
	}

	.cwa-4-img-wrap {
		width: auto !important;
		position: relative !important;
		right: 0 !important;
		top: 0 !important;
	}

	.cwa-4-col-2 {
		padding-top: 0 !important;
		padding-right: 20px !important;
	}

	.cwa-4 {
		padding-top: 40px !important;
	}

	.cwa-5 {
		margin-top: 60px !important;
	}

	.consumer-wallet-app-main {
		overflow-x: hidden !important;
	}

	.cwa-6 {
		padding-bottom: 80px !important;
		padding-top: 40px !important;
	}

	.cwa-5-slider .img-wrap {
		padding: 140px 15px !important;
	}

	.cwa-5-slider .img-wrap img {
		border-radius: 10px !important;
	}

	.cwa-5-col-2 {
		margin-top: -90px !important;
	}

	.cwa-5 {
		margin-top: 20px !important;
	}

	.mwa-5-watch-video {
		left: -90px;
		width: 200px;
	}

	.mwa-5-video-wrap {
		margin-bottom: 30px;
		margin-top: 45px;
	}

	.mwa-6-img-wrap-1 {
		width: 100%;
		margin-left: 0;
	}

	.mwa-6 {
		padding-bottom: 0 !important;
	}
	
	.cwa-5 p {
		font-size:16px !important;
		margin-top:0 !important;
		margin-bottom:40px !important;
	}
	
	.cwa-6 .n-font-18 {
		font-size:16px !important;
	}
	
	.custom-wallet-app .site-menu-mobile-btn {
		display:none !important;
	}
	
	.cwa-5-slider .slick-arrow.slick-prev {
		left:10px !important;
	}
	
	.cwa-5-slider .slick-arrow.slick-next {
		right:10px !important;
	}
	
	.tools-2-logged {
		margin-top:130px;
	}
	
	.new-site-social-col {
		display:none !important;
	}
	
	.n-font-43 {
		font-size:33px !important;
	}
	
	.new-home-2-right-img-1 {
		max-width:365px;
	}
	
	.new-home-2-right-img-2 {
		max-width:439px;
	}
	
	.new-home-2 {
		padding-top:80px;
	}
	
	.new-home-2-right-img-2 {
		margin-bottom:0;
	}
	
	.new-home-3-row {
		padding:40px 20px 30px 20px;
	}
	
	.new-home-3-col-text-1 {
		font-size:45px;
	}
	
	.new-home-4 {
		padding-top:60px;
	}
	
	.n-font-48 {
		font-size:38px !important;
	}
	
	.new-home-4-img-text-wrap .text-wrap {
		padding-left:30px;
	}
	
	.new-home-4-img-text-wrap h3 {
		font-size:18px;
	}
	
	.new-home-4-img-text-wrap p {
		font-size:16px;
	}
	
	.new-home-4-img-left {
		padding-right:60px;
	}
	
	.new-home-5::before {
		height:106px;
	}
	
	.new-home-5 {
		padding-top:180px !important;
	}
	
	.cwa-popup-form-step-wrap {
		margin-bottom:40px !important;
	}
	
	.custom-signup-main .site-logo {
		margin-left:0;
	}
	
	#fta-view-popup .suc-popup-inner,
	.fta-view-popup .suc-popup-inner {
		width:95%;
	}
	
	.top-curve-4::before {
		top:-100px;
		height:100px;
	}
	
	.gsn-1-new-video-wrap-img {
		right: -5%;
		width: 300px;
		top: 236px;
	}
	
	.tools-2-new {
		margin-top:-160px; 
		padding-bottom:40px;
	}
	
	.top-curve-5::before {
		height:113px;
	}
	
	.tools-3-new {
		padding-top:120px;
	}
	
	.top-curve-6::before {
		height:100px;
	}
	
	.tools-4-new {
		padding-top:140px;
	}
	
	.tools-3-new .row {
		max-width:90%;
	}
	
	.tools-4-new .row {
		max-width:90%;
	}
	
	.eo-1 {
		padding-top: 40px;
		margin-top: 120px;
	}
	
	.eo-1 h1 {
		font-size:30px;
	}
	
	.eo-1-box {
		width:95%;
		padding:40px;
	}
	
	.sign-up-2 {
		margin-top:180px;
	}
	
	.custom-wallet-app-new .site-header {
		background:#fff;
	}
	
	.custom-wallet-app-new .site-header-lower {
		height:107px;
	}
	
	.custom-wallet-app-new .site-header .site-translation-selected-lang {
		color: #1B246A;
	}
	
	.custom-wallet-app-new .site-header .site-translation-selected-down {
		color: #1B246A;
	}
	
	.custom-wallet-app-new .site-header.active .site-header-lower {
		height:90px;
	}
	
	.site-header-sponsor-left p {
		font-size:14px !important;
	}
	
	.site-header-sponsor-right-p {
		font-size:14px;
	}
	
	.mw-1 {
		padding-bottom:500px;
	}
	
	.promo-1-left .font-67 {
		font-size:47px !important;
	}
	
	.promo-1-rating .img-wrap {
		width:150px;
	}
	
	.promo-btn {
		font-size:22px;
	}
	
	.promo-1-header p {
		font-size:22px !important;
	}
	
	.promo-free-img-wrap {
		width:400px;
	}
	
	.promo-1-client-img-wrap {
		width:auto;
		left:0;
	}
	
	.promo-1-app-img-wrap {
		width:auto;
		top:340px;
	}
	
	.promo-2 {
		background-size:cover;
	}
	
	.tools-new-2-logged-2 {
		margin-top:0 !important;
	}
	
	.site-header-sponsor-left {
		justify-content:center;
	}
	
	.mobile-sponsor .site-header-sponsor-tshirt-right {
		position:relative;
	}
	
	.eo-2-row-2-heading .img-wrap {
		width: 150px;
		left: -185px;
		top: -5px;
	}
	
	.pledge-2-text {
		font-size: 100px !important;
	}
	
	.jko-1 {
		padding-top:1rem;
	}
	
	.jko-4 {
		background-size:100% 100%;
	}
	
	.jko-easy {
		background-size:100% 100%;
		margin-bottom:0;
	}
	
	.jko-easy-img-1 {
		width:auto;
		margin:0;
	}
	
	.jko-easy-tee {
		position: relative;
		right: 0;
		bottom: 0;
	}
	
	.cp-col-box .mw-collection-box-btn {
		font-size:14px;
		padding: 10px 20px;
	}
	
	.new-banner-img {
		width:377px;
	}
	
	.new-home-44 .img-wrap {
		max-width:768px;
	}
	
	.l-font-28 {
		font-size:28px !important;
	}
	
	.new-home-4-img-text-wrap-new .text-wrap {
		padding-left:0;
	}
	
	.new-home-4-img-text-wrap-new p br {
		display:none;
	}
	
	.home-3-heading-new {
		margin-bottom:1rem;
	}
	
	/* HIW */
	.hiw-1-text {
		font-size:27px !important;
	}
	
	.hiw-1-ul li {
		font-size:16px !important;
	}
	
	.hiw-2 {
		padding:10rem 0 6rem 0;
	}
	
	.hiw-2 .n-font-39 {
		font-size:36px !important;
	}
	
	.hiw-2 .n-font-25 {
		font-size:22px !important;
	}
	
	.hiw-2-box h3 {
		font-size:24px;
	}
	
	.hiw-2-box p {
		font-size:17px;
	}
	
	.hiw-3 h2 {
		font-size:36px !important;
	}
	
	.hiw-1-img-wrap {
		padding-right:0;
	}
	
	/* NFT */
	.nft-1 p {
		font-size:20px !important;
	}
	
	.nft-2 {
		background-size:cover;
		padding-top:6rem;
	}
	
	.nft-2 h1 {
		font-size:40px !important;
	}
	
	.nft-3 {
		padding-top:12rem;
	}
	
	.nft-3-left-col h3 {
		font-size:20px;
	}
	
	.nft-3-row ul li {
		font-size:16px;
	}
	
	.nft-3-row h4 {
		font-size:18px;
	}
	
	/* Tools New */
	.tools-3 {
		padding-top:3rem;
	}
	
	.tools-3-row {
		max-width:90% !important;
	}
	
	.mw-1 {
		padding-top:180px;
	}
	
	/* Get Started 2 */
	.get-started-box-header h2 {
		font-size:32px;
	}
	
	.get-started-box-header p {
		font-size:22px;
	}
	
	.get-started-box-body {
		padding:3rem;
	}
	
	.get-started-box .site-btn-2-wrap {
		margin-top:1.5rem !important;
	}
	
	.gs-sml-header-title {
		font-size:28px;
	}
	
	.gs-sml-header-price {
		font-size:20px;
	}
	
	.gs-sml-body {
		flex-flow:column;
		padding:2rem 1rem 1rem 1rem;
	}
	
	.gs-sml-level {
		max-width:100%;
		flex:0 0 100%;
		margin-top: 1rem;
	}
	
	.gs-sml-footer {
		padding-bottom:3rem;
	}
	
	.v2-mw-1 {
		padding-bottom:300px;
	}
	
	.top-curve-7::before {
		height:71px;
	}
	
	.tl-pt-8 {
		padding-top:8rem !important;
	}
	
	.gt_switcher-popup span {
		margin-right:0 !important;
	}
	
	.gt_switcher-popup img {
		width:32px !important;
	}
	
	/* Margin Right */
	.v2-tl-mr--12 {
		margin-right:-12rem !important;
	}

	.v2-tl-mr-0 {
		margin-right:0rem !important;
	}
	
	/* Margin Top */
	.v2-tl-mt-2 {
		margin-top:2rem !important;
	}
	
	.v2-tl-mt-4 {
		margin-top:4rem !important;
	}
	
	/* How it Works V2 */
	.hiw-2-img-v2 {
		max-width:100% !important;
	}

	/* Fonts */
	.v2-tl-font-22 {
		font-size: 22px !important;
	}

	.v2-tl-font-36 {
		font-size: 36px !important;
	}

	.new-last-section {
		padding-bottom: 2rem !important;
		margin-bottom: -10rem;
	}

	.v2-tl-pt-10 {
		padding-top: 10rem !important;
	}
} /* END 1199px */

@media (max-width: 910px) {
	p:last-of-type {
		margin-bottom:0;	
	}
	
	html body .col, html body .col-md-4, html body .col-md-5, html body .col-md-6, html body .col-md-7, html body .col-md-8, html body .col-md-12, html body .col-sm-4, html body .col-sm-8 {
		-webkit-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
	}
	
	.img-wrap {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	
  .font-30 {
    font-size: 25px !important;
  }

  .col-sm {
    text-align: center;
  }

  .home-banner {
    min-height: 800px;
  }

  .home-banner-heading {
    margin-top: 175px;
  }

  .slick-arrow {
    width: 18px;
    height: 32px;
  }

  .home-all-in-one .col-sm {
    padding-left: 0;
  }

  h2 {
    font-size: 35px;
  }

  .home-6-reasons-heading img {
    display: inline-block;
    vertical-align: bottom;
    width: 150px;
  }

  .home-6-reasons-video {
    height: 500px;
  }

  .col-sm {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .home-6-reasons-lists p {
    text-align: left;
  }

  .home-6-reasons-lists img {
    width: 78px;
  }

  .home-6-reasons-lists {
    margin-top: 45px;
  }

  .home-6-reasons-cta {
    margin-top: 30px;
  }

  .home-6-reasons {
    padding-bottom: 120px;
  }

  .owards-img img {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .owards-cta a {
    font-size: 22px;
  }

  .home-marketing-solution {
    padding-top: 80px;
  }

  .home-marketing-solution p {
    text-align: left;
  }

  .home-marketing-solution .img-wrap {
    text-align: center;
  }

  .home-marketing-solution p img {
    width: 78px;
  }

  .home-marketing-solution-img img {
    width: 200px !important;
  }

  .home-marketing-solution {
    min-height: 1040px;
  }

  .col-sm-6 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    text-align: center;
  }

  .home-follow-signup-left {
    padding-right: 12px;
    border-right: 0;
    border-bottom: 2px solid #75a9c1;
    margin-bottom: 30px;
    max-width: 90%;
    margin: 0 auto 30px;
  }

  .home-follow-right {
    padding-left: 12px;
  }

  .font-39 {
    font-size: 30px;
  }

  .subscribe-form {
    margin: auto;
  }

  .home-follow-signup {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .site-footer {
    padding-top: 60px;
  }

  .site-footer-col-1,
  .site-footer-col-2,
  .site-footer-col-3,
  .site-footer-col-4 {
    margin-bottom: 10px;
  }

  .subpage-banner {
    min-height: 472px;
  }

  .subpage-banner h1 {
    margin-top: 180px;
  }

  .mobile-keywords-2 {
    padding-top: 60px;
  }

  .mobile-keywords-2 {
    background-size: 100%;
    min-height: 1px;
  }

  .mobile-keywords-3 {
    padding-top: 40px !important;
  }

  .col-sm-5,
  .col-sm-7 {
    flex: 0 0 100% !important;
    width: 100% !important;
  }

  .mobile-keywords-3-img img {
    width: 250px;
  }

  .mt-45 {
    margin-top: 30px !important;
  }

  .mobile-keyword-count {
    max-width: 100%;
  }

  .font-51 {
    font-size: 30px;
  }

  .mobile-keywords-4 {
    padding-top: 40px;
  }

  .mobile-keywords-4 .img-col {
    order: 1;
  }

  .mobile-keywords-4 .text-col {
    order: 2;
  }

  .mobile-keywords-4 img {
    width: 175px;
  }

  .mobile-keywords-4 .row {
    margin-bottom: 60px;
  }

  .mobile-keywords-4 {
    background-size: 100% 225px;
  }

  .mobile-keywords-4 {
    padding-bottom: 60px;
  }

  .inds-banner h1 {
    margin-top: 32px;
  }

  .inds-banner {
    min-height: 846px !important;
  }

  .inds-banner p {
    max-width: 300px;
  }

  .inds-2 {
    padding-top: 0px !important;
  }

  .sms-banner h1 {
    margin-top: 15px;
  }

  .font-65 {
    font-size: 40px;
  }

  .sms-banner {
    min-height: 577px;
    padding-bottom: 90px;
  }

  .sms-banner .col-sm-7 {
    max-width: 55%;
    flex: 0 0 55%;
  }

  .sms-banner .col-sm-7 br {
    display: none;
  }

  .sms-2 {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .sms-2 .col-sm-4 {
    margin-bottom: 30px;
  }

  .font-41 {
    font-size: 25px;
  }

  h3 {
    font-size: 35px;
  }

  .sms-3 {
    padding-bottom: 20px;
  }

  .sms-4-col .img-wrap {
    position: static;
    width: 400px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .sms-4-col {
    text-align: center;
  }

  .sms-4 {
    padding-bottom: 0;
  }

  .sms-5 .col-sm-4 {
    background: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
    padding: 40px;
  }

  .sms-5 {
    padding-bottom: 130px;
  }

  .sms-6 {
    padding-top: 0 !important;
    text-align: center;
  }

  .sms-6-col-text {
    border-radius: 0 0 66px 66px;
    padding: 40px;
  }

  .sms-6 br {
    display: none;
  }

  .sms-6-col-img {
    min-height: 300px;
    border-radius: 66px 66px 0 0;
  }

  .sms-6-row-1 {
    max-width: 400px;
    margin: 0 auto;
  }

  .sms-5-row-2 {
    max-width: 400px;
    margin: 0 auto;
  }

  .sms-7-col-img .img-wrap {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    left: 0;
  }

  .sms-7 {
    text-align: center;
  }

  .sms-8 {
    text-align: center;
  }

  .sms-8 br {
    display: none;
  }

  .sms-8-col-img .img-wrap {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    max-width: 400px;
  }

  .sms-8-col-img {
    order: 1;
    margin-bottom: 45px;
  }

  .sms-8-col-text {
    order: 2;
  }

  .sms-8 {
    padding-bottom: 210px;
    padding-top: 40px;
  }

  .sms-9-col-img .img-wrap {
    width: 100%;
  }

  .sms-9-col-img .img-wrap {
    width: 100%;
    left: 0;
    max-width: 400px;
    margin: 0 auto;
  }

  .sms-9 {
    margin-top: -100px;
    text-align: center;
  }

  .sms-9 br {
    display: none;
  }

  .mt-60 {
    margin-top: 30px !important;
  }

  .sms-10 {
    padding: 210px 0;
    margin-bottom: -80px;
  }

  .sms-10 .row {
    max-width: 500px;
    margin-left: 0;
  }

  .sms-3-inner-wrap {
    max-width: 400px;
    margin: 0 auto;
  }

  .text-to-vote-banner {
    min-height: 550px;
  }

  .col-sm-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }

  .t2v-2-row-2::after {
    display: none;
  }

  .t2v-2-row-2 {
    max-width: 400px;
    margin: 0 auto;
  }

  .t2v-2-row-2 .col-sm-3 {
    margin-bottom: 15px;
  }

  .t2v-2 {
    background-size: 100%;
    padding-top: 40px;
    min-height: 1px;
    padding-bottom: 100px;
  }

  .t2v-3 .img-wrap {
    width: 100%;
    left: 0;
    max-width: 400px;
    margin: 0 auto;
  }

  .t2v-3-list-content h3 {
    text-align: center;
  }

  .t2v-3-list-content p {
    text-align: center;
  }

  .t2v-3-list {
    margin-bottom: 30px;
  }

  .t2v-3-list {
    flex-flow: column;
    max-width: 400px;
    margin: 0 auto 20px auto;
  }

  .t2v-3-list-img {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .t2v-3-list-content {
    padding-left: 0;
  }

  .t2v-3 {
    padding-bottom: 120px;
  }

  .col-sm-8 {
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
  }

  .t2v-4 {
    text-align: center;
  }

  .ul-checklist-2 li {
    text-align: left;
  }

  .ul-checklist-2 li {
    font-size: 24px;
  }

  .t2v-4-right {
    order: 1;
  }

  .t2v-4-left {
    order: 2;
  }

  .t2v-4 .img-wrap {
    width: 100%;
    left: 0;
    max-width: 500px;
    margin: 0 auto;
  }

  .font-44 {
    font-size: 25px;
  }

  .social-influencers-banner img {
    width: 200px;
  }

  .social-influencers-banner h1 {
    margin-top: 40px !important;
  }

  .font-37 {
    font-size: 25px;
  }

  .mt-75 {
    margin-top: 15px !important;
  }

  .si-2-row-3 .img-wrap {
    width: 100%;
    left: 0;
    max-width: 400px;
    margin: 30px auto 0 auto;
  }

  .transparent-wave::after {
    height: 70px;
  }

  .si-3 {
    text-align: center;
  }

  .font-229 {
    font-size: 99px;
  }

  .transparent-wave-2 {
    padding-bottom: 120px;
  }

  .si-4 {
    text-align: center;
  }

  .si-4-img-1 {
    max-width: 400px;
    margin: 30px auto 0 auto;
  }

  .si-4 {
    padding-bottom: 140px;
  }

  .si-5-row-1 .pt-20 {
    padding-top: 0 !important;
  }

  .si-5-col-img {
    max-width: 400px;
    margin: 0 auto;
  }

  .si-5-col-text {
    padding-left: 12px;
    margin-top: 30px;
  }

  .si-5-col-text-left {
    padding-top: 0;
    padding-right: 12px;
    order: 2;
  }

  .si-5-col-img-right {
    order: 1;
    max-width: 400px;
    margin: 0 auto;
  }

  .si-5-row-3 {
    margin-top: 30px !important;
  }

  .si-5-col-text-left {
    margin-top: 15px;
  }

  .si-5-row-4-img-2 {
    left: 0;
    top: 0;
    max-width: 800px;
  }

  .si-5-row-4-col-left {
    padding-top: 80px;
  }

  .si-5-row-4-img-1 {
    width: auto;
    left: 0;
    max-width: 550px;
    margin: 0 auto;
    margin-top: 0px;
  }

  .si-5-row-4-img-2 {
    left: 50%;
    top: 0;
    max-width: 600px;
    margin-left: -300px;
  }

  .si-5-row-4-col-right {
    margin-top: 45px;
  }

  .contact-details {
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }

  .contact-2-left {
    padding-right: 12px;
  }

  .contact-icon {
    margin-right: 0;
  }

  .font-25 {
    font-size: 20px;
  }

  .map-wrap {
    max-width: 400px;
    margin: 0 auto 30px auto;
  }

  .contact-2-right {
    padding-left: 12px;
  }

  .contact-2 {
    padding-top: 40px;
  }

  html body div.wpforms-container-full .wpforms-form input[type='text'],
  html body div.wpforms-container-full .wpforms-form input[type='email'] {
    height: 50px;
    font-size: 16px;
  }

  html body div.wpforms-container-full .wpforms-form .wpforms-field-label {
    font-size: 16px;
  }

  html body div.wpforms-container-full .wpforms-form button[type='submit'] {
    height: 60px;
    font-size: 20px;
  }

  .subscribe-form,
  .subscribe-form form {
    margin: auto;
  }

  .banner-404 h1 {
    margin-top: 0;
  }

  .banner-404 {
    padding-top: 140px;
  }

  .site-footer-404 {
    padding-top: 20px;
  }

  .font-256 {
    font-size: 100px;
  }

  .font-54 {
    font-size: 29px;
  }

  .modal-design-1 .modal-content::before {
    display: none;
  }

  .modal-design-1 .modal-content {
    padding: 0 20px;
    min-height: 1px !important;
  }

  html
    body
    .modal-design-1
    div.wpforms-container-full
    .wpforms-form
    .wpforms-field-text,
  html
    body
    .modal-design-1
    div.wpforms-container-full
    .wpforms-form
    .wpforms-field-email,
  html body .modal-design-1 .wpforms-submit-container {
    padding: 10px;
  }

  .search-text-box {
    width: 100%;
  }

  .search-submit {
    position: static;
  }

  .search-close {
    right: 0;
    top: 0;
  }

  .search-form {
    padding-top: 40px;
  }

  .search-submit {
    margin-top: 20px;
    width: 100%;
  }

  .search-form button {
    width: 100%;
  }

  .search-text-box .form-control {
    padding-right: 20px;
  }

  .search-2 {
    padding-top: 40px;
  }

  .search-result h3 {
    font-size: 22px;
  }

  .search-result {
    margin-bottom: 20px;
  }

  .search .search-form {
    padding-top: 0;
  }

  .crm-2-img,
  .crm-3-img,
  .crm-4-img {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .crm-2 h2 {
    margin-top: 15px;
  }

  .ul-checklist-3 li {
    text-align: left;
  }

  .crm-2 {
    padding-bottom: 80px;
  }

  .crm-3 {
    padding-top: 20px;
  }

  .md-order-1 {
    order: 1;
  }

  .md-order-2 {
    order: 2;
  }

  .crm-3-img,
  .crm-4-img {
    margin-bottom: 30px;
  }

  .crm-3 {
    padding-bottom: 100px;
  }

  .crm-4-img {
    margin-top: 0;
  }

  .crm-4 {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .crm-5-img {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .crm-5 {
    padding-bottom: 80px !important;
  }

  .integrated-col-img .integrated-2-img {
    max-width: 400px;
    margin: 0 auto 30px auto;
    padding: 0;
  }

  .integrated-2 .row {
    margin-top: 75px !important;
  }

  .integrated-2 {
    padding-bottom: 150px;
  }

  .integrated-3 {
    padding-bottom: 140px;
  }

  .social-marketing-banner img {
    width: 200px;
  }

  .pt-60 {
    padding-top: 40px !important;
  }

  .social-marketing-2-img {
    width: auto;
    max-width: 400px;
    margin: 0 auto 30px;
  }

  .social-marketing-2-box-row .col-sm-2 {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .social-marketing-2-box-row {
    justify-content: center;
  }

  .transparent-wave-3 {
    padding-bottom: 100px;
  }

  .social-marketing-5-img {
    width: auto;
    top: 0;
    left: 0;
    max-width: 400px;
    margin: 0 auto 30px auto;
  }

  .col-sm-9 {
    width: 100%;
  }

  .font-29 {
    font-size: 23px;
  }

  .social-marketing-5 {
    text-align: center;
    padding-bottom: 60px !important;
    padding-top: 60px !important;
  }

  .font-43 {
    font-size: 23px;
  }

  html body br {
    display: none;
  }

  .img-facebook-tab-editor {
    width: auto;
    margin: 0 auto 30px auto;
    text-align: center;
  }

  .social-marketing-6 {
    text-align: center;
  }

  .ul-checklist-4 li {
    text-align: left;
  }

  .divider-ridge {
    margin-bottom: 20px;
  }

  .img-like-gating {
    width: auto;
    left: 0;
    max-width: 600px;
    margin: 15px auto 30px auto;
  }

  .mt-90 {
    margin-top: 30px !important;
  }

  .img-facebook-coupon {
    margin: 0;
  }

  .img-multi-channel-voting {
    right: 0;
    top: 0;
    width: auto;
    position: relative;
    max-width: 400px;
    margin: 0 auto 30px auto;
  }

  .multi-channel-voting-row {
    padding-bottom: 40px;
  }

  .social-marketing-7-img {
    max-width: 400px;
    margin: 0 auto 10px auto;
  }

  .social-marketing-7 {
    padding-top: 60px !important;
    text-align: center;
  }

  .font-97 {
    font-size: 37px;
  }

  .investors-banner {
    min-height: 600px !important;
  }

  .investor-2-img {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }

  .investors-2 {
    text-align: center;
  }

  .investors-3 {
    text-align: center;
    padding-top: 40px;
  }

  .font-19 {
    font-size: 17px;
  }

  .investor-3-img {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }

  .investors-4 {
    padding-top: 40px;
  }

  .transparent-wave-1::after {
    height: 50px;
  }

  .transparent-wave-1 {
    padding-bottom: 120px;
  }

  .investors-5 .row .col-sm-4 {
    margin-bottom: 60px;
  }

  .transparent-wave-1 {
    padding-bottom: 100px;
  }

  .investors-5 .row {
    margin: 0 !important;
  }

  .investors-5 {
    padding-bottom: 80px;
  }

  .investors-6 {
    padding-top: 60px;
  }

  .investor-6-img {
    margin: 0 auto 20px auto;
    max-width: 400px;
  }

  .md-order-1 {
    order: 1;
  }

  .md-order-2 {
    order: 2;
  }

  #download-modal .modal-content {
    padding-left: 50px;
  }

  .terms-of-use-2 {
    padding-top: 40px;
  }

  .owards-rewards-banner-img {
    width: auto;
    position: static;
  }

  .owards-rewards-banner-img {
    max-width: 500px;
    margin: 0 auto;
  }

  .font-81 {
    font-size: 41px !important;
  }

  .owards-rewards-2 {
    padding-top: 0 !important;
  }

  .owards-rewards-2-row-2 .col-sm-4 {
    margin-bottom: 20px !important;
  }

  .owards-rewards-3-img {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }

  .owards-rewards-3 {
    text-align: center;
  }

  .img-wrap-col {
    order: 1;
  }

  .text-col-wrap {
    order: 2;
  }

  .owards-rewards-3-img-2 {
    width: auto;
    max-width: 400px;
    margin: 0 auto 30px;
  }

  .img-text-wrap {
    flex-flow: column;
    margin: 0;
  }

  .img-text-icon {
    margin: 0 0 20px 0;
  }

  .media-2 .col-inner {
    max-width: 400px;
    margin: 0 auto 30px auto;
  }

  .font-42 {
    font-size: 32px;
  }

  html body .anti-spam-banner br {
    display: block;
  }

  .anti-spam-banner h1 {
    line-height: 1.1;
  }

  .anti-spam-banner {
    min-height: 400px !important;
  }

  .help-form {
    width: 400px;
  }

  .help-2 {
    padding-top: 20px !important;
  }

  .help-2-single-content p {
    font-size: 17px;
  }

  .help-2-single-content ul {
    font-size: 17px;
  }

  .font-16 {
    font-size: 14px !important;
  }

  .other-help-col {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 15px;
  }

  .other-help-col .inner {
    min-height: 1px;
    padding-left: 25px;
  }

  .other-help-wrap {
    margin-top: 15px !important;
  }

  .font-17 {
    font-size: 16px;
  }

  .other-help-heading span {
    background: #fefefe;
  }

  .get-free-01 {
    background-size: cover;
    background-position: center bottom;
  }

  .mobile-only {
    display: block !important;
  }

  .font-71 {
    font-size: 45px !important;
  }

  .desktop-only {
    display: none !important;
  }

  .get-free-img-wrap {
    margin-top: 30px !important;
    position: relative;
    z-index: 1;
    margin-bottom: -140px;
  }

  .get-free-form-wrap {
    padding-top: 120px;
  }

  .get-free-01 {
    padding-bottom: 0;
  }

  .get-free-dl-wrap {
    margin-top: 10px;
  }

  .get-free-footer p {
    margin-top: 45px;
  }

  .affiliate-01 h1 {
    font-size: 40px;
    text-align: center;
  }

  .affiliate-01 h2 {
    font-size: 23px;
    text-align: center;
  }

  .affiliate-read-all {
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-left: 45px;
  }

  .get-free-form-wrap {
    padding-top: 40px;
  }

  .affiliate-form-wrap p {
    text-align: center;
  }

  .font-30 {
    font-size: 25px !important;
  }

  .home-1-img {
    margin-top: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-2-col {
    max-width: 400px;
    margin: 0 auto 40px auto;
  }

  .font-57 {
    font-size: 30px;
  }

  .site-btn {
    font-size: 18px;
  }

  html body .home-2 {
    padding-bottom: 0;
  }

  html body .home-3-img {
	  margin-top: 45px !important;
	  margin-left: auto;
	  margin-right: auto;
	}

  .home-3-box {
    margin-left: auto;
    margin-right: auto;
  }

  .home-3-right {
    text-align: left;
  }

  .home-3-right h3 {
    font-size: 25px;
  }

  .home-3-right p {
    font-size: 18px;
  }

  .home-4 {
    text-align: center;
    padding-bottom: 200px;
  }

  .home-4-img-wrap {
    margin-top: 15px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 0;
  }

  .home-5 {
    text-align: center;
  }

  .home-5 p {
    margin-bottom: 30px;
  }

  .home-5 .col-sm-3 {
    margin-bottom: 30px;
  }

  .white-bottom-curve::after {
    height: 100px;
    bottom: -100px;
  }

  .home-6 {
    padding-top: 180px;
  }

  .home-6 .col-sm-4 {
    max-width: 400px;
    margin: 0 auto 100px auto;
  }

  .site-footer {
	  background-size: cover;
	  background-position: center top;
	  padding-top: 140%;
  }

  .home-6 {
    padding-bottom: 100px;
  }

  .home-4 {
    padding-top: 20px;
  }

  .footer-col-2 {
    padding-left: 15px !important;
  }

  .footer-col-3 {
    padding-left: 15px !important;
  }

  .footer-col-4 {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
  }

  html body div.wpforms-container-full .wpforms-form button[type='submit'] {
    height: 50px;
    font-size: 18px;
    width: 100%;
  }

  .footer-col-1 {
    margin-bottom: 25px;
  }

  .footer-col-1 img {
    width: 150px;
  }
	
	.site-translation-col {
		width:auto !important;
	}
	
	.site-logo-col {
		max-width: 100px !important;
		flex: 0 0 100% !important;
		text-align: center !important;
		margin: 0 auto;
	}
	
	.site-translation-options li {
		text-align:left;
	}
	
	.home-1-img-wrap {
		max-width:400px;
		margin:0 auto;
	}
	
	h1, h2, h3 {
		font-size:30px;
	}
	
	.home-2 {
		text-align:center;
	}
	
	.font-73 {
		font-size:35px !important;
	}
	
	.mission-left-col {
		padding-left:20px;
		order:2;
		margin-top:30px;
	}
	
	.mission-right-col {
		order:1;
	}
	
	.opportunity-img-wrap {
		max-width:400px;
		margin: 0 auto;
		margin-top:45px;
	}
	
	.home-3 {
		margin-top:-40px;
		margin-bottom:-120%;
	}
	
	.opportunity-right-col {
		margin-top:30px !important;
		padding-left:20px !important;
		padding-right: 20px !important;
	}
	
	.home-3-col {
		margin-top:30px;
	}
	
	.home-3-heading {
		margin-bottom:15px !important;
	}
	
	.home-3-col ul {
		max-width:400px;
		margin:0 auto;
	}
	
	.footer-col {
		max-width: 100% !important;
		flex: 0 0 100% !important;
		margin-bottom: 15px;
	}
	
	.footer-col-heading {
		font-size:18px;
		font-weight:600;
	}
	
	.footer-ovato-img {
		max-width:200px;
		margin:0 auto;
	}
	
	.site-footer-lower {
		margin-top:0;
	}
	
	.site-footer ul {
		flex-flow: column;
		padding: 5px 20px;
	}
	
	.site-footer-lower-ul li::after {
		display:none;
	}
	
	.site-footer ul li {
		text-align:center;
	}
	
	.mindmap-col {
		max-width:50%;
		flex: 0 0 50%;
	}
	
	.mindmap-col-inner-big {
		min-height:299px;
	}
	
	.mindmap-col-top {
		margin-top:0;
	}
	
	.mindmap-col {
		text-align:left;
	}
	
	.mindmap-col-img-1 {
		width:225px;
	}
	
	.mindmap-col-img-2 {
		width:250px;
		right:auto;
		left:40px;
		top:0;
	}
	
	.mindmap-col-img-4 {
		width: 225px;
		top: 20px;
		right: -20px;
	}
	
	.mindmap-1 {
		margin-bottom:-120%;
	}
	
	.mindmap-1 .container {
		position:relative;
		z-index:5;
	}
	
	.gsn-1-col-right {
		order:1;
	}
	
	.gsn-1-col-left {
		order:2;
	}
	
	.gsn-1-video-wrap {
		max-width:600px;
		margin:0 auto;
	}
	
	.gsn-1-video-wrap::after {
		width:225px;
		height:225px;
		right:-112px;
		bottom: -112px;
	}
	
	.gsn-1-col-left {
		margin-top:60px;
		max-width:400px;
		margin-left:auto;
		margin-right:auto;
	}
	
	.gsn-1 {
		padding-bottom:60px;
	}
	
	.gsn-2-col-img {
		max-width:400px;
		margin:0 auto;
	}
	
	.gsn-2-col-right {
		margin-top: 45px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.tp-mt-15 {
		margin-top:15px !important;
	}
	
	/* 	Fonts  */
	.tp-n-font-32 {
		font-size:32px !important;
	}
	
	.tp-n-font-22 {
		font-size:22px !important;
	}
	
	.gsn-2 {
		margin-bottom:-120%;
	}
	
	.tools-video-wrap {
		max-width:100%;
	}
	
	.tools-2 {
		padding-top:140px;
		margin-bottom:-120%;
	}
	
	.sign-up-1-row {
		flex-flow:row;
	}
	
	.sign-up-1-row .col-sm-3 {
		max-width:128px !important;
		flex: 0 0 128px !important;
	}
	
	.sign-up-1-row .col-sm-9 {
		max-width:unset !important;
		flex: 1 !important;
	}
	
	.sign-up-1-row {
		max-width:460px;
		margin:0 auto;
	}
	
	.sign-up-2 {
		padding-top:120px;
		margin-bottom:-120%;
	}
	
	.cp-1-left {
		order:2;
		padding-right:12px;
	}
	
	.cp-1-right {
		order:1;
	}
	
	.cp-1-img-wrap {
		max-width: 400px;
		margin: 0 auto;
	}
	
	.font-51 {
		font-size:35px !important;
	}
	
	.font-18 {
		font-size:17px !important;
	}
	
	.cp-1-video-wrap {
		max-width: 600px;
		margin: 0 auto;
		width: 100% !important;
		display: block;
		margin-top:30px;
	}
	
	.cp-1-left {
		margin-top: 30px;
		text-align: center;
	}
	
	.cp-2 .container {
		top:0;
	}
	
	.cp-2 {
		margin-bottom: -140%;
		padding-top: 80px;
		padding-bottom: 140px;
	}
	
	.cp-1 {
		margin-bottom:0;
	}
	
	.cp-col {
		max-width:50%;
		flex:0 0 50%;
		margin-bottom:30px;
	}
	
	.cp-col-box {
		min-height:1px !important;
	}
	
	.font-49 {
		font-size:35px !important;
	}
	
	.mission-img-wrap {
		margin-left:auto;
	}
	
	.tools-1-row-2 {
		text-align:center;
	}
	
	.font-53 {
		font-size:33px !important;
	}
	
	.tools-2-right {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 45px;
	}
	
	.tools-video-wrap {
		margin-top:60px !important;
	}
	
	.sign-up-1 .container {
		position:relative;
		z-index:5;
	}
	
	.sign-up-1-row {
		flex-flow:row;
		align-items:center;
		justify-content:center;
		max-width:300px;
	}
	
	.mindmap-col-inner {
		min-height:270px !important;
	}
	
	.mindmap-col-1 .mindmap-col-inner {
		padding-left:40px;
		padding-right:40px;
	}
	
	.mindmap-col-inner {
		justify-content:flex-end;
	}
	
	.mindmap-col-img-2 {
		width:200px;
	}
	
	.cp-1-row .font-16 {
		font-size:16px !important;
	}
	
	.mw-1 .cp-1-left {
		max-width:100%;
	}
	
	.mw-1 {
		background-size: 100%;
		padding-bottom: 340px;
		padding-top:140px;
	}
	
	.mw-2::before {
		height:175px;
		top:-175px;
	}
	
	.mw-2 {
		padding-top:0;
		margin-top:0;
	}
	
	.mw-2-row-3 {
		max-width:300px;
	}
	
	.mw-2-row-3::before {
		width: 325px;
		height: 182px;
		left: -220px;
		top: -20px;
	}
	
	.mw-collection-title {
		font-size:25px;
	}
	
	.mw-collection-price {
		font-size:18px;
		margin-bottom:15px;
	}
	
	.mw-2-row-3 .col-sm-4 {
		margin-bottom:40px !important;
	}
	
	.mw-2-row-4 {
		margin-top:0 !important;
	}
	
	.mw-2-row-2 {
		margin-top:40px !important;
	}
	
	html body .site-footer .wpforms-container {
		max-width:400px;
	}
	
	.pd-1-box {
		flex-flow:column;
	}
	
	.pd-1-box-left {
		max-width: 100%;
		flex: 0 0 100%;
		padding: 0 20px;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	
	.pd-1-box-right {
		width:100%;
		flex:0 0 100%;
		max-width:100%;
		margin-top:30px;
	}
	
	.pd-1-curve::before {
		top:-70px;
		height:70px;
	}
	
	.pd-1-box {
		margin-top:0;
		position:relative;
		z-index:6;
	}
	
	.pd-1-curve {
		margin-top:260px;
	}
	
	.pd-1 {
		margin-bottom:-90%;
	}
	
	.pd-form-left {
		max-width:100%;
		flex:0 0 100%;
		border:0;
	}
	
	.pd-form-right {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.pd-form .suf-row-submit {
		margin-top:20px;
	}
	
	.mw-2 {
		margin-bottom:60px;
	}
	
	.mw-3 {
		margin-bottom:-120%;
	}
	
	.ln-1-right-col {
		order: 1;
		background: rgb(55, 197, 238);
		background: linear-gradient(
			90deg,
			rgba(55, 197, 238, 1) 0%,
			rgba(27, 36, 106, 1) 100%
		);
		display: flex !important;
		flex-flow: row !important;
		justify-content: space-between;
		padding: 15px;
	}

	.ln-1-left-col {
		order: 2;
	}

	.ln-1 {
		padding-top: 0;
	}

	.ln-1-gift-heading {
		text-align: left;
		font-size: 24px !important;
	}

	.ln-1-gift-text {
		font-size: 14px !important;
	}

	.ln-1-gift-link {
		font-size: 13px !important;
	}

	.ln-1-gift-left {
		flex: 0 0 77px !important;
	}

	.ln-1-gift-sponsor-wrap {
		margin-top: 0 !important;
	}

	.ln-1-gift-right {
		flex: unset;
	}

	.ln-1-gift-wrap {
		justify-content: center;
	}

	.ln-1-left-col {
		padding: 20px;
	}

	.ln-1 {
		position: relative;
	}

	.ln-2 {
		margin-top: 70px;
	}

	.half-curve::before {
		width: 100%;
	}

	.ln-2-header-right p {
		text-align: left;
	}

	.ln-2-header {
		justify-content: center;
	}

	.ln-2-heading {
		font-size: 30px !important;
		max-width: 400px;
		margin: 20px auto 0 auto;
	}

	.ln-2-right-img-wrap-1 {
		width: auto;
		top: 0;
		left: 0;
		position: relative;
		margin-top: 40px;
	}

	.ln-2-list {
		justify-content: center;
	}

	.ln-2-img-wrap {
		margin-top: 40px !important;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.ln-2-right-img-wrap-2 {
		width: 250px;
		position: relative;
		left: 0;
		bottom: 0;
		margin-top: -115px;
	}

	.ln-2-right-img-wrap-2 img {
		margin-left: -115px;
	}

	.ln-3-top-curve::before {
		height: 50px;
		top: -50px;
	}

	.ln-3 {
		margin-top: 60px;
	}

	.ln-3-heading-1 {
		margin-top: 40px !important;
	}

	.ln-3-img-wrap-2 {
		margin-top: 40px !important;
		max-width: 500px;
	}

	.ln-4-top-curve::before {
		top: -60px;
		height: 60px;
	}

	.ln-4 {
		margin-top: 120px;
		padding-top: 90px;
	}

	.ln-4-img-wrap {
		max-width: 600px !important;
	}

	.ln-4-img-wrap-2 {
		max-width: 600px !important;
	}

	.ln-4-img-wrap-2 {
		margin-top: -40px;
	}

	.ln-5 {
		background-size: cover;
		background-position: center;
		padding-top: 260px;
		margin-top: -100px;
		padding-bottom: 100px;
	}

	.ln-5-box {
		max-width: 500px;
		margin: 0 auto;
	}

	.ln-5-box-ul li {
		text-align: left;
	}

	.ln-5-row-2 .col {
		margin-bottom: 60px;
	}

	.ln-6-row-1-img {
		max-width: 250px !important;
	}

	.ln-6 {
		padding-top: 0 !important;
	}

	.ln-6-header-right {
		text-align: left;
	}

	.ln-6-row-3 .col {
		margin-bottom: 40px !important;
	}

	.ln-6-row-4 {
		margin-top: 20px;
	}

	.ln-6-row-4 {
		padding: 60px;
	}

	.ln-6-earn-img {
		max-width: 300px;
	}

	.ln-6-earn {
		margin-top: 40px !important;
	}

	.ln-7-ul li {
		text-align: left;
	}

	.ln-7 .btn-wrap {
		margin-top: 30px;
	}

	.ln-7-right-img {
		max-width: 400px;
		margin-top: 15px;
	}

	.ln-7-row-2 {
		margin-top: 0;
	}

	.ln-8-top-curve::before {
		height: 60px;
		top: -60px;
	}

	.ln-8-img-wrap {
		max-width: 250px;
	}

	.ln-8-row-1-right h2 {
		text-align: left;
	}

	.ln-8-row-1-right {
		text-align: left !important;
	}

	.ln-8 {
		margin-top: 100px;
	}

	.ln-8-row-2 {
		margin-top: 60px;
	}

	.ln-8-row-2-img-wrap {
		margin-top: 60px;
	}

	.ln-9 h2 {
		max-width: 400px;
		margin: 0 auto;
		margin-top: 60px !important;
		text-align: center;
	}

	.ln-9-ul li {
		text-align: left;
	}

	.ln-9-box {
		max-width: 400px;
		margin: 0 auto;
	}

	.ln-9-btn-wrap {
		margin-left: -35px;
		margin-top: -70px;
		text-align:center;
	}

	.ln-9-btn {
		font-size: 15px;
		font-weight: 600;
		padding: 10px 30px;
	}

	.ln-9 {
		padding-top: 440px;
		margin-top: -460px;
	}

	.ln-8 {
		margin-bottom: 0;
	}
	
	.ln-7-right-img {
		margin-bottom:0;
	}
	
	.home-1 {
		padding-top: 220px;
		padding-bottom: 220px;
	}
	
	html body .home-2 {
		padding-top:260px;
	}
	
	.ul-checklist li::before {
		width:20px;
		height:20px;
	}
	
	.mission-left-col .ul-checklist {
		margin-top:20px;
	}
	
	.opportunity-row {
		margin-top:0;
	}
	
	.opportunity-right-col .ul-checklist {
		margin-top: 15px;
	}
	
	.home-3 {
		margin-top:0;
		padding-top:20px;
	}
	
	.home-3-btn-wrap {
		margin-top:40px;
	}
	
	.mw-2-col-wrap .cp-col {
		max-width:50%;
		flex:0 0 50%;
	}
	
	.custom-signup-main .site-logo {
		margin-left:0;
	}
	
	.sign-up-2 {
		padding-top:20px;
	}
	
	.sign-up-1 {
		padding-top:179px;
	}
	
	.sign-up-2::before {
		top:0;
	}
	
	.site-menu-sponsor-col {
		display:none !important;
	}
	
	.cwa-1-img-1 {
		text-align: center !important;
		margin-top: 30px !important;
	}

	.cwa-1-heading {
		max-width: 400px !important;
		margin: 0 auto !important;
	}

	.cwa-1-text {
		max-width: 500px !important;
		margin: 20px auto 0 auto !important;
	}

	.cwa-1-img-3 {
		margin-top: 40px !important;
	}

	.cwa-1-count-wrap {
		justify-content: center !important;
	}

	.cwa-1-count {
		font-size: 53px !important;
	}

	.cwa-1-count-wrap {
		margin-bottom: 15px !important;
	}

	.cwa-2-row-2 .col-md-4 {
		margin-bottom: 60px !important;
	}

	.cwa-2-row-2 .col-md-4 p {
		margin-top: 15px !important;
	}

	.cwa-2-row-3 {
		margin-top: 0 !important;
	}

	.cwa-3 {
		padding-top: 160px !important;
	}

	.cwa-ul li {
		text-align: left !important;
	}

	.cwa-ul-3 {
		margin-top: 45px !important;
	}

	.cwa-3-col-2 {
		padding-left: 20px !important;
	}

	.cwa-4 {
		padding-top: 80px !important;
	}

	.cwa-4-col-1 {
		order: 1 !important;
	}

	.cwa-4-col-2 {
		order: 2 !important;
	}

	.cwa-5 {
		margin-top: 100px !important;
	}

	.cwa-5 p {
		max-width: 400px;
		margin: 0 auto !important;
		margin-bottom:40px !important;
	}

	.cwa-6-col-2 {
		padding-left: 20px !important;
		padding-top: 40px !important;
	}

	.cwa-6 p {
		max-width: 400px !important;
		margin: 0 auto !important;
	}

	.cwa-6 {
		padding-top: 0 !important;
		background-size: 30% !important;
		margin-bottom:-120%;
	}

	.cwa-6-btn-wrap {
		margin-top: 30px !important;
	}

	.cwa-1-heading {
		margin-top: 30px !important;
		font-size:35px !important;
	}

	.cwa-1 {
		background-image: none;
	}

	.mwa-1 .cwa-1-img-3 {
		margin-left: auto;
	}

	.cwa-6 p {
		padding-right: 0;
	}

	.mwa-6 {
		padding-bottom: 60px !important;
	}

	.mwa-6 .cwa-6-col-2 {
		padding-top: 0 !important;
	}
	
	.custom-wallet-app .n-font-40 {
		font-size:30px !important;
	}
	
	.custom-wallet-app p {
		font-weight:400;
	}
	
	.custom-wallet-app .n-font-18 {
		font-size:16px !important;
	}
	
	.custom-wallet-app .n-font-20 {
		font-size:18px !important;
	}
	
	.cwa-2-row-2 .col-sm-4 {
		margin-bottom:60px !important;
	}
	
	.cwa-2-row-2 {
		margin-top:40px;
	}
	
	.cwa-5-slider .img-wrap {
		padding-left:15px !important;
		padding-right:15px !important;
	}
	
	.cwa-5-slider .slick-arrow.slick-prev {
		left: 10px !important;
		width: 20px !important;
		height: 52px !important;
	}
	
	.cwa-5-slider .slick-arrow.slick-next {
		right: 10px !important;
		width: 20px !important;
		height: 52px !important;
	}
	
	.custom-wallet-app .site-header-lower {
		height:auto;
	}
	
	.custom-wallet-app .site-header-upper {
		display:none;
	}
	
	.custom-wallet-app .site-header-lower {
		padding:10px 0;
	}
	
	.site-menu-sponsor-col-mobile {
		display:block !important;
	}
	
	.custom-wallet-app .site-translation-col {
		top:45px;
	}
	
	.cwa-sponsor-wrap {
		margin-right:0;
		justify-content:center;
	}
	
	.cwa-sponsor-right-col {
		text-align:left;
	}
	
	.cwa-tshirt-left-col {
		margin-left:5px;
	}
	
	.cwa-tshirt-wrap {
		justify-content:center;
	}
	
	.cwa-tshirt-free-text {
		position:relative;
		transform:none;
		left:0;
		top:0;
		margin-top:5px;
	}
	
	.cwa-tshirt-free-text::after {
		position:relative;
		left:0;
		top:0;
		transform:rotate(15deg);
		margin-top: -10px;
	}
	
	.cwa-tshirt-right-col .custom-pink-btn {
		min-width: 249px;
		min-height: 44px;
		font-size: 15px;
	}
	
	.cwa-tshirt-left-col .img-wrap::after {
		right:0;
		bottom: -20px;
		top: auto;
		transform: rotate(90deg);
	}
	
	.custom-wallet-app .site-header {
		position:relative;
	}
	
	.cwa-1 {
		padding-top:0;
	}
	
	.cwa-popup-form-body-img {
		max-width:100%;
	}
	
	.cwa-popup-form-header p {
		line-height:1.1;
	}
	
	.cwa-popup-form {
		padding:0 20px;
	}
	
	.cwa-popup-wrap {
		width:95%;
		max-width:100%;
	}
	
	.login-2::before {
		top: 0;
		background-size: contain;
		height: 144px;
	}
	
	.login-2 {
		padding-top:80px;
	}
	
	.tools-2-logged {
		margin-bottom:100px;
	}
	
	.site-footer-2 .site-footer-lower-ul {
		display:block;
		text-align:center;
	}
	
	.tools-2-logged-row-2 .col-sm-4 {
		max-width:50%;
		flex:0 0 50%;
	}
	
	.leaderboard-tbl-header {
		display:none;
	}
	
	.leaderboard-tbl-body-row {
		background: #f8f8f8;
		flex-flow: column;
		border: 1px solid #ddd;
		margin-bottom: 15px;
	}
	
	.leaderboard-tbl-body-row:nth-of-type(2n) {
		background: #f8f8f8;
	}
	
	.leaderboard-tbl-body-row > div::before {
		content: attr(data-label);
		font-weight: 500;
		color: #666;
		margin-right:20px;
	}
	
	.leaderboard-tbl-body-row > div {
		flex: 1;
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
		font-weight: 400;
		color: #818181;
		padding: 10px 25px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #ddd;
	}
	
	.wcp-1 .leaderboard-header {
		justify-content:center;
	}
	
	.wcp-1 {
		margin-bottom:-110%;
	}
	
	.suc-add-btn-wrap {
		text-align:center;
		margin-top:30px;
	}
	
	#suc-tbl-body div[data-label="Code"] {
		width:100%;
	}
	
	.n-font-43 {
		font-size:28px !important;
	}
	
	.new-home-2 {
		text-align:center;
		padding-top:20px;
	}
	
	.new-home-2-rating-wrap .img-wrap {
		margin-bottom:0;
		margin-left:0;
	}
	
	.new-home-2-rating-wrap {
		justify-content:center;
	}
	
	.new-home-2-rating-wrap {
		margin-top:20px;
	}
	
	.new-home-2-dl-wrap {
		justify-content:center;
		margin-top:30px;
	}
	
	.new-home-2-dl-wrap .img-wrap {
		margin-left:0;
	}
	
	.new-home-2-right-img-2 {
		max-width:100%;
	}
	
	.new-home-2-right {
		width:100%;
		margin-top:20px;
		margin-bottom:-310px;
	}
	
	.new-home-3-col-text-1 {
		margin:0;
	}
	
	.new-home-3-col-inner {
		margin:10px 0;
		justify-content:center;
	}
	
	.new-home-3-col-text-2 {
		flex: unset;
	}
	
	.n-font-48 {
		font-size:35px !important;
	}
	
	.new-home-4-img-left {
		padding-right:0;
		margin-bottom:30px;
	}
	
	.new-home-4-img-text-wrap .text-wrap {
		text-align:left;
	}
	
	.new-home-4-img-text-wrap {
		margin-top:15px;
	}
	
	.new-home-5::before {
		height:75px;
	}
	
	.new-home-4 {
		padding-bottom:40px;
	}
	
	.new-home-5 {
		padding-top:120px !important;
	}
	
	.double-underline-left::after {
		margin-left:auto;
		margin-right:auto;
	}
	
	.cwa-popup-form-step-text {
		font-weight:700 !important;
	}
	
	.cwa-popup-form-step span {
		font-weight:700 !important;
	}
	
	#fta-tbl-body .leaderboard-tbl-body-row > div,
	.fta-tbl-body .leaderboard-tbl-body-row > div{
		flex:0 0 100%;
		max-width:100%;
		text-align:right;
	}
	
	.gsn-1-new-video-wrap {
		text-align:center;
	}
	
	.gsn-1-new-video-wrap-img {
		right:0;
	}
	
	.gsn-1-new {
		padding-bottom:360px;
	}
	
	.gsn-1-new .container {
		z-index:5;
	}
	
	.gsn-2-new {
		margin: 0 0 -115% 0;
		margin-top: 0px;
		padding: 0;
		padding-top: 0px;
		margin-top: -240px;
		padding-top: 40px;
	}
	
	.top-curve-4::before {
		top:-75px;
		height:75px;
	}
	
	.gsn-1-new-video-wrap-img {
		width:250px;
	}
	
	.tools-2-new {
		margin-top: -180px !important;
		margin: 0;
		margin-top: 0px;
		padding: 0;
		padding-top: 0px;
		padding-top: 120px;
		padding-bottom:40px;
	}
	
	.tools-1-new-video-wrap {
		margin-top:15px !important;
	}
	
	.tools-3-new-right {
		order:2;
		margin-top:30px;
	}
	
	.tools-3-new-left {
		order:1;
	}
	
	.tools-3-new {
		padding-top:100px;
	}
	
	.tools-4-new {
		margin-bottom:-110%;
	}
	
	.eo-1-box-upgrade-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.eo-ul li {
		text-align:left;
	}
	
	.eo-1-box-upgrade-col-2 {
		margin-top:0;
	}
	
	.eo-1-box-upgrade-col .eo-payments {
		margin-top:20px;
	}
	
	.eo-1 {
		margin-bottom:-120%;
	}
	
	.leaderboard-search-right {
		margin-top:20px;
	}
	
	.site-header-sponsor-right {
		justify-content:center;
	}
	
	.w-sponsor-header .ln-2-new {
		margin-top:220px;
	}
	
	.ln-1-trading-wrap-new, .ln-1-download-wrap-new {
		justify-content:center;
	}
	
	.ln-1-trading-wrap-new li {
		color:#999;
	}
	
	.custom-wallet-app-new .site-header-sponsor {
		display:none;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-header {
		padding-right: 40px;
		padding-left:40px;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-header p {
		font-size:35px !important;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-header p br {
		display:block;
	}
	
	.cwa-popup-section-ty .img-wrap {
		margin-left:0;
	}
	
	.site-header-sponsor-left .n-font-22 {
		font-size:20px !important;
	}
	
	.mw-2-new-img-wrap {
		margin-top:0;
	}
	
	.promo-1-header .img-wrap {
		margin:0;	
	}
	
	.promo-1-header {
		justify-content:center;
		margin-bottom:-15px;
	}
	
	.promo-2 {
		margin-top:-90%;
		padding-top:700px;
	}
	
	.promo-1-left .font-67 {
		font-size:37px !important;
		text-align:center;
	}
	
	.promo-1-rating {
		justify-content:center;
	}
	
	.promo-1-rating .img-wrap {
		margin:0;
	}
	
	.promo-1-dl-wrap .img-wrap {
		margin:0 10px;
	}
	
	.promo-1-dl-wrap {
		justify-content:center;
		margin-top:30px;
	}
	
	.promo-btn {
		font-size:18px;
	}
	
	.promo-btn-wrap {
		margin-top:30px;
		text-align:center;
	}
	
	.promo-free-img-wrap {
		max-width:300px;
	}
	
	.promo-1-client-img-wrap {
		position:relative;
	}
	
	.promo-1-app-img-wrap {
		position:relative;
		top:0;
		margin-top:-300px;
	}
	
	.w-sponsor-header .promo-1 {
		padding-bottom:0;
		margin-top: 176px;
	}
	
	.tools-new-2-logged-2 {
		margin-top:0 !important;
		padding-top:80px;
	}
	
	.tools-new-2-logged-3-box {
		text-align:left;
		padding:50px;
	}
	
	.tools-new-2-logged-3::before {
		height:58px;
	}
	
	.w-sponsor-header .pd-1-curve {
		margin-top:400px;
	}
	
	.w-sponsor-header .promo-1 {
		margin-top:160px;
	}
	
	.malar-1::before {
		height:100px;
	}
	
	.malar-1 {
		padding-top:120px;
		margin-bottom:-120%;
	}
	
	.malar-right-info {
		text-align:left;
	}
	
	.malar-left-wrap {
		align-items:center;
	}
	
	.malar-right-title {
		margin: 0 0 3px 0;
		font-size:20px;
	}
	
	.malar-left-col {
		border-bottom: 1px solid #31A7D6;
		border-right:0;
		padding:0;
		padding-bottom:30px;
	}
	
	.malar-right-col {
		padding:0;
		text-align:left !important;
		padding-top:30px;
	}
	
	.malar-right-col p {
		margin-bottom:15px;
	}
	
	.malar-1-row {
		padding:40px;
	}
	
	.malar-right-3-col p {
		font-size:18px !important;
	}
	
	.pd-box-2-follow {
		justify-content:center;
		margin-top:15px !important;
	}
	
	.pd-1-box-sponsor {
		justify-content:center;
		margin-bottom:15px !important;
	}
	
	.eo-2-row-2-heading {
		max-width:400px;
		margin:0 auto;
	}
	
	.eo-2-option-premium {
		left:-15px;
		top:31px;
	}
	
	.eo-2-row-2-heading .img-wrap {
		top: -15px;
		left: -180px;
	}
	
	.eo-2-option {
		left:0;
	}
	
	.pledge-1 .row {
		flex-flow: row;
	  }

	  .pledge-1 .row .col {
		flex: 1;
	  }

	  .pledge-1 .row .col:first-of-type {
		text-align: left;
	  }

	  .pledge-1 .row .col:first-of-type img {
		width: 125px;
	  }

	  .pledge-1 .row .col:nth-of-type(2) img {
		width: 100px;
	  }

	  .pledge-1 .row .col:nth-of-type(3) img {
		width: 125px;
	  }

	  .pledge-2 {
		padding: 6rem 0;
	  }

	  .pledge-2-text {
		font-size: 80px !important;
	  }

	  .pledge-ul li {
		text-align: left !important;
	  }
	
	.t-font-83 {
		font-size:83px !important;
	}
	
	.t-font-48 {
		font-size:48px !important;
	}
	
	.t-font-72 {
		font-size:72px !important;
	}
	
	.jko-time {
		max-width:600px;
		margin: 0 auto;
	}
	
	.jko-img-wrap {
		width: auto;
		position: relative;
		right: 0;
		top: 0;
		max-width: 100% !important;
	}
	
	.jko-2 {
		padding-top:0;
	}
	
	.jko-tbl-circle {
		display:none;
	}
	
	.jko-tbl-ovato {
		position: absolute;
		left: 50%;
		top: -85px;
		margin-left: -100px;
		width: 200px;
	}
	
	.jko-white-box {
		padding-top:120px;
	}
	
	.jko-4 {
		background-size: 100%;
		min-height: 1px;
		margin-top: -80px;
		padding: 6rem 0;
	}
	
	.jko-4 .container::before {
		right:50px;
		top:-90px;
		width:75px;
		height:75px;
	}
	
	.jko-4 .container::after {
		left: 20px;
		bottom: -100px;
	}
	
	.jko-easy {
		background-size: cover;
		padding: 0 !important;
		min-height: 1px;
		margin: 0;
	}
	
	.t-font-38 {
		font-size:38px !important;
	}
	
	.jko-rating .img-wrap {
		margin:0;
	}
	
	.jko-rating {
		justify-content:center;
	}
	
	.jko-dl {
		justify-content:center;
	}
	
	.t-justify-center {
		justify-content:center !important;
	}
	
	.jko-easy-tee {
		margin-top:60px !important;
	}
	
	.jko-easy {
		margin-bottom:60px;
	}
	
	/* V3 Signup Popup */
	.signup-v3-popup .cwa-popup-form-wrap {
		grid-template-columns: 1fr;
	}
	
	.signup-v3-popup .cwa-popup-form-header {
		background-size: 100% 250px;
		border-radius: 60px 60px 0 0;
	}
	
	.cwa-v3-left-heading {
		margin-top:200px;
	}
	
	.signup-v3-popup .cwa-popup-request-star-2 .img-wrap {
		margin:0 auto;
	}
	
	.signup-v3-popup .cwa-popup-form-body {
		border-radius:0 0 60px 60px;
	}
	
	/* Thank You Popup */
	#thank-you-popup h2,
	#thank-you-popup .btn-wrap {
		position:relative;
		z-index:2;
	}
	
	#thank-you-popup h2 {
		font-size:40px !important;
	}
	
	.ty-popup-header p {
		margin:0 auto !important;
	}
	
	.ty-popup-body .font-16 {
		font-size:16px !important;
	}
	
	.ty-popup-body .font-15 {
		font-size:15px !important;
		margin-bottom:1rem !important;
	}
	
	.home-1-right-col-new {
		max-width:537px !important;
		margin:0 auto;
	}
	
	.new-banner-img {
		left:0;
		left: 50%;
		margin-left: -25%;
	}
	
	.new-home-3-row-new {
		padding-top:6rem;
	}
	
	.new-home-4-img-text-wrap-new * {
		text-align:center;
	}
	
	.new-home-4-new h3,
	.new-home-4-new p {
		text-align:center;
	}
	
	.new-home-4-new p {
		margin-bottom:1rem;
	}
	
	.new-home-4-row-2-new {
		margin-bottom:1rem;
	}
	
	.new-home-44 .img-wrap {
		max-width:600px;
	}
	
	.new-home-44 h2 {
		font-size:32px !important;
	}
	
	.new-home-44 {
		padding:8rem 0;
	}
	
	.new-home-33 .home-3-col {
		margin-top:0;
	}
	
	.new-home-33 {
		padding-bottom:4rem;
		margin-bottom: -20rem !important;
	}

	.new-last-section {
		margin-bottom: -20rem !important;
	}
	
	/* HIW */
	.hiw-1 h1 {
		font-size:36px !important;
	}
	
	.hiw-1-text {
		font-size:22px !important;
	}
	
	.hiw-2 {
		background-size:cover;
		padding-top:10rem;
	}
	
	.hiw-2-box {
		padding: 1.5rem 2rem;
		text-align: left;
		margin-bottom: 1rem;
	}
	
	.hiw-2-img {
		max-width:100%;
	}
	
	.hiw-2 .img-wrap {
		max-width:600px;
	}
	
	.hiw-2 h2 {
		font-size:32px !important;
	}
	
	.hiw-3-img {
		max-width:100%;
	}
	
	.hiw-3 .img-wrap {
		max-width:100%;
	}
	
	.hiw-3 {
		margin-bottom:-110%;
	}
	
	.hiw-2 .n-font-39 {
		font-size:32px !important;
	}
	
	.hiw-2 {
		padding-top:9rem;
	}
	
	/* NFT */
	.nft-1 p {
		font-size:18px !important;
	}
	
	.nft-2 h1 {
		font-size:32px !important;
		margin-bottom:3rem;
	}
	
	.nft-2 {
		padding-top:4rem;
		padding-bottom:8rem;
	}
	
	.nft-3 h2 {
		font-size:28px !important;
		margin-bottom:0;
	}
	
	.nft-3-left-col {
		background: linear-gradient(180deg, rgb(27, 36, 106) 50%, rgb(55, 199, 240) 100%);
	}
	
	.nft-3-left-col::after {
		width: 0;
		height: 0;
		border-width: 18px 38px 0 38px;
		border-color: #36C1EB transparent transparent transparent;
		border-style: solid;
		left: 50%;
		margin-left: -37px;
		top: auto;
		bottom: -17px;
	}
	
	.nft-3-left-col h3 {
		margin-top:1.5rem;
	}
	
	.nft-3-mid-col {
		padding-left:1rem;
	}
	
	.nft-3-mid-col,
	.nft-3-right-col {
		max-width: 50% !important;
		flex: 0 0 50% !important;
		text-align: left !important;
		padding: 2rem !important;
		padding-bottom:1rem;
	}
	
	.nft-3-left-col {
		padding-bottom:1rem;
	}
	
	.nft-3 {
		padding-top: 12rem;
		margin-bottom: -110%;
	}
	
	/* Tools New */
	.tools-3 {
		margin-bottom:-120%;
	}
	
	.tools-3-col-5 .img-wrap {
		margin-top:2rem;
	}
	
	.tools-3-col-5 .tools-3-col-5-left {
		padding-left:1rem;
	}
	
	/* Get Started 2 */
	.get-started-box-body {
		min-height:1px;
	}
	
	.get-started-box-body-choices {
		display:grid;
		grid-template-columns:1fr 1fr 1fr;
	}
	
	.wc-popup-form {
		margin-top:1.5rem;
	}
	
	/* 	GS Step Wrap */
	.gs-step-wrap {
		flex-flow:row wrap;
	}
	
	.gs-step-col {
		flex: 0 0 33.3% !important;
		max-width: 33.3% !important;
	}
	
	.gs-step-text br {
		display:block !important;
	}
	
	.gs-step-text {
		font-size:16px;
	}
	
	.gs-step-wrap::after {
		max-width: 61%;
		margin-left: -30%;
	}
	
	.gs-step-col {
		padding:2rem 0;
	}
	
	.gs-sml-row .col {
		max-width: 50%;
		flex: 0 0 50%;
		margin-top: 2rem;
	}
	
	.gs-sml-row {
		margin-top:1rem !important;
	}
	
	.gs-sml-body {
		flex-flow: row wrap;
		padding: 2rem 1rem 1rem 1rem;
	}
	
	.gs-sml-level {
		max-width:50%;
		flex: 0 0 50%;
	}
	
	.home-1-right-col-new-2 {
		height:537px;
	}
	
	.v2-mw-1 {
		padding-bottom: 300px;
		margin-bottom: -100px;
	}
	
	.c-video-wrap {
		margin-left:auto;
		margin-right:auto;
	}
	
	.c-video-wrap-full {
		max-width:600px;
		margin-top:2rem;
	}
	
	.c-video-play-text-bottom img {
		width:90px;
	}
	
	.tp-pt-6 {
		padding-top:6rem !important;
	}
	
	.tp-pt-8 {
		padding-top:8rem !important;
	}
	
	.top-curve-7::before {
		height:51px;
	}
	
	.rules-box-h2 span {
		font-size:24px;
	}
	
	.rules-2 {
		margin-bottom:-115%;
	}
	
	/* Fonts */
	.v2-tp-font-22 {
		font-size:22px !important;
	}
	
	html body .v2-tp-font-28 {
		font-size:28px !important;
	}

	.v2-tp-font-32 {
		font-size:32px !important;
	}

	/* Paddings */
	.v2-tp-pt-2 {
		padding-top: 2rem !important;
	}

	.v2-tp-pt-4 {
		padding-top: 4rem !important;
	}

	.v2-tp-pt-12 {
		padding-top: 12rem !important;
	}
	
	/* Content */
	.tp-content-flex-center {
		justify-content:center !important;
	}
	
	.fi-header-img-wrap img {
		width:150px;
	}
	
	.fi-popup-tbl-body-row {
		grid-template-columns:1fr;
		padding:1rem;
	}
	
	.fi-popup-text {
		display:inline-flex;
		font-size:13px;
	}
	
	.fi-popup-tbl-header {
		display:none;
	}
	
	.fi-popup-tbl-body-col-1 {
		text-align:center;
	}
	
	.fi-popup-tbl-body-col {
		text-align: center;
		padding: 0.25rem;
	}
	
	.fi-popup-tbl {
		margin-top:2rem !important;
	}
	
	.tools-4-ebooks-row .col-sm-4 {
		max-width:50% !important;
		flex:0 0 50% !important;
	}
	
	/* 	Margins */
	.v2-tp-mx-auto {
		margin-left:auto !important;
		margin-right:auto !important;
	}

	.v2-tp-mt--2 {
		margin-top:-2rem !important;
	}

	.v2-tp-mt-0 {
		margin-top: 0 !important;
	}

	.v2-tp-mt-2 {
		margin-top:2rem !important;
	}
	
	/* Paddings */
	.v2-tp-pr-1 {
		padding-right:1rem !important;
	}
	
	/* WOC Page */
	.woc-2 {
		margin-bottom:-110% !important;
	}
	
	/* Max Width */
	.v2-tp-mw-40 {
		max-width:40% !important;
	}

	.v2-tp-mw-50 {
		max-width:50% !important;
	}
	
	.v2-tp-mw-60 {
		max-width:60% !important;
	}
	
	.v2-tp-mw-70 {
		max-width:70% !important;
	}
	
	.v2-tp-mw-80 {
		max-width:80% !important;
	}
	
	.v2-tp-mw-90 {
		max-width:90% !important;
	}
	
	.v2-tp-mw-100 {
		max-width:100% !important;
	}

	.nft-3 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	/* Margins */
	.v2-tp-mt-4 {
		margin-top: 4rem !important;
	}

	/* Paddings */
	.v2-tp-pb-0 {
		padding-bottom: 0 !important;
	}

	/* ORder */
	.v2-tp-order-1 {
		order: 1 !important;
	}

	.v2-tp-order-2 {
		order: 2 !important;
	}

	.v2-tp-order-1 {
		order: 3 !important;
	}

	/* Widths */
	.v2-tp-col-6 {
		max-width: 50% !important;
		flex:0 0 50% !important;
	}

	/* Margin top */
	.v2-tp-mt-8 {
		margin-top: 8rem !important;
	}
} /* END 910px */

/* Mobile */
@media (max-width: 600px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .site-logo img {
    width: 82px;
  }

  .home-1-img {
    margin-top: 15px;
  }

  html body br {
    display: block !important;
  }

  .other-help-heading span {
    padding: 0 10px;
  }

  .site-header.sticky {
    border-bottom: 3px solid #ff880c;
  }

  .font-57 {
    font-size: 39px !important;
  }

  .home-banner-heading {
    margin-top: 110px;
    font-size: 30px !important;
    line-height: 1.2;
  }

  .font-26 {
    font-size: 20px;
  }

  .home-banner {
    /* ../img/mobile-bg-home-banner.png */
    background-image: url('') !important;
    background-size: 100% 100%;
    background-position: 100% 100%;
    min-height: 899px;
  }

  .home-banner-text {
    font-weight: 500;
    line-height: 1.3;
  }

  .home-banner-dl img {
    margin: 0 5px;
    width: 130px;
  }

  .home-banner-dl {
    margin-top: 30px;
  }

  .font-32 {
    font-size: 22px;
  }

  .home-display .row {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .home-display-slider-wrap {
    margin-top: 10px;
  }

  h2 {
    font-size: 25px;
  }

  p {
    font-size: 17px;
  }

  .home-all-in-one-heading {
    margin-top: 0;
  }

  .home-all-in-one .col-sm {
    padding-left: 12px;
  }

  .all-in-one-lists li {
    width: 100%;
    display: block;
    padding-right: 0;
  }

  .all-in-one-lists {
    margin-top: 30px;
  }

  .home-all-in-one {
    /* ../img/mobile-bg-all-in-one.png */
    background: #fff url('') no-repeat center
      bottom !important;
    padding-bottom: 690px;
  }

  .all-in-one-lists-cta.mt-4 {
    margin-top: 45px !important;
  }

  .home-6-reasons-video {
    height: 250px;
  }

  .home-6-reasons-video {
    border: 10px solid #fff;
  }

  .home-video-play {
    width: 60px;
  }

  .play-video-text {
    margin-left: 35px;
    margin-top: 0;
    width: 75px;
  }

  .home-6-reasons-lists {
    margin-top: 30px;
  }

  .home-6-reasons-lists img {
    width: 60px;
    margin-right: 15px;
  }

  .home-6-reasons-lists p {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.3;
  }

  .home-6-reasons-cta {
    margin-top: 15px;
  }

  .home-6-reasons-cta .site-btn {
    margin: 0 0 15px 0;
  }

  .owards-rewards::before {
    height: 80px;
    top: -40px;
  }

  .home-6-reasons {
    padding-bottom: 60px;
  }

  .owards-heading {
    font-size: 28px;
  }

  .owards-img {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-marketing-solution {
    padding-top: 20px;
  }

  .home-marketing-solution-img img {
    width: 150px !important;
    margin-right: 0 !important;
  }

  .home-marketing-solution h2 {
    margin-bottom: 15px;
  }

  .home-marketing-solution p img {
    width: 68px;
    margin-right: 15px;
  }

  .home-marketing-solution .img-wrap {
    margin-bottom: 30px;
  }

  .home-marketing-solution-lists p {
    display: flex;
    line-height: 1.3;
    align-items: center;
  }

  .home-marketing-solution-cta {
    margin-top: 45px;
  }

  .home-marketing-solution {
    /* ../img/mobile-bg-solution.png */
    background: no-repeat center bottom url('') !important;
    padding-bottom: 800px !important;
  }

  .site-social-2 li a {
    width: 36px;
    height: 36px;
    background: #bdbfd4;
    color: #fff;
    font-size: 18px;
  }

  .home-follow-signup-left {
    border-bottom: 1px solid #75a9c1;
  }

  .subscribe-form {
    width: 100%;
  }

  .home-follow-right h2 {
    margin-bottom: 10px;
  }

  .subscribe-form [type='submit'] {
    height: 40px;
    font-size: 16px;
    width: 100px;
  }

  .subscribe-form input[type='text'] {
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .home-follow-signup {
    padding-bottom: 40px;
  }

  .site-footer-menu {
    width: 100%;
    display: block;
    margin-bottom: 0;
  }

  .site-footer-menu li {
    padding-right: 0;
  }

  .site-footer-heading {
    margin-bottom: 15px;
  }

  .site-footer-heading {
    font-size: 18px;
  }

  .site-footer {
    padding-top: 160px;
  }

  .site-footer-col-1,
  .site-footer-col-2,
  .site-footer-col-3,
  .site-footer-col-4 {
    margin-bottom: 30px;
  }

  .footer-logo-ovato {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .after-footer {
    margin-top: 0;
  }

  .after-footer p {
    font-size: 12px;
  }

  .rewards-wrap {
    display: none;
  }

  .rewards-wrap-mobile {
    display: block;
  }

  .rewards-wrap-mobile .rewards-col {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .rewards-wrap-mobile .rewards-col::after {
    display: none;
  }

  .rewards-wrap-mobile .rewards-col-inner {
    border-right: 0;
  }

  .rewards-wrap-mobile .rewards-col-img {
    min-height: 1px;
    margin-bottom: 15px;
  }

  .rewards-col-img img {
    filter: grayscale(100%);
    opacity: 0.51;
  }

  .rewards-col-mobile:hover .rewards-col-img img {
    filter: grayscale(0%);
    opacity: 1;
  }

  .modal-header h2 {
    font-size: 20px;
  }

  .modal-body {
    padding: 20px;
  }

  .font-65 {
    font-size: 40px;
  }

  .mobile-keywords-banner {
    /* ../img/bg-mobile-keywords.png */
    background-image: url('') !important;
    background-size: cover;
    background-position: center bottom;
    min-height: 571px;
  }

  .subpage-banner h1 {
    margin-top: 0;
  }

  .subpage-banner {
    padding-top: 107px;
  }

  .mobile-keywords-2 {
    padding-top: 40px;
  }

  .mobile-keywords-2 h2 {
    margin-bottom: 15px;
  }

  .mobile-keywords-2 {
    /* ../img/mobile-bg-keywords-2.png */
    background-image: url('') !important;
    padding-bottom: 80px;
  }

  .mobile-keywords-3-img img {
    width: 200px;
  }

  .mt-45 {
    margin-top: 15px !important;
  }

  .rounded-num {
    width: auto;
    height: 100px;
    flex: 0 0 100px;
    font-size: 35px;
    margin-right: 30px;
  }

  .mobile-keyword-count::before {
    left: 50px;
    top: 0;
  }

  .mobile-keyword-count p {
    margin-bottom: 30px;
  }

  .mobile-keyword-count {
    margin-top: 30px;
  }

  .mobile-keywords-3,
  .mobile-keywords-4 {
    /* ../img/mobile-bg-keywords-3-new.png */
    background: url('') center bottom
      no-repeat !important;
    padding-bottom: 80px;
    background-size: 100% auto !important;
  }

  .mobile-keyword-count-row {
    position: relative;
  }

  .mobile-keyword-count-row::before {
    content: '';
    position: absolute;
    left: -110px;
    top: 0;
    width: 403px;
    height: 450px;
    /* ../img/mobile-bg-how-it-works-cp.png */
    background: no-repeat center top url('');
    background-size: auto;
    background-size: contain;
  }

  .mobile-keywords-3-img img {
    position: relative;
  }

  .mobile-keywords-4 img {
    width: 150px;
  }

  .mobile-keywords-4 {
    padding-bottom: 40px;
  }

  .inds-banner {
    text-align: center;
    /* ../img/mobile-bg-inds-banner.png */
    background-image: url('') !important;
    background-position: center bottom;
  }

  .inds-banner p {
    text-align: center;
  }

  .inds-2 .img-wrap {
    margin-left: 0;
  }

  .inds-2 {
    padding-bottom: 120px;
  }

  .sms-banner .col-sm-7 {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }

  .font-65 {
    font-size: 30px;
  }

  .sms-banner {
    /* ../img/mobile-bg-banner-sms.png */
    background-image: url('') !important;
    padding-bottom: 390px;
    background-position: center bottom;
  }

  .sms-2 img {
    width: 150px;
  }

  .font-39 {
    font-size: 25px;
  }

  .sms-2 {
    padding-bottom: 40px;
  }

  .font-41 {
    font-size: 22px;
  }

  h3 {
    font-size: 30px;
  }

  .sms-3-inner {
    padding: 50px 30px 20px 30px;
  }

  .sms-3-inner h4 {
    font-size: 22px;
  }

  .sms-3-inner p {
    font-size: 15px;
  }

  .sms-3-inner .img-wrap img {
    width: 125px;
  }

  .sms-4 {
    padding: 40px 20px 0 20px;
  }

  .sms-4-col .img-wrap {
    width: 100%;
  }

  .sms-5 {
    padding-top: 40px;
    padding-bottom: 150px;
  }

  .font-39 {
    font-size: 22px;
  }

  .ul-checklist li {
    font-size: 16px;
  }

  .sms-5 .col-sm-4 {
    padding: 30px 30px 15px 30px;
    box-shadow: 0 12px 93px rgba(0, 0, 0, 0.09);
  }

  .sms-5 {
    /* ../img/mobile-bg-sms-5.png */
    background: no-repeat center bottom url('') !important;
    background-size: 100% auto !important;
  }

  .sms-6-col-img {
    min-height: 200px;
  }

  .sms-6-col-text {
    padding: 30px 20px 20px 20px;
  }

  .sms-6 {
    padding-top: 40px !important;
  }

  .sms-7 {
    padding-top: 60px;
  }

  .sms-7-col-img .img-wrap {
    max-width: 225px;
  }

  .sms-8-col-img .img-wrap {
    max-width: 225px;
  }

  .sms-8 {
    /* ../img/mobile-bg-sms-8.png */
    background: no-repeat right top url('') !important;
    background-size: 150px !important;
  }

  .sms-9 {
    margin-top: 0;
  }

  .sms-8 {
    padding-bottom: 30px;
    min-height: 1px;
  }

  .sms-9-col-img .img-wrap {
    max-width: 250px;
  }

  .sms-10 {
    background: no-repeat center #ff880c !important;
    background-size: contain !important;
    margin-bottom: 20px;
    padding-top: 45px;
    padding-bottom: 0;
    position: relative;
  }

  .sms-10::before {
    content: '';
    position: absolute;
    left: 0;
    top: -96px;
    width: 100%;
    height: 98px;
    /* ../img/mobile-before-sms-10.png */
    background: no-repeat center url('');
    background-size: cover;
    display: block;
  }

  .sms-10::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 83px;
    /* ../img/mobile-after-sms-10.png */
    background: no-repeat center url('');
    background-size: cover;
    display: block;
    bottom: -4px;
  }

  .mobile-only {
    display: block;
  }

  .sms-10 .img-wrap {
    max-width: 300px;
    margin: auto;
  }

  .sms-9 {
    padding-bottom: 120px;
  }

  .font-24 {
    font-size: 18px;
  }

  .inds-banner p {
    max-width: 100%;
  }

  .text-to-vote-banner {
    min-height: 1px;
    background-size: cover;
    background-position: center bottom;
    padding-bottom: 320px;
  }

  .t2v-2 {
    padding-bottom: 40px;
  }

  .t2v-3 {
    padding-top: 40px;
  }

  .t2v-3-list-content h3 {
    font-size: 22px;
  }

  .t2v-3 {
    padding-bottom: 40px;
  }

  .ul-checklist-2 li {
    font-size: 22px;
  }

  .ul-checklist-2 li::before {
    width: 28px;
    height: 24px;
  }

  .t2v-4 {
    padding-bottom: 80px;
  }

  .social-influencers-banner h1 {
    margin-top: 15px !important;
  }

  .social-influencers-banner img {
    width: 150px;
  }

  .si-2 {
    padding-top: 40px;
  }

  .font-37 {
    font-size: 22px;
  }

  .font-50 {
    font-size: 24px;
  }

  .font-229 {
    font-size: 75px;
  }

  .transparent-wave::after {
    height: 35px;
  }

  .transparent-wave-2 {
    padding-bottom: 60px;
  }

  .si-4 {
    padding-top: 20px;
  }

  .si-5-col-text {
    margin-top: 15px;
  }

  .si-5-row-3 {
    margin-top: 15px !important;
  }

  .font-38 {
    font-size: 25px;
  }

  .si-5-row-4-img-2 {
    max-width: 500px;
    margin-left: -250px;
  }

  .map-wrap iframe {
    height: 250px;
  }

  .form-control {
    height: 55px;
    font-size: 15px;
  }

  textarea.form-control {
    height: 162px;
  }

  [type='button'],
  [type='reset'],
  [type='submit'] {
    height: 57px;
    font-size: 22px;
  }

  html
    body
    div.wpforms-container-full
    .wpforms-form
    textarea.wpforms-field-medium {
    height: 150px;
  }

  .subscribe-form,
  .subscribe-form form {
    width: 100%;
  }

  html
    body
    div.wpforms-container-full.subscribe-form
    .wpforms-form
    .wpforms-submit-container {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }

  .subscribe-form input[type='text'],
  html
    body
    div.wpforms-container-full.subscribe-form
    .wpforms-form
    input[type='email'] {
    height: 55px;
    padding-right: 20px;
  }

  .font-256 {
    font-size: 80px;
  }

  .font-54 {
    font-size: 24px;
  }

  .banner-404 {
    padding-top: 90px;
    min-height: 400px;
  }

  .site-footer-404 {
    padding-top: 20px;
  }

  .modal-header h2 {
    font-size: 22px;
    padding-top: 55px;
  }

  .modal-design-1 .modal-dialog {
    width: 95%;
    margin: 0 auto;
  }

  .modal-body {
    padding: 10px 0;
  }

  html body .modal-design-1 div.wpforms-container-full .wpforms-form ul li {
    padding-right: 0 !important;
    display: block;
    width: 100%;
  }

  #search-modal .modal-body {
    padding: 30px 20px;
  }

  .search-text-box .form-control {
    font-size: 18px !important;
  }

  .search-form button {
    font-size: 20px;
  }

  .search-result h3 {
    font-size: 20px;
  }

  .wp-pagenavi span {
    display: block;
    margin-bottom: 10px;
  }

  .contact-banner {
    min-height: 150px !important;
    padding-top: 40px !important;
  }

  .contact-banner h1 {
    margin-top: 55px !important;
  }

  .crm-banner .img-wrap {
    width: 250px;
  }

  .crm-banner h1 {
    line-height: 1.1;
    margin-top: 30px !important;
  }

  .crm-2 {
    padding-top: 120px;
  }

  .ul-checklist-3 li {
    font-size: 18px;
    padding-left: 50px;
  }

  .ul-checklist-3 li::before {
    width: 35px;
    height: 35px;
  }

  .crm-2 {
    padding-bottom: 40px;
  }

  .crm-4 {
    padding-bottom: 60px;
  }

  .crm-5 {
    padding-bottom: 50px !important;
  }

  .integrated-banner {
    min-height: 350px !important;
  }

  .integrated-banner h1 {
    margin-top: 55px !important;
  }

  .ul-checklist-3-with-p span {
    font-size: 22px;
    margin: 0 0 5px 0;
  }

  .ul-checklist-3-with-p p {
    font-size: 18px;
  }

  .integrated-2 {
    padding-bottom: 90px;
  }

  .integrated-3 {
    padding-top: 40px;
  }

  .sms-3-inner-wrap .col-sm-4 {
    padding: 0;
  }

  .sms-3-inner {
    padding: 50px 20px 20px 20px;
  }

  .integrated-3 {
    padding-bottom: 50px;
  }

  .font-44 {
    font-size: 22px;
  }

  .social-marketing-banner img {
    width: 150px;
  }

  .transparent-wave-3 {
    padding-bottom: 60px;
  }

  .font-31 {
    font-size: 20px;
  }

  .pt-80 {
    padding-top: 40px !important;
  }

  .social-marketing-5 {
    padding-top: 40px !important;
  }

  .social-marketing-5-img {
    max-width: 250px;
  }

  .font-29 {
    font-size: 22px;
  }

  .font-18 {
    font-size: 16px;
  }

  .social-marketing-5-btn a {
    margin-bottom: 20px;
  }

  .social-marketing-5 {
    padding-bottom: 20px !important;
  }

  .social-marketing-5-new {
    padding-bottom: 40px !important;
  }

  .ul-checklist-4 li {
    font-size: 16px;
  }

  .multi-channel-voting-row {
    padding-bottom: 20px;
  }

  .social-marketing-7 {
    padding-top: 40px !important;
  }

  .font-43 {
    font-size: 22px;
  }

  .investors-4 .img-wrap {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .investor-5-btn {
    min-width: 100% !important;
    justify-content: center !important;
  }

  .investors-6 h2 {
    margin-bottom: 0 !important;
  }

  .investors-5 {
    padding-bottom: 40px;
  }

  .investor-6-img {
    margin-top: 20px;
  }

  .investors-6 {
    padding-bottom: 40px;
  }

  .font-30 {
    font-size: 25px !important;
  }

  #download-modal .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .terms-of-use-banner {
    min-height: 156px !important;
    padding-top: 80px;
  }

  .terms-of-use-banner h1 {
    margin-top: 20px !important;
  }

  .terms-of-use-2 {
    padding-top: 20px;
  }

  .terms-of-use-2 p {
    font-size: 14px;
  }

  .terms-of-use-2 h2 {
    font-size: 18px;
    line-height: 1.2;
  }

  .news-2 .col {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .news-2 img {
    height: 150px;
  }

  .news-2 .col-body {
    padding: 30px;
  }

  .news-2 .site-btn {
    font-size: 16px;
    height: 50px;
  }

  .font-22 {
    font-size: 20px;
  }

  .font-17 {
    font-size: 16px;
  }

  .news-banner {
    min-height: 200px !important;
    padding-top: 70px;
  }

  .news-single p {
    font-size: 16px;
  }

  .news-single img {
    max-width: 100%;
  }

  .news-sidebar-wrap {
    padding: 0 20px;
  }

  .news-sidebar-date,
  .news-sidebar-cta {
    font-size: 14px;
  }

  .news-single h2 {
    font-size: 20px;
  }

  .owards-rewards-banner {
    /* ../img/banner-rewards-mobile.png */
    background-image: url('') !important;
    background-size: 100% 95%;
    background-position: center top;
  }

  .font-28 {
    font-size: 18px;
  }

  .mb-30 {
    margin-bottom: 15px !important;
  }

  .owards-rewards-3-img {
    max-width: 250px;
  }

  .owards-rewards-3-img-2 {
    max-width: 250px;
  }

  .media-2 .col-inner {
    min-height: 250px;
  }

  .anti-spam-banner h1 {
    margin-top: 20px !important;
  }

  .font-42 {
    font-size: 25px !important;
  }

  html body .anti-spam-banner br {
    display: none !important;
  }

  .font-23 {
    font-size: 18px;
  }

  .help-form {
    width: 100%;
    margin-bottom: 40px;
  }

  .help-form input {
    font-size: 15px;
    height: 50px;
    padding-left: 20px;
  }

  .help-lists li {
    font-size: 16px;
  }

  .help-2-single-content p {
    font-size: 16px;
  }

  .help-2-single-content ul {
    font-size: 16px;
  }

  .font-17 {
    font-size: 15px;
  }

  .other-help-col .inner::after {
    font-size: 18px;
  }

  .help-2-single-content ul,
  .help-2-single-content ol {
    padding: 0;
    font-size: 16px;
  }

  html body .custom-accordion .accordion-button {
    font-size: 18px;
    line-height: 1.3;
    display: block;
    height: auto;
  }

  html body .accordion-body p,
  html body .accordion-body ol,
  html body .accordion-body ul {
    font-size: 16px;
  }

  html body .accordion-body {
    padding-left: 15px;
  }

  .get-free-01-logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .get-free-01-heading br {
    display: block !important;
  }

  .get-free-01-heading {
    font-size: 32px !important;
  }

  .get-free-img-wrap {
    width: 225px;
    margin-bottom: -70px;
  }

  .get-free-form-wrap {
    padding-top: 60px;
  }

  .get-free-form-wrap p {
    line-height: 1.3;
  }

  .get-free-form-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .get-free-form .promo-code {
    display: block !important;
  }

  html body div.wpforms-container-full .wpforms-form .wpforms-field-label {
    margin-bottom: 10px;
  }

  .get-free-form-wrap {
    padding-bottom: 10px;
  }

  .affiliate-form-wrap {
    padding-top: 30px;
  }

  .affiliate-01 h2 {
    font-size: 20px;
  }

  .affiliate-01 h1 {
    font-size: 32px !important;
  }

  .font-57 {
    font-size: 30px !important;
  }

  p {
    font-size: 16px;
  }

  .home-2 p br {
    display: none !important;
  }

  .home-2 p {
    color: #4b4b4b;
  }

  .home-2-box {
    margin-bottom: 30px;
    max-width: 250px;
    padding: 40px;
    min-width: 1;
    min-height: 1px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-2-col {
    margin: 0 auto 25px auto;
  }

  .home-2-col h3 br {
    display: none !important;
  }

  .home-2-box h3 {
    font-size: 20px;
  }

  .watch-demo-btn {
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .watch-demo-btn i {
    display: none;
  }

  .home-3-right p {
    font-size: 18px;
  }

  .home-3-icon {
    font-size: 60px;
  }

  html body .home-3-img {
    width: 100%;
  }

  .home-3-icon {
    font-size: 55px;
  }

  .home-4 {
    background: #f8f9fc;
  }

  .pink-bottom-curve::after {
    /* ../img/bg-otext-mobile-pink-curve.png */
    background-image: url('');
    height: 86px;
    width: 100%;
    bottom: 0;
  }

  .home-5 {
    margin-top: 0;
  }

  .home-4 {
    padding-bottom: 0;
  }

  .home-5 {
    background-color: #fff;
    margin-top: 0;
    padding-top: 35px;
  }

  .home-6 .container::before {
    width: 225px;
    height: 125px;
    right: -75px;
    top: -150px;
  }

  .home-5-col-4 .img-wrap {
    margin-top: 0 !important;
  }

  .home-5 .container {
    margin-bottom: -100px;
  }

  .home-6 {
    padding-bottom: 100px;
  }

  .home-6 {
    padding-top: 160px;
  }

  .site-footer::before {
    top: -80px;
    height: 80px;
  }

  .home-6 {
    padding-bottom: 0;
  }

  html body div.wpforms-container-full .wpforms-form input[type='text'],
  html body div.wpforms-container-full .wpforms-form input[type='email'] {
    text-align: center;
  }

  .footer-col-4 form {
    max-width: 320px;
    margin: 0 auto;
  }

  .home-6-row-2-slider .c-slider-col {
    padding-top: 60px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 60px !important;
  }

  .home-6-box {
    box-shadow: unset;
  }

  .home-6-img img {
    margin-top: 0;
    position: relative;
    top: -50px;
    margin-bottom: -50px;
  }

  .home-6-row-2 {
    margin-top: 15px;
  }

  .home-6 {
    padding-bottom: 40px;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: -5px;
  }

  .slick-next {
    right: -5px;
  }

  .footer-col-2 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .footer-col-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }
	
	.site-translation-selected {
		display:block;
	}
	
	.site-translation-selected-flag {
		display:block;
		margin-right:0;
	}
	
	.site-translation-selected-lang {
		margin-right:0;
	}
	
	.site-translation-col {
		padding-right:5px;
	}
	
	.site-menu-mobile-btn {
		padding-left:5px;
	}
	
	.home-1 {
		padding-top:200px;
	}
	
	.home-1-right-col {
		padding:0 20px;
	}
	
	h1, h2, h3 {
		font-size:25px;
		line-height:1.3;
	}
	
	.home-1 {
		padding-bottom:20px;
	}
	
	p {
		line-height:1.6;
	}
	
	html body .home-2 {
		padding-top:100px;
	}
	
	.top-curve::before {
		height:69px;
	}
	
	.mission-img-wrap {
		max-width:300px;
	}
	
	.mission-left-col {
		padding-left:20px;
		padding-right:20px;
	}
	
	.mission-left-col h3 {
		margin-bottom:15px;
	}
	
	.top-curve-2::before {
		height:76px;
	}
	
	.home-3 {
		margin-top:0;
		padding-top:40px;
		margin-bottom:0;
	}
	
	.home-3-col {
		margin-top:45px;
	}
	
	.home-3-heading {
		margin-bottom:0 !important;
	}
	
	.home-3-btn-wrap {
		margin-top:45px !important;
	}
	
	.site-footer {
        /* /wp-content/uploads/2022/11/bg-footer-mobile-new.png */
		background:no-repeat center top url('');
		background-size:cover;
	}
	
	.footer-col {
		margin:0;
		margin-bottom:15px;
	}
	
	.footer-form-wrap {
		max-width:300px;
		margin:0 auto;
	}
	
	.footer-app-dl {
		flex-flow:row !important;
	}
	
	.mindmap-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.mindmap-col-inner {
		padding:30px;
	}
	
	.mindmap-1 {
		margin-bottom:0;
	}
	
	.mindmap-wrap {
		margin-top:60px;
	}
	
	.gsn-1-video-wrap::before {
		width: 50px;
		height: 50px;
		left: -25px;
		top: -25px;
	}
	
	.gsn-1-video-wrap::after {
		width:125px;
		height:125px;
		right: -50px;
		bottom: -50px;
	}
	
	.gsn-1-video-wrap {
		margin-top:30px !important;
	}
	
	.top-curve-3::before {
		height:40px;
	}
	
	.gsn-1 {
		padding-bottom:40px;
	}
	
	.gsn-2 {
		margin-bottom:0;
		padding-top:60px;
	}
	
	.m-mt-45 {
		margin-top:45px !important;
	}
	
	.m-mt-0 {
		margin-top:0 !important;
	}
		
	/* Fonts */
	.m-n-font-28 {
		font-size:28px !important;
	}
	
	.tools-video-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.tools-2 {
		margin-bottom:0;
	}
	
	.tools-2 {
		padding-top:100px;
	}
	
	.sign-up-2 {
		margin-bottom:0;
		padding-top:80px;
	}
	
	.sign-up-1-row .col-sm-3 {
		max-width:100% !important;
		flex: 0 0 100% !important;
		text-align:center;
	}
	
	.sign-up-1-text-1 {
		font-size: 18px !important;
		line-height: 1.3;
	}
	
	.sign-up-1-text-2,
	.sign-up-1-email {
		font-size:15px !important;
	}
	
	.sign-up-1-email {
		padding:0 10px;
		padding-bottom:5px;
	}
	
	.sign-up-form {
		margin-top:30px !important;
	}
	
	.sign-up-form input[type="text"], .sign-up-form input[type="email"], .sign-up-form input[type="phone"] {
		height:48px;
		font-size:14px;
	}
	
	.suf-left {
		max-width:48px;
		flex: 0 0 48px;
		height:48px;
	}
	
	.sign-up-2 {
		padding-top:160px;
		margin-top:0;
	}
	
	.suf-row label {
		font-size:14px;
	}
	
	.suf-row .suf-upload-file-btn {
		font-size:14px;
	}
	
	.suf-row button {
		height:51px;
		font-size:18px;
	}
	
	.cp-1-img-wrap {
		max-width:250px;
		padding-left:0;
	}
	
	.cp-1-left h1 {
		line-height:1.1;
	}
	
	.cp-col {
		max-width:100%;
		flex:0 0 100%;
		margin-bottom:45px;
	}
	
	.cp-2 {
		margin-bottom:-140px;
	}
	
	.cp-col-num {
		width:56px;
		height:56px;
	}
	
	.site-header.active .site-logo {
		margin:0 auto;
		max-width:50px;
	}
	
	.font-49 {
		font-size:25px !important;
	}
	
	.home-1-row-2 {
		margin-top:15px;
	}
	
	.home-1-right-col {
		margin-top:0;
	}
	
	.tools-1-row-2 h2 br {
		display:none !important;
	}
	
	.tools-1-row-2 .btn-wrap {
		margin-top:15px !important;
	}
	
	.tools-video-col {
		padding:0;
		margin-bottom:20px;
	}
	
	.tools-video-wrap {
		margin-top:45px !important;
	}
	
	.tools-2-heading br {
		display:none !important;
	}
	
	.tools-2-heading {
		text-align:center;
	}
	
	.tools-2-right .btn-wrap {
		text-align:center;
	}
	
	.suf-left-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.suf-right-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.site-footer-2 {
		position:relative;
	}
	
	.site-footer-lower-ul {
		flex-flow:column;
		align-items:center;
	}
	
	.site-footer-2 .site-footer-lower-ul li {
		margin-bottom:5px;
	}
	
	.site-footer-2 .site-footer-lower-ul li:last-of-type {
		margin-bottom:0;
	}
	
	.top-curve-login::before {
		height: 51px;
		top: -51px;
	}
	
	.login-2 {
		margin-top:220px;
	}
	
	.sign-up-form {
		max-width:327px;
	}
	
	.cp-curve::before {
		top:-69px;
	}
	
	.opportunity-img-wrap {
		margin-top:0;
	}
	
	.home-2 .mt-60 {
		margin-top:15px !important
	}
	
	.mw-1 h1 {
		font-size: 25px;
		max-width: 225px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.mw-1 .cp-1-left {
		padding-right:12px;
	}
	
	.mw-1 {
		background-size: 100%;
		padding-bottom: 283px;
		padding-top: 160px;
		margin-bottom: -15px;
        /* /wp-content/uploads/2022/11/mw-banner-mobile.jpg */
		background-image:url('');
	}
	
	.mw-2-row-2 h2 {
		margin-bottom:10px;
	}
	
	.mw-2-row-3 {
		max-width:250px;
	}
	
	.mw-collection-title {
		font-size:21px;
		margin-bottom:5px;
	}
	
	.mw-collection-price {
		font-size:16px;
		margin-bottom:10px;
	}
	
	.mw-2-row-3::before {
		background-image:url('./assets/img/compensation-plan/img-get-3d-mobile.png');
		width: 250px;
		height: 150px;
		left: -40px;
		top: -50px;
	}
	
	.mw-collection-box-num {
		font-size:14px;
	}
	
	.mw-collection-box-country {
		font-size:10px;
	}
	
	.mw-collection-box-btn {
		font-size:8px;
	}
	
	.mw-2-row-3 .col-sm-4 {
		margin-bottom:30px !important;
	}
	
	.mw-collection-box {
		padding:15px;
	}
	
	.mw-2-row-1 .cp-col {
		flex:0 0 100% !important;
		max-width: 100% !important;
		margin-bottom: 1rem;
	}
	
	.mw-2-row-1 .col-sm {
		padding:0 !important;
	}
	
	.mw-2-row-1 .cp-col-box {
		min-height:1px !important;
	}
	
	.mw-2 {
		padding-top:20px;
	}
	
	.mw-2-row-2 {
		margin-top:0 !important;
	}
	
	.mobile-flex {
		display:flex !important;
	}
	
	.pd-1-box-img {
		width: 150px;
		height: 150px;
		margin-top: -110px;
		border: 7px solid #fff;
		box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
		margin-bottom:10px;
	}
	
	.pd-1-curve::before {
		top: -35px;
		height: 35px;
	}
	
	.pd-1 {
		margin-top:240px;
	}
	
	.pd-1-box-left-text-user {
		font-size:20px !important;
		text-align:center;
	}
	
	.pd-1-box-sponsor-right p {
		text-align:left;
	}
	
	.pd-1-box-sponsor-right .n-font-20 {
		font-size:14px !important;
		line-height:1.2;
	}
	
	.pd-1-box-sponsor-img {
		margin-right:10px;
	}
	
	.pd-1-box-sponsor {
		justify-content:center;
	}
	
	.pd-1-box-sponsor {
		margin-bottom:30px;
		margin-top:15px;
	}
	
	.pd-1-box {
		margin-bottom:0;
	}
	
	.pd-1 {
		margin-bottom:0;
	}
	
	.pd-1-box-aff-ul li {
		font-size:13px;
		overflow:hidden;
	}
	
	.pd-1-box-right {
		padding:0 20px;
	}
	
	.pde-1-box-heading {
		margin-bottom:30px;
		font-size:25px !important;
	}
	
	.pde-1 {
		margin-top:210px;
	}
	
	.pd-form-right,
	.pd-form-left {
		padding:0 12px;
	}
	
	.file_drag_file_col {
		max-width:50%;
		flex:0 0 50%;
	}
	
	.file_drag_area {
		height:auto;
	}
	
	.pd-form input {
		font-size:14px;
	}
	
	.mw-2-row-3-new {
		margin-top:15px;
	}
	
	.mw-3 {
		margin-bottom:0;
	}
	
	.ln-1-trading-wrap li {
		font-size: 10px;
	}

	.ln-1-download-wrap {
		flex-flow: row;
	}

	.ln-1-right-col {
		align-items: center !important;
	}

	.ln-1-download-wrap li {
		margin-left: 0;
		margin-right: 10px;
	}

	.ln-1-right-col {
		padding: 10px;
	}

	.ln-1-gift-wrap {
		flex-flow: row;
	}

	.ln-1-gift-heading {
		font-size: 18px !important;
	}

	.ln-1-gift-sponsor-wrap {
		flex-flow: row;
		margin-top: 3px !important;
	}

	.ln-1-gift-text {
		font-size: 11px !important;
		text-align: left;
		line-height: 1.3;
	}

	.ln-1-gift-line {
		width: 65px;
		font-size:12px;
	}

	.ln-1-gift-link {
		font-size: 10px !important;
		line-height: 1.5;
	}

	.half-curve::before {
        /* /wp-content/uploads/2022/11/ln-2-curve-mobile.jpg */
		background-image: url('');
		top: -41px;
		height: 41px;
		background-size: cover;
	}

	.ln-2-header-right p {
		font-size: 12px;
		line-height: 1.3;
	}

	.ln-2-header-left {
		flex: 0 0 102px;
		margin-left: 0;
		margin-right: 5px;
	}

	.ln-2-header-right {
		flex: 0 0 210px;
	}

	.ln-2 {
		padding-top: 30px;
		margin-top: 21px;
	}

	.ln-2-list {
		flex-flow: column;
		margin-top: 20px;
	}

	.ln-2-list li {
		margin-right: 0;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.ln-2-right {
		padding-right: 0 !important;
	}

	.ln-2-big-img-wrap-mobile {
		margin-top: 30px;
	}

	.ln-2-img-wrap {
		margin-top: 5px !important;
	}

	.ln-3-top-curve::before {
		height: 25px;
		top: -25px;
	}

	.n-font-55 {
		font-size: 35px !important;
	}

	.ln-3-row-2 p {
		text-align: left;
	}

	.ln-3-row-3-left {
		padding: 0 20px !important;
		text-align: left;
	}

	.ln-4-top-curve::before {
		top: -30px;
		height: 30px;
	}

	.ln-3-row-3-left {
		text-align: left !important;
	}

	.ln-4-img-wrap img {
		margin-left: 30px;
	}

	.ln-4-img-wrap-2 {
		width: 406px;
	}

	.ln-4-img-wrap-2 img {
		margin-left: -25px;
	}

	.ln-4-img-wrap {
		text-align: center;
	}

	.ln-4-img-wrap .mobile {
		display: inline-block !important;
	}

	.ln-4-img-wrap-2 {
		margin-top: -15px;
	}

	.n-font-42 {
		font-size: 32px !important;
	}

	.ln-4 {
		margin-top: 80px;
	}

	.ln-5 {
        /* /wp-content/uploads/2022/11/bg-ln-5-mobile.png */
		background: no-repeat center url('');
		background-size: 100% 100%;
		padding: 0;
		margin: 0;
		padding-top: 60px;
		margin-top: 40px;
		padding-bottom: 60px;
	}

	.n-font-39 {
		font-size: 29px !important;
	}

	.ln-5-box-img img {
		max-width: 200px;
	}

	.ln-5-box-img-3 img {
		max-width: 150px;
	}

	.ln-6-header-num.mobile {
		display: flex !important;
		margin-bottom: 30px;
	}

	.ln-6-header-num {
		margin: -43.5px auto 0 auto;
	}

	.ln-6-row-1-img {
		max-width: 200px !important;
	}

	.ln-6-header-right {
		margin-top: 10px;
	}

	.ln-6-header-right-text-3 {
		color: #3e3e3e !important;
		font-size: 25px !important;
	}

	.ln-6-row-2-text {
		font-size: 20px !important;
	}

	.ln-6-row-3 .col {
		max-width: 250px;
		margin-left: auto;
		margin-right: auto;
	}

	.ln-6-row-4 {
		margin-top: 0;
	}

	.ln-6-row-4 {
		padding: 40px 20px 40px 20px;
		width: 95%;
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.ln-6-earn-text {
		font-size: 18px;
		padding: 40px;
	}

	.ln-6-earn {
		margin-top: 20px !important;
	}

	.ln-6-earn-img {
		max-width: 250px;
	}

	.n-font-23 {
		font-size: 20px !important;
	}

	.ln-7-num {
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}

	.ln-7-left-heading {
		padding-left: 0 !important;
		font-size: 35px !important;
	}

	.ln-7-left {
		padding: 0 20px !important;
	}

	.ln-7-img-1-wrap {
		max-width: 250px !important;
	}

	.ln-7-row-2 p {
		line-height: 1.3;
	}

	.ln-8-top-curve::before {
		height: 30px;
		top: -30px;
	}

	.ln-8 {
		margin-top: 60px;
	}

	.ln-8-row-1-right {
		padding: 0 20px !important;
	}

	.ln-8-row-1-right {
		padding-top: 20px !important;
	}

	.ln-8-row-1-right h2 {
		text-align: center;
	}

	.ln-8-img-wrap {
		max-width: 200px !important;
	}

	.ln-7-left-heading {
		margin-bottom: 15px !important;
	}

	.ln-8-row-1-right p {
		margin-top: 0 !important;
		text-align: center;
	}

	.ln-8-row-2-left {
		padding: 0 20px !important;
	}

	.ln-8-row-2-left p {
		font-size: 16px !important;
	}

	.ln-8-row-2-left h4 {
		font-size: 18px !important;
	}

	.n-font-35 {
		font-size: 25px !important;
	}

	.ln-8-row-2 {
		margin-top: 40px !important;
	}

	.ln-8-row-2-left .btn-wrap {
		margin-top: 30px;
	}

	.ln-8-row-2-img-wrap {
		max-width: 300px;
		margin-top: 40px;
	}

	.ln-9 h2 {
		text-align: left;
		font-size: 28px !important;
	}

	.ln-9-box-left p {
		line-height: 1.3;
		font-size: 16px;
	}

	.ln-9-box-right {
		flex: 0 0 145px;
	}

	.ln-9-btn {
		font-size: 14px;
		font-weight: 600;
		padding: 10px 25px;
		margin-top: 8px;
	}

	.ln-9 {
		padding-top: 420px;
		margin-top: -460px;
	}
	
	.sign-up-sponsor-img {
		max-width:89px;
		max-height:89px;
		object-fit:contain;
		margin:auto;
	}
	
	.sign-up-sponsor-img-tshirt {
		margin-top:30px;
	}
	
	.home-1 {
        /* /wp-content/uploads/2022/11/bg-banner-mobile-new.png */
		background-image:url('');
	}
	
	html body .home-2 {
		margin-top: -200px !important;
		padding-top: 280px !important;
	}
	
	.home-1-heading {
		font-size:30px !important;
		line-height:1.2;
	}
	
	.home-1-img-wrap {
		margin-top: 30px;
		margin-bottom: -100px;
	}
	
	.opportunity-img-wrap {
		margin-top:40px;
	}
	
	.mw-3::before {
		height: 54px;
		background-size: contain;
		top: -13px;
	}
	
	.mw-2-new-img-wrap {
		margin-top:15px;
		max-width:300px;
	}
	
	.mw-2-new::before {
		height:66px;
	}
	
	.mw-2-new {
		padding-bottom:20px;
	}
	
	.sign-up-1-row {
		flex-flow:column;
	}
	
	.sign-up-2::before {
		height:100px;
	}
	
	.sign-up-1 {
		padding-top:150px;
	}
	
	.sign-up-sponsor-img-tshirt {
		max-width:136px;
	}
	
	.cwa-1-heading {
		font-size: 22px !important;
		line-height: 1.4;
	}
	
	.cwa-1-logo img {
		width: 100px !important;
	}

	.cwa-1-img-1 img {
		width: auto;
		height: auto;
	}

	.font-58 {
		font-size: 31px !important;
	}

	.cwa-1-btn-wrap .custom-pink-btn {
		margin-right: 0 !important;
		margin-bottom: 15px;
	}

	.cwa-1-img-3 {
		width: 100%;
		margin-left: 0;
		margin-top: 30px;
	}

	.cwa-1-count {
		font-size: 43px;
	}

	.cwa-1-btn-wrap .custom-pink-btn {
		min-width: 250px;
	}

	.cwa-1-count-text {
		font-size: 16px;
	}

	#consumer-wallet-app-banner {
		background-image: none;
	}

	.cwa-1-row-3::after {
		width: 90%;
		left: 5%;
	}

	.cwa-1-heading {
		margin-top: 30px !important;
		font-size:22px !important;
	}

	.cwa-1-btn-wrap {
		margin-top: 30px !important;
	}

	.cwa-1-count-wrap {
		margin-bottom: 0 !important;
		margin-top: 15px !important;
	}

	.cwa-1-row-3 {
		margin-top: 0;
	}

	.cwa-2-row-2 h3 {
		font-size: 22px !important;
	}

	.cwa-2-row-2 .col-md-4 {
		margin-bottom: 45px;
	}

	.custom-pink-btn {
		min-width: 270px;
	}

	.cwa-2-row-2 {
		margin-top: 45px;
	}

	.cwa-ul li {
		margin-bottom: 30px;
	}

	.cwa-btn-wrap-3 {
		padding-left: 0;
	}

	.cwa-ul-3 {
		margin-top: 30px;
	}

	.cwa-4 {
		padding-top: 60px;
	}

	.cwa-5-slider .img-wrap {
		padding: 140px 10px !important;
		padding-bottom:100px !important;
	}

	.cwa-5-slider .img-wrap img {
		transform: scale(0.9);
	}

	.cwa-5 {
        /* /wp-content/uploads/2022/11/bg-cwa-5-mobile-1.png */
		background-image: url('');
		background-size: 100% 100%;
	}

	.cwa-5 h2 {
		margin-top: 100px;
	}

	.cwa-5 br {
		display: block !important;
	}

	.cwa-5 h2 {
		line-height: 1.1;
		margin-top: 100px !important;
	}

	.cwa-5-col-2 col {
		margin-top: -75px;
	}

	.cwa-5 p {
		margin-top: 15px !important;
	}

	.cwa-5 {
		margin-top: 15px !important;
	}

	.cwa-6 br {
		display: block !important;
	}

	.cwa-6 p {
		margin-top: 25px !important;
		padding: 0 15px;
	}

	.cwa-6-img-wrap-1 {
		margin-top: 60px;
	}

	.cwa-6-img-2 a {
		margin: 0 5px;
	}

	.cwa-6-img-2 {
		margin-top: 45px;
	}

	.cwa-6-col-2 {
		padding-top: 20px !important;
	}

	.cwa-5 p {
		padding: 0 40px;
	}

	.cwa-1-img-3-col {
		padding: 0 !important;
	}

	.cwa-2 br {
		display: block !important;
	}

	.cwa-3-img-col {
		padding: 0 !important;
	}

	.cwa-3 {
		padding-top: 60px !important;
	}

	.cwa-3-img-col {
		margin-bottom: -30px;
	}

	.cwa-4-col-1 {
		padding: 0 !important;
	}

	.cwa-4 br {
		display: block !important;
	}

	.cwa-4-col-1 {
		margin-bottom: -190px !important;
	}

	.cwa-5 p br {
		display: none !important;
	}

	.cwa-5 h2 {
		padding: 0 20px;
	}

	.mwa-1 .cwa-1-img-3 {
		margin-top: 30px;
	}

	.mwa-1 .cwa-1-row-3 {
		padding-top: 0;
		margin-top: -30px;
	}

	.mwa-1 br {
		display: block !important;
	}

	.mwa-3 .cwa-3-img-wrap::before {
		display: none;
	}

	.mwa-3 .cwa-3-img-wrap {
		width: 434px;
	}

	.mwa-3 .cwa-3-img-col {
		margin: 0 !important;
	}

	.mwa-3 .cwa-3-col-2 {
		margin-top: 30px;
	}

	.mwa-4 .cwa-4-col-2 {
		margin-top: 60px;
	}

	.mwa-5 {
		background-position: center 300px;
	}

	.mwa-5-video-wrap {
		position: relative;
		z-index: 2;
	}

	.mwa-5-btn {
		margin-top: -34px;
		margin-left: -34px;
		width: 68px;
		height: 68px;
	}

	.mwa-5-watch-video {
		position: absolute;
		left: 15px;
		top: -80px;
		width: 150px;
	}

	.mwa-5-video-wrap {
		position: relative;
		z-index: 2;
		margin-top: 110px !important;
		margin-bottom: 15px;
	}

	.mwa-5 {
		background-position: 100%;
		background-size: 100%;
		margin-top: 0 !important;
	}

	.mwa-5 h2 {
		margin-top: 45px !important;
	}
	
	.mwa-5 .cwa-5-slider {
		margin-top:0 !important;
	}

	.mwa-6 .cwa-6-img-2 {
		margin-top: 0 !important;
	}

	.mwa-6::before {
		display: none;
	}

	.mwa-6 {
		margin-top: -30px;
		padding-bottom: 60px;
		background-size: 100% !important;
	}

	.cwa-6 {
		background-size: 100% !important;
		margin-bottom:-80px;
	}
	
	.cwa-1-img-2 {
		margin-top:20px;
	}
	
	.custom-wallet-app .n-font-40 {
		font-size:25px !important;
	}
	
	.site-menu-sponsor-col-mobile {
		margin-top:20px;
	}
	
	.cwa-sponsor-right-col .n-font-19 {
		font-size:18px !important;
	}
	
	.cwa-tshirt-left-col {
		margin-right:0;
		max-width: 30%;
		flex: 0 0 30%;
	}
	
	.cwa-sponsor-right-col {
		flex:0 0 70%;
		max-width:70%;
	}
	
	.cwa-sponsor-right-col .n-font-14,
	.cwa-sponsor-right-col .n-font-16 {
		font-size:14px !important;
	}
	
	.cwa-popup-form-body-img img {
		width:90px;
		height:90px;
		border-width: 3px;
		margin-top:-80px;
	}
	
	.cwa-popup-form-header .n-font-33 {
		font-size:21px !important;
	}
	
	.cwa-popup-form-header .n-font-30 {
		font-size: 18px !important;
		margin-top: 10px;
	}
	
	.w-orange-double-underline::after {
		width:152px;
	}
	
	.cwa-popup-form-header {
		padding-top:30px;
		padding-bottom:30px;
	}
	
	.cwa-radio-ul li {
		margin-bottom:10px;
	}
	
	.cwa-shirt-row .cwa-label-title {
		margin-bottom:15px;
	}
	
	.cwa-radio-ul li {
		font-size:15px;
	}
	
	.cwa-user-col-1 {
		max-width:100% !important;
		flex:0 0 100% !important;
	}
	
	.cwa-shirt-row > div {
		padding:0;
	}
	
	.cwa-user-row > div {
		max-width:100%;
		flex:0 0 100%;
		padding:0;
		margin-bottom:15px;
	}
	
	.cwa-user-row > div:last-of-type {
		margin-bottom:0;
	}
	
	.cwa-label-input {
		font-size:14px;
	}
	
	.cwa-ship-inner-col {
		max-width:100%;
		flex:0 0 100%;
	}
	
	.cwa-ship-inner {
		padding: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
		border-radius: 0;
	}
	
	.cwa-ship-row {
		padding:0;
	}
	
	.cwa-ship-inner-col label {
		width: 170px;
		margin-right: 20px;
		font-size: 14px;
	}
	
	.cwa-popup-form input[type="text"], .cwa-popup-form input[type="email"], .cwa-popup-form select {
		font-size:13px;
	}
	
	.cwa-ship-inner-col-1 .cwa-label-title {
		padding-left:0;
	}
	
	.cwa-ship-inner-col > div {
		padding:0;
	}
	
	.cwa-user-row > div .cwa-label-input {
		width:170px;
	}
	
	.cwa-user-col-1 {
		border-top:1px solid #C8C8C8;
	}
	
	.cwa-user-col-1 .cwa-label-title {
		margin-top: -17px;
		background: #fff;
		padding-right: 33px;
	}
	
	.cwa-ship-inner-col-1 .cwa-label-title {
		padding-right:46px;
	}
	
	.cwa-shirt-row {
		margin-bottom:25px;
	}
	
	.mwa-5-watch-video {
		position: absolute;
		left: 15px;
		top: -80px;
		width: 150px;
	}
	
	.gsn-1-col-right {
		margin-top:20px;
	}
	
	.btn-wrap {
		text-align:center;
	}
	
	.pd-1-box-2 {
		margin-top:20px;
	}
	
	.font-53 {
		font-size:30px !important;
	}
	
	.tools-1-row-2 {
		margin-top:20px !important;
	}
	
	.tools-2-logged {
		padding-top:60px;
		margin-top:160px;
	}
	
	.tools-2-logged-p {
		font-size:16px !important;
	}
	
	.tools-2-logged-row-2 .site-btn {
		font-size: 9px;
		min-width: 1px;
		min-height: 1px;
		padding: 13px 30px;
	}
	
	.tools-2-logged-img {
		padding-left:40px;
	}
	
	.leaderboard-header {
		justify-content:center;
	}
	
	.leaderboard-header h1 {
		margin-bottom:20px !important;
	}
	
	#wcp-body {
		margin-top:20px;
	}
	
	.wcp-1 {
		margin-bottom:0;
	}
	
	.suc-popup-inner {
		max-width:95%;
	}
	
	.n-font-43 {
		font-size:23px !important;
	}
	
	.new-home-1 h1 {
		max-width: 250px;
		margin-left: auto;
		margin-right:auto;
	}
	
	.new-home-2 {
		padding-top:30px;
	}
	
	.n-font-28 {
		font-size:22px !important;
	}
	
	.new-home-2-rating-wrap p {
		font-size:16px !important;
	}
	
	.new-home-2-rating-wrap img {
		width:150px;
	}
	
	.new-home-2-right {
		margin-bottom:0;
	}
	
	.desktop {
		display:none;
	}
	
	.mobile {
		display:block;
	}
	
	.new-home-2-right-img-3 {
		margin-bottom: -170px;
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.new-home-3 {
		padding-top:300px;
	}
	
	.new-home-3-col-inner {
		flex-flow:column;
	}
	
	.new-home-3-col-text-1 {
		padding-right:0;
	}
	
	.new-home-3-col-text-2 {
		text-align:center;
	}
	
	.new-home-3-row::after {
		height:41px;
		top:-41px;
	}
	
	.new-home-4 {
		padding-top:40px;
	}
	
	.new-home-4-img-left {
		max-width:300px;
	}
	
	.new-home-4-img-text-wrap {
		margin-top:40px;
	}
	
	.new-home-4-img-text-wrap:nth-of-type(1) {
		margin-top:40px;
	}
	
	html body .new-home-5 {
		margin-top:0 !important;
		padding-top:0 !important;
		padding-top:80px !important;
	}
	
	.new-home-5::before {
		height:50px;
	}
	
	.new-home-4-img-text-wrap .img-wrap,
	.new-home-4-img-text-wrap .text-wrap {
		max-width:100%;
		flex:0 0 100%;
		text-align:center;
		padding:0;
	}
	
	.new-home-5 .mission-img-wrap {
		max-width:250px;
	}
	
	.new-home-5 h2 br {
		display:none !important;
	}
	
	.n-font-48 {
		font-size:28px !important;
	}
	
	.new-home-5 .n-font-19 {
		font-size:17px !important;
	}
	
	.cwa-popup-form-step-wrap {
		padding:0 20px;
	}
	
	.cwa-popup-form-img-wrap img {
		width:125px;
	}
	
	.suc-popup-details-left,
	.suc-popup-details-right {
		max-width:100%;
		flex: 0 0 100%;
	}
	
	#fta-view-popup .suc-popup-inner,
	.fta-view-popup .suc-popup-inner {
		padding: 20px;
	}
	
	.suc-popup-details-row {
		margin-top:20px;
	}
	
	.suc-popup-details-left,
	.suc-popup-details-right {
		font-size:14px;
	}
	
	.leaderboard-header-2 {
		margin-top:0;
	}
	
	.leaderboard-search {
		flex-flow:row wrap;
	}
	
	.leaderboard-search-left {
		margin-bottom:30px;
	}
	
	.leaderboard-search input {
		width:100%;
	}
	
	.gsn-1-new {
		padding-bottom:340px;
	}
	
	.top-curve-4::before {
		top:-25px;
		height:25px;
	}
	
	.gsn-1-new-video-wrap-img {
		width: 200px;
		top: 0;
		right: 0;
		position: relative;
		margin-left: auto;
		display: block;
		margin-top: -60px;
		margin-bottom: 30px;
	}
	
	.gsn-1-new .gsn-1-col-left {
		margin-top:0;
	}
	
	.tools-1-new-video-wrap {
		margin-top:0 !important;
	}
	
	.tools-1-new {
		padding-bottom:300px;
	}
	
	.tools-2-new {
		margin-top:-240px !important;
		padding-top:20px;
	}
	
	.top-curve-5::before {
		height:50px;
	}
	
	.tools-3-new {
		padding-top:60px;
	}
	
	.top-curve-6::before {
		height:50px;
	}
	
	.tools-4-new {
		margin-bottom:60px;
		padding-top:80px;
	}
	
	.tools-4-new-heading {
		margin-top:30px !important;
	}
	
	.tools-logged-new-2 {
		padding-top:40px;
		padding-bottom:0;
	}
	
	.tools-logged-new-1 {
		padding-bottom:260px;
	}
	
	.eo-1 h1 {
		font-size:25px;
	}
	
	.eo-1-box {
		margin-top:40px;
		padding:40px 20px;
	}
	
	.eo-payments li {
		max-width:100%;
		flex: 0 0 100%;
		text-align:left;
	}
	
	.eo-payments {
		margin-top:20px;
	}
	
	.eo-1-box-upgrade-col .eo-payments {
		margin-top:10px;
	}
	
	.eo-1 {
		margin-bottom:-60px;
	}
	
	.eo-1-box-upgrade-heading {
		font-size:18px !important;
	}
	
	.eo-1-box-upgrade h4 {
		font-size:20px !important;
	}
	
	.eo-1-box-upgrade-img-2 img {
		max-width:225px;
	}
	
	.eo-1-box-upgrade-heading span {
		padding:0 10px;
	}
	
	.leaderboard-search-right {
		margin-top:0;
	}
	
	.leaderboard-filter-select-wrap {
		margin-bottom:20px;
	}
	
	.site-header-sponsor {
		display:none;
	}
	
	.w-sponsor-header .site-header-sponsor {
		display:none;
	}
	
	.w-sponsor-header .site-header-sponsor.mobile-sponsor {
		display:block;
	}
	
	.w-sponsor-header .new-home-1 {
		margin-top:250px;
	}
	
	.sign-up-1 {
		padding-top:120px;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-header {
		flex-flow:column;
	}
	
	.cwa-popup-section-ty .img-wrap {
		margin: 0 auto;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-header p {
		margin-top:15px;
		font-size:30px !important;
	}
	
	.cwa-popup-section-ty iframe {
		height:250px;
	}
	
	.cwa-popup-section-ty .btn-wrap .site-btn {
		font-size:16px;
	}
	
	.cwa-popup-section-ty .cwa-popup-form-body {
		padding-bottom:20px;
	}
	
	.cwa-popup-section-ty .video-wrap {
		padding-top:66.6%;
	}
	
	.w-header-sponsor .site-header-sponsor.mobile-sponsor {
		display:flex;
		flex-flow:column;
	}
	
	.mobile-sponsor .site-header-sponsor-tshirt {
		flex:0 0 60px;
		height:60px;
		margin-left:0;
		margin-right:0;
		display:none;
	}
	
	.mobile-sponsor .site-header-sponsor-for-free {
		display:none;
	}
	
	.mobile-sponsor .site-header-sponsor-tshirt-right {
		flex-flow:column;
		padding: 0 15px;
	}
	
	.mobile-sponsor .site-header-sponsor-btn {
		font-size:10px;
	}
	
	.mobile-sponsor .site-header-sponsor-left {
		justify-content:center;
	}
	
	.mobile-sponsor .site-header-sponsor-left .n-font-22 {
		font-size:16px !important;
		margin-bottom:3px;
	}
	
	.mobile-sponsor .site-header-sponsor-right-p {
		display:flex;
		font-size:12px;
		align-items:center;
	}
	
	.mobile-sponsor .site-header-sponsor-right-p span {
		white-space:nowrap;
	}
	
	.site-header-sponsor-right-p img {
		width:50px;
		height:50px;
	}
	
	.w-sponsor-header .ln-1 {
		padding-top:140px;
	}
	
	.site-menu-sponsor-col-mobile {
		display:none !important;
	}
	
	.new-site-header.active .site-header-sponsor.mobile-sponsor {
		display:none;
	}
	
	.mw-1 {
		padding-top:100px;
	}
	
	.promo-1-header {
		flex-flow:column;
		text-align:center;
		margin-bottom:0;
	}
	
	.promo-1-header {
		margin-left:0 !important;
	}
	
	.promo-1-header p {
		margin:0 !important;
	}
	
	.promo-1-header .img-wrap {
		margin-bottom:-10px;
	}
	
	.promo-1-left .font-67 {
		margin-top:15px;
	}
	
	.promo-1-left .font-67 br {
		display:none !important;
	}
	
	.promo-1-client-img-wrap {
		margin-top:40px;
	}
	
	.promo-1-app-img-wrap {
		margin-top:-260px; 
		left:0;
	}
	
	.promo-2 {
        /* /wp-content/uploads/2023/01/bg-promo-2-mobile.png */
		background-image:url('');
		background-size: 100% 100%;
		margin-top: -500px;
		padding-top: 420px;
		min-height:1px;
		padding-bottom:60px;
	}
	
	.promo-free-img-wrap {
		max-width:250px;
	}
	
	.promo-2-video-play-wrap img {
		width:50px;
	}
	
	.promo-2-arrow img {
		width:20px;
	}
	
	.promo-2-video-wrap {
		margin-top:-80px;
	}
	
	.promo-2-video-play-wrap {
		margin-left:-25px;
		margin-top:-25px;
	}
	
	.w-sponsor-header.w-sponsor-header-mobile .site-header-sponsor {
		display:block;
	}
	
	.w-sponsor-header .promo-1 {
		margin-top:140px;
	}
	
	.promo-2 .promo-btn-wrap {
		margin-top: 40px !important;
	}
	
	.tools-logged-new-2 {
		margin-top:0 !important;
		padding-top:20px !important;
	}
	
	.tools-new-2-logged-1 {
		background-size:cover;
		padding-bottom:160px;
	}
	
	.tools-new-2-logged-2-row-1 h2 {
		font-size:20px;
	}
	
	.tools-new-2-logged-3 {
		margin-top: -120px !important;
		padding-top: 120px;
		padding-bottom: 60px;
	}
	
	.tools-new-2-logged-3-box {
		padding:40px;
	}
	
	.tools-new-2-logged-3-box p {
		font-size:15px;
	}
	
	.tools-new-2-logged-3-box ul li {
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}
	
	.w-sponsor-header .pd-1-curve {
		margin-top:340px;
	}
	
	.tools-new-2-logged-3-box h2 {
		font-size:22px !important;
	}
	
	.w-sponsor-header .promo-1 {
		margin-top:140px;
	}
	
	.mobile-sponsor .site-header-sponsor-for-free {
		display: block;
		position: absolute;
		left: -100px;
		top: 0;
		font-size:12px !important;
	}
	
	.site-header-sponsor-for-free::after {
		width:90px;
	}
	
	.site-header-sponsor-for-free::before {
		left: 70px;
		top: 20px;
		transform: rotate(-180deg);
		width:50px;
	}
	
	.malar-1::before {
		height:50px;
	}
	
	.malar-1 {
		padding-top:80px;
	}
	
	.malar-1-heading {
		margin-bottom:30px;
	}
	
	.malar-right-content {
		font-size:15px;
		line-height:1.4;
	}
	
	.malar-right-title {
		font-size:18px;
	}
	
	.malar-1-row {
		padding:30px 20px;
	}
	
	.malar-1 {
		margin-bottom:0;
	}
	
	.malar-right-3-col {
		flex-flow:column;
		text-align:center;
	}
	
	.malar-right-3-col p {
		text-align:center;
	}
	
	.malar-right-4-col {
		display:flex;
		flex-flow:column;
	}
	
	.malar-download a img {
		width:37px;
	}
	
	.malar-download a {
		font-size:14px;
	}
	
	.malar-download-wrap {
		padding:0;
		margin-top:40px;
	}
	
	.custom-affiliate-code-wrap-2 {
		flex-flow:column;
		margin-bottom:30px !important;
	}
	
	.custom-affiliate-code-left {
		margin-bottom:15px;
	}
	
	.pd-1-box-premium .pd-1-box-sponsor a:hover {
		color:#000;
	}
	
	.pd-1-box-premium .pd-1-box-img {
		margin-top:0;
	}
	
	.pd-1-box-premium .pd-1-box-left-text-user {
		color:#fff !important;
	}
	
	.pd-1-box-premium .pd-1-box-sponsor-right p {
		color:#fff !important;
	}
	
	.pd-1-box-premium.pd-1-box {
		padding-top:20px !important;
	}
	
	.pd-1 {
		margin-top:160px;
	}
	
	.pd-1-box-premium .pd-1-box-img {
		width:200px;
		height:200px;
	}
	
	.eo-2-row-2 .flex {
		flex-flow:column;
		margin-top:40px !important;
	}
	
	.eo-2-row-2 .pr-3 {
		padding-right:0;
	}
	
	.eo-2-row-2 .img-wrap {
		margin-top:20px;
	}
	
	.eo-2-row-2 .mw-45 {
		max-width:100%;
	}
	
	.eo-2-btn {
		padding:10px 40px;
		font-size:16px;
	}
	
	.eo-2-row-2 br {
		display:none !important;
	}
	
	.eo-2-row {
		padding:40px 20px;
	}
	
	.eo-2-row-text {
		margin-top:40px !important;
		font-size:16px !important;
	}
	
	.eo-2-col-img {
		order: 1;
	}
	
	.eo-2-col-content {
		order: 2;
	}
	
	.eo-2-row-2-exit {
		margin-bottom: 10px;
		margin-top: 40px;
	}
	
	.eo-2-row-2-heading .img-wrap {
		width: 150px;
		left: 0;
		top: 0;
		position: relative;
		max-width: unset;
		margin: 0;
	}
	
	.eo-2-row-2 .flex.m-mt-0 {
		margin-top:0 !important;
	}
	
	.eo-2-row-text {
		margin-bottom:0 !important;
	}
	
	.eo-2-option-premium {
		left: 15px;
		top: 85px;
	}
	
	.eo-2-row-inner {
		padding-left:20px;
		padding-right:20px;
	}
	
	.pledge-1 .row .col:first-of-type img {
    width: 100px;
  }

  .pledge-1 .row .col:nth-of-type(2) img {
    width: 75px;
  }

  .pledge-1 .row .col:nth-of-type(3) img {
    width: 100px;
  }

  .pledge-2-heading {
    font-size: 32px !important;
  }

  .pledge-2-text {
    font-size: 35px !important;
  }

  .pledge-2 {
    padding: 10rem 0 11rem;
    /* /wp-content/uploads/2023/03/banner-pledge-2-mobile.jpg */
    background-image: url('');
    background-size: cover;
    background-position: center;
  }

  .pledge-3-heading {
    font-size: 32px !important;
  }

  .pledge-3-subheading {
    font-size: 28px !important;
  }

  .pledge-3-box-img {
    left: 0;
    top: 0;
    position: relative;
    margin-top: -60px;
  }

  .pledge-3-box {
    padding: 20px;
    flex-flow: column;
  }

  .pledge-3-box-text {
    margin-top: 20px;
	 font-size:22px !important;
  }

  .pledge-3-text br {
    display: block !important;
    margin-top: 20px;
  }

  .pledge-3-text {
    max-width: 70%;
  }

  .pledge-3-box {
    margin-bottom: 60px;
  }

  .pledge-3 {
    padding-bottom: 4rem;
  }

  .pledge-4-time-col-text {
    font-size: 9px;
  }

  .pledge-4-time-col-num {
    font-size: 30px;
  }

  .pledge-4-time-sep {
    font-size: 20px;
  }

  .pledge-4-time-col {
    padding: 0 15px;
  }

  .pledge-4-time {
    padding: 30px 20px;
    margin-top: 0;
  }

  .pledge-form-field {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .pledge-form {
    margin-top: 40px;
  }

  .pledge-form-field label {
    font-size: 20px;
  }

  .pledge-form-field input {
    height: 55px;
    font-size: 16px;
  }

  .pledge-form-field {
    margin-bottom: 20px;
  }

  .pledge-group-btn {
    margin-top: 15px;
    padding: 0 15px;
  }

  .pledge-form-button {
    width: 100%;
  }

  .pledge-4 {
    padding: 0 15px 40px 15px;
  }

  .pledge-4 .col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
	
	.sign-up-form-2 {
		flex-flow:column;
	}
	
	.sign-up-form-2  .cwa-popup-form-1 {
		flex-flow:column;
	}
	
	.cwa-popup-form-header-v2 {
		padding:3rem;
	}
	
	.cwa-popup-form-header-v2 .cwa-popup-form-img-wrap {
		flex-flow:row;
	}
	
	.cwa-popup-form-header-v2 .cwa-popup-request-star-2 {
		flex-flow:column;
	}
	
	.cwa-popup-form-header-v2 .img-wrap {
		margin-bottom: 1rem;
		margin-right: auto;
		margin-left: auto;
	}
	
	.cwa-popup-form-body-2 .cwa-popup-form-body-img img {
		margin-top:-20px;
		margin-left:auto;
		margin-right:auto;
		display:block;
	}
	
	.sign-up-form-2 {
		margin-top:0 !important;
	}
	
	.sign-up-form-2 .cwa-field-row {
		display:flex;
		flex-flow:column;
	}
	
	.sign-up-form-2 .cwa-ship-inner-col label {
		width:100%;
		text-align:left;
		margin-bottom:0.5rem;
	}
	
	.sign-up-form-2 input[type="text"], 
	.sign-up-form-2 input[type="email"], 
	.sign-up-form-2 select {
		width:100%;	
		height:48px;
	}
	
	.cwa-popup-form-2 {
		margin-top:1rem;
	}
	
	.m-font-15 {
		font-size:15px !important;
	}
	
	.m-font-62 {
		font-size:62px !important; 
	}
	
	.m-font-36 {
		font-size:36px !important;
	}
	
	.m-font-54 {
		font-size:54px !important;
	}
	
	.jko-time-col-text {
		font-size:12px;
	}
	
	.jko-time-col-num {
		font-size:36px;
	}
	
	.jko-time-col {
		padding: 1rem;
	}
	
	.jko-1 {
		padding-bottom:1rem;
	}
	
	.jko-tbl-ovato {
		width:175px;
		margin-left: -87px;
	}
	
	.jko-white-box {
		margin-top:2rem;
		padding-top:6rem;
	}
	
	.jko-tbl-col {
		text-align:left;
	}
	
	.jko-white-box {
		padding-left:2rem;
		padding-right:2rem;
	}
	
	.m-font-29 {
		font-size:29px !important;
	}
	
	.jko-tbl-row {
		margin-top:0;
	}
	
	.jko-btn-wrap a {
		font-size:16px;
		padding: 1rem 2rem;
	}
	
	.jko-4 {
		background-size:cover;
	}
	
	.jko-4 .container::before {
		right: 25px;
		top: -90px;
		width: 75px;
		height: 75px;
	}
	
	.m-font-28 {
		font-size:28px !important;
	}
	
	.m-font-32 {
		font-size:32px !important;
	}
	
	.jko-5 {
		padding-top:2rem;
	}
	
	.jko-easy-img-1 {
		width:300px;
	}
	
	.site-header-sponsor-right {
		margin-top:1rem;
	}
	
	/* Sign Up V3 Popup */
	.signup-v3-popup .cwa-popup-form-header {
		background-size: 100% 200px;
	}
	
	.cwa-v3-left-heading {
		margin-top:140px !important;
		font-size:32px !important;
	}
	
	.cwa-v3-congrats {
		font-size:18px !important;
	}
	
	.cwa-v3-by a {
		font-size:16px;
	}
	
	.suf-row-file-group {
		display:flex;
		flex-flow:row wrap;
	}
	
	.signup-v3-popup .cwa-popup-wrap {
		max-width:100%;
		width:100%;
	}
	
	/* Ty Popup */
	#thank-you-popup .cwa-popup-form-header {
		padding: 3rem;
		order: 2;
		border-radius: 0;
		min-height: 378px;
	}
	
	#thank-you-popup h2 {
		font-size:32px !important;
		line-height:1.2;
	}
	
	#thank-you-popup .cwa-popup-form-header::after {
		background-size:cover;
	}
	
	.ty-popup-header::before {
		background-position:center;
		background-size:cover;
        /* /wp-content/uploads/2023/09/bg-ty-popup-right-mobile.png */
		background-image:url('');
		width:300px;
	}
	
	.ty-popup-header p {
		font-size: 18px;
		max-width: 200px !important;
	}
	
	.ty-popup-header {
		position: relative;
		min-height: 65px;
		padding: 6rem 2rem 1.5rem 2rem;
	}
	
	#thank-you-popup .cwa-popup-form-body {
		border-radius:20px 20px 0px 0px;
		overflow:hidden;
	}
	
	#thank-you-popup .cwa-popup-form-header::after {
        /* /wp-content/uploads/2023/09/ty-popup-left-mobile.png */
		background-image:url('');
		height: 245px;
		width: 100%;
	}
	
	#thank-you-popup .cwa-popup-wrap {
		max-width:95%;
	}
	
	.site-header-upper-new p {
		font-size:12px !important;
		letter-spacing:1px !important;
	}
	
	.site-header.active .site-header-upper-new p {
		font-size:10px !important;
	}
	
	.home-1-right-col {
		width: 300px !important;
		max-width: 300px !important;
		height: 300px !important;
		padding: 2rem !important;
	}
	
	.home-1-right-col-new::before,
	.home-1-right-col-new::after {
		display:none;
	}
	
	.home-1-right-col-new .n-font-28 {
		font-size:18px !important;
		margin-bottom:0.5rem !important;
	}
	
	.home-1-right-col-new .n-font-16 {
		font-size:10px !important;
	}
	
	.new-home-2-rating-wrap-2 p {
		font-size:10px !important;
	}
	
	.new-home-2-rating-wrap-2 img {
		width:100px;
	}
	
	.new-home-2-new {
		min-height:825px;
	}
	
	.new-home-2-dl-wrap-2 {
		flex-flow:row;
	}
	
	.new-home-2-dl-wrap-2 img {
		width:75px;
	}
	
	.new-home-2-rating-wrap {
		margin-top:0.5rem;
	}
	
	.new-home-2-dl-wrap-2 .img-wrap {
		margin-bottom:0;
	}
	
	.new-home-2-dl-wrap-2 {
		margin-top:1rem;
	}
	
	.new-home-2-new {
		background-image:url('./assets/img/home/home-banner-mobile1.png');
		background-position:center bottom;
		background-size:cover;
	}
	
	.new-banner-img {
		width:277px;
		left: 50%;
		margin-left: -137.5px;
		top: 140px;
	}
	
	.new-banner-img {
		left: 50%;
		margin-left: -137.5px;
	}
	
	.new-home-2-new {
		min-height: 625px;
		margin-top: 140px;
	}
	
	.new-home-3-row-new {
		padding-top:0rem;
		display:grid !important;
		grid-template-columns:1fr 1fr !important;
	}
	
	.new-home-3-row-new .new-home-3-col-text-1 {
		font-size:28px;
	}
	
	.new-home-3-row-new  .new-home-3-col-text-2 {
		font-size:14px;
	}
	
	.ul-checklist li {
		font-size:15px;
	}
	
	.new-home-4-new .new-home-4-img-text-wrap:nth-of-type(1) {
		margin-top:1rem;
	}
	
	.new-home-44 {
		background-image:url('./assets/img/home/bg-ecosystem-mobile.png');
	}
	
	.double-underline-3::after {
		width:300px;
	}
	
	.new-home-44 h2 {
		font-size:24px !important;
	}
	
	html body .new-home-55 {
		margin-top: -100px !important;
		padding-top: 20px !important;
	}
	
	.m-font-20 {
		font-size:20px !important;
	}
	
	.new-home-33 {
		padding-bottom:0rem;
	}
	
	.opportunity-left-col {
		padding-right:0 !important;
	}
	
	.opportunity-left-col img {
		width:100%;
	}
	
	/* HIW */
	.hiw-1 h1 {
		font-size:28px !important;
	}
	
	.hiw-1 {
		padding-top:2rem;
		padding-bottom:1rem;
	}
	
	.hiw-1-text {
		font-size: 20px !important;
		line-height: 1.2;
		max-width: 300px;
		margin: 0 auto;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-top: 1rem;
	}
	
	.hiw-1-ul {
		flex-flow: column;
		justify-content: flex-start;
		text-align: left;
		align-items: flex-start;
	}
	
	.hiw-1-ul li {
		margin-bottom:1rem;
	}
	
	.hiw-2 {
        /* /wp-content/uploads/2024/01/bg-full-circle.png */
		background-image:url('');
		background-size:100% 100%;
		padding-bottom:2rem;
		padding-top:8rem;
	}
	
	.hiw-2 .n-font-39 {
		font-size:28px !important;
	}
	
	.hiw-2 .n-font-25 {
		font-size:18px !important;
	}
	
	.hiw-2-box h3 {
		font-size:22px;
	}
	
	.hiw-2-box p {
		font-size:16px;
	}
	
	.hiw-2 .img-wrap {
		position:relative;
		left:40px;
	}
	
	.hiw-3 {
		margin-bottom:0;
	}
	
	.hiw-3 p {
		font-size:16px !important;
	}
	
	.hiw-3 p br {
		display:none !important;
	}
	
	.hiw-3 h2 {
		font-size:32px !important;
	}
	
	.hiw-3-tbl-col {
		display:flex;
	}
	
	.hiw-3-tbl-col span {
		flex: 1;
		padding:1rem;
	}
	
	.hiw-3-tbl-col-th {
		background: rgb(27,36,106);
		background: linear-gradient(45deg, rgba(27,36,106,1) 0%, rgba(55,199,240,1) 100%); 
		color:#fff;
		font-size:14px;
		font-family:'Poppins', sans-serif;
		font-weight:600;
		text-align:left;
	}
	
	.hiw-3-tbl-row .hiw-3-tbl-col {
		background:#FFFFFF;
	}
	
	.hiw-3-tbl-row .hiw-3-tbl-col:nth-of-type(even) {
		background:#F9F9F9;
	}
	
	.hiw-3-tbl-col-td {
		font-size:16px;
		font-family:'Poppins', sans-serif;
		font-weight:600;
		color:#777777;
	}
	
	/* NFT */
	.nft-1 p {
		font-size: 16px !important;
		line-height: 1.4;
	}
	
	.nft-2 h1 {
		font-size:28px !important;
		margin-bottom:2rem;
	}
	
	.nft-2 {
		padding-top:3rem;
        /* /wp-content/uploads/2024/01/bg-banner-nft-mobile.png */
		background:no-repeat center url('');
		background-size:100% 100%;
		min-height: 1000px;
	}
	
	.nft-3 h2 {
		font-size:24px !important;
	}
	
	.nft-3-left-col .img-wrap {
		margin-top: -5rem;
	}
	
	.nft-3-row {
		margin-top:5rem !important;
	}
	
	.nft-3 {
		margin-bottom:0;
	}
	
	.nft-3-mid-col, .nft-3-right-col {
		padding:2rem 1.5rem 1.5rem 1.5rem !important;
	}
	
	.nft-3-row h4 {
		font-size:15px;
	}
	
	.nft-3-row ul li {
		font-size:13px;
	}
	
	/* Tools New */
	.tools-3 {
		background:#fff;
		padding-top:0;
		margin-bottom:0;
	}
	
	.tools-3-col-heading {
		font-size:18px !important;
	}
	
	.tools-3-num {
		left: 50%;
		top: -20px;
		margin-left: -25.5px;
	}
	
	.tools-3-col {
		padding: 4rem 2rem 2rem 2rem !important;
	}
	
	.tools-3-col p {
		font-size:17px;
	}
	
	.mw-1 {
		padding-top:140px;
	}
	
	/* Get Started 2 */
	.get-started-box-header {
		padding:2rem;
	}
	
	.get-started-box-header h2 {
		font-size:24px;
	}
	
	.get-started-box-header p {
		font-size:18px;
	}
	
	.get-started-box-body .ul-checklist li {
		font-size:16px;
	}
	
	.get-started-box-body {
		padding:2rem;
	}
	
	.get-started-box-body-choices {
		grid-template-columns:1fr;
	}
	
	.get-started-box-body-choices label {
		width:100%;
		text-align:left;
	}
	
	.get-started-box-body-choices {
		row-gap: 0.5rem;
		max-width: 180px;
		margin: 0 auto;
		margin-top: 0px;
		margin-top: 2rem !important;
	}
	
	.get-started-1 .tools-3-subheading {
		font-size:18px !important;
	}
	
	.get-started-1 {
		background: rgb(224,248,255);
		background: linear-gradient(180deg, rgba(224,248,255,1) 0%, rgba(255,255,255,1) 100%);
		padding-top:2rem;
		margin-top:9rem;
	}
	
	.get-started-box-1 {
		margin-top:2rem;
	}
	
	.wc-popup-form input {
		font-size:14px;
		height:45px;
	}
	
	.wc-popup-form button {
		width:36px;
		height:36px;
		right: 6px;
		top: 4px;
	}
	
	.wc-popup-header .font-14 {
		font-size:12px !important;
	}
	
	.gs-step-text {
		font-size:10px;
		margin-top:0.5rem;
	}
	
	.gs-step-num {
		width:30px;
		height:30px;
		font-size:14px;
	}
	
	.gs-step-status span {
		font-size:9px;
	}
	
	.gs-step-status span::before {
		width:10px;
		height:10px;
	}
	
	.gs-step-status-complete span::before {
		width:10px;
	}
	
	.gs-step-wrap::after {
		margin-top:-5px;
	}
	
	.gs-sml-row .col {
		max-width:100%;
		flex: 0 0 100%;
	}
	
	.gs-sml-level {
		max-width: 100%;
		flex: 0 0 100%;
		text-align: center;
	}
	
	.gs-sml-row {
		margin-top:0rem !important;
	}
	
	.new-home-2-rating-wrap-3 img {
		width: 75px;
	}
	
	.home-1-right-col-new-2 li {
		font-size:11px;
		padding-left:1.5rem;
		margin-bottom: 0.25rem;
	}
	
	.home-1-right-col-new-2 .ul-checklist li::before {
		width:12px;
		height:12px;
	}
	
	.home-1-right-col-new-2 .n-font-28 {
		font-size:16px !important;
	}
	
	.home-1-right-col-new-2 .ul-checklist {
		margin-bottom:0;
	}
	
	.new-home-2-dl-wrap-2 img {
		width:65px;
	}
	
	.new-home-2-dl-wrap-2 {
		margin-top:0.5rem;
	}
	
	.home-1-right-col-new .n-font-16 {
		font-size: 11px !important;
		margin-bottom: 0.25rem;
	}
	
	.new-home-2-rating-wrap-3 p.n-font-16 {
		margin-bottom:0 !important;
	}
	
	.v2-mw-1 {
		background-image:url('./assets/img/compensation-plan/bg-compensation-mobile.jpg') !important;
		padding-bottom:220px;
	}
	
	.c-video-play-text img {
		width:68px;
	}
	
	.c-video-play-text {
		right: auto;
		left: 50%;
		top: -50px;
		margin-left: -44px;
	}
	
	.c-video-wrap {
		margin-top:4rem;
	}
	
	.v2-mw-2 {
		margin-top:-100px;
	}
	
	.v2-nft-2 {
		background:no-repeat center url('./assets/img/nft/mobile-banner-nft-2.png');
		background-size:100% 100%;
		min-height: 900px;
	}
	
	.c-video-play-text-bottom {
		top: auto;
		margin-left: 20px;
		bottom: -100px;
	}
	
	.c-video-play-btn-full img {
		width:50px;
		height:50px;
	}
	
	.c-video-wrap-full {
		margin-top:2rem;
	}
	
	html body .m-p-2 {
		padding: 2rem !important;
	}
	
	.m-font-16 {
		font-size:16px;
	}
	
	.rules-1 .img-wrap img {
		width:275px;
	}
	
	.top-curve-7::before {
		height:41px;
	}
	
	.rules-2 {
		margin-bottom:0;
	}
	
	.m-mw-100 {
		max-width:100% !important;
	}
	
	.m-pt-5 {
		padding-top:5rem !important;
	}
	
	.rules-box-ul {
		padding-left:0;
	}
	
	.rules-box-ul li {
		font-size:16px;
	}
	
	.rules-box {
		margin-top:4rem;
	}
	
	.rules-box-h2 span {
		font-size:22px;
	}
	
	.fi-popup-body {
		padding:2rem 1.5rem;
	}
	
	/* Max Width */
	.v2-m-mw-80 {
		max-width:80% !important;
	}

	.v2-m-mw-90 {
		max-width:90% !important;
	}

	.v2-m-mw-100 {
		max-width:100% !important;
	}

	/* Padding Horizontal */
	html body .v2-m-px-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	/* Paddings */
	.v2-m-pt-10 {
		padding-top: 10rem !important;
	}

	html body .v2-m-pb-2 {
		padding-bottom: 2rem !important;
	}

	html body .v2-m-pb-3 {
		padding-bottom: 3rem !important;
	}
	
	/* Fonts */
	.v2-m-font-14 {
		font-size:14px !important;
	}

	html body .v2-m-font-17 {
		font-size:17px !important;
	}
	
	.v2-m-font-16,
	.v2-m-font-16 li {
		font-size:16px !important;
	}
	
	.v2-m-font-18 {
		font-size:18px !important;
	}
	
	html body .v2-m-font-20 {
		font-size:20px !important;
	}

	.v2-m-font-22 {
		font-size:22px !important;
	}
	
	.v2-m-font-28 {
		font-size:28px !important;
	}
	
	.v2-m-font-32 {
		font-size:32px !important;
	}

	/* Padding */
	.v2-m-pl-1 {
		padding-left: 1rem !important;
	}
	
	.fi-popup-header-main {
		padding:1rem;
		text-align:center;
	}
	
	.fi-popup-header {
		background:#3274B3 no-repeat center top url('./assets/img/popup/mobile-fi-popup-header.png');
		background-size: cover;
		padding-top: 8rem;
		border-radius: 30px 30px 0 0;
	}
	
	.tools-4-link-wrap .custom-affiliate-title {
		text-align:center;
	}
	
	.tools-4-link-wrap {
		padding:2rem;
	}
	
	.tools-4-link-wrap .custom-affiliate-code-count {
		margin-top:1rem;
	}
	
	.tools-4-link-wrap .custom-affiliate-code {
		margin-top:1rem;
	}
	
	.tools-4-logged-in .tools-3-col {
		padding-bottom:2rem;
	}
	
	.tools-4-ebooks-row .col-sm-4 {
		max-width:100% !important;
		flex:0 0 100% !important;
		margin-top:1rem !important;
	}
	
	.tools-4-logged-download .malar-download a {
		font-size:10px;
	}
	
	.tools-4-logged-download {
		margin-top:1rem;
	}
	
	.tools-4-logged-download .malar-download a img {
		width:30px;
	}
	
	/* Margins */
	.v2-m-mb-0 {
		margin-bottom: 0 !important;
	}
	
	.v2-m-mt--6 {
		margin-top:-6rem !important;
	}

	.v2-m-mt--4 {
		margin-top:-4rem !important;
	}

	.v2-m-mt--2 {
		margin-top:-2rem !important;
	}

	.v2-m-mt-0 {
		margin-top:0 !important;
	}

	html body .v2-m-mt-1 {
		margin-top:1rem !important;
	}

	.v2-m-mt-1-5 {
		margin-top: 1.5rem !important;
	}
	
	.v2-m-mt-2 {
		margin-top:2rem !important;
	}

	.v2-m-mt-3 {
		margin-top:3rem !important;
	}

	.v2-m-mt-4 {
		margin-top:4rem !important;
	}

	.v2-m-mt-14 {
		margin-top:14rem !important;
	}

	/* Text Align */
	.v2-m-text-center {
		text-align: center !important;
	}

	/* Widths */
	.v2-m-col-12 {
		max-width: 100% !important;
		flex: 0 0 100% !important;
	}
	
	/* WOC */
	.woc-2 {
		margin-bottom:0 !important;
	}
	
	/* HIW1 V2 */
	.hiw-1-v2 {
        /* /wp-content/uploads/2024/05/bg-how-it-works-banner.png */
		background:no-repeat center url('./assets/img/how-it-works/bg-how-it-works-banner.png') !important;
		background-size:100% 100% !important;
		padding-bottom:4rem !important;
	}
	
	.hiw-3-v2 {
		margin-top:-8rem !important;
	}

	.new-home-4-new h3 {
		margin-bottom: 5px !important;
	}

	.site-header .container {
		padding:0 !important;
	}

	.new-last-section {
		margin-bottom: 0rem !important;
	}

	.first-row {
		padding-top: 167px;
	}

	.v2-c-video-wrap {
		margin-top: 2rem;
	}

	.v2-comp-plan-2 {
		margin-bottom: 0 !important;
	}
	
} /* END 600px */