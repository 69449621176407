.v2-c-video-wrap .c-video-play-text img {
    width: 125px;
}

.v2-c-video-wrap .c-video-play-text {
    right: 0;
    top: auto;
    z-index: 2;
    bottom: -60px;
}

.v2-c-btn {
    min-width: 1px;
    font-size: 32px;
    text-transform: none;
}

/* Comp 2 */
.v2-comp-plan-2 {
    position: relative;
    background-color: #ecfbff;
    height: 1033px;
    margin-bottom: -40rem;
    margin-top: -34rem;
}

.v2-comp-plan-2::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 133px;
    background: no-repeat center url('../img/compensation-plan-2/img-curve.jpg');
    background-size: cover;
}

@media (max-width:1199px) {
    .v2-c-btn {
        font-size: 24px;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .v2-c-btn {
        font-size: 28px;
    }

    .v2-c-video-wrap .c-video-play-text img {
		width: 100px;
	}

    .v2-c-video-wrap .c-video-play-text {
        right: 0;
        top: auto;
        z-index: 2;
        bottom: -40px;
        left: auto;
    }

    .v2-comp-plan-2  {
        margin-bottom: -50rem !important;
    }
}
/* END 910px */

@media (max-width:600px) {
    .v2-comp-plan-2 {
        height: auto;
        margin-top: 0 !important;
        padding-bottom: 20rem;
        margin-bottom: -20rem !important;
    }

    .v2-comp-plan-2::before {
        height: 35px;
    }

    .v2-c-btn {
        font-size: 18px;
        font-weight: 600;
        min-width: 1px;
        padding: .9rem 4rem;
    }

}
/* END 600px */