.v2-get-started-1 {
    background: #EEFCFD  no-repeat center bottom url('../img/get-started-2/bg-banner.png');
    background-size: 100% 100%;
    padding-top: 6rem;
    padding-bottom: 14rem;
    margin-top: 10rem;
}

.v2-gs-status-main {
    margin-top: -1rem;
}

.v2-gs-row {
    background: rgb(27,36,106);
    background: linear-gradient(200deg, #2F9DCD 0%, #2870A9 100%);
    max-width: 1000px !important;
}

.v2-gs-col {
    border-right: 1px solid #fff;
}

.v2-gs-col:last-of-type {
    border-right: 0;
}

.v2-gs-row {
    max-width: 1200px;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 15px;
}

.v2-gs-status-subtext {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-top: 0.75rem;
}

.v2-gs-status-wrap {
    border-radius: 50px;
    background-color: #FFEDD5;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 1.5rem;
}

.v2-gs-col-complete.v2-gs-status-wrap {
    background-color: #E9F9DD;
}

.v2-gs-status-num {
    border-radius: 50px;
    width: 38px;
    height: 38px;
    border: 1px solid #C11D52;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #C11D52;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}

.v2-gs-status-text {
    font-size: 16px;
    font-weight: 600;
    color: #EEA33D;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
}

/* Current */
.v2-gs-status-text-active::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: no-repeat center url('../img/get-started-2/icon-todo.png');
    background-size: contain;
    margin-right: 0.75rem;
}

/* Completed */
.v2-gs-status-text-completed {
    color: #6FA843;
}

.v2-gs-status-text-completed::before {
    content: '';
    display: block;
    background: no-repeat center url('../img/get-started-2/icon-completed.png');
    background-size: contain;
    display: block;
    width: 25px;
    height: 22px;
    margin-right: 0.5rem;
}

.v2-gs-col {
    padding:0rem 2rem 1rem 2rem !important;
}

/* GS Box */
.v2-get-started-2 {
    background: #f2fcfb;
    padding-bottom: 20rem;
}

.v2-gs-box {
    background-color: #FFFFFF;
    box-shadow: 0 24px 23px rgba(97, 164, 190, .13);
    border-radius: 25px;
    padding: 0.75rem;
    position: relative;
}

.v2-gs-box-header {
    background-color: #DCEDF5;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #1B246A;
    text-align: center;
    padding: 0.75rem;
}

.v2-gs-box-body div {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #3E3E3E;
    text-align: center;
    margin-top: 0.25rem;
}

.v2-gs-box-body {
    padding: 1rem 1rem 1.5rem 1rem;
}

.v2-gs-box-starting::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 96px;
    height: 96px;
    background: no-repeat center url('../img/get-started-2/img-starting-soon.png');
    background-size: contain;
}

/* Box Available */
.v2-gs-box-available::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 66px;
    height: 66px;
    background: no-repeat center url('../img/get-started-2/img-available.png');
    background-size: contain;
}

.v2-gs-box-running::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -85px;
    width: 106px;
    height: 117px;
    background: no-repeat center url('../img/get-started-2/img-running-now.png');
    background-size: contain;
}

/* Blue Box */
.v2-gs-blue-box {
    background: rgb(27,36,106);
    background: linear-gradient(210deg, #1B246A 0%, #2C8BBE 100%); 
}

.v2-gs-blue-box .v2-gs-box-header {
    background: linear-gradient(45deg, #2666A1 0%, #309DCD 100%); 
    color: #fff;
}

.v2-gs-blue-box .v2-gs-box-body div {
    color: #fff;
}

.v2-gs-2-container {
    max-width: 1000px !important;
    margin:0 auto !important;
}

.v2-gs-big-box {
    position: relative;
    z-index: 2;
    transform: scale(1.5);
}

/* Get Started 2 */
.v2-gs-2-row-2 {
   position: relative;
   z-index: 2;
}

/* Get Started 3 */
.v2-get-started-3 {
    position: relative;
    background-color: #fff;
    margin-bottom: -14rem;
}

.v2-get-started-3::before {
    content: '';
    display: block;
    width: 100%;
    height: 199px;
    background: no-repeat center url('../img/get-started-2/img-top-curve-2.jpg');
    background-size: cover;
}

.v2-get-started-3 .gs-sml-header {
    text-align: left;
    padding-left: 3rem;
    background: linear-gradient(145deg, #1B246A 0%, #2E92C4 100%);
}

.v2-get-started-3 .gs-sml-header-ovo {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255,255,255,.41)
}

.v2-get-started-3 .gs-sml-header-title {
    font-size: 35px;
}

.v2-get-started-3 .gs-sml-body-main {
    background: linear-gradient(145deg, #1B246A 0%, #276DA6 100%);
    overflow: hidden;
}

.v2-get-started-3 .gs-sml-main {
    overflow: hidden;
}

.v2-get-started-3 .gs-sml-level-title {
    color: #fff;
}

.v2-get-started-3 .gs-sml-ul li {
    color: #fff;
}

.v2-get-started-3 .gs-sml-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.v2-get-started-3 .site-btn-pink {
    background: rgb(238,163,61);
    background: linear-gradient(201deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%); 
}

.v2-get-started-3 .site-cta {
    color: #4EC4F3;
}

.v2-get-started-3 .site-cta:hover {
    color: #fff;
}

.v2-get-started-3 .gs-sml-row {
    margin-top: -19rem !important;
}

@media (max-width:1199px) {
    .v2-get-started-3 .gs-sml-header-title {
        font-size: 28px;
    }

    .v2-gs-big-box {
        transform: scale(1.3);
    }
}
/* END 1199px */

@media (max-width:910px) {
    .v2-get-started-1 {
        background-size: cover;
    }

    .v2-gs-row {
        flex-flow: row;
    }

    .v2-gs-col {
        flex: 1 !important;
        max-width: unset !important;
        width: unset !important;
    }

    .v2-gs-status-text {
        font-size: 14px;
    }

    .v2-gs-status-subtext {
        font-size: 13px;
    }

    .v2-gs-big-box {
        transform: unset;
    }

    .v2-gs-box-available::before {
        width: 96px;
        height: 96px;
    }

    .v2-gs-blue-box .v2-gs-box-header {
        font-size: 24px;
    }

    .v2-gs-blue-box .v2-gs-box-body div {
        font-size: 22px;
    }

    .v2-get-started-2 {
		padding-bottom: 8rem;
	}
}
/* END 910px */

@media (max-width:600px) {
    .v2-get-started-3 {
        margin-bottom: 0 !important;
    }

    .v2-get-started-1 {
        background: no-repeat center bottom url('../img/get-started-2/mobile-banner.png') !important;
        background-size: cover !important;
        padding-bottom: 13rem;
        padding-top: 4rem;
        padding-top: 2rem;
        margin-top: 8rem;
    }

    .v2-gs-col {
        border:0;
    }

    .v2-gs-status-main {
        margin-top: 0;
    }

    .v2-gs-row {
        padding-top: 0.75rem;
        padding-bottom: 1rem;
    }

    .v2-gs-col {
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
        padding-top: 1.5rem !important;
    }

    .v2-gs-col:last-of-type {
        border:0 !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .v2-gs-status-subtext {
        font-size: 15px;
    }

    .v2-gs-box-running::after {
        width: 150px;
        height: 150px;
        right: auto;
        left: 50%;
        margin-left: -55px;
        bottom: -115px;
    }

    .v2-get-started-3 .gs-sml-header {
        padding-left: 1.5rem;
    }


}
/* END 600px */