.footer {
    background: no-repeat center url('../img/footer/bg-footer-.png');
    background-size: 100% 100%;
    padding-top: 30rem;
    z-index: 2;
    position: relative;
}

.footer-img-ovato {
    width: 279px;
    margin-left: -35px;
    margin-bottom: -15px;
    margin-top: -100px;
}

/* Footer Menu */
.footer-menu-title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.3;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.footer-menu {
    padding:0;
    margin:0;
}

.footer-menu li {
    list-style-type: none;
}

.footer-menu li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 10px;
    display: inline-flex;
}

.footer-menu li a:hover {
    color: #eea33d;
}

/* Footer Form */
.footer-form input {
    height: 45px;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 15px;
    border: 1px solid #fff;
    width: 100%;
    display: flex;
    max-width: 100%;
    background: transparent;
}

.footer-form button {
    height: 49px !important;
    padding: 0 !important;
    width: 100% !important;
    font-size: 18px !important;
    letter-spacing: 1px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    border-radius: 50px;
    text-transform: uppercase;
    border:0;
    background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%);
    transition: all 0.3s ease;
    color:#fff;
    margin-top: 0.75rem;
}

.footer-form button:hover {
    transform: scale(1.01);
}

/* Footer Lower */
.footer-lower {
    background: rgba(0,0,0,0.09);
    margin-top: 60px;
}

.footer-copyright {
    padding:0;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-copyright li {
    font-weight: 300;
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
    list-style-type: none;
    margin:1.25rem 0;
}

.footer-copyright li::after {
    content: '|';
    color: #fff;
    margin: 0 15px;
}

.footer-copyright li:last-of-type:after {
    display: none;
}

.footer-copyright li a {
    text-decoration: none;
    color: #fff;
}

.footer-copyright li a:hover {
    color: rgba(255,255,255,.8);
}

@media (max-width:1199px) {
    .footer-menu-title {
        font-size: 16px;
    }

    .footer-img-ovato {
        width: auto;
        margin-left: 0;
    }

    .footer-form button {
        font-size: 14px !important;
    }

    .footer {
        background-size: cover;
        padding-top: 26rem;
    }

    .footer-lower {
        margin-top: 4rem;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .footer-img-ovato {
        margin:0 auto;
        width: 200px;
    }

    .footer {
        padding-top: 130%;
        background-position: center top;
    }

    .footer-menu-title {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .footer-menu-wrap {
        margin-top: 2rem;
    }

    .footer-form {
        margin-top: 1.5rem;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-lower {
        margin-top: 2rem;
    }

    .footer-copyright {
        flex-flow: column;  
        margin: 1rem 0 1.25rem;
    }

    .footer-copyright li:after {
        display: none;
    }

    .footer-copyright li {
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    .footer-img-ovato {
        margin-top: 2rem;
    }
}
/* END 910px */

@media (max-width:600px) {
    .footer {
        background: no-repeat center top url('../img/footer/bg-footer-mobile-new.png');
        background-size: cover;
        padding-top: 8rem;
    }

    .footer-form {
        max-width: 300px;
    }
}
/* END 600px */