/* Header */
.v2-discover-main .site-header {
    z-index: 1;
}

.v2-discover-main .new-home-2-new {
    margin-top: 39px;
}

.v2-discover-main .footer-lower {
    background: #DCECEE !important;
}

/* Section 1 */
.v2-discover-1 {
    z-index: unset;
    position: static;
}

.v2-discover-1 .home-1-right-col-new::before {
    display: none;
}

.v2-discover-1 .home-1-right-col-new::after {
    display: none;
}

.v2-discover-1 .home-1-row-2 {
    position: relative;
    z-index: 2;
}

.v2-discover-1 .container {
    z-index: unset;
    position: static;
}

.v2-discover-1 .new-banner-img {
    position: relative;
    margin: 0 0 0 auto;
    top: 0;
    left: 250px;
    width: 350px;
}

.v2-discover-1 .home-1-right-col-new-2 {
    height: 575px;
    width: 564px !important;
}

.v2-discover-1 {
    background-image:url('../img/discover2/bg-01.jpg');
}

.v2-discover-1 .discover-play-img img {
    width: 233px;
}

/* Section 2 */
.v2-discover-2 {
    background-color: #DCF7FF;
}

.v2-discover-2-row-2 img {
    height: 229px;
    object-fit: contain;
}

.v2-discover-2-row-2 {
    margin-top: -3rem !important;
}

.v2-discover-main .site-header.active {
    z-index: 9999999;
}

.v2-discover-play-btn {
    background-color: #EEA33D;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 114px;
    border-radius: 100%;
    border:2px solid #fff;
    outline: 6px solid #EEA33D;
    position: relative;
    margin:0 auto;
    transition:all 0.3s ease;
    animation: bgPulse 3s infinite ease-in-out;
}

.v2-discover-play-btn:hover {
    transform: scale(1.02);
}

.v2-discover-watch-video-text {
    position: absolute;
    right: -85px;
    top: -25px;
    width: 67px;
}

.v2-discover-glow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(238,163,61,.22);
    border-radius: 100%;
    animation: pulse 3s infinite;
}

.v2-discover-play-img {
    position: relative;
    z-index: 2;
}

.v2-discover-play-img img {
    width: 41px;
    margin-left: 12px;
}

@keyframes bgPulse {
    0%, 100% {
        background-color: #EEA33D; /* Base color (orange) */
        outline-color: #EEA33D;
        transform: scale(1);
    }
    50% {
        background-color: #FFD700; /* Yellow */
        outline-color: #FFD700;
        transform: scale(1.02);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width:1199px) {
    .v2-discover-main .new-home-2-new {
        margin-top: 33px;
    }

    .v2-discover-main .new-home-2-new {
        margin-top: 33px;
        height: auto;
        min-height: 671px;
    }

    .v2-discover-1 .new-banner-img {
        left: 0;
        width: 300px;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .v2-discover-1 .new-banner-img {
        margin:2rem auto 0 auto;
    }
}
/* END 910px */

@media (max-width:600px) {
    .v2-discover-main .new-home-2-new {
        margin-top: 27px;
    }

    .v2-discover-1 .discover-play-img img {
        width: 136px;
    }

    .v2-discover-1 .discover-logo-img-wrap {
        margin-top: 0;
    }

    .v2-discover-1 {
        background-image:url('../img/discover2/bg-01-mobile.png');
    }

    .v2-discover-2-row-2 {
        margin-top: 2rem !important;
    }

    .v2-discover-main .home-3-btn-wrap {
        margin-top: 2rem !important;
    }

    .v2-discover-1 .new-banner-img {
        margin-top: 10rem !important;
    }

    .v2-discover-play-btn {
        width: 72px;
        height: 72px;
    }

    .v2-discover-play-img img {
        width: 30px;
        margin-left: 10px;
    }

    .v2-discover-watch-video-text {
        right: -60px;
        top: -15px;
        width: 50px;
    }

    .v2-discover-1 .new-banner-img {
        width: 250px;
    }
}
/* END 600px */