.v3-discover-box {
    border-radius: 29px;
    position: relative;
    padding-bottom: 3rem;
}

.v3-discover-orange-box {
    background: rgb(193,29,82);
    background: linear-gradient(135deg,  rgba(238,163,61,1) 0%, rgba(193,29,82,1) 100%); 
}

.v3-discover-timer {
    border-radius: 10px;
    border: 1px solid #FADA5E;
    font-family: 'Poppins', sans-serif;
    font-size: 17.25px;
    font-weight: 600;
    color: #FADA5E;
    display: inline-flex;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    min-width: 150px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.v3-discover-timer span {
    margin-left: 0.25rem;
}

.v3-discover-bonus {
    position: absolute;
    left: 0;
    top: 0;
    width: 126.75px;
}

.v3-discover-nfts-wrap {
    padding: 0 2.5rem;
}

.v3-discover-btn {
    margin-top: 2rem !important;
    padding: 0 1rem !important;
}

.v3-discover-btn .signup-v3-btn {
    min-width: 1px;
    font-size: 18px;
    padding: 0;
    display: inline-flex;
    padding: 1rem 2.5rem;
}

/* Blue Box */
.v3-discover-blue-box {
    background-color: #CFEBF2;
}

.v3-discover-ul {
    padding:0;
    margin:0;
}

.v3-discover-ul li {
    list-style-type: none;
    text-align: left;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #1B246A;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.v3-discover-ul li::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top:7px;
    width: 11px;
    height: 8px;
    background: no-repeat center url('../img/discover-3/icon-check-pink.png');
    background-size: contain;
}

/* Time Radio Button */
.v3-radio-time-label {
    display: inline-flex;
    border: 1px solid #37C7F0;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0.5rem 1.5rem;
    min-width: 250px;
    justify-content: center;
    align-items: center;
}

.v3-radio-time-label:hover {
    opacity: 0.8;
}

.v3-radio-time-text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #1B246A;
    position: relative;
    padding-left: 2rem;
}

.v3-radio-time-text::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    width: 15px;
    height: 15px;
    background: no-repeat center url('../img/discover-3/icon-radio.png');
    background-size: contain;   
}

.v3-radio-time-input {
    position: absolute;
    visibility: hidden;
}

.v3-radio-time-input:checked + .v3-radio-time-text::before {
    background-image: url('../img/discover-3/icon-active-radio.png');
}

/* Invite Box */
.v3-discover-invite-box {
    background-color: #BDDDE8;
    border-radius: 30px;
    padding: 1.5rem 6rem 2rem 6rem;
}

/* Invite Form */
.v3-discover-invite-form input {
    border: 1px solid #B0D6E1;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    height: 39px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #333;
    display: flex;
    width: 100%;
    padding: 0 1rem;
}

.v3-discover-invite-form button {
    background: rgb(238,163,61);
    background: linear-gradient(135deg, rgba(238,163,61,1) 0%, rgba(213,130,17,1) 100%); 
    display: flex;
    border: 0;
    border-radius: 50px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 0.75rem 2rem;
    text-transform: uppercase;
    margin-top: 0.75rem;
    width: 100%;
}

.v3-discover-blue-box {
    padding: 2rem 4rem;
}

@media (max-width:1199px) {
    .v3-discover-invite-box {
        padding-left: 2rem;
        padding-right: 2rem;
        
    }

    .v3-discover-blue-box {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .v3-discover-blue-box {
        margin-top: 3rem;
    }
}
/* END 910px */

@media (max-width:600px) {
    .v3-discover-bonus {
        width: 100px;
    }

    .v3-discover-timer {
        position: static;
        margin-bottom: 1.5rem;
    }

    .v3-discover-nfts-wrap {
        padding:0 1rem;
    }

    .v3-discover-box {
        padding:2rem;
    }

    .v3-discover-invite-form button {
        font-size: 12px;
    }

    .v3-discover-orange-box {
        padding: 0 0rem 2rem 0rem;
    }
}
/* END 600px */