.bottom-nav-3 {
    display: block;
}

.bottom-nav-3 .new-bottom-main {
    background-image: url('../img/bottom-nav-3/bg-footer.png');
    position: relative;
    background-position: center top;
    background-size: cover;
    padding-top: 24rem;
}

.bottom-nav-3 .new-bottom-main::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top:-8rem;
    width: 226px;
    height: 384px;
    background: no-repeat center url('../img/bottom-nav-3/img-top-right-circle.png');
    background-size: contain;
}

.bottom-nav-3 .new-bottom-main::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom:0;
    width: 373px;
    height: 421px;
    background: no-repeat center url('../img/bottom-nav-3/bottom-left-circle.png');
    background-size: contain;
}

.bottom-nav-3  .new-bottom-main .bottom_main_head.v2-mb-0 {
    margin-bottom: 0 !important;
}

.footer-v3-partners {
    text-align: center;
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
}

.footer-v3-partners li {
    margin-bottom: 1.5rem !important;
}

.footer-v3-logo {
    filter: drop-shadow(0px 17px 17px rgba(0, 0, 0, 0.1));
}

.bottom-nav-3 .new-footer-join-newsletter-col {
    margin-top: 0 !important;
}

.bottom-nav-3 .new-bottom-main .footer-container-2{
    background: rgba(27,36,106,.65);
    position: relative;
}

.new-bottom-main .bottom_main_head {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 30px !important;
    display: block;
    color: #fff;
}

.new-bottom-main .bottom_list li a {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.new-bottom-main .bottom_list {
    line-height: 1.5;
    position: relative;
    z-index: 1;
}

.new-bottom-main .bottom_list li {
    margin-bottom: 5px;
}

.new-bottom-main .footer-container-1 {
    max-width: 1600px !important;
    padding-bottom: 40px;
    padding-top: 0;
}

.bottom-nav-3 .new-footer-join-newsletter-col {
    margin-top: 0 !important;
}

.bottom_main {
    color: white;
}

.new-bottom-main label {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.bottom_input {
    background-color: rgba(0, 0, 0, 0) !important;
    width: 100% !important;
    border-radius: 60px;
    height: 50px;
    border: 1px solid white;
    color: white;
    padding-left: 20px;
}

.bottom_input::placeholder {
    color: #fff !important;
}

.bottom_subscribe {
    background: #f69139 !important;
    font-size: 16px !important;
    width: 100%;
    height: 50px;
    margin: 4% 0%;
    border: 0px !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.new-bottom-main .bottom_storeicon {
    height: auto;
    width: 90px;
    margin-bottom: 15px;
}

.new-bottom-main .bottom_info_text a {
    font-family: 'Poppins', sans-serif;
    color: #fff !important;
    font-size: 14px;
}

.new-bottom-main .bottom_info_text {
    font-family: 'Poppins', sans-serif;
    color: #fff !important;
    font-size: 14px;
    font-weight: 300;
}

.bottom_info_text {
    color: #9a9ebe !important;
    margin-bottom: 5px;
}

.bottom-nav-3 .new-bottom-main .footer-container-2 {
    background: rgba(27,36,106,.65);
    position: relative;
}

.footer-container-2 {
    background: #192160;
    padding-top: 20px;
    padding-bottom: 15px;
}

@media (max-width:1199px) {
    .bottom-nav-3 .new-bottom-main::after {
        width: 176px;
        height: 334px;
        top: 0;
    }

    .bottom-nav-3 .new-bottom-main::before {
        width: 310px;
        height: 350px;
    }

    .bottom-nav-3 .bottom_main_row .col {
        max-width: 33.3%;
        flex:0 0 33.3%;
        margin-top: 2rem;
    }

    .bottom-nav-3 .new-bottom-main::after {
        top: 10rem;
    }

    .bottom-nav-3 .new-bottom-main::before {
        display: none;
    }

    .bottom-nav-3 .new-footer-join-newsletter-col {
        margin-top: 2rem !important;
    }

    html body .footer-container-2 {
        margin-top: 0 !important;
    }

    .bottom-nav-3 .new-bottom-main {
        padding-top: 28rem;
    }
}
/* END 1199px */

@media (max-width:910px) {
    .bottom-nav-3 .bottom_main_row .col {
        max-width: 50%;
        flex:0 0 50%;
    }

    .bottom-nav-3 .new-bottom-main {
        padding-top: 36rem;
    }

    .bottom-nav-3 .new-bottom-main::after {
        top:20rem;
    }
}
/* END 910px */

@media (max-width:600px) {
    .bottom-nav-3 .new-bottom-main {
        padding-top: 10rem !important;
        background: no-repeat center top url('../img/bottom-nav-3/bg-footer-mobile.png') !important;
        background-size: cover !important;
    }

    .footer-v3-logo {
        width: 150px;
    }

    .bottom-nav-3 .bottom_main_row .col {
        margin-top: 1rem !important;
    }

    .bottom-nav-3 .new-bottom-main::after {
        top: -4rem;
        width: 97px;
        height: 236px;
        background-image: url('../img/bottom-nav-3/top-right-circle-mobile.png');
    }

    .bottom-nav-3 .new-bottom-main .footer-container-2 {
        background: rgba(0,0,0,0.09);
        padding:2rem 0;
    }

    .bottom-nav-3 .bottom_info_text.mobile {
        display: flex !important;
        flex-flow: column !important;
    }

    .bottom-nav-3 .bottom_info_text.mobile span {
        margin-bottom:1rem;
    }

    .bottom-nav-3 .bottom_info_text.mobile span:last-of-type {
        margin-bottom: 0;
    }

    .bottom-nav-3 .bottom_main_row .col {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .new-footer-join-newsletter-col {
        max-width: 300px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
/* END 600px */