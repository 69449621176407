.top-menu-steps a {
    background-color: #F1F6FB;
    display: flex;
    border-radius: 13px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    padding: 1rem;
    margin: 0 0.5rem;
}

.tms-text {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #A5B4CF;
    font-weight: 500;
}

.top-menu-steps li {
    padding:0;
    margin:0;
}

.tms-menu-end {
    justify-content: flex-end !important;
}

@media (max-width:1199px) {
    .top-menu-steps-mobile a {
        display: flex;
        align-items: center;
    }
    
    .top-menu-steps-mobile .tms-text {
        margin-right: 0.5rem;
    }
}
/* END 1199px */